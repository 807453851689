import Axios, { AxiosError, AxiosResponse } from "axios";
import { Dispatch, AnyAction } from "redux";
import { API_URL } from "~/config";
import { withAuthorizationHeader } from ".";

// ------------------------------------
// Constants
// ------------------------------------
export const OPTIONS_GET_FAILURE = "OPTIONS_GET_FAILURE";
export const OPTIONS_GET_REQUEST = "OPTIONS_GET_REQUEST";
export const OPTIONS_GET_SUCCESS = "OPTIONS_GET_SUCCESS";

export const GET_COUNTRIES_REQUEST = "GET_COUNTRIES_REQUEST";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";

export const GET_LANGUAGES_REQUEST = "GET_LANGUAGES_REQUEST";
export const GET_LANGUAGES_FAILURE = "GET_LANGUAGES_FAILURE";
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";

// ------------------------------------
// Interfaces
// ------------------------------------
export interface IUmbrellaCompany {
  id: number;
  company_name: string;
  order_id: number | null;
}

// ------------------------------------
// Action Creators
// ------------------------------------
function getGlobalOptionsRequest() {
  return { type: OPTIONS_GET_REQUEST };
}

function getGlobalOptionsSuccess(res: AxiosResponse) {
  return { type: OPTIONS_GET_SUCCESS, payload: res.data };
}

function getGlobalOptionsFailure(err: AxiosError) {
  return {
    type: OPTIONS_GET_FAILURE,
    payload: err && err.response && err.response.data,
  };
}

function getCountriesRequest() {
  return { type: GET_COUNTRIES_REQUEST };
}
function getCountriesFailure(err: AxiosError) {
  return {
    type: GET_COUNTRIES_FAILURE,
    payload: err && err.response && err.response.data,
  };
}
function getCountriesSuccess(res: AxiosResponse) {
  return {
    type: GET_COUNTRIES_SUCCESS,
    payload: res.data,
  };
}

function getLanguagesRequest() {
  return { type: GET_LANGUAGES_REQUEST };
}
function getLanguagesFailure(err: AxiosError) {
  return {
    type: GET_LANGUAGES_FAILURE,
    payload: err?.response?.data,
  };
}
function getLanguagesSuccess(res: AxiosResponse) {
  return {
    type: GET_LANGUAGES_SUCCESS,
    payload: res.data,
  };
}

// ------------------------------------
// Actions
// ------------------------------------
const getOptions = () => (dispatch: Dispatch, getState: () => any) => {
  return new Promise(async (resolve, reject) => {
    const token = getState().users.authenticatedUser.token;
    if (token) {
      dispatch(getGlobalOptionsRequest());
      Axios.get(`${API_URL}/api/options`, withAuthorizationHeader(token))
        .then((res) => {
          dispatch(getGlobalOptionsSuccess(res));
          resolve(res);
        })
        .catch((err) => {
          dispatch(getGlobalOptionsFailure(err));
          reject(err);
        });
    }
  });
};

const getCountries = () => (dispatch: Dispatch, getState: () => any) => {
  return new Promise(async (resolve, reject) => {
    const token = getState().users.authenticatedUser.token;
    if (token) {
      try {
        dispatch(getCountriesRequest());
        const res = await Axios.get(`${API_URL}/api/countries`, withAuthorizationHeader(token));
        dispatch(getCountriesSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(getCountriesFailure(err));
        reject(err);
      }
    }
  });
};

const getLanguages = () => (dispatch: Dispatch, getState: () => any) => {
  return new Promise(async (resolve, reject) => {
    const token = getState().users.authenticatedUser.token;
    if (token) {
      try {
        dispatch(getLanguagesRequest());
        const res = await Axios.get(`${API_URL}/api/languages`, withAuthorizationHeader(token));
        dispatch(getLanguagesSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(getLanguagesFailure(err));
        reject(err);
      }
    }
  });
};

export const optionsActions = {
  getOptions,
  getCountries,
  getLanguages,
};

// ------------------------------------
// State
// ------------------------------------
const initialState = {
  errors: [],
  isFetching: false,
  options: null,
  countries: [],
  languages: [],
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case OPTIONS_GET_REQUEST:
    case GET_COUNTRIES_REQUEST:
    case GET_LANGUAGES_REQUEST:
      return { ...state, isFetching: true };

    case OPTIONS_GET_FAILURE:
    case GET_COUNTRIES_FAILURE:
    case GET_LANGUAGES_FAILURE:
      return { ...state, isFetching: false, errors: action.payload };

    case OPTIONS_GET_SUCCESS:
      const refer_talent_popup_settings = action.payload.refer_talent_popup_settings.reduce(
        (acc: { (key: string): string }, { code, value }: { code: string; value: string }) => ({
          ...acc,
          [code]: value,
        }),
        {}
      );

      return { ...state, isFetching: false, options: { ...action.payload, refer_talent_popup_settings } };
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        countries: action.payload.map((country: { name: string; code: string }) => ({
          label: country.name,
          value: country.code,
        })),
      };
    case GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        languages: action.payload,
      };
    default:
      return state;
  }
};
