import Axios from "axios";
import { API_URL } from "~/config";
import { withAuthorizationHeader } from "~/store";

export class PreferencesService {
  static getPreferences = async (token: string) =>
    await Axios.get(`${API_URL}/api/user/current/talent_preferences`, withAuthorizationHeader(token));
  static updatePreferenceById = async (token: string, id: number, value: boolean) =>
    await Axios.put(`${API_URL}/api/user/current/talent_preferences/${id}`, { value }, withAuthorizationHeader(token));
}
