import * as Yup from "yup";
import { yearMaxValueTest, yearMinValueTest } from "~/utils";

export const getGettingPaidSchema = (LtdCompanyTypeId: number) =>
  Yup.object().shape({
    rate: Yup.number().positive().round("round").required("Please, fill in required field"),
    annual_rate: Yup.number().nullable().positive().round("round"),
    registered_tax_country: Yup.string().nullable().min(2, "Please, add at least 2 symbols").max(2, "2 characters max"),
    insurance_number: Yup.string()
      .nullable()
      .when("registered_tax_country", {
        is: "GB",
        then: Yup.string()
          .min(9, "Wrong insurance number format")
          .matches(/[a-z]{2}\d{6}[a-z]/i, "Wrong insurance number format"),
      }),
    country: Yup.string().nullable().min(2, "Please, add at least 2 symbols").max(2, "2 characters max"),
    company_types: Yup.array().of(Yup.number()),
    ir35_types: Yup.array().of(Yup.number()),
    umbrella_companies: Yup.array().of(Yup.string()).max(8),
    company_name: Yup.string()
      .nullable()
      .min(2, "Please, add at least 2 symbols")
      .max(100, "100 characters max")
      .when("company_types", {
        is: (company_types) => company_types.includes(LtdCompanyTypeId),
        then: Yup.string().required("Please, fill in required field"),
      }),
    company_number: Yup.string()
      .nullable()
      .min(2, "Please, add at least 2 symbols")
      .max(100, "100 characters max")
      .when("company_types", {
        is: (company_types) => company_types.includes(LtdCompanyTypeId),
        then: Yup.string().required("Please, fill in required field"),
      }),
    company_registration_year: Yup.string()
      .nullable()
      .length(4)
      .label("Year")
      .test(yearMinValueTest)
      .test(yearMaxValueTest),
    use_personal_address: Yup.boolean(),
    company_country: Yup.string()
      .nullable()
      .min(2, "Please, add at least 2 symbols")
      .max(2, "2 characters max")
      .when("company_types", {
        is: (company_types) => company_types.includes(LtdCompanyTypeId),
        then: Yup.string().required("Please, fill in required field"),
      }),
    company_city: Yup.string()
      .nullable()
      .min(2, "Please, add at least 2 symbols")
      .max(100, "100 characters max")
      .when("company_types", {
        is: (company_types) => company_types.includes(LtdCompanyTypeId),
        then: Yup.string().required("Please, fill in required field"),
      }),
    company_address_1: Yup.string()
      .nullable()
      .min(2, "Please, add at least 2 symbols")
      .max(100, "100 characters max")
      .when("company_types", {
        is: (company_types) => company_types.includes(LtdCompanyTypeId),
        then: Yup.string().required("Please, fill in required field"),
      }),
    company_address_2: Yup.string().nullable().min(2, "Please, add at least 2 symbols").max(100, "100 characters max"),
    company_postcode: Yup.string()
      .nullable()
      .min(3, "Please, add at least 3 symbols")
      .max(16, "Please, make it shorter than 16 symbols")
      .when("company_types", {
        is: (company_types) => company_types.includes(LtdCompanyTypeId),
        then: Yup.string().required("Please, fill in required field"),
      }),
    company_tax_number: Yup.string().nullable().min(2, "Please, add at least 2 symbols").max(100, "100 characters max"),
    insurance_provider: Yup.string()
      .nullable()
      .min(3, "Please, add at least 3 symbols")
      .max(100, "Please, make it shorter than 100 symbols"),
    insurance_expire_at: Yup.string()
      .nullable()
      .min(2, "Please, add at least 2 symbols")
      .max(100, "Please, make it shorter than 100 symbols"),
    equipment_type: Yup.string().nullable().oneOf(["TYPE_PERSONAL", "TYPE_COMPANY", "TYPE_FLEXIBLE", null]),
    substitution_type: Yup.string().nullable().oneOf(["TYPE_YES", "TYPE_LATER", "TYPE_NO", null]),
  });
