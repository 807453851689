import * as Yup from "yup";
import { yupString, yupUrl, yearMinValueTest, yearMaxValueTest } from "~/utils";

export const MAX_MOST_INFLUENTIAL = 3;
export const MAX_SECTORS = 4;
export const MAX_PLATFORMS = 5;

export const noticeDurationOptions = [
  { id: 1, name: "Weeks" },
  { id: 2, name: "Months" },
];

const SkillsManagementSchema = Yup.object().shape({
  id: Yup.number(),
  name: yupString(true),
  order_id: Yup.number(),
});

export const MostInfluentialManagementSchema = Yup.object().shape({
  client_name: yupString(true, null, 100),
  start_year: yupString(true)
    .label("Start Year")
    .length(4, "Start Year must be exactly 4 characters")
    .test(yearMinValueTest),
  end_year: yupString(true)
    .label("Finish Year")
    .length(4, "Finish Year must be exactly 4 characters")
    .test(
      "finish-year-later-or-equal-to-start-year",
      "Finish Year must be later than or equal to Start Year",
      function (endYear: string) {
        const startYear = this.parent.start_year;
        if (!endYear || !startYear) return true;
        return +startYear <= +endYear;
      }
    )
    .test(yearMaxValueTest),
});

export const TheWorkSchema = Yup.object().shape({
  discipline: yupString(),
  specialism_code: Yup.string()
    .required()
    .when("discipline", {
      is: (discipline) => discipline === "DISCIPLINE_BUSINESS" || discipline === "DISCIPLINE_STRATEGY",
      then: Yup.string(),
    }),
  level_code: Yup.string().required().when("discipline", {
    is: "DISCIPLINE_SOCIAL",
    then: Yup.string(),
  }),
  subhead: yupString(false, null, 250),
  skills: Yup.array()
    .of(SkillsManagementSchema)
    .min(1, "At least one Superpower should be assigned")
    .required("Please, fill in required field"),
  sectors: Yup.array().when("discipline", {
    is: "DISCIPLINE_DESIGN",
    then: Yup.array().nullable(),
    otherwise: Yup.array()
      .of(SkillsManagementSchema)
      .min(1, "At least one Sector should be assigned")
      .max(4, "Max 4 Sectors should be assigned")
      .required("Please, fill in required field"),
  }),
  platforms: Yup.array().when("discipline", {
    is: "DISCIPLINE_SOCIAL",
    then: Yup.array()
      .of(SkillsManagementSchema)
      .min(1, "At least one Platform should be assigned")
      .max(5, "Max 5 Platforms should be assigned")
      .required("Please, fill in required field"),
  }),
  description: yupString(true, null, 1200),
  portfolio_url: yupUrl().required("Please, fill in required field"),
  video_url: yupUrl(),
  best_work_url: yupUrl(),
  most_influential: Yup.array().of(MostInfluentialManagementSchema).max(4, "Max 4 Companies should be assigned"),
  working_status_id: Yup.number().nullable(),
  employer: yupString(false, 2, 100),
});

export const TalentResponseSchema = Yup.object().shape({
  rate: Yup.number().min(1).required("Please, fill in required field"),
  reason: yupString(false, 1, 1000),
  subhead: yupString(true),
  description: yupString(true, null, 1200),
  portfolio_url: yupUrl().required("Please, fill in required field"),
});

export const NoticePeriodSchema = Yup.object().shape({
  type: Yup.number()
    .oneOf(noticeDurationOptions.map((type) => type.id))
    .required("Please, fill in required field"),
  duration: Yup.number()
    .integer()
    .positive()
    .typeError("Please enter a valid number")
    .label("Number")
    .min(1)
    .max(99)
    .required("Please, fill in required field"),
});
