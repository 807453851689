import classNames from "classnames";
import React from "react";
import { useDispatch } from "react-redux";
import ProfileForm from "~/components/forms/ProfileForm/ProfileForm";
import { modalActions } from "~/store/modal";
import Modal from "../AbstractModal/AbstractModal";
import "./ProfileModal.scss";

interface Props {
  side: string;
}

export default function ProfileModal(props: Props) {
  const dispatch = useDispatch();

  return (
    <Modal
      className={classNames("ProfileModal", { "no-animate": props._noAnimate })}
      side={props.side}
      title={<h1>Profile</h1>}
      headerLeftButton={
        <button className="mobile-only" type="button" onClick={() => dispatch(modalActions.closeModal())}>
          <i className="icon-back"></i>
        </button>
      }
      headerRightButton={<button className="invisible" type="button"></button>}
    >
      <ProfileForm />
    </Modal>
  );
}
