import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import Loader from "~/components/common/Loader/Loader";
import { Badge } from "primereact/badge";
import { useSelector } from "react-redux";
import { BriefService } from "~/API/BriefService";
import "./ConfirmDates.scss";

interface IDates {
  durationDays: number;
  durationWeekends: number;
}

interface IProps {
  title: any;
  startDate: Moment;
  endDate: Moment;
  isWeekendsIncluded: boolean;
}

const ConfirmDates = (props: IProps) => {
  const { title: Title, startDate, endDate, isWeekendsIncluded } = props;
  const token = useSelector((state: any) => state.users.authenticatedUser.token);
  const [isFetrching, setIsFetrching] = useState(true);
  const initialDates: IDates = { durationDays: 0, durationWeekends: 0 };
  const [dates, setDates] = useState(initialDates);

  useEffect(() => {
    (async () => {
      try {
        const res = await BriefService.briefValidate({
          startDate,
          endDate,
          isWeekendsIncluded,
          token,
        });
        const { duration_days: durationDays, duration_weekends: durationWeekends } = res;
        setDates({ durationDays, durationWeekends });
        setIsFetrching(false);
      } catch (error) {
        console.error("error: ", error);
      }
    })();
    return () => {
      setDates(initialDates);
    };
  }, []);

  return (
    <>
      <header>
        <Title />
      </header>
      <div className="wrp-confirm-dates">
        <h2>
          <small>
            {moment(startDate).format("DD/MM/YYYY")} — {moment(endDate).format("DD/MM/YYYY")}
          </small>
        </h2>
        {isFetrching ? (
          <Loader />
        ) : (
          <div className="BriefDatesDaysCount__stats">
            <div>
              <i className="pi pi-calendar p-mr-4 p-text-secondary p-overlay-badge">
                <Badge value={dates.durationDays} severity="danger"></Badge>
              </i>
              <span>
                Weekdays
                {!isWeekendsIncluded && (
                  <>
                    <br />
                    (weekends excluded)
                  </>
                )}
              </span>
            </div>
            {isWeekendsIncluded && dates.durationWeekends > 0 && (
              <>
                <div>
                  <i className="pi pi-plus"></i>
                </div>
                <div>
                  <i className="pi pi-calendar p-mr-4 p-text-secondary p-overlay-badge">
                    <Badge value={dates.durationWeekends} severity="danger"></Badge>
                  </i>
                  <span>Weekend Days</span>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ConfirmDates;
