import classNames from "classnames";
import { Badge } from "primereact/badge";
import React, { KeyboardEvent, SyntheticEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { conversationActions } from "~/store/chat";
import { IState } from "~/store/reducers";
import { formatCurrency } from "../../utils";
import Outside from "../common/Outside";
import EmojiPanel from "../EmojiPanel/EmojiPanel";
import "./ChatPanel.scss";

const MAX_MESSAGE_LENGTH = 1000;

function ChatPanel() {
  const useThunkDispatch = () => useDispatch<typeof store.dispatch>();
  const dispatch = useThunkDispatch();
  const store = useStore();

  const [caret, setCaret] = useState({
    caretEnd: 0,
    caretStart: 0,
  });
  const inputPrefix = useSelector((state: IState): string | boolean => {
    const { messages } = state.chat;
    return messages.length &&
      messages[messages.length - 1].input_opts &&
      messages[messages.length - 1].input_opts.prefix
      ? messages[messages.length - 1].input_opts.prefix
      : false;
  });
  const inputSanitize = useSelector((state: IState): RegExp | boolean => {
    const { messages } = state.chat;
    return messages.length &&
      messages[messages.length - 1].input_opts &&
      messages[messages.length - 1].input_opts.regexp
      ? new RegExp(messages[messages.length - 1].input_opts.regexp)
      : false;
  });

  const [message, setMessage] = useState("");
  const [isMenuOpen, setMenu] = useState(false);
  const [isEmojiPanelOpen, setEmojiPanel] = useState(false);
  const [isBriefsBadgeShown, setIsBriefsBadgeShown] = useState(true);

  const messageRef = useRef(null);

  const isConnected = useSelector((state: IState) => state.websocket.connected);
  const isInputAllowed = useSelector((state: IState): boolean => {
    const { messages, isDebugMode } = state.chat;
    return isDebugMode ? true : messages.length ? messages[messages.length - 1].is_input_allowed : false;
  });
  const { pendingBriefs } = useSelector((state: IState) => state.users.authenticatedUser);

  useEffect(() => {
    setMessage("");
  }, [isInputAllowed]);

  function handleMessageInput(e: SyntheticEvent) {
    e.stopPropagation();
    const { value } = e.target as HTMLInputElement;
    value !== "\n" && setMessage(value);
  }

  function handleSanitizeInput(e: KeyboardEvent) {
    e.stopPropagation();
    const systemKeys = [
      "Escape",
      "Shift",
      "Control",
      "Enter",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "Backspace",
      "Delete",
      "Alt",
      "ArrowLeft",
    ];

    if (inputSanitize && !systemKeys.includes(e.key) && !`${message}${e.key}`.match(inputSanitize as RegExp)) {
      e.preventDefault();
    }
  }

  function handleMessageSend() {
    if (isConnected && message.length) {
      const text_content = message.trim();
      if (inputPrefix === "£") {
        const thousands = (message.match(/k/g) || []).length;
        const rate = parseFloat(message.replace("k", "")) * (thousands ? 1000 : 1);
        conversationActions.sendMessage({
          text_content: formatCurrency(rate),
          payload: {
            payload: `${rate}`,
          },
        })(dispatch, store);
      } else {
        conversationActions.sendMessage(text_content)(dispatch, store);
      }
      setMessage("");
    }
  }

  function handleMessageSendWithEnter(e: KeyboardEvent) {
    e.stopPropagation();
    if (e.key === "Enter") {
      if (!(e.shiftKey || e.ctrlKey)) {
        handleMessageSend();
      }
    }
  }

  function handleSaveCaret(e: KeyboardEvent) {
    e.stopPropagation();
    const input: HTMLInputElement = e.target as HTMLInputElement;
    setCaret({
      caretEnd: input.selectionEnd || 0,
      caretStart: input.selectionStart || 0,
    });
  }

  function toggleMenu(e: React.MouseEvent) {
    if (e.nativeEvent) {
      e.nativeEvent.stopImmediatePropagation();
      setMenu(!isMenuOpen);
      setEmojiPanel(false);
    } else {
      e.stopPropagation();
      isMenuOpen && setMenu(false);
    }
  }

  function toggleEmojiPanel(e: React.MouseEvent) {
    if (e.nativeEvent) {
      e.nativeEvent.stopImmediatePropagation();
      setEmojiPanel(!isEmojiPanelOpen);
      setMenu(false);
    } else {
      e.stopPropagation();
      isEmojiPanelOpen && setEmojiPanel(false);
    }
  }

  function handleEmoji(emoji: any) {
    if (messageRef && messageRef.current) {
      const start = messageRef.current.selectionStart;
      const end = messageRef.current.selectionEnd;
      setMessage([message.slice(0, start as number), emoji.native, message.slice(end as number)].join(""));
      // Set caret position
      const pos = end - (end - start) + emoji.native.length;
      messageRef.current.focus();
      messageRef.current.setSelectionRange(pos, pos);
      setCaret({
        caretEnd: pos,
        caretStart: pos,
      });
      setEmojiPanel(false);
    }
  }

  function handleMenuItem(
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    text_content: string,
    payload: string = `general persistent menu ${text_content}`
  ) {
    e.stopPropagation();
    conversationActions.sendMessage({
      text_content,
      payload: { payload },
    })(dispatch, store);
    setMenu(false);
  }

  // Update caret position
  useEffect(() => {
    if (messageRef && messageRef.current !== null && caret.caretStart !== 0 && caret.caretEnd !== 0) {
      (messageRef as any).current.setSelectionRange(caret.caretStart, caret.caretEnd);
    }
  }, [caret]);

  useEffect(() => {
    if (messageRef && messageRef.current) {
      if (message !== "") {
        messageRef.current.style.height = "0px";
        const scrollHeight = messageRef.current.scrollHeight;
        messageRef.current.style.height = `${Math.min(scrollHeight, 120)}px`;
      } else {
        messageRef.current.value = "";
        messageRef.current.style.height = "24px";
      }
    }
  }, [message]);

  return (
    <footer className="ChatPanel">
      <Outside className={classNames("emoji-wrapper", { open: isEmojiPanelOpen })} callback={toggleEmojiPanel}>
        <EmojiPanel selectEmoji={handleEmoji} shown={isEmojiPanelOpen} />
      </Outside>
      <div className="wrapper">
        <div className="menu">
          <button className={classNames("btn-menu")} onClick={toggleMenu} type="button">
            <i className="icon-menu p-overlay-badge">
              {!!pendingBriefs && isBriefsBadgeShown && <Badge value={pendingBriefs} severity="danger" />}
            </i>
          </button>
          <Outside className={classNames({ open: isMenuOpen })} callback={toggleMenu}>
            <ul>
              {!!pendingBriefs && (
                <li
                  onClick={(e) => {
                    handleMenuItem(e, "View briefs", "talent pending briefs");
                    setIsBriefsBadgeShown(false);
                  }}
                >
                  {`View briefs (${pendingBriefs})`}
                </li>
              )}
              {/* <li onClick={(e) => handleMenuItem(e, "Options")}>My Options</li> */}
              <li onClick={(e) => handleMenuItem(e, "Help")}>Help</li>
              <li onClick={toggleMenu}>Cancel</li>
            </ul>
          </Outside>
        </div>
        {inputPrefix && <span className="input-prefix">{inputPrefix}</span>}
        <textarea
          autoFocus
          disabled={!isInputAllowed}
          onChange={handleMessageInput}
          onContextMenu={(e: React.MouseEvent) => {
            e.preventDefault();
            return false;
          }}
          onKeyDown={handleSanitizeInput}
          onKeyUp={handleSaveCaret}
          onKeyPress={handleMessageSendWithEnter}
          placeholder={isInputAllowed ? "You can freetype in here now" : "Pls use the button(s)"}
          maxLength={MAX_MESSAGE_LENGTH}
          ref={messageRef}
          value={message}
        />
        {isInputAllowed && (
          <button className={classNames("btn-emoji")} onClick={toggleEmojiPanel} type="button">
            <i className="icon-emoji"></i>
          </button>
        )}
        <button
          disabled={!isConnected || !isInputAllowed}
          className={classNames("btn-send", { disabled: message.length === 0 })}
          onClick={handleMessageSend}
          type="submit"
        >
          SEND
          {!!message.length && (
            <span>
              {MAX_MESSAGE_LENGTH - message.length}/{MAX_MESSAGE_LENGTH}
            </span>
          )}
        </button>
      </div>
    </footer>
  );
}

export default ChatPanel;
