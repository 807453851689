import cn from "classnames";
import { useFormik } from "formik";
import moment from "moment";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Card } from "primereact/card";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GmDropdown from "~/components/common/GmDropdown/GmDropdown";
import GmFileUpload from "~/components/common/GmFileUpload/GmFileUpload";
import GmInputText from "~/components/common/GmInputText/GmInputText";
import GmInputTextarea from "~/components/common/GmInputTextarea/GmInputTextarea";
import Loader from "~/components/common/Loader/Loader";
import useTalent from "~/hooks/useTalent";
import { TalentResponseSchema } from "~/schemas/TheWorkSchema";
import { modalActions } from "~/store/modal";
import { optionsActions } from "~/store/options";
import { usersActions } from "~/store/user";
import { makeUniqUrl, validateFile } from "~/utils";
import "./EditTalentResponseForm.scss";

const CREATIVE_TEAM = "Creative Team";
const NO_REASON_GIVEN = "No Reason Given";

export default function EditTalentResponseForm({
  rate,
  reason,
  noticePeriod,
  briefType,
  submitTrigger,
  setSubmitTrigger,
  edit,
  setEdit,
}: {
  rate: string;
  reason: string;
  noticePeriod: string;
  briefType: string;
  submitTrigger: boolean;
  setSubmitTrigger: any;
  edit: boolean;
  setEdit: any;
}) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { updateTalentCV, deleteTalentCV, isFetching: isCVFetching, updateFlow } = useTalent();

  const { countries: countryOptions, isFetching: isFetchingOptions } = useSelector((state: any) => state.options);
  const {
    authenticatedUser: { talent } = { talent: null },
    isFetching: isFetchingUser,
    workProfile,
    personalProfile,
  } = useSelector((state: any) => state.users);

  const isFetching = isFetchingUser || isFetchingOptions;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      rate,
      reason: reason === NO_REASON_GIVEN ? "" : reason,
      name: talent.name,
      subhead: workProfile.subhead,
      description: workProfile.description,
      country_of_residence: personalProfile.country_of_residence,
      portfolio_url: workProfile.portfolio_url,
      cv_url: "",
      noticePeriod,
    },
    validationSchema: TalentResponseSchema,
    onSubmit: () => {},
  });

  useEffect(() => {
    formik.setFieldValue("cv_url", workProfile.cv_url);
  }, [workProfile.cv_url]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(usersActions.getWorkProfile());
    dispatch(usersActions.getPersonalProfile());
    dispatch(optionsActions.getCountries());
  }, []);

  useEffect(() => {
    if (Object.keys(formik.errors).length !== 0) {
      setSubmitTrigger(false);
      return;
    }
    if (submitTrigger && edit) {
      setEdit(false);
      setSubmitTrigger(false);
    }
    if (submitTrigger && !edit) {
      const {
        description,
        portfolio_url,
        subhead,
        rate,
        country_of_residence,
        reason: inputReason,
        noticePeriod,
      } = formik.values;
      const reason = noReason ? NO_REASON_GIVEN : inputReason;
      Promise.all([
        dispatch(usersActions.updateTalentProfiles({ description, portfolio_url, subhead, country_of_residence })),
        updateFlow({ rate, reason, noticePeriod }, "brief"),
      ]).then(() => {
        dispatch(modalActions.closeModal());
      });
    }
  }, [submitTrigger]);

  const noReason = useMemo(
    () => !formik.values.reason || formik.values.reason === NO_REASON_GIVEN,
    [formik.values.reason]
  );

  const countryLabel = useMemo(() => {
    const selectedCountry = countryOptions.find(
      (item: { value: string; label: string }) => item.value === formik.values.country_of_residence
    );
    return selectedCountry ? selectedCountry.label : "";
  }, [formik.values.country_of_residence, countryOptions]);

  const rateType = useMemo(() => {
    switch (briefType) {
      case "FIXEDTERM":
        return "Pro Rata Annual Salary";
      case "FREELANCE":
        return "Day rate";
      case "PERMANENT":
        return "Salary Expectation";
    }
    return "";
  }, [briefType]);

  function onAddImage(blob: File) {
    dispatch(usersActions.updateImageProfile(blob));
    dispatch(modalActions.closeModal());
  }

  function onUploadCV([file]: [File]) {
    const errors = validateFile(file, {
      size: 10000000,
      types: ["xls", "doc", "pdf"],
    });

    if (errors.length) {
      formik.setFieldError("cv_url", errors.join(", "));
    } else {
      updateTalentCV(file);
    }
  }

  function openModal(e: React.MouseEvent<HTMLElement>, type: string) {
    e.preventDefault();

    dispatch(
      modalActions.openModal(type, {
        side: "right",
        onSubmit: (data: string) => formik.setFieldValue("noticePeriod", data),
        ...(type === "NOTICE_PERIOD_MODAL" &&
          /\d+\s(Week|Month)/.test(formik.values.noticePeriod) && {
            type: formik.values.noticePeriod.indexOf("Week") > 0 ? 1 : 2,
            duration: parseInt(formik.values.noticePeriod),
          }),
        ...(type === "DATE_MODAL" && {
          startDate: moment(formik.values.noticePeriod).isValid() ? formik.values.noticePeriod : null,
          dateFormat: "DD MMMM YYYY",
        }),
      })
    );
  }

  const header = (
    <span
      className="wrp-img"
      onClick={() => {
        dispatch(
          modalActions.openModal("IMAGE_CROP_MODAL", {
            side: "right",
            onSubmit: onAddImage,
          })
        );
      }}
    >
      <img alt="Card" src={makeUniqUrl(workProfile.image_url)} />
      <i className="icon-edit" />
    </span>
  );

  const title = (
    <>
      <i className="icon-edit" onClick={() => setEdit(!edit)} />
      <p>{formik.values.name}</p>
      <p>{formik.values.subhead}</p>
    </>
  );

  const subTitle = (
    <>
      <p>{talent.specialism_name}</p>
      <p>
        {rateType}: £{formik.values.rate}
      </p>
    </>
  );

  const feedbackTitle = talent.specialism === CREATIVE_TEAM ? "why we're right" : "why i'm right";

  const tabs = [
    {
      id: 0,
      title: "More info",
      description: (
        <div className="textWrapper">
          {briefType === "PERMANENT" && (
            <p>
              <b>Notice period:</b> {formik.values.noticePeriod}
            </p>
          )}
          {countryLabel && (
            <p className="country">
              <b>Talent country:</b> {countryLabel}
            </p>
          )}
          <p>{formik.values.description}</p>
        </div>
      ),
    },
    {
      id: 1,
      title: feedbackTitle,
      description: <p className="textWrapper">{noReason ? NO_REASON_GIVEN : formik.values.reason}</p>,
    },
    {
      id: 2,
      title: `Portfolio${formik.values.cv_url ? " / CV" : ""}`,
      description: (
        <>
          <a href={formik.values.portfolio_url} target="_blank">
            <span className="icon-back" /> View portfolio
          </a>
          {formik.values.cv_url && (
            <a href={formik.values.cv_url} target="_blank">
              <span className="icon-back" /> Download CV
            </a>
          )}
        </>
      ),
    },
  ];

  if (isFetching) return <Loader />;

  return (
    <div className="wrp-talents">
      <form>
        <>
          {!edit && (
            <Card
              title={title}
              subTitle={subTitle}
              header={header}
              className={cn(
                (!talent.talent_feedback || talent.talent_feedback === NO_REASON_GIVEN) && "without-one-tab"
              )}
            >
              <Accordion
                activeIndex={activeIndex}
                className="accordion-custom"
                onTabChange={(e: any) => setActiveIndex(e.index)}
              >
                {tabs.map((tab: any) => (
                  <AccordionTab key={tab.id} header={tab.title}>
                    <div className="wrp-description">{tab.description}</div>
                  </AccordionTab>
                ))}
              </Accordion>
            </Card>
          )}
          {edit && (
            <>
              <div className="field">
                <GmInputText label="Talent name" id="name" formik={formik} noField disabled />
              </div>
              <div className="field">
                <GmInputText label="Subhead" id="subhead" formik={formik} noField />
              </div>
              <div className="field">
                <GmInputTextarea label="Bio" id="description" formik={formik} noField />
              </div>
              <div className="field">
                <GmInputTextarea label="Why I'm Right" id="reason" formik={formik} noField />
              </div>
              <div className="field">
                <GmInputText label={rateType} id="rate" formik={formik} noField keyfilter={/[0-9]+/} price />
              </div>
              <div className="field">
                <GmDropdown
                  filter
                  formik={formik}
                  id="country_of_residence"
                  label="Country"
                  noField
                  options={countryOptions}
                />
              </div>
              <div className="field">
                <GmInputText label="Portfolio" id="portfolio_url" formik={formik} noField />
              </div>
              <div className="field">
                {isCVFetching ? (
                  <Loader />
                ) : (
                  <GmFileUpload
                    formik={formik}
                    noField
                    id="cv_url"
                    label="CV"
                    mode="basic"
                    accept=".doc,.docx,.pdf,.xls,.xlsx"
                    auto
                    onChange={onUploadCV}
                    chooseLabel={"Upload new"}
                    onDelete={deleteTalentCV}
                  />
                )}
              </div>
              {briefType === "PERMANENT" && (
                <div className="field">
                  <GmInputText id="noticePeriod" label="Notice / Start Date" disabled formik={formik} noField />
                </div>
              )}
              {briefType === "PERMANENT" && (
                <div className="field">
                  <div className="SetNoticePeriod">
                    <a href="#" onClick={(e) => openModal(e, "NOTICE_PERIOD_MODAL")}>
                      <span className="icon-back" /> Set Minimum Notice Period
                    </a>
                    <a href="#" onClick={(e) => openModal(e, "DATE_MODAL")}>
                      <span className="icon-back" /> Choose Ideal Start Date
                    </a>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        formik.setFieldValue("noticePeriod", "No Starting Requirements");
                      }}
                    >
                      <span className="icon-back" /> No Starting Requirements
                    </a>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      </form>
    </div>
  );
}
