import classNames from "classnames";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPopup } from "../../store/toasts";
import { IState } from "../../store/reducers";
import "./Toaster.scss";

export default function Popup() {
  const dispatch = useDispatch();
  const popup = useSelector((state: IState) => state.toasts.popup);

  const buttons = (popup && popup.buttons) ?? [
    {
      text: "Got It",
    },
  ];

  useEffect(() => {
    const closePopup = ({ key }: { key: string }) => {
      if (key === "Escape") {
        document.removeEventListener("keyup", closePopup, false);
        dispatch(setPopup());
      }
    };

    if (popup) {
      document.addEventListener("keyup", closePopup, false);
    } else {
      document.removeEventListener("keyup", closePopup, false);
    }
  }, [popup]);

  return popup ? (
    <div className="popup-container">
      <div className="container">
        <div className="popup">
          {typeof popup.content === "string" ? (
            <div className="content" dangerouslySetInnerHTML={{ __html: popup.content }} />
          ) : (
            <div className="content">{popup.content}</div>
          )}
          <footer>
            {buttons.length > 0 && (
              <div className="buttons">
                {buttons.map(({ text, callback }: { text: string; callback: any }, key: number) => (
                  <button
                    key={key}
                    onClick={() => {
                      callback && callback();
                      dispatch(setPopup());
                    }}
                    className={classNames({ two: popup.buttons ? popup.buttons.length > 1 : false })}
                  >
                    {text}
                  </button>
                ))}
              </div>
            )}
          </footer>
        </div>
      </div>
    </div>
  ) : null;
}
