import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { usersActions } from "~/store/user";
import { LOGIN_URL } from "~/config";

const Authorization = (props: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      if (props.otp) {
        try {
          localStorage.removeItem("user");
          localStorage.removeItem("permissions");
          const res = await dispatch(usersActions.loginUserByOtp(props.otp));

          if (res && res.status === 200) {
            location.href = localStorage.getItem("origin") || "/";
            localStorage.removeItem("origin");
          }
        } catch (err) {
          location.href = LOGIN_URL;
        }
      }
    })();
  }, []);
  return null;
};

export default withRouter(Authorization);
