import React, { ChangeEventHandler, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import ItemSelector from "../../ItemSelector/ItemSelector";
import GmDropdown from "~/components/common/GmDropdown/GmDropdown";
import CurrentEmployerSelect from "~/components/common/CurrentEmployerSelect/CurrentEmployerSelect";
import { FormikProps } from "formik";
import GmInputNumber from "~/components/common/GmInputNumber/GmInputNumber";

type SelectItemProps = {
  items: any;
  maxItems?: number;
  selected?: any;
  onSave: any;
  selectorStep?: number;
  onStepChange?: (step: number) => void;
};

export const Slide1 = function () {
  return (
    <div className="slide slide-1">
      <h1>Welcome to Genie!</h1>
      <p>
        Let's take a minute to create your profile, so we can start matching you to awesome briefs. This can be updated
        at any time in your profile section.
      </p>
      <p>
        <i className="icon-user"></i>
      </p>
    </div>
  );
};

export const Slide2 = function (props: SelectItemProps) {
  const [step, setStep] = useState<number>(1);
  return (
    <div className="slide slide-2">
      <h1>Your key skills</h1>
      <ItemSelector
        {...props}
        itemName="Skill"
        prioritize={true}
        onStepChange={(step) => {
          setStep(step);
          props.onStepChange && props.onStepChange(step);
        }}
        text={
          step === 1 ? (
            <p>
              What are your top superpowers (skills)?
              <br />
              Choose all that apply to you from the list below, then use the prioritise button to order correctly.
            </p>
          ) : (
            <p>
              Prioritise your selected skills.
              <br />
              Drag the most important to the top.
            </p>
          )
        }
      />
    </div>
  );
};

export const Slide3 = function (props: SelectItemProps) {
  const [step, setStep] = useState<number>(1);
  return (
    <div className="slide slide-3">
      <h1>Which sectors do you work in?</h1>
      <ItemSelector
        {...props}
        maxItems={4}
        prioritize
        itemName="Sector"
        onStepChange={(step) => {
          setStep(step);
          props.onStepChange && props.onStepChange(step);
        }}
        text={
          step === 1 ? (
            <p>Choose the sectors you have experience in (max 4) and use the prioritise button to order correctly.</p>
          ) : (
            <p>
              Prioritise your selected sectors.
              <br />
              Drag the most important to the top.
            </p>
          )
        }
      />
    </div>
  );
};

export const Slide4 = function (props: SelectItemProps) {
  return (
    <div className="slide slide-4">
      <h1>Platform Expertise</h1>
      <ItemSelector
        {...props}
        maxItems={5}
        prioritize
        itemName="Platform"
        text={
          <p>
            You've probably worked across all platforms but which are your top?
            <br />
            (max of 5)... and prioritise using the button at the bottom please!
          </p>
        }
      />
    </div>
  );
};

export const Slide5 = function ({ onChangeAbout }: { onChangeAbout: ChangeEventHandler<HTMLTextAreaElement> }) {
  return (
    <div className="slide slide-5">
      <h1>Your bio</h1>
      <p>
        Please provide a brief description of your skills and experience to date. This can be similar to your LinkedIn
        bio.
        <br />A well crafted bio can increase your chance of being booked by 50%.
      </p>
      <p className="aboutDescription">
        (write this from both of you if in a creative team)
        <br />
        eg. I have been working in the industry with many agencies and brands on award winning global work. I pride
        myself on my creative and professional approach along with a can do attitude. The work I have done that really
        showcases my expertise is.......
      </p>
      <InputTextarea
        onChange={onChangeAbout}
        autoResize={false}
        maxLength={1200}
        placeholder={`(and if you have a password for your portfolio add it at the end of your paragraph)`}
      />
    </div>
  );
};

export const Slide6 = function ({
  formik,
  currentWorkingStatusCode,
  workingStatuses,
}: {
  formik: FormikProps<any>;
  currentWorkingStatusCode: string;
  workingStatuses: { value: number; label: string }[];
}) {
  const isEmployerPickerShown =
    currentWorkingStatusCode === "PERMANENT" || currentWorkingStatusCode === "FREELANCING_AND_PERMANENT";

  return (
    <div className="slide slide-6">
      <h1>Current working status</h1>
      <p>
        Please add your current working status below.
        <br />
        <br />
        If you are in a permanent position, please add your current employer. You can always update this setting in
        <strong> The Work</strong> section of your profile
      </p>
      <ul className="currentEmployerForm">
        <li>
          <GmDropdown
            formik={formik}
            id="working_status_id"
            label="Current Working Status"
            noField
            options={workingStatuses}
          />
        </li>
        <li>{isEmployerPickerShown && <CurrentEmployerSelect formik={formik} />}</li>
      </ul>
    </div>
  );
};
export const Slide7 = function ({ briefPreferences }: { briefPreferences: JSX.Element[] | undefined }) {
  return (
    <div className="slide slide-7">
      <h1>Brief Preferences</h1>
      <p>
        Please select the type of briefs you wish to receive.
        <br />
        You must select at least one
      </p>
      <div className="briefPreferencesForm">{briefPreferences}</div>
    </div>
  );
};

export const Slide8 = function ({ formik }: { formik: FormikProps<any> }) {
  return (
    <div className="slide slide-8">
      <h1>Typical Day Rate</h1>
      <p>
        This will only be an indication of your typical day rate to help our matching and can be updated at any time.
        This will never be shared without your <i>consent</i>. It can be updated at any time from the Getting Paid
        section of your profile. You will have the opportunity to propose a custom amount each time you respond to a new
        brief.
      </p>
      <div className="field">
        <GmInputNumber
          autoFocus
          required
          noField
          formik={formik}
          id="rate"
          placeholder="E.g. £500"
          mode="currency"
          currency="GBP"
          max={1000000}
          maxFractionDigits={0}
        />
      </div>
    </div>
  );
};

export const Slide9 = function ({ formik }: { formik: FormikProps<any> }) {
  return (
    <div className="slide slide-9">
      <h1>Annual Salary Expectation</h1>
      <p>
        This will only be an indication of your annual salary expectation to help our matching for permanent and fixed
        term opportunities. We will never share this information without your <i>consent</i>. It can be updated at any
        time from the Getting Paid section of your profile. You will have the opportunity to propose a custom amount
        each time you respond to a new brief.
      </p>
      <div className="field">
        <GmInputNumber
          autoFocus
          required
          noField
          formik={formik}
          id="annual_rate"
          placeholder="E.g. £50'000"
          mode="currency"
          currency="GBP"
          max={1000000}
          maxFractionDigits={0}
        />
      </div>
    </div>
  );
};

export const Slide10 = function ({ skipOnboarding }: { skipOnboarding: boolean }) {
  return (
    <div className="slide slide-10">
      <h1>Excellent, all done!</h1>
      {!skipOnboarding && <p>You're now matchable to awesome briefs from world leading companies.</p>}
      {skipOnboarding && <p>Someone from your team has already started the onboarding.</p>}
      <p>Remember, you can click on your profile section at anytime to sharpen it further. Such as:</p>
      <ul>
        <li>Adding an image of some work that you're proud of</li>
        <li>Personalising your headline</li>
      </ul>
      <p>Make sure your notifications are turned on, so you don't miss any opportunities.</p>
    </div>
  );
};
