import React, { useMemo } from "react";
import { IAttachment, InvoiceAttachTypes, InvoiceStatus } from "~/API/InvoiceService";
import { Button } from "primereact/button";

export default function RowPDF({ status, attachments }: { status?: InvoiceStatus; attachments: IAttachment[] }) {
  if (!status || !["PAID", "AUTHORISED"].includes(status)) return null;

  const url = useMemo(
    () =>
      attachments?.find(({ type }: { type: InvoiceAttachTypes }) => type === InvoiceAttachTypes.TYPE_INVOICE_TALENT_PDF)
        ?.url,
    [attachments]
  );

  if (!url) return null;

  return (
    <Button
      icon="pi pi-file-pdf"
      className="p-button-text"
      onClick={(event) => {
        event.stopPropagation();
        window.open(url, "_blank");
      }}
    />
  );
}
