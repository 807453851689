import React from "react";
import { createContext, useContext } from "react";
import useTalent, { ITalentContext } from "~/hooks/useTalent";

export const TalentContext = createContext(null as ITalentContext | null);

export const useTalentContext = () => {
  const context = useContext(TalentContext);
  if (!context) {
    throw new Error("useTalentContext must be used within a TalentContextProvider");
  }
  return context;
};

const TalentContextProvider = ({ children }: any) => {
  return (
    <TalentContext.Provider
      value={{
        ...useTalent(),
      }}
    >
      {children}
    </TalentContext.Provider>
  );
};

export default TalentContextProvider;
