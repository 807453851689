import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import { IInvoice, InvoiceAttachTypes } from "~/API/InvoiceService";
import { LINE_ITEM_UNIT_TYPES } from "~/components/forms/InvoiceForm/LineItemForm";
import { formatCurrency } from "~/utils";
import { Message } from "primereact/message";
import last from "lodash/last";
import { extractFileName } from "~/utils";
import "./InvoiceDetails.scss";
import { getIR35InsideDisclaimer } from "~/config";

export default function InvoiceDetails({ invoice }: { invoice: IInvoice }) {
  const { subtotal, total_tax, total, line_items: lineItems, notes = "", attachments, disputes, status } = invoice;
  const dispute = last(disputes);

  return (
    <>
      {invoice.booking?.ir35_code === "IR35_INSIDE" && (
        <div className="disclaimer-wrapper">
          <b className="red-bordered" dangerouslySetInnerHTML={{ __html: getIR35InsideDisclaimer(invoice) }} />
        </div>
      )}
      <DataTable autoLayout={true} className="InvoiceDetails__table" value={(lineItems ?? []) as any[]}>
        <Column field="description" header="Description" sortable={false} />
        <Column
          field="unit_type"
          header="Unit"
          sortable={false}
          body={(rowData: any) => (
            <span> {(LINE_ITEM_UNIT_TYPES.find(({ value }) => value === rowData.unit_type) || {}).label}</span>
          )}
        />
        <Column
          field="unit_amount"
          header="Amount"
          sortable={false}
          body={(rowData: any) => <span> £{rowData.unit_amount}</span>}
        />
        <Column field="quantity" header="Quantity" sortable={false} body={(rowData: any) => rowData.quantity} />
        <Column
          field="start_date"
          header="Start date"
          sortable={false}
          body={(rowData: any) => (
            <span> {rowData.start_date ? moment(rowData.start_date).format("MMM DD, YYYY") : "N/A"}</span>
          )}
        />
        <Column
          field="end_date"
          header="End date"
          sortable={false}
          body={(rowData: any) => (
            <span> {rowData.end_date ? moment(rowData.end_date).format("MMM DD, YYYY") : "N/A"}</span>
          )}
        />
        <Column
          field="line_amount"
          header="Total"
          sortable={false}
          body={(rowData: any) => <span> {formatCurrency(rowData.line_amount)}</span>}
        />
      </DataTable>
      <div className="grid">
        <div className="notes md:col-8">
          {!!notes && (
            <>
              <h4>Notes</h4>
              <InputTextarea disabled autoResize value={notes} />
            </>
          )}
          {!!attachments &&
            attachments.length > 0 &&
            attachments.some(({ type }) => type === InvoiceAttachTypes.TYPE_INVOICE_ATTACH) && (
              <>
                <h4>Attachments</h4>
                {attachments.map(({ url, id, type }) =>
                  type === InvoiceAttachTypes.TYPE_INVOICE_ATTACH ? (
                    <div key={id} className="attachments">
                      <i className="pi pi-paperclip" />
                      <a href={url} target="_blank" className="attachments__name">
                        {extractFileName(url)}
                      </a>
                    </div>
                  ) : null
                )}
              </>
            )}
          {status === "DISPUTED" && !!dispute && (
            <div className="InvoiceDetails__table__dispute">
              <h4>Dispute reason</h4>
              <Message
                severity="warn"
                content={
                  <>
                    <label>{moment(dispute.created_at).format("MMM DD, YYYY")}</label>
                    <span>{dispute.description}</span>
                  </>
                }
              />
            </div>
          )}
        </div>
        <div className="total-amount md:col-4">
          <ul>
            <li>
              <p>Subtotal:</p>
              <p>{formatCurrency(subtotal)}</p>
            </li>
            <li>
              <p>Total VAT, 20%:</p>
              <p>{formatCurrency(total_tax)}</p>
            </li>
            <li>
              <p>Total:</p>
              <p>{formatCurrency(total)}</p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
