import React, { Dispatch, SetStateAction, useEffect } from "react";
import Modal from "../AbstractModal/AbstractModal";
import "./PreferencesModal.scss";
import usePreferences, { IPreference } from "~/hooks/usePreferences";
import { InputSwitch } from "primereact/inputswitch";
import Loader from "~/components/common/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setPopup } from "~/store/toasts";

export enum preferenceTypes {
  TYPE_BRIEF_AND_NOTIFICATION = "TYPE_BRIEF_AND_NOTIFICATION",
  TYPE_SMS = "TYPE_SMS",
  TYPE_MARKETING = "TYPE_MARKETING",
}
export enum NotificationTypes {
  BRIEF_PREFERENCES = "BRIEF_PREFERENCES",
  NOTIFICATION_PREFERENCES = "NOTIFICATION_PREFERENCES",
}
export enum preferenceCodes {
  DAY_RATE = "DAY_RATE",
  FIXED_TERM = "FIXED_TERM",
  PERMANENT = "PERMANENT",
}

export default function PreferencesModal() {
  const { isFetching, getPreferences, preferences, setPreferences, updatePreferenceById } = usePreferences();
  const modals = useSelector((state: any) => state.modal.modals);
  const modalType = modals[0].modalProps.type;

  useEffect(() => {
    getPreferences();
  }, []);

  const renderPreference = (preference: IPreference) => (
    <Preference
      key={preference.id}
      preferences={preferences}
      preference={preference}
      updatePreferenceById={updatePreferenceById}
      setPreferences={setPreferences}
    />
  );

  return (
    <Modal
      askOnClose
      backModal="PROFILE_MODAL"
      className="ProfileModal NotificationsModal no-animate"
      side="left"
      title={<h1>{modalType === NotificationTypes.BRIEF_PREFERENCES ? "Brief Preferences" : "Notifications"}</h1>}
      headerRightButton={<button className="invisible" type="button" />}
    >
      {isFetching ? (
        <Loader />
      ) : (
        <form className="Preferences">
          {modalType === NotificationTypes.BRIEF_PREFERENCES && (
            <>
              <b>
                Brief Types
                <small>Please select the type of briefs you want to receive</small>
              </b>
              {preferences
                ?.filter((preference) => preference.type === preferenceTypes.TYPE_BRIEF_AND_NOTIFICATION)
                ?.map(renderPreference)}
            </>
          )}
          {modalType === NotificationTypes.NOTIFICATION_PREFERENCES && (
            <>
              <b>SMS Preferences</b>
              {preferences?.filter((preference) => preference.type === preferenceTypes.TYPE_SMS)?.map(renderPreference)}
              <hr />
              <b>Marketing Notification Preferences</b>
              {preferences
                ?.filter((preference) => preference.type === preferenceTypes.TYPE_MARKETING)
                ?.map(renderPreference)}
            </>
          )}
        </form>
      )}
    </Modal>
  );
}

export function Preference({
  preferences,
  preference,
  updatePreferenceById,
  setPreferences,
}: {
  preferences: IPreference[] | null;
  preference: IPreference;
  updatePreferenceById: ({ id, value }: { id: number; value: boolean }) => void;
  setPreferences: Dispatch<SetStateAction<IPreference[] | null>>;
}) {
  const dispatch = useDispatch();

  const handleChange = (preference: IPreference, currentValue: boolean) => {
    const { id: currentId, type } = preference;
    const ifNoneBriefTypeSelected =
      type === preferenceTypes.TYPE_BRIEF_AND_NOTIFICATION &&
      currentValue === false &&
      preferences?.filter(({ type, value }) => type === preferenceTypes.TYPE_BRIEF_AND_NOTIFICATION && value === true)
        .length === 1;

    if (ifNoneBriefTypeSelected) {
      dispatch(
        setPopup({
          content: (
            <>
              <i className="pi pi-exclamation-triangle" />
              <p>
                You have opted out of receiving all brief types. This means you will no longer receive any briefs from
                Genie.
              </p>
            </>
          ),
        })
      );
    }

    setPreferences((configuration: IPreference[] | null) => {
      return (
        configuration &&
        configuration.map((el: IPreference) => ({
          ...el,
          value: el.id === currentId ? currentValue : el.value,
        }))
      );
    });

    updatePreferenceById({ id: currentId, value: currentValue });
  };

  return (
    <div className="field" key={preference.id}>
      <InputSwitch
        checked={preference.value}
        id={preference.title}
        onChange={(e) => handleChange(preference, e.value)}
      />
      <label>
        <p>{preference.title}</p>
        <small className="description">{preference.description}</small>
      </label>
    </div>
  );
}
