import React from "react";
import cn from "classnames";
import { checkBeforeDueDate, checkIsCurrentDay } from "../helpers";

export default function RowDueDate({ dueDate, status }: { dueDate: string; status: string }) {
  const isBeforeDueDate = checkBeforeDueDate(dueDate);
  const isCurrentDay = checkIsCurrentDay(dueDate);
  const isOverdueStatus = status === "AUTHORISED" && isBeforeDueDate && !isCurrentDay;

  return <span className={cn(isOverdueStatus && "due-date")}>{dueDate}</span>;
}
