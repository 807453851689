import axios from "axios";
import get from "lodash/get";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IBankProfile, IInvoice, InvoiceService } from "~/API/InvoiceService";
import { IState } from "~/store/reducers";
import { setToasts, setPopup, TOAST_ERROR_MESSAGE, TOAST_SUCCESS_MESSAGE } from "~/store/toasts";
import { modalActions } from "~/store/modal";

export enum BankProfileTypes {
  LOCAL = "LOCAL",
  INTERNATIONAL = "INTERNATIONAL",
}

const useInvoice = () => {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [isBankProfileFetching, setIsBankProfileFetching] = useState(false);
  const [invoices, setInvoices] = useState<IInvoice[] | null>(null);
  const [invoice, setInvoice] = useState<IInvoice | null>(null);
  const [bankProfile, setBankProfile] = useState<IBankProfile | null>(null);
  const [validatedInvoice, setValidatedInvoice] = useState<IInvoice | null>(null);
  const [isValidatedInvoiceFetching, setIsValidatedInvoiceFetching] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const token = useSelector((state: IState) => state.users.authenticatedUser.token);

  const getInvoices = async (params: { [key: string]: any }) => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await InvoiceService.getInvoices(token, params);
        if (res.status === 200) {
          const { data, total } = res.data;

          setInvoices(data);
          setTotal(total);
        }
      } catch (err) {
        dispatch(setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      } finally {
        setIsFetching(false);
      }
    }
  };

  const getInvoice = async (id: number) => {
    if (token) {
      try {
        setIsFetching(true);
        const { status, data } = await InvoiceService.getInvoice(token, id);
        if (status === 200) {
          setInvoice(data);
        }
      } catch (err) {
        dispatch(setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      } finally {
        setIsFetching(false);
      }
    }
  };

  const postInvoice = async (booking_id: number, invoice: IInvoice): Promise<{ status: number; id?: number }> => {
    if (token) {
      try {
        setIsFetching(true);
        const { status, data } = await InvoiceService.postInvoice(booking_id, invoice, token);
        status === 200 && dispatch(setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
        return { status, id: data.id };
      } catch (err) {
        dispatch(setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
        if (axios.isAxiosError(err)) return { status: err.response?.status as number };
      } finally {
        setIsFetching(false);
      }
    }

    return { status: 400 };
  };

  const putInvoice = async (invoice: any): Promise<number> => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await InvoiceService.putInvoice(invoice, token);
        if (res.status === 200) {
          setInvoices((invoices || []).map((invoice) => (invoice.id === res.data.id ? res.data : invoice)));
          dispatch(setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
        }
        return res.status;
      } catch (err) {
        dispatch(setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
        if (axios.isAxiosError(err)) return err.response?.status as number;
      } finally {
        setIsFetching(false);
      }
    }

    return 400;
  };

  const uploadAttachments = async (id: number, files: File[]) => {
    if (token) {
      try {
        setIsFetching(true);
        return await Promise.allSettled(
          files.map(async (file: File) => {
            try {
              await InvoiceService.uploadAttachment(id, file, token);
              Promise.resolve();
            } catch (err) {
              const message = get(err, "response.data.message", false);
              dispatch(setToasts([{ severity: "error", summary: "", detail: message || TOAST_ERROR_MESSAGE }]));
              Promise.reject(message);
            }
          })
        );
      } catch (err) {
        Promise.reject();
      } finally {
        setIsFetching(false);
      }
    }
  };

  const deleteAttachment = async (invoiceId: number, fileId: number) => {
    if (token) {
      try {
        setIsFetching(true);
        const { status } = await InvoiceService.deleteAttachment(invoiceId, fileId, token);

        status === 200 && dispatch(setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
        return status;
      } catch (err) {
        dispatch(setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
        if (axios.isAxiosError(err)) return err.response?.status as number;
      } finally {
        setIsFetching(false);
      }
    }

    return 400;
  };

  const getBankProfile = async () => {
    if (token) {
      try {
        setIsBankProfileFetching(true);
        const res = await InvoiceService.getBankProfile(token);
        res.status === 200 && setBankProfile(res.data);
        return res.data;
      } catch (err) {
        dispatch(setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      } finally {
        setIsBankProfileFetching(false);
      }
    }
  };

  const updateBankProfile = async (bankProfile: IBankProfile) => {
    if (token) {
      try {
        setIsBankProfileFetching(true);
        const res = await InvoiceService.updateBankProfile(token, bankProfile);
        if (res.status === 200) {
          setBankProfile(res.data);
          dispatch(setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
        }
      } catch (err) {
        dispatch(setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      } finally {
        setIsBankProfileFetching(false);
      }
    }
  };

  const validateBankProfile = async (callback: () => void) => {
    const bankProfile = await getBankProfile();
    const { type, bank_account_name, bank_account_number, sort_code, global_account_name, iban } = bankProfile || {};
    const isLocalBankValid =
      type === BankProfileTypes.LOCAL && !!bank_account_name && !!bank_account_number && !!sort_code;
    const isInternationalBankValid = type === BankProfileTypes.INTERNATIONAL && !!global_account_name && !!iban;
    if (isLocalBankValid || isInternationalBankValid) {
      callback && callback();
      return;
    }
    dispatch(
      setPopup({
        content:
          "<h3>Information Required</h3>" +
          "We must have your relevant bank details before you can generate your invoice/timesheet. " +
          "Please go to the Banking information section of your profile and enter the relevant information.",
        buttons: [
          {
            text: "Dismiss",
          },
          {
            text: "Go to banking information",
            callback: () => {
              dispatch(
                modalActions.openModal("BANKING_INFORMATION_MODAL", {
                  _onlyOne: true,
                  side: "left",
                })
              );
            },
          },
        ],
      })
    );
  };

  const validateInsuranceNumber = (ir35Code: string, insuranceNumber: string): boolean => {
    if (ir35Code !== "IR35_INSIDE" || !!insuranceNumber) {
      return true;
    }

    dispatch(
      setPopup({
        content: `<h2>Information required to proceed</h2>
        <p>Your national insurance number is required in order to generate invoices for Inside IR35 bookings.</p>
        <p>Please go to the Getting Paid section of your profile to add this information.</p>`,
        buttons: [
          { text: "Cancel" },
          {
            text: "Go to Getting Paid",
            callback: () => {
              dispatch(
                modalActions.openModal("GETTING_PAID_MODAL", {
                  _onlyOne: true,
                  side: "left",
                  scrollTo: "insurance_number_field",
                })
              );
            },
          },
        ],
      })
    );

    return false;
  };

  const getValidatedInvoice = async (bookingId: number, invoice: IInvoice) => {
    if (token) {
      try {
        setIsValidatedInvoiceFetching(true);
        const res = await InvoiceService.getValidatedInvoice(token, bookingId, invoice);
        res.status === 200 && setValidatedInvoice(res.data);

        return res.data as IInvoice;
      } catch (err) {
        dispatch(setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      } finally {
        setIsValidatedInvoiceFetching(false);
      }
    }
  };

  return {
    isFetching,
    isBankProfileFetching,
    invoices,
    invoice,
    total,
    bankProfile,
    getInvoices,
    getInvoice,
    setInvoices,
    postInvoice,
    putInvoice,
    uploadAttachments,
    deleteAttachment,
    getBankProfile,
    updateBankProfile,
    validateBankProfile,
    validateInsuranceNumber,
    getValidatedInvoice,
    validatedInvoice,
    setValidatedInvoice,
    isValidatedInvoiceFetching,
  };
};

export default useInvoice;
