import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "~/components/common/Loader/Loader";
import TheWorkForm from "~/components/forms/TheWorkForm/TheWorkForm";
import { modalActions } from "~/store/modal";
import { usersActions } from "~/store/user";
import Modal from "../AbstractModal/AbstractModal";
import "../ProfileModal/ProfileModal.scss";

export default function TheWorkModal() {
  const dispatch = useDispatch();
  const isUserProfileFetching: boolean = useSelector((state: any) => state.users.isFetching);
  const [screen, setScreen] = useState({
    buttons: [
      {
        className: "primary",
        text: "Update",
        onClick: () => setScreen({ ...screen, submit: true }),
      },
    ],
    loader: [
      {
        className: "primary loader",
        text: <Loader />,
      },
    ],
    submit: false,
    title: "The Work",
    touched: false,
  });

  function onUpdateWorkProfile(data: any) {
    let form = { ...data };

    delete form.discipline;
    delete form.image_url;

    const skills = form.skills
      .filter((skill: any) => skill.id > 0)
      .map((skill: any) => ({
        id: skill.id,
        order_id: skill.order_id,
      }));
    const custom_skills = form.skills
      .filter((skill: any) => skill.id < 1)
      .map((skill: any) => ({
        name: skill.name,
      }));
    const sectors = (form.sectors || [])
      .filter((sector: any) => sector.id > 0)
      .map((sector: any) => ({
        id: sector.id,
        order_id: sector.order_id,
      }));
    const custom_sectors = (form.sectors || [])
      .filter((sector: any) => sector.id < 1)
      .map((sector: any) => sector.name);
    const platforms = (form.platforms || [])
      .filter((platform: any) => platform.id > 0)
      .map((platform: any) => ({
        id: platform.id,
        order_id: platform.order_id,
      }));
    const custom_platforms = (form.platforms || [])
      .filter((platform: any) => platform.id < 1)
      .map((platform: any) => platform.name);

    form = {
      ...form,
      custom_sectors,
      custom_skills,
      custom_platforms,
      skills,
      sectors,
      platforms,
    };

    try {
      dispatch(usersActions.updateTalentProfiles(form))
        .then(onBack)
        .catch(() => setScreen({ ...screen, submit: false }));
    } catch (err) {
      setScreen({ ...screen, submit: false });
    }
  }

  function onBack() {
    dispatch(modalActions.openModal("USER_PROFILE_MODAL", { _onlyOne: true, side: "left" }));
  }

  return (
    <Modal
      askOnClose
      backModal="USER_PROFILE_MODAL"
      className="ProfileModal TheWorkModal no-animate"
      buttons={isUserProfileFetching ? screen.loader : screen.buttons}
      shouldIAsk={screen.touched}
      side="left"
      title={<h1>The Work</h1>}
      headerRightButton={<button className="invisible" type="button"></button>}
    >
      <TheWorkForm
        onSubmit={onUpdateWorkProfile}
        onTouch={(touched: boolean) => setScreen({ ...screen, touched })}
        onValidationFail={() => setScreen({ ...screen, submit: false })}
        submitTrigger={screen.submit}
      />
    </Modal>
  );
}
