import classNames from "classnames";
import Lottie from "lottie-react";
import moment from "moment";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import LogoIcon from "~/assets/genie-logo.svg";
import { API_PLACEHOLDER, API_URL } from "~/config";
import { conversationActions, IMessage, IQuickReply } from "~/store/chat";
import { modalActions } from "~/store/modal";
import { IState } from "~/store/reducers";
import { setPopup } from "~/store/toasts";
import { usersActions } from "~/store/user";
import { getModalProps, getParameterByName, linkWithProtocol, parseModalUrl } from "~/utils";
import Confetti from "../../assets/confetti.json";
import BriefCardPermanent, { IBriefCardPermanent } from "../BriefCardPermanent/BriefCardPermanent";
import Typing from "../common/Typing";
import EditTalentResponse from "../EditTalentResponse/EditTalentResponse";
import LoadMoreButton from "../LoadMoreButton/LoadMoreButton";
import OpenModalButton from "../OpenModalButton/OpenModalButton";
import BriefResponseCard, { IResponseCard } from "../ResponseCard/BriefResponseCard";
import ResponseCard from "../ResponseCard/ResponseCard";
import SelectDateButton from "../SelectDateButton/SelectDateButton";
import SelectSkillsButton from "../SelectSkillsButton/SelectSkillsButton";
import SelectUmbrellaCompany from "../SelectUmbrellaCompany/SelectUmbrellaCompany";
import Attachments from "./Attachments";
import "./Chat.scss";
import Day from "./Day";
import linkifyHtml from "linkify-html";
import parse, { domToReact } from "html-react-parser";

const TYPING_ONE_CHAR_TIME = 15; //milliseconds
const TYPING_ANIMATION_DELAY = 500; //milliseconds
const ANIMATION_PAUSE_CHANCE = 0.16; // 0 - 1 range
const MIN_TEXT_LENGTH_TO_PAUSE_ANIMATION = 120;

const REQUIRED_COMPANY_TYPES = ["COMPANY_LTD", "COMPANY_UMBRELLA", "COMPANY_NON_UK_DOMICILED"];

interface ICompany {
  id: number;
  name: string;
  code: string;
}

export default function Chat() {
  let setTypingFalseId: number | undefined;
  let setDelayFalseId: number | undefined;
  let setDelayTrueId: number | undefined;

  const lottieRef: MutableRefObject<any> = useRef();
  const messagesEnd: MutableRefObject<any> = useRef();
  const loadMoreMessages: MutableRefObject<any> = useRef();

  const user = useSelector((state: IState) => state.users);
  const chat = useSelector((state: IState) => state.chat.messages);
  const connected = useSelector((state: IState) => state.websocket.connected);
  const isFetching = useSelector((state: IState) => state.chat.isFetching);
  const totalMessages = useSelector((state: IState) => state.chat.totalMessages);

  const [doNotScroll, setDoNotScroll] = useState(false);
  const [firstChatRender, setFirstChatRender] = useState(true);
  const [animation, setAnimation] = useState<string | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    messagesEnd.current && messagesEnd.current.addEventListener("scroll", fetchMessages);
  }, [messagesEnd.current]);

  useEffect(() => {
    if (chat.length) {
      loadMoreMessages.current = {
        ...loadMoreMessages.current,
        load: chat.length < totalMessages,
        count: chat.length,
      };

      if (!loadMoreMessages.current.id) {
        loadMoreMessages.current.id = chat[0].id;
      }
    }
  }, [chat.length]);

  useEffect(() => {
    if (firstChatRender && chat.length) {
      setTimeout(() => {
        messagesEnd.current.scrollTo({
          top: 9e9,
          behavior: "smooth",
        });
        setFirstChatRender(false);
      }, 500);
    }
  });

  useEffect(() => {
    if (chat.length) {
      if (!doNotScroll && !firstChatRender) {
        // New message was arrived, scroll to bottom
        messagesEnd.current.scrollTo({
          top: 9e9,
          behavior: "smooth",
        });
      } else {
        // Prepending new messages to top - scroll to first last message
        const el = document.getElementById(loadMoreMessages.current.id);
        if (el) {
          messagesEnd.current.scrollTop = el.offsetTop - 88;
          loadMoreMessages.current.id = chat[0].id;
          setDoNotScroll(false);
        }
      }
    }
  }, [chat]);

  const fetchMessages = () => {
    if (!isFetching && loadMoreMessages.current.load && messagesEnd.current.scrollTop === 0) {
      setDoNotScroll(true);
      dispatch(conversationActions.loadMessages(loadMoreMessages.current.count));
    }
  };

  async function handleQuickReply(e: React.MouseEvent<HTMLSpanElement, MouseEvent>, msg: IMessage) {
    const {
      dataset: { id, reply, title },
    } = e.target as HTMLSpanElement;

    if (reply && reply.includes("?modal=")) {
      const modalParam = reply.match(/modal=([^&]*)/)?.[1];
      !!modalParam &&
        parseModalUrl(modalParam, (id: string, params: {}) => dispatch(modalActions.openModal(id, params)));
      return;
    }

    if (reply && /(https?:\/\/[^\s]+)/.test(reply)) {
      window.open(reply.replace(API_PLACEHOLDER, API_URL), "_blank");
    } else if (reply && connected) {
      if (reply === "accept") {
        await Promise.all([dispatch(usersActions.getWorkProfile()), dispatch(usersActions.getPaymentProfile())]);

        const { company_types } = user.workProfile;
        const { company_name, company_number, company_country, company_city, company_address_1, company_postcode } =
          user.paymentProfile;
        const briefIR35Type = msg?.input_opts?.brief_ir35;
        const shouldValidateCompanyTypes = briefIR35Type === "IR35_INSIDE" || briefIR35Type === "IR35_OUTSIDE";

        if (
          shouldValidateCompanyTypes &&
          (!company_types.some((company: ICompany) => REQUIRED_COMPANY_TYPES.includes(company.code)) ||
            (company_types.find((company: ICompany) => company.code === "COMPANY_LTD") &&
              (!company_name ||
                !company_number ||
                !company_country ||
                !company_city ||
                !company_address_1 ||
                !company_postcode)))
        ) {
          dispatch(
            setPopup({
              content: (
                <>
                  <h2>HOLD UP</h2>
                  <p>
                    <strong>** Ltd company information required **</strong>
                    <br />
                    Please update your profile by going to the <strong>Intended Work Set-Up</strong> in the{" "}
                    <strong>Getting Paid</strong> menu to accept IR35 designated briefs.
                  </p>
                  <p>
                    <strong>Don’t have an Ltd company set up?</strong>
                    <br />
                    You can still be booked via an Umbrella company or if you are a non-UK domiciled individual. Select
                    one of these options in your <strong>Intended Work Set-Up</strong> section.
                  </p>
                </>
              ),
              buttons: [
                {
                  text: "Dismiss",
                },
                {
                  text: "Go to Getting Paid Menu",
                  callback: () => {
                    dispatch(
                      modalActions.openModal("GETTING_PAID_MODAL", {
                        _onlyOne: true,
                        side: "left",
                        scrollTo: "company_types_field",
                      })
                    );
                  },
                },
              ],
            })
          );
        }
      }

      dispatch(
        conversationActions.sendMessage({
          text_content: title as string,
          payload: {
            message_id: id,
            payload: reply,
            button_type: "quick_reply",
          },
        })
      );
    }
  }

  function handlePostBack(
    id: string,
    payload: string,
    text_content: string,
    animationOpts?: { [key: string]: string }
  ) {
    if (animationOpts?.type) {
      setAnimation(animationOpts.type);

      animationOpts.type === "confetti"
        ? setTimeout(() => {
            lottieRef.current?.setSpeed(0.35);
            lottieRef.current?.play();
          })
        : setAnimation(null);
    }

    if (id && payload && connected) {
      dispatch(
        conversationActions.sendMessage({
          text_content,
          payload: {
            message_id: id,
            payload: payload,
            button_type: "postback",
          },
        })
      );
    }
  }

  function handleInformerClick(
    event: { preventDefault: () => void; stopPropagation: () => void },
    content: string | undefined
  ) {
    event.preventDefault();
    event.stopPropagation();
    dispatch(setPopup({ content: <div dangerouslySetInnerHTML={{ __html: content as string }}></div> }));
  }

  function renderResponseCards(msg: IMessage, nextMessage: any) {
    const cards = {
      ...msg.response_cards,
      ...msg.input_opts,
    };
    const disabled = nextMessage !== null;

    switch (cards.type) {
      case "Buttons":
        return (
          <div className={`ButtonsGroup buttons-${cards.elements.length}`}>
            {cards.elements.map(
              (element: { type: any; params: { id: string }; title: string; payload: any }, i: number) => {
                switch (element.type) {
                  case "ButtonOpenModal":
                    const defaultParams = getModalProps(element.params.id);
                    return (
                      <OpenModalButton
                        key={i}
                        title={element.title}
                        id={defaultParams.id}
                        props={{
                          ...defaultParams.params,
                          ...element.params,
                        }}
                        disabled={disabled}
                      />
                    );
                  default:
                    return (
                      <span
                        className={classNames("ChatButton", {
                          disabled: !connected || disabled,
                        })}
                        data-id={msg.id}
                        data-reply={element.payload ?? element.title}
                        data-title={element.title}
                        onClick={(event) => !disabled && handleQuickReply(event, msg)}
                        key={i}
                      >
                        <i className="icon-back" />
                        {element.title}
                      </span>
                    );
                }
              }
            )}
          </div>
        );
      case "ResponseBrief":
        return <ResponseCard {...cards.params} onPostBack={handlePostBack.bind(null, msg.id as string)} />;
      case "ResponseBriefCard":
      case "ResponseBriefPermanent":
        return <BriefCardPermanent {...cards.params} onPostBack={handlePostBack.bind(null, msg.id as string)} />;
      case "BriefsCarousel":
        const settings = {
          dots: false,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 500,
          responsive: [
            {
              breakpoint: 480,
              settings: {
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        };

        return (
          <>
            {!!msg?.input_opts?.params && (
              <Slider {...settings}>
                {msg.input_opts.params.map((card: IResponseCard | IBriefCardPermanent, i: number) => {
                  return !!card.ir35_code ? (
                    <BriefCardPermanent key={i} {...card} onPostBack={handlePostBack.bind(null, msg.id as string)} />
                  ) : (
                    <BriefResponseCard key={i} {...card} onPostBack={handlePostBack.bind(null, msg.id as string)} />
                  );
                })}
              </Slider>
            )}
          </>
        );
    }

    if (cards?.payload?.elements?.length) {
      const cardItems = cards.payload.elements;

      const { type, title, url, payload } = cardItems[0]?.buttons[0];
      const isSelectSkill = type === "web_url" && url.indexOf("/open_skills?") !== -1;
      const isDatepicker = type === "web_url" && url.indexOf("/open_calendar?") !== -1;
      const isUmbrellaSelector = type === "web_url" && url.indexOf("/umbrella_companies") !== -1;
      const isLoadMore = payload && payload?.indexOf(" page ") !== -1;
      const isEditBooking =
        cardItems[0]?.buttons[1]?.url && cardItems[0]?.buttons[1]?.url?.indexOf("/edit_booking") !== -1;

      if (isLoadMore) {
        return <LoadMoreButton title={title} payload={payload} sendMessage={conversationActions.sendMessage} />;
      } else if (isSelectSkill) {
        const discipline = getParameterByName("discipline", url) as string;
        return <SelectSkillsButton disabled={nextMessage !== null} discipline={discipline} />;
      } else if (isDatepicker) {
        const isRangeMode = getParameterByName("isRangeMode", url) === "true";
        const startDate = getParameterByName("startDate", url);
        const endDate = getParameterByName("endDate", url);

        const showIncludeWeekends = getParameterByName("showIncludeWeekends", url) === "true";

        const includeWeekends = getParameterByName("includeWeekends", url) === "true";

        return (
          <SelectDateButton
            disabled={nextMessage !== null}
            isDateRange={isRangeMode}
            startDate={startDate}
            endDate={endDate}
            showIncludeWeekends={showIncludeWeekends}
            includeWeekends={includeWeekends}
          />
        );
      } else if (isUmbrellaSelector) {
        return <SelectUmbrellaCompany max={1} title={title} disabled={nextMessage !== null} />;
      } else if (isEditBooking) {
        const url = cardItems[0].buttons[1].url;
        return (
          <EditTalentResponse
            reason={getParameterByName("reason", url)}
            rate={getParameterByName("rate", url)}
            briefType={getParameterByName("briefType", url)}
            disabled={nextMessage !== null}
            buttons={cardItems[0].buttons}
          />
        );
      } else {
        const settings = {
          dots: false,
          infinite: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          speed: 500,
          responsive: [
            {
              breakpoint: 480,
              settings: {
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        };

        return (
          <Slider {...settings}>
            {cardItems.map((card: IResponseCard, i: number) => (
              <ResponseCard key={i} {...card} onPostBack={handlePostBack.bind(null, msg.id as string)} />
            ))}
          </Slider>
        );
      }
    }
    return null;
  }

  return (
    <section className="Chat" ref={messagesEnd}>
      <ul className="messages">
        {chat.map((msg: IMessage, key: number) => {
          const previousMessage = key !== 0 ? chat[key - 1] : null;
          const dateFormat = moment().diff(msg.created_at, "years") > 0 ? "D MMM, YYYY" : "D MMM";
          const informerMessage = msg.text_content?.match(/\[popup\((.+)\)popup]/s);

          if (previousMessage?.showTyping) {
            return null;
          }

          if (msg.renderDelay) {
            clearTimeout(setDelayFalseId);
            setDelayFalseId = window.setTimeout(
              () => dispatch(conversationActions.setRenderDelay(msg.id as string, false)),
              TYPING_ANIMATION_DELAY
            );
            return null;
          }

          if (msg.showTyping) {
            if (
              !!msg.text_content &&
              msg.text_content.length > MIN_TEXT_LENGTH_TO_PAUSE_ANIMATION &&
              Math.random() < ANIMATION_PAUSE_CHANCE
            ) {
              clearTimeout(setDelayTrueId);

              setDelayTrueId = window.setTimeout(
                () => dispatch(conversationActions.setRenderDelay(msg.id as string, true)),
                TYPING_ANIMATION_DELAY
              );
            } else {
              clearTimeout(setTypingFalseId);

              const typingAnimationDuration = TYPING_ONE_CHAR_TIME * msg.text_content.length;

              setTypingFalseId = window.setTimeout(
                () => dispatch(conversationActions.setTyping(msg.id as string)),
                typingAnimationDuration > TYPING_ANIMATION_DELAY ? typingAnimationDuration : TYPING_ANIMATION_DELAY
              );
            }

            return (
              <li key={key} className="msg last typing">
                <div className="avatar">
                  <img src={LogoIcon} />
                </div>
                <div className="Chat__Message">
                  <p>
                    <Typing />
                  </p>
                </div>
              </li>
            );
          }
          const nextMessage = key !== chat.length - 1 ? chat[key + 1] : null;

          return (
            <React.Fragment key={key}>
              <Day currentMessage={msg} dateFormat={dateFormat} previousMessage={previousMessage} />
              <li
                id={msg.id}
                className={classNames("msg", {
                  group: previousMessage && previousMessage.is_mine === msg.is_mine,
                  last:
                    (nextMessage && nextMessage.is_mine !== msg.is_mine) ||
                    (nextMessage && nextMessage.response_cards) ||
                    key === chat.length - 1,
                  me: msg.is_mine,
                })}
                key={key}
              >
                {(previousMessage === null || previousMessage.is_mine !== msg.is_mine) && (
                  <div className="avatar">{msg.is_mine ? <i className="icon-user"></i> : <img src={LogoIcon} />}</div>
                )}
                {(msg.text_content || msg.attachments) && (
                  <div
                    className={classNames("Chat__Message", {
                      informer: !!informerMessage,
                    })}
                    onClick={
                      !!informerMessage ? (event) => handleInformerClick(event, informerMessage?.[1]) : undefined
                    }
                  >
                    {msg.text_content && (
                      <>
                        {parse(
                          linkifyHtml(
                            !!informerMessage ? msg.text_content.replace(informerMessage?.[0], "️") : msg.text_content
                          ),
                          {
                            replace: ({ name, attribs, children }) => {
                              if (name === "a" && attribs.href) {
                                return (
                                  <a href={linkWithProtocol(attribs.href)} target="_blank">
                                    {domToReact(children)}
                                  </a>
                                );
                              }
                              return null;
                            },
                          }
                        )}
                        <small>
                          {moment(msg.created_at).format("hh:mm A")}
                          {msg.is_mine && (
                            <i
                              className={classNames({
                                "icon-msg-sent": msg.status === 0,
                                "icon-msg-delivered": msg.status === 1,
                                "icon-msg-read": msg.status === 2,
                              })}
                            ></i>
                          )}
                        </small>
                      </>
                    )}
                    {msg.attachments && <Attachments attachments={msg.attachments} />}
                    {!!informerMessage && <a className="pi pi-info-circle" />}
                  </div>
                )}
                {msg.quick_replies &&
                  msg.quick_replies.length &&
                  (chat.length - 1 === key || msg.quick_replies[0].payload.indexOf("client review talents") === 0) && (
                    <div className="Chat__QuickReplies">
                      {msg.quick_replies.map((reply: IQuickReply, i: number) => (
                        <span
                          className={classNames({
                            disabled: !connected,
                          })}
                          data-id={msg.id}
                          data-reply={reply.payload}
                          data-title={reply.title}
                          onClick={(event) => handleQuickReply(event, msg)}
                          key={i}
                        >
                          {reply.title}
                        </span>
                      ))}
                    </div>
                  )}
                {renderResponseCards(msg, nextMessage)}
              </li>
            </React.Fragment>
          );
        })}
      </ul>
      {!!animation && (
        <div className="confetti">
          <Lottie
            autoplay={false}
            loop={false}
            lottieRef={lottieRef}
            animationData={Confetti}
            onComplete={() => setAnimation(null)}
          />
        </div>
      )}
    </section>
  );
}
