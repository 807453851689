import React from "react";
import ThemedInput from "~/components/common/ThemedInput/ThemedInput";
import PasswordRules from "~/components/PasswordRules/PasswordRules";

interface Props {
  error: string;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleChangePassword: Function;
  passwords: {
    current_password: string;
    new_password: string;
    confirm_password: string;
  };
}

const customValidationMessage = "Sorry but we'll struggle to log you in without a password.";

export default (props: Props) => {
  return (
    <form onSubmit={props.onSubmit}>
      <h1>Change Password</h1>
      <PasswordRules password={props.passwords.new_password} />
      <div className="field">
        <label>Current Password</label>
        <ThemedInput
          className="input"
          eye={true}
          id="current_password"
          onChange={props.handleChangePassword}
          placeholder="Type your current password"
          customValidationMessage={customValidationMessage}
          required
          type="password"
          value={props.passwords.current_password}
        />
      </div>
      <div className="field">
        <label>New Password</label>
        <ThemedInput
          className="input"
          eye={true}
          id="new_password"
          onChange={props.handleChangePassword}
          placeholder="Type new password"
          customValidationMessage={customValidationMessage}
          required
          type="password"
          value={props.passwords.new_password}
        />
      </div>
      <div className="field">
        <label>Confirm New Password</label>
        <ThemedInput
          className="input"
          eye={true}
          id="confirm_password"
          match={props.passwords.new_password}
          onChange={props.handleChangePassword}
          placeholder="Type new password"
          customValidationMessage={customValidationMessage}
          required
          type="password"
          validation="match"
          value={props.passwords.confirm_password}
        />
      </div>
      {props.error && <div className="error-msg">{props.error}</div>}
    </form>
  );
};
