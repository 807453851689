import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "~/store/modal";
import Modal from "../AbstractModal/AbstractModal";
import "./MultiSelectModal.scss";

interface IProps {
  side: string;
  dataType: string;
  itemsList: { id: number; name: string }[];
  selectedItems: number[];
  onSubmit: (items: number[]) => void;
}

export default function MultiSelectModal(props: IProps) {
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState<number[]>(props.selectedItems || []);

  const selectItem = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    const { dataset } = e.target;
    const value = +dataset.value;

    if (selectedItems.includes(value)) {
      setSelectedItems(selectedItems.filter((item) => item !== value));
      return;
    }

    setSelectedItems([...selectedItems, value]);
  };

  const closeModal = () => dispatch(modalActions.closeModal());
  const totalSelected = selectedItems.length;

  return (
    <Modal
      className="MultiSelectModal"
      headerLeftButton={<button className="invisible" type="button" />}
      headerRightButton={
        <button type="button" onClick={closeModal}>
          <i className="icon-cancel" />
        </button>
      }
      buttons={[
        { text: "Cancel", onClick: closeModal },
        {
          className: totalSelected === 0 ? undefined : "primary",
          disabled: totalSelected === 0,
          text: `Add ${props.dataType}${totalSelected > 1 ? "s" : ""}`,
          onClick: () => props.onSubmit(selectedItems),
        },
      ]}
      title={<h1>{`Select ${props.dataType}s`}</h1>}
      side={props.side}
    >
      <ul>
        {props.itemsList &&
          props.itemsList.map((item) => (
            <li
              key={item.id}
              className={selectedItems.includes(item.id) ? "selected" : undefined}
              data-value={item.id}
              onClick={selectItem}
            >
              {item.name}
            </li>
          ))}
      </ul>
    </Modal>
  );
}
