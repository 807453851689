export const themeConfig = {
  accentColor: "#d4272b",
  floatingNav: {
    background: "rgba(56, 87, 138, 0.94)",
    chevron: "#ffa726",
    color: "#fff",
  },
  headerColor: "#d4272b",
  selectionColor: "#d4272b",
  textColor: {
    active: "#fff",
    default: "#111",
  },
  todayColor: "#ffa726",
  weekdayColor: "#d4272b",
};
export const displayConfig = {
  shouldHeaderAnimate: false,
  showTodayHelper: false,
  showOverlay: false,
  showHeader: false,
};
export const localeConfig = {
  headerFormat: "MMM DD",
  todayLabel: {
    long: "Today",
  },
  weekdays: ["SU", "MO", "TU", "WE", "TH", "FR", "SA"],
  weekStartsOn: 1,
};
