import React from "react";
import { Link } from "react-router-dom";
import { IInvoice, InvoiceAttachTypes } from "~/API/InvoiceService";
import { InputTextarea } from "primereact/inputtextarea";
import LineItemFormMobile from "~/components/forms/InvoiceForm/LineItemFormMobile";
import { conditionalWrapper, extractFileName, formatCurrency } from "~/utils";
import { getInvoiceStatusData } from "./helpers";
import RowInvoiceNumber from "./rowCallbacks/RowInvoiceNumber";
import last from "lodash/last";
import { Message } from "primereact/message";
import moment from "moment";
import RowPDF from "~/components/Invoice/List/rowCallbacks/RowPDF";
import { getIR35InsideDisclaimer } from "~/config";

export default function InvoiceDetailMobile({
  activeInvoice,
  insurance_number,
  setActiveInvoice,
  putInvoice,
  validateBankProfile,
  validateInsuranceNumber,
}: {
  activeInvoice: IInvoice;
  insurance_number: string;
  setActiveInvoice: (invoice: IInvoice | null) => void;
  putInvoice: (invoice: IInvoice) => void;
  validateBankProfile: (cb: any) => void;
  validateInsuranceNumber: (ir35Code: string, insuranceNumber: string) => boolean;
}) {
  const dispute = last(activeInvoice.disputes);

  return (
    <>
      {!!activeInvoice && (
        <>
          <i className="icon-back desktop:hidden" onClick={() => setActiveInvoice(null)}></i>
          <div className="invoiceDetail desktop:hidden">
            <h1>
              <RowInvoiceNumber id={activeInvoice.id} />
            </h1>
            <h2>{activeInvoice.reference}</h2>
            {["DRAFT", "DISPUTED"].includes(activeInvoice.status || "") && (
              <Link to={`/invoices/${activeInvoice.id}`}>
                <i className="icon-edit" />
              </Link>
            )}
            <RowPDF status={activeInvoice.status} attachments={activeInvoice.attachments} />
            <article>
              {activeInvoice.line_items.map((item) => (
                <LineItemFormMobile key={item.id} item={item} onClick={() => {}} />
              ))}
              {(!!activeInvoice.notes ||
                !!activeInvoice.attachments?.length ||
                (activeInvoice.status === "DISPUTED" && !!dispute)) && (
                <div className="invoiceDescription">
                  <div className="grid">
                    <div className="col-12">
                      {activeInvoice.notes && (
                        <>
                          <label>Additional notes</label>
                          <InputTextarea className="detail" value={activeInvoice.notes} disabled autoResize />
                        </>
                      )}
                      {!!activeInvoice.attachments?.length &&
                        activeInvoice.attachments.some(
                          ({ type }) => type === InvoiceAttachTypes.TYPE_INVOICE_ATTACH
                        ) && (
                          <>
                            <label>Attachments</label>
                            <div className="attachments">
                              {activeInvoice.attachments.map(({ url, type }, id) =>
                                type === InvoiceAttachTypes.TYPE_INVOICE_ATTACH ? (
                                  <a href={url} target="_blank" key={id}>
                                    {extractFileName(url)}
                                  </a>
                                ) : null
                              )}
                            </div>
                          </>
                        )}
                      {activeInvoice.status === "DISPUTED" && !!dispute && (
                        <div className="dispute">
                          <label>Dispute reason</label>
                          <Message
                            severity="warn"
                            content={
                              <>
                                <strong>{moment(dispute.created_at).format("MMM DD, YYYY")}</strong>
                                <span>{dispute.description}</span>
                              </>
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {activeInvoice.booking?.ir35_code === "IR35_INSIDE" && (
                <b
                  className="red-bordered"
                  dangerouslySetInnerHTML={{ __html: getIR35InsideDisclaimer(activeInvoice) }}
                />
              )}
              <div className="invoiceTotal">
                <div className="grid">
                  <div className="col-9">Subtotal:</div>
                  <div className="col-3">{formatCurrency(activeInvoice.subtotal, 2)}</div>
                </div>
                <div className="grid">
                  <div className="col-9">Include Total VAT, 20%:</div>
                  <div className="col-3">{formatCurrency(activeInvoice.total_tax, 2)}</div>
                </div>
                <div className="grid">
                  <div className="col-9">Total:</div>
                  <div className="col-3">{formatCurrency(activeInvoice.total, 2)}</div>
                </div>
              </div>
            </article>
            <footer>
              {(activeInvoice.available_statuses || []).map((status, i) => (
                <button
                  type="button"
                  key={i}
                  onClick={() => {
                    if (validateInsuranceNumber(activeInvoice?.booking?.ir35_code, insurance_number)) {
                      conditionalWrapper(
                        status === "SUBMITTED",
                        () => {
                          putInvoice({ id: activeInvoice.id, status } as IInvoice);
                          setActiveInvoice(null);
                        },
                        validateBankProfile
                      );
                    }
                  }}
                >
                  {getInvoiceStatusData(status, true).labelStatus}
                </button>
              ))}
            </footer>
          </div>
        </>
      )}
    </>
  );
}
