import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToasts, TOAST_ERROR_MESSAGE, TOAST_SUCCESS_MESSAGE } from "~/store/toasts";
import { PreferencesService } from "~/API/PreferencesService";
import { IState } from "~/store/reducers";
import { preferenceCodes } from "~/components/modals/PreferencesModal/PreferencesModal";

export interface IPreference {
  id: number;
  code: string;
  title: string;
  description: string;
  type: string;
  value: boolean;
}

export type Props = IPreference[] | null;

const usePreferences = () => {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [preferences, setPreferences] = useState<IPreference[] | null>(null as Props);
  const token = useSelector((state: IState) => state.users.authenticatedUser.token);

  const isPreferenceSelected = (preferenceCode: preferenceCodes) =>
    preferences?.find(({ code }) => code === preferenceCodes[preferenceCode]).value;

  const getPreferences = async () => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await PreferencesService.getPreferences(token);
        res.status === 200 && setPreferences(res.data);
      } catch (err) {
        dispatch(setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      } finally {
        setIsFetching(false);
      }
    }
  };
  const updatePreferenceById = async ({ id, value }: { id: number; value: boolean }) => {
    if (token) {
      try {
        const res = await PreferencesService.updatePreferenceById(token, id, value);
        res.status === 200 &&
          dispatch(setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
      } catch (err) {
        dispatch(setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      }
    }
  };

  return {
    isFetching,
    preferences,
    setPreferences,
    getPreferences,
    updatePreferenceById,
    isPreferenceSelected,
  };
};

export default usePreferences;
