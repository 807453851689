import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ITalent, TalentService } from "~/API/TalentService";
import { setToasts, TOAST_ERROR_MESSAGE, TOAST_SUCCESS_MESSAGE } from "~/store/toasts";
import { updateTalentCVsuccess, updateTalentInUser } from "~/store/user";
import { isMoment } from "moment";
import { ILineItemUnit } from "~/API/InvoiceService";

export interface ITalentContext {
  introductionStarted: () => Promise<boolean>;
}

interface IReferCode {
  limit: number;
  code: string;
  link: string;
}
export interface IInvoiceReadyBooking {
  booking_id: number;
  client_name: string;
  contract_code: ILineItemUnit;
  contract_name: string;
  start_date: string;
  end_date: string;
  talent_rate: string;
  invoices_count: number;
  invoice_completed: boolean;
  ir35_code: keyof typeof IR35_TYPES;
  ir35_name: string;
}

export enum IR35_TYPES {
  IR35_INSIDE = "Inside IR35",
  IR35_OUTSIDE = "Outside IR35",
  IR35_UMBRELLA = "Paid Via Umbrella Company",
  IR35_PERMANENT = "Permanent",
}

const useTalent = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingBooking, setIsFetchingBooking] = useState<number | null>(null);
  const [referCode, setReferCode] = useState<IReferCode | null>(null);
  const [invoiceReadyBookings, setInvoiceReadyBookings] = useState<IInvoiceReadyBooking[] | null>(null);
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.users.authenticatedUser.token);

  const updateTalent = async (values: any, cb: any) => {
    try {
      setIsFetching(true);
      const payload: ITalent = {
        is_available: values.is_available,
        start_from: values.start_from.format("YYYY-MM-DD"),
        remind_at:
          values.is_available || !values.is_remind
            ? null
            : isMoment(values.remind_at)
            ? values.remind_at.format("YYYY-MM-DD")
            : values.remind_at,
      };
      const { status } = await TalentService.updateTalent(payload, token);
      dispatch(updateTalentInUser(payload));

      status === 200 && cb();
    } catch (err) {
      dispatch(setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetching(false);
    }
  };

  const updateTalentCV = async (file: any, cb?: any) => {
    try {
      setIsFetching(true);
      const { status, data } = await TalentService.updateTalentCV(file, token);
      dispatch(updateTalentCVsuccess(data));
      dispatch(setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
      status === 200 && !!cb && cb();
    } catch (err: any) {
      if (err.response?.status === 400 && err.response?.data?.code === "FILE_UPLOAD_FAILED") {
        dispatch(
          setToasts([{ severity: "error", summary: "", detail: err.response?.data?.message || TOAST_ERROR_MESSAGE }])
        );
      } else {
        dispatch(setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      }
    } finally {
      setIsFetching(false);
    }
  };

  const deleteTalentCV = async () => {
    try {
      setIsFetching(true);
      const { data } = await TalentService.deleteTalentCV(token);
      dispatch(updateTalentCVsuccess(data));
    } catch (err) {
      dispatch(setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetching(false);
    }
  };

  const updateFlow = async (payload: { [key: string]: any }, type: string) => {
    try {
      setIsFetching(true);
      await TalentService.updateFlow(payload, type, token);
    } catch (err) {
      dispatch(setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetching(false);
    }
  };

  const getReferCode = async () => {
    try {
      setIsFetching(true);

      const { status, data } = await TalentService.getReferCode(token);
      if (status === 200 || status === 201) {
        setReferCode(data);
      } else {
        dispatch(setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      }
    } catch (err) {
      dispatch(setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetching(false);
    }
  };

  const getInvoiceReadyBookings = async () => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await TalentService.getInvoiceReadyBookings(token);
        res.status === 200 && setInvoiceReadyBookings(res.data);
      } catch (err) {
        dispatch(setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      } finally {
        setIsFetching(false);
      }
    }
  };

  const setBookingCompleted = async (bookingId: number) => {
    if (token) {
      try {
        setIsFetchingBooking(bookingId);
        const res = await TalentService.setBookingCompleted(bookingId, token);

        if (res.status === 200) {
          const updatedBookings =
            invoiceReadyBookings &&
            invoiceReadyBookings.map((booking) =>
              booking.booking_id === bookingId ? { ...booking, invoice_completed: true } : booking
            );
          setInvoiceReadyBookings(updatedBookings);
          dispatch(setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
        }
      } catch (err) {
        dispatch(setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      } finally {
        setIsFetchingBooking(null);
      }
    }
  };

  const setBookingUncompleted = async (bookingId: number) => {
    if (token) {
      try {
        setIsFetchingBooking(bookingId);
        const res = await TalentService.setBookingUncompleted(bookingId, token);

        if (res.status === 200) {
          const updatedBookings =
            invoiceReadyBookings &&
            invoiceReadyBookings.map((booking) =>
              booking.booking_id === bookingId ? { ...booking, invoice_completed: false } : booking
            );
          setInvoiceReadyBookings(updatedBookings);
          dispatch(setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
        }
      } catch (err) {
        dispatch(setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      } finally {
        setIsFetchingBooking(null);
      }
    }
  };

  const introductionStarted = async () => {
    if (token) {
      try {
        await TalentService.postUserEvent(token, "onboarding_started");
        return true;
      } catch (err) {
      } finally {
      }
    }
    return false;
  };

  return {
    isFetching,
    isFetchingBooking,
    updateTalent,
    updateTalentCV,
    deleteTalentCV,
    updateFlow,
    getReferCode,
    referCode,
    invoiceReadyBookings,
    introductionStarted,
    getInvoiceReadyBookings,
    setBookingCompleted,
    setBookingUncompleted,
  };
};

export default useTalent;
