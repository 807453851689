import classnames from "classnames";
import { Field, FieldInputProps, FieldMetaProps } from "formik";
import { InputText, InputTextProps } from "primereact/inputtext";
import React, { ReactNode } from "react";
import PopupIcon from "~/components/Toaster/PopupIcon";
import { formatCurrency } from "~/utils";
import "./GmInputText.scss";

interface IGmInputText extends InputTextProps {
  noField?: boolean;
  formik?: any;
  price?: boolean;
  sublabel?: string;
  type?: string;
  info?: string | ReactNode;
  label: string;
}

export default function GmInputText(props: IGmInputText) {
  let meta, field;
  if (props.noField && props.formik) {
    meta = props.formik.getFieldMeta(props.id);
    field = props.formik.getFieldProps(props.id);
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>, field: FieldInputProps<any> | null = null) {
    e.stopPropagation();
    const { value } = e.target;
    const val = props.price && !!~value.indexOf("£") ? value.replace("£", "") : value;
    if (field && field?.onChange) {
      e.target.value = val;
      field.onChange(e);
    } else {
      props.formik.setFieldValue(props.id, val);
    }
  }

  return props.noField && meta ? (
    <div
      className={classnames("GmInputText", props.className, {
        error: meta.touched && meta.error,
      })}
    >
      {props.label && (
        <label htmlFor={props.id} className="label">
          {props.label} {props.info && <PopupIcon content={props.info} />}
        </label>
      )}
      {props.sublabel && <span className="sublabel">{props.sublabel}</span>}
      <InputText
        disabled={props.disabled}
        id={props.id}
        keyfilter={props.keyfilter}
        maxLength={props.maxChars || props.maxLength}
        placeholder={props.placeholder}
        required={props.required}
        type={props.type || "text"}
        max={props.max}
        min={props.min}
        name={field.name}
        onChange={props.price ? onChange : field.onChange}
        onBlur={field.onBlur}
        value={
          props.price
            ? props.value === "" || field.value === ""
              ? ""
              : "£" + (props.value || field.value)
            : props.value || field.value || ""
        }
      />
      {props.disabled && <i className="icon-disabled"></i>}
      {props.maxChars && (
        <div className="charCount p-text-right">{props.maxChars - field.value.length} characters left</div>
      )}
      {meta.touched && meta.error && <sub>{meta.error}</sub>}
    </div>
  ) : (
    <Field name={props.id}>
      {({ field, meta }: { field: FieldInputProps<any>; meta: FieldMetaProps<any> }) => (
        <div
          className={classnames("GmInputText", props.className, {
            error: meta.touched && meta.error,
          })}
        >
          {props.label && <label htmlFor={props.id}>{props.label}</label>}
          {props.sublabel && <span className="sublabel">{props.sublabel}</span>}
          <InputText
            {...field}
            disabled={props.disabled}
            id={props.id}
            keyfilter={props.keyfilter}
            maxLength={props.maxChars || props.maxLength}
            placeholder={props.placeholder}
            required={props.required}
            type={props.type || "text"}
            max={props.max}
            min={props.min}
            onChange={props.price ? (e: React.ChangeEvent<HTMLInputElement>) => onChange(e, field) : field.onChange}
            value={
              props.price
                ? field.value === ""
                  ? ""
                  : props.disabled
                  ? formatCurrency(field.value)
                  : "£" + field.value
                : field.value ?? ""
            }
          />
          {props.maxChars && (
            <div className="charCount p-text-right">{props.maxChars - field.value.length} characters left</div>
          )}
          {meta.touched && meta.error && <sub>{meta.error}</sub>}
        </div>
      )}
    </Field>
  );
}
