import React from "react";
import RowAmount from "./rowCallbacks/RowAmount";
import RowDueDate from "./rowCallbacks/RowDueDate";
import RowInvoiceNumber from "./rowCallbacks/RowInvoiceNumber";
import RowInvoiceStatus from "./rowCallbacks/RowInvoiceStatus";
import RowPDF from "~/components/Invoice/List/rowCallbacks/RowPDF";

export const getColumns = () => {
  let columns = [
    {
      name: "Number",
      sortable: false,
      field: "id",
      cellCallback: ({ id }: { id: number }) => <RowInvoiceNumber id={id} />,
    },
    {
      name: "Ref",
      sortable: false,
      field: "reference",
    },
    {
      name: "Issue Date",
      sortable: false,
      field: "date",
      style: "fixed-column",
    },
    {
      name: "Due Date",
      sortable: false,
      field: "due_date",
      style: "fixed-column",
      cellCallback: (rowData: any) => <RowDueDate dueDate={rowData.due_date} status={rowData.status} />,
    },
    {
      name: "Total",
      sortable: false,
      field: "total",
      cellCallback: (rowData: any) => <RowAmount total={rowData.total} />,
    },

    {
      name: "Status",
      sortable: false,
      field: "status",
      cellCallback: (rowData: any) => <RowInvoiceStatus rowData={rowData} />,
    },
    {
      name: "PDF",
      field: "pdf",
      cellCallback: (rowData: any) => <RowPDF status={rowData.status} attachments={rowData.attachments} />,
    },
  ];

  return columns;
};
