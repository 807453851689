import classNames from "classnames";
import { Button } from "primereact/button";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { AGClientSocket } from "socketcluster-client";
import { modalActions } from "~/store/modal";
import { MessageWithContent, setAlerts } from "~/store/toasts";
import "./AbstractModal.scss";

const mapDispatchToProps = {
  openModal: modalActions.openModal,
  closeModal: modalActions.closeModal,
  setAlerts: setAlerts,
};

const mapStateToProps = (state: any) => ({
  wsInstance: state.websocket.instance,
});

namespace AbstractModal {
  interface StateProps {
    openModal?: (modalType: string, modalProps: object) => Action;
    closeModal?: () => void;
    setAlerts?: (alerts: MessageWithContent | MessageWithContent[]) => Action;
    wsInstance?: AGClientSocket | null;
  }

  interface OwnProps {
    askOnClose?: boolean;
    backModal?: string;
    buttons?: any[] | undefined;
    children?: any;
    className?: string;
    side: string;
    shouldIAsk?: boolean;
    title: string | JSX.Element;
    handleClose?: Function;
    headerLeftButton?: JSX.Element;
    headerRightButton?: JSX.Element;
    headerContent?: boolean;
  }
  export type Props = StateProps & OwnProps;
  export type State = {
    showContent: boolean;
  };
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export default class AbstractModal extends PureComponent<AbstractModal.Props, AbstractModal.State> {
  private closeTimeout: number = -1;
  private showTimer: number | null = null;

  constructor(props: AbstractModal.Props) {
    super(props);
    this.state = { showContent: false };
    this.showTimer = setTimeout(() => this.setState({ showContent: true }), 500);
  }

  componentDidMount() {
    if (this.props.askOnClose) {
      window.addEventListener("beforeunload", this.handleWindowClose);
      window.addEventListener("unload", this.handleWindowQuit);
    }
  }

  componentWillUnmount() {
    if (this.props.askOnClose) {
      window.removeEventListener("beforeunload", this.handleWindowClose);
      window.removeEventListener("unload", this.handleWindowQuit);
    }
    if (this.showTimer) {
      clearTimeout(this.showTimer);
    }
  }

  handleWindowClose(e: BeforeUnloadEvent) {
    if (this.props.askOnClose && this.props.shouldIAsk) {
      this.closeTimeout = setTimeout(() => {
        this.props.wsInstance && this.props.wsInstance.connect();
      }, 0);
      e.preventDefault();
      e.returnValue = "onbeforeunload";
      return "onbeforeunload";
    }
    return;
  }

  handleWindowQuit() {
    clearTimeout(this.closeTimeout);
  }

  handleClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (this.props.askOnClose && this.props.shouldIAsk && this.props.setAlerts) {
      this.props.setAlerts([
        {
          closable: false,
          content: this.renderAskModal(this.onConfirmClose.bind(this), this.onClose),
          severity: "warn",
          sticky: true,
        },
      ]);
    } else {
      if (e.currentTarget === e.target || e.target.className === "icon-cancel") {
        e.stopPropagation();
        this.props.closeModal && this.props.closeModal();
      }
    }
  };

  onConfirmClose(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (e.currentTarget === e.target || (e.target && e.target.className === "p-button-label p-c")) {
      e.stopPropagation();
      this.props.setAlerts([]);
      this.props.closeModal();
    }
  }

  onClose = () => {
    this.props.setAlerts([]);
  };

  renderAskModal(
    onConfirm: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    onClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  ) {
    return (
      <div className="flex flex-column confirmModal">
        <div className="text-center">
          <i className="pi pi-exclamation-triangle"></i>
          <p>Hold on, you haven’t saved the changes you made yet, do you still want to leave this section?</p>
        </div>
        <footer>
          <Button className="p-button-secondary" label="Don’t Save" onClick={onConfirm} type="button" />
          <Button className="p-button-success" label="I Want To Save" onClick={onClose} type="button" />
        </footer>
      </div>
    );
  }

  onConfirmBack = () => {
    if (this.props.backModal) {
      this.props.setAlerts([]);
      this.props.openModal(this.props.backModal, {
        _noAnimate: true,
        _onlyOne: true,
        side: this.props.side,
      });
    }
  };

  onBack = () => {
    if (this.props.askOnClose && this.props.shouldIAsk) {
      this.props.setAlerts([
        {
          closable: false,
          content: this.renderAskModal(this.onConfirmBack, this.onClose),
          severity: "warn",
          sticky: true,
        },
      ]);
    } else if (this.props.backModal) {
      this.props.openModal(this.props.backModal, {
        _noAnimate: true,
        _onlyOne: true,
        side: this.props.side,
      });
    }
  };

  render() {
    return (
      <div className={classNames("modal", this.props.className, this.props.side)}>
        <div className={classNames("inner", this.props.side)} onClick={() => false}>
          <button type="button" className="close-button" onClick={this.handleClose}>
            <i className="icon-cancel"></i>
          </button>
          <header>
            {this.props.askOnClose && this.props.backModal ? (
              <button type="button" onClick={this.onBack}>
                <i className="icon-back"></i>
              </button>
            ) : (
              this.props.headerLeftButton
            )}
            {this.props.title && <div>{this.props.title}</div>}
            {this.props.headerRightButton}
          </header>
          <div
            className={classNames({
              shown: this.state.showContent,
              contentHeader: this.props.headerContent,
            })}
          >
            {this.state.showContent && this.props.children}
          </div>
          {this.props.buttons && this.props.buttons.length > 0 && (
            <footer>
              {[...this.props.buttons].map((button, i: number) => (
                <button
                  className={button.className}
                  disabled={!!button.disabled}
                  type="button"
                  key={i}
                  onClick={button.onClick}
                >
                  {button.text}
                </button>
              ))}
            </footer>
          )}
        </div>
      </div>
    );
  }
}
