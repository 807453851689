import React, { isValidElement } from "react";
import * as Yup from "yup";
import { NotificationTypes } from "~/components/modals/PreferencesModal/PreferencesModal";
import { URL_REGEXP } from "./config";
import { every, get } from "lodash";

export const PASSWORD_CHECK_LENGTH = 8;
export const PASSWORD_CHECK_LOWERCASE = /[a-z]+/;
export const PASSWORD_CHECK_UPPERCASE = /[A-Z]+/;
export const PASSWORD_CHECK_NUMBER = /[0-9]+/;
export const PASSWORD_CHECK_SPECIAL = /[\!\@\#\$\%\^\&\*]+/;

export function checkLength(password: string) {
  return password.length >= PASSWORD_CHECK_LENGTH;
}

export function checkLowercase(password: string) {
  return PASSWORD_CHECK_LOWERCASE.test(password);
}

export function checkCapital(password: string) {
  return PASSWORD_CHECK_UPPERCASE.test(password);
}
export function checkNumber(password: string) {
  return PASSWORD_CHECK_NUMBER.test(password);
}

export function checkSpecial(password: string) {
  return PASSWORD_CHECK_SPECIAL.test(password);
}

export function checkConfirm(password: string, confirm_password: string) {
  return password && password === confirm_password;
}

export function validatePassword(password: string, confirm_password: string) {
  return (
    checkLength(password) &&
    checkLowercase(password) &&
    checkCapital(password) &&
    checkNumber(password) &&
    checkSpecial(password) &&
    checkConfirm(password, confirm_password)
  );
}

export function isLocalStorageAvailable(): boolean {
  var test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

export function getParameterByName(name: string, url: string) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const escapeRegExp = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

export const yupString = (
  required: boolean = false,
  min: number | null = null,
  max: number | null = null
): Yup.StringSchema<any, object> => {
  let field: Yup.StringSchema<any, object> = Yup.string();
  if (required) {
    field = field.required("Please, fill in required field");
  }
  if (min) {
    field = field.min(min, `Please, add at least ${min} symbols`);
  }
  if (max) {
    field = field.max(max, `Please, make it shorter than ${max} symbols`);
  }
  return field;
};

export const yupUrl = (): Yup.StringSchema<any, object> => {
  return yupString().matches(URL_REGEXP, "Please, enter URL in correct format");
};

export const yupMessages = {
  required: "Please, fill in required field",
  min: "Please, add at least ${min} symbols",
  max: "Please, make it shorter than ${max} symbols",
  length: "This field must contain exactly ${length} symbols",
  digits: "This field must contain only digits",
  alphanumeric: "This field must contain only alphanumeric characters",
};

export const yearMinValueTest: any = {
  name: "year-min-value",
  message: "${path} must not be earlier than 1980",
  test: (value: string) => !value || +value >= 1980,
};

export const yearMaxValueTest: any = {
  name: "year-max-value",
  message: "${path} must not be later than current year",
  test: (value: string) => !value || +value <= new Date().getFullYear(),
};

export const arrUnique = (array: any[]) => array.filter((elem, pos, arr) => arr.indexOf(elem) === pos);

export const formatCurrency = (amount: number | string = 0, maximumFractionDigits = 2) =>
  (typeof amount === "string" ? parseFloat(amount) : amount)?.toLocaleString("en-GB", {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits,
  });

export const copyToClipboard = (str: string) => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

export const selectAndCopyToClipboard = (htmlElement: HTMLElement) => {
  const range = document.createRange();
  range.selectNodeContents(htmlElement);
  const selection = window.getSelection();
  selection?.removeAllRanges();
  selection?.addRange(range);
  document.execCommand("copy");
  window.getSelection()?.removeAllRanges();
};

export const reactNodeToString = (reactNode: React.ReactNode): string => {
  let string = "";
  if (typeof reactNode === "string") {
    string = reactNode;
  } else if (typeof reactNode === "number") {
    string = reactNode.toString();
  } else if (reactNode instanceof Array) {
    reactNode.forEach((child) => {
      string += reactNodeToString(child);
    });
  } else if (isValidElement(reactNode)) {
    string += reactNodeToString(reactNode.props.children);
  }
  return string;
};

export const getModalProps = (id: string) =>
  ({
    THE_WORK: { id: "THE_WORK_MODAL", params: { side: "left", _onlyOne: true } },
    PERSONAL: { id: "PERSONAL_PROFILE_MODAL", params: { side: "left", _onlyOne: true } },
    GETTING_PAID: { id: "GETTING_PAID_MODAL", params: { side: "left", _onlyOne: true } },
    PROFILE: { id: "USER_PROFILE_MODAL", params: { side: "left", _onlyOne: true } },
    MAIN_MENU: { id: "PROFILE_MODAL", params: { side: "left", _onlyOne: true } },
    AVAILABILITY: { id: "AVAILABILITY_MODAL", params: { side: "left", _onlyOne: true } },
    REFER_TALENT: { id: "REFER_TALENT_MODAL", params: { side: "left", _onlyOne: true } },
    PREFERENCES: {
      id: "PREFERENCES_MODAL",
      params: { side: "left", _onlyOne: true, type: NotificationTypes.NOTIFICATION_PREFERENCES },
    },
    BRIEF_PREFERENCES: {
      id: "PREFERENCES_MODAL",
      params: { side: "left", _onlyOne: true, type: NotificationTypes.BRIEF_PREFERENCES },
    },
    SUPERPOWERS: { id: "THE_WORK_MODAL", params: { side: "left", _onlyOne: true, openModal: "SKILLS_MODAL" } },
    NOTICE_MIN: { id: "NOTICE_PERIOD_MODAL", params: { side: "right", _onlyOne: true } },
    CALENDAR: { id: "DATE_MODAL", params: { side: "right", _onlyOne: true } },
    EDIT_TALENT_RESPONSE: { id: "EDIT_TALENT_RESPONSE", params: { side: "right" } },
    BOOKINGS: { id: "SELECT_BOOKING_MODAL", params: { _onlyOne: true } },
  }[id] || { id, params: { side: "left", _onlyOne: true } });

export const parseModalUrl = (modal: string, openModal: (id: string, params: {}) => void) => {
  const modalProps = getModalProps(modal);
  !!modalProps.id && openModal(modalProps.id, modalProps.params);
};

export const isPayloadFromLastMessage = (str: string, quickReplies) =>
  quickReplies.map(({ payload }: { payload: string }) => payload).some((payload: string) => payload === str);

export const makeUniqUrl = (url: string) => `${url}?${+new Date()}`;

export const extractFileName = (url: string) => url.replace(/^.*[\\\/]/, "");

export const validateFile = (file: File, opts: { size?: number; types?: string[] }) => {
  const errors: string[] = [];

  opts.size && file.size > opts.size && errors.push(`Maximum upload size is ${opts.size / 1000000}Mb`);

  if (opts.types) {
    const mime: { [key: string]: string[] } = {
      xls: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"],
      doc: ["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
      pdf: ["application/pdf"],
    };

    !opts.types.reduce((acc: boolean, ext: string) => acc || mime[ext].includes(file.type), false) &&
      errors.push(`Incorrect file type`);
  }

  return errors;
};

export const formatPlural = (n: number, one: string, many: string) => (n === 1 ? one : many);

export const padWithZero = (num: number, targetLength: number) => String(num).padStart(targetLength, "0");

export const linkWithProtocol = (url: string, { https = true } = {}) => {
  url = url.trim();

  if (url.match(/(mailto:)|(tel:)|(sms:)/g)) {
    return url;
  }

  return url.replace(/^(?!(?:\w+?:)?\/\/)/, https ? "https://" : "http://");
};

export const conditionalWrapper = (condition: boolean, func: any, wrapper: any) => {
  condition ? wrapper(func) : func();
};

export const isValidUrl = (url: string) => !!new RegExp(URL_REGEXP).test(url);

export const getGlobalOptionValue = (
  globalOptions: any,
  section: string,
  optionCode: string,
  format: "INT" | "FLOAT" | "STRING" = "STRING"
) => {
  const formatFunc = {
    INT: parseInt,
    FLOAT: parseFloat,
    STRING: (arg: string) => arg,
  };

  return formatFunc[format](globalOptions?.[section].find(({ code }) => code === optionCode)?.value);
};

export const compareArrays = (array1, array2, key) => {
  if (array1.length !== array2.length) {
    return false;
  }

  const isEqual = every(array1, (obj1, index) => {
    const obj2 = array2[index];
    return get(obj1, key) === get(obj2, key);
  });

  return isEqual;
};

export const setOriginURL = () => {
  const { pathname, search, hash } = window.location;
  localStorage.setItem("origin", `${pathname}${search}${hash}`);
};
