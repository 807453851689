import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { setPopup } from "../../store/toasts";
import { Popup } from "../../store/toasts";

import "./Toaster.scss";

export default function PopupIcon({ content, popup }: { content?: string | ReactNode; popup?: Popup }) {
  const dispatch = useDispatch();
  const item: Popup | null = popup ?? (content ? { content } : null);

  return item ? <span className="pi pi-info-circle popup-item" onClick={() => dispatch(setPopup(item))}></span> : null;
}
