import React, { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import Loader from "~/components/common/Loader/Loader";
import { usersActions } from "~/store/user";
import "./TheWorkForm.scss";
import { useFormik } from "formik";
import { MAX_MOST_INFLUENTIAL, MAX_PLATFORMS, TheWorkSchema } from "~/schemas/TheWorkSchema";
import { optionsActions } from "~/store/options";
import { modalActions } from "~/store/modal";
import GmDropdown from "~/components/common/GmDropdown/GmDropdown";
import GmInputText from "~/components/common/GmInputText/GmInputText";
import GmInputTextarea from "~/components/common/GmInputTextarea/GmInputTextarea";
import { Button } from "primereact/button";
import GmSelectModal from "~/components/common/GmSelectModal/GmSelectModal";
import GmFileUpload from "~/components/common/GmFileUpload/GmFileUpload";
import useTalent from "~/hooks/useTalent";
import { validateFile } from "~/utils";
import CurrentEmployerSelect from "~/components/common/CurrentEmployerSelect/CurrentEmployerSelect";

interface Props {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  onTouch: (value: boolean) => void;
  onValidationFail: () => void;
  submitTrigger: boolean;
}

export default function TheWorkForm(props: Props) {
  const dispatch = useDispatch();
  const workProfile = useSelector((state: any) => state.users.workProfile);
  const { options: globalOptions } = useSelector((state: any) => state.options);
  const user = useSelector((state: any) => state.users.authenticatedUser);
  const [specialismOptions, setSpecialismOptions] = useState([]);
  const [workingStatusesOptions, setWorkingStatusesOptions] = useState([]);
  const [presetSkills, setPresetSkills] = useState([]);
  const [presetSectors, setPresetSectors] = useState([]);
  const [presetPlatforms, setPresetPlatforms] = useState([]);
  const [levelOptions, setLevelOptions] = useState([]);
  const { updateTalentCV, deleteTalentCV, isFetching: isCVFetching } = useTalent();
  const { modals } = useSelector((state: any) => state.modal);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      discipline: user.talent.discipline,
      specialism_code: workProfile.specialism_code || "",
      level_code: workProfile.level_code || "",
      subhead: workProfile.subhead || "",
      skills: workProfile.skills || [],
      sectors:
        (workProfile.sectors &&
          workProfile.custom_sectors && [
            ...workProfile.sectors,
            ...workProfile.custom_sectors.map((skill: { id: number; name: string }) => ({
              id: 0 - skill.id,
              name: skill.name,
            })),
          ]) ||
        [],
      platforms:
        (workProfile.platforms &&
          workProfile.custom_platforms && [
            ...workProfile.platforms,
            ...workProfile.custom_platforms.map((platform: { id: number; name: string }) => ({
              id: 0 - platform.id,
              name: platform.name,
            })),
          ]) ||
        [],
      description: workProfile.description || "",
      portfolio_url: workProfile.portfolio_url || "",
      cv_url: "",
      video_url: workProfile.video_url || "",
      best_work_url: workProfile.best_work_url || "",
      most_influential: workProfile.most_influential || [],
      working_status_id: workProfile.working_status_id || null,
      employer: workProfile.employer || "",
    },
    validationSchema: TheWorkSchema,
    onSubmit: () => {},
  });

  useEffect(() => {
    formik.setFieldValue("cv_url", workProfile.cv_url);
  }, [workProfile.cv_url]);

  const filterLevels = (level: { code: string }) => {
    if (user.talent.discipline === "DISCIPLINE_CREATIVE") {
      return level.code !== "LEVEL_FLEXIBLE";
    }
    return level.code !== "LEVEL_FLEXIBLE" && level.code !== "LEVEL_MIDSENIOR";
  };

  useEffect(() => {
    if (globalOptions && user.talent) {
      const disciplineOptions = globalOptions.disciplines.find(
        (discipline: { code: string }) => discipline.code === user.talent.discipline
      );
      if (disciplineOptions) {
        const specialism = disciplineOptions.specialisms.find(
          (specialism: { code: string }) => specialism.code === formik.values.specialism_code
        );
        if (specialism) {
          setLevelOptions(
            specialism.levels
              .filter(filterLevels)
              .map((s: { code: string; name: string }) => ({
                value: s.code,
                label: s.name,
              }))
              .reverse()
          );
        }
      }
    }
  }, [globalOptions, user.talent, formik.values.specialism_code]);

  useEffect(() => {
    const { cv_url: cv, ...values } = formik.values;
    const { cv_url, ...initial } = formik.initialValues;
    props.onTouch(JSON.stringify(values) !== JSON.stringify(initial));
  }, [formik.values]);

  useEffect(() => {
    if (props.submitTrigger) {
      formik.validateForm().then((res) => {
        formik.handleSubmit();
        if (!Object.keys(res).length) {
          props.onSubmit(formik.values);
        } else {
          props.onValidationFail();
        }
      });
    }
  }, [props.submitTrigger]);

  useEffect(() => {
    if (globalOptions && user.talent) {
      const disciplineOptions = globalOptions.disciplines.find(
        (discipline: { code: string }) => discipline.code === user.talent.discipline
      );
      if (disciplineOptions) {
        setSpecialismOptions(
          disciplineOptions.specialisms.map((specialism: { code: string; name: string }) => ({
            value: specialism.code,
            label: specialism.name,
          }))
        );
        setPresetSkills(disciplineOptions.skills);
        setPresetSectors(disciplineOptions.sectors);
        setPresetPlatforms(globalOptions.platforms);
        setWorkingStatusesOptions(
          globalOptions.working_statuses.map((status: { id: string; name: string }) => ({
            value: status.id,
            label: status.name,
          }))
        );
      }
    }
  }, [globalOptions]);

  useEffect(() => {
    batch(() => {
      dispatch(usersActions.getWorkProfile());
      dispatch(optionsActions.getOptions());
    });
  }, []);

  useEffect(() => {
    if (presetSkills.length && modals?.[0]?.modalProps?.openModal === "SKILLS_MODAL") {
      openSkillsModal();
    }
  }, [presetSkills.length]);

  const openSkillsModal = () =>
    dispatch(
      modalActions.openModal("SKILLS_MODAL", {
        side: "left",
        onSubmit: onSetSkillsSectors,
        presetSkills,
        selected: formik.values.skills,
        customSkills: false,
      })
    );

  function onAddEmployer({
    client_name,
    start_year,
    end_year,
  }: {
    client_name: string;
    start_year: string;
    end_year: string;
  }) {
    formik.setFieldValue("most_influential", [
      ...formik.values.most_influential,
      { client_name, start_year, end_year },
    ]);
    dispatch(modalActions.closeModal());
  }

  function onUpdate(index: number, field: string, value: any) {
    if (index > -1) {
      const newArr = formik.values[field].map((item, i: number) => (i === index ? value : item));
      formik.setFieldValue(field, newArr);
      dispatch(modalActions.closeModal());
    }
  }

  function onDelete(index: number, field: string) {
    if (index > -1) {
      const newArr = formik.values[field] ? [...formik.values[field]] : [];
      newArr.splice(index, 1);
      formik.setFieldValue(field, newArr);
      dispatch(modalActions.closeModal());
    }
  }

  function onUploadCV([file]: [File]) {
    const errors = validateFile(file, {
      size: 10000000,
      types: ["xls", "doc", "pdf"],
    });

    if (errors.length) {
      formik.setFieldError("cv_url", errors.join(", "));
    } else {
      updateTalentCV(file);
    }
  }

  function onSetSkillsSectors(field: string, value: any[]) {
    formik.setFieldValue(field, value);
    dispatch(modalActions.closeModal());
  }

  const currentWorkingStatusCode = globalOptions?.working_statuses?.find(
    (status: { id: string }) => status.id === formik.values.working_status_id
  )?.code;

  const isEmployerPickerShown =
    currentWorkingStatusCode === "PERMANENT" || currentWorkingStatusCode === "FREELANCING_AND_PERMANENT";

  useEffect(() => {
    !isEmployerPickerShown && formik.setFieldValue("employer", "");
  }, [isEmployerPickerShown]);

  return workProfile ? (
    <form className="TheWorkForm" onSubmit={formik.handleSubmit}>
      {formik.values.discipline !== "DISCIPLINE_STRATEGY" && formik.values.discipline !== "DISCIPLINE_BUSINESS" && (
        <div className="field">
          <GmDropdown
            formik={formik}
            id="specialism_code"
            label="Job Title (Specialism)"
            noField
            options={specialismOptions}
          />
        </div>
      )}
      {formik.values.discipline !== "DISCIPLINE_SOCIAL" && formik.values.specialism_code && (
        <div className="field">
          <GmDropdown formik={formik} id="level_code" label="Level" noField options={levelOptions} />
        </div>
      )}
      <div className="field">
        <GmInputTextarea
          formik={formik}
          id="subhead"
          label="Subhead"
          maxChars={80}
          noField
          placeholder="(e.g. W+K, Droga5, Collaborator, Cannes Winning, Purpose Driven)"
          required
        />
      </div>
      <hr />
      <div className="field">
        <GmSelectModal
          formik={formik}
          label="Superpowers"
          placeholder={
            formik.values.skills.length ? `${formik.values.skills.length} selected & ranked` : "Select & Rank"
          }
          onClick={() => {
            dispatch(
              modalActions.openModal("SKILLS_MODAL", {
                side: "left",
                onSubmit: onSetSkillsSectors,
                presetSkills,
                selected: formik.values.skills,
                customSkills: false,
              })
            );
          }}
          id="skills"
        />
      </div>
      {formik.values.discipline !== "DISCIPLINE_DESIGN" && (
        <div className="field">
          <GmSelectModal
            formik={formik}
            label="Sector Experience"
            placeholder={
              formik.values.sectors.length ? `${formik.values.sectors.length} selected & ranked` : "Select & Rank"
            }
            onClick={() => {
              dispatch(
                modalActions.openModal("SECTORS_MODAL", {
                  side: "left",
                  onSubmit: onSetSkillsSectors,
                  presetSectors,
                  selected: formik.values.sectors,
                  customSectors: false,
                })
              );
            }}
            id="sectors"
          />
        </div>
      )}

      {formik.values.discipline === "DISCIPLINE_SOCIAL" && (
        <div className="field">
          <GmSelectModal
            formik={formik}
            label="Platform Experience"
            placeholder={
              formik.values.platforms.length ? `${formik.values.platforms.length} selected and ranked` : "Select & Rank"
            }
            onClick={() => {
              dispatch(
                modalActions.openModal("PLATFORMS_MODAL", {
                  side: "left",
                  onSubmit: onSetSkillsSectors,
                  presetPlatforms,
                  selected: formik.values.platforms,
                  max: MAX_PLATFORMS,
                  customPlatforms: true,
                })
              );
            }}
            id="platforms"
          />
        </div>
      )}
      <div className="field">
        <GmInputTextarea
          formik={formik}
          id="description"
          label="Bio"
          maxChars={1200}
          placeholder="(As your agent I need a quick bit on what makes you a star. I know it’s weird but try to use 3rd person so I can rep you! tips, inc perm roles, key work anything else juicy.)"
          noField
          required
        />
      </div>
      <div className="field">
        <GmInputText
          formik={formik}
          id="portfolio_url"
          label="Portfolio URL"
          noField
          placeholder="Link to your online portfolio"
          required
        />
      </div>
      <div className="field">
        {isCVFetching && <Loader />}
        {!isCVFetching && (
          <GmFileUpload
            formik={formik}
            noField
            id="cv_url"
            label="CV"
            mode="basic"
            accept=".doc,.docx,.pdf,.xls,.xlsx"
            auto
            onChange={onUploadCV}
            chooseLabel={"Upload new"}
            onDelete={deleteTalentCV}
          />
        )}
      </div>
      <div className="field">
        <GmInputText
          formik={formik}
          id="video_url"
          label="Showreel URL (if applicable)"
          noField
          placeholder="Insert Vimeo/ YouTube link"
          required
        />
      </div>
      <div className="field">
        <GmDropdown
          formik={formik}
          id="working_status_id"
          label="Current Working Status"
          noField
          options={workingStatusesOptions}
        />
      </div>
      {isEmployerPickerShown && <CurrentEmployerSelect formik={formik} />}
      <hr />
      <div className="field">
        <GmInputText
          formik={formik}
          id="best_work_url"
          label="Your most impactful work"
          noField
          placeholder="A link to some work that really nailed it"
        />
      </div>
      <div className="field">
        <label htmlFor="most_influential">Your most influential employers</label>
        {formik.values.most_influential.map((ref: any, i: number) => (
          <Button
            className="input-like"
            key={i}
            label={ref.client_name}
            onClick={() => {
              dispatch(
                modalActions.openModal("INFLUENTIAL_EMPLOYER_MODAL", {
                  side: "left",
                  form: formik.values.most_influential[i],
                  index: i,
                  onDelete,
                  onUpdate,
                })
              );
            }}
            type="button"
          />
        ))}
        {formik.values.most_influential.length < MAX_MOST_INFLUENTIAL && (
          <Button
            id="most_influential"
            label="+ Add Employer"
            onClick={() => {
              dispatch(
                modalActions.openModal("INFLUENTIAL_EMPLOYER_MODAL", {
                  side: "left",
                  onSubmit: onAddEmployer,
                })
              );
            }}
            type="button"
          />
        )}
      </div>
    </form>
  ) : (
    <Loader />
  );
}
