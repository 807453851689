import React from "react";
import { useDispatch, batch } from "react-redux";
import { modalActions } from "~/store/modal";
import { getPersonalProfileRequest } from "~/store/user";
import Modal from "../AbstractModal/AbstractModal";

export default function UserProfileModal() {
  const dispatch = useDispatch();
  const openModal = (modalType: string) => {
    batch(() => {
      dispatch(
        modalActions.openModal(modalType, {
          _onlyOne: true,
          side: "left",
        })
      );
      dispatch(getPersonalProfileRequest());
    });
  };

  return (
    <Modal
      askOnClose
      backModal="PROFILE_MODAL"
      className="UserProfileModal no-animate"
      side="left"
      title={<h1>Profile</h1>}
      headerRightButton={<button className="invisible" type="button"></button>}
    >
      <ul className="profile-list">
        <li className="screen" onClick={() => openModal("PERSONAL_PROFILE_MODAL")}>
          <i className="icon-user-outline" />
          Personal Details
        </li>
        <li className="screen" onClick={() => openModal("GETTING_PAID_MODAL")}>
          <i className="icon-briefcase"></i>
          Getting Paid
        </li>
        <li className="screen" onClick={() => openModal("THE_WORK_MODAL")}>
          <i className="icon-tie"></i>
          The Work
        </li>
      </ul>
      <ul className="profile-list">
        <li className="screen" onClick={() => openModal("CHANGE_PASSWORD_MODAL")}>
          <i className="icon-lock"></i>Change Password
        </li>
      </ul>
    </Modal>
  );
}
