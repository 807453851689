import { routerReducer, RouterState } from "react-router-redux";
import { combineReducers, Reducer, Store } from "redux";
import { IBriefState } from "./brief";
import chat from "./chat";
import location from "./location";
import modal from "./modal";
import options from "./options";
import skills from "./skills";
import toasts from "./toasts";
import users from "./user";
import websocket from "./websocket";

export interface IState {
  chat: any;
  brief: IBriefState;
  location: any;
  modal: any;
  options: any;
  router: Reducer<RouterState>;
  skills: any;
  talents: any;
  toasts: any;
  users: any;
  websocket: any;
}

export const makeRootReducer = (asyncReducers?: {}) => {
  return combineReducers({
    chat,
    location,
    modal,
    options,
    router: routerReducer,
    skills,
    toasts,
    users,
    websocket,
    ...asyncReducers,
  });
};

export const injectReducer = (
  store: Store & { [key: string]: any },
  { key, reducer }: { key: string; reducer: any }
) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;
  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
