import { applyMiddleware, compose, createStore as createReduxStore, Store } from "redux";
import { routerMiddleware } from "react-router-redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import makeRootReducer from "./reducers";
import { updateLocation } from "./location";
import { __DEV__ } from "../config";
import { History } from "history";

export const withAuthorizationHeader = (token: string): any =>
  token && { headers: { Authorization: `Bearer ${token}` } };

export default (initialState = {}, history: History) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [routerMiddleware(history), thunk, logger];

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers: any[] = [];
  let composeEnhancers = compose;

  if (__DEV__) {
    if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === "function")
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store: Store & { [key: string]: any } = createReduxStore(
    makeRootReducer(),
    initialState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers)
  );
  store.asyncReducers = {};

  // To unsubscribe, invoke `store.unsubscribeHistory()` anytime
  store.unsubscribeHistory = history.listen(updateLocation(store));

  if (module.hot) {
    module.hot.accept("./reducers", () => {
      const reducers = require("./reducers").default;
      store.replaceReducer(reducers(store.asyncReducers));
    });
  }

  return store;
};
