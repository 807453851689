import classnames from "classnames";
import { Field } from "formik";
import { RadioButton } from "primereact/radiobutton";
import React, { ReactNode } from "react";
import PopupIcon from "~/components/Toaster/PopupIcon";
import "./GmRadioButton.scss";

interface GmRadioButton {
  noField?: boolean;
  formik?: any;
  className?: string;
  id: string;
  label?: string;
  options: any[];
  info?: string | ReactNode;
}

interface IOption {
  id: number;
  name: string;
}

export default function GmRadioButton(props: GmRadioButton) {
  let meta, field;
  const { id, formik, noField, className, label, info, options } = props;

  if (noField && formik) {
    meta = formik.getFieldMeta(id);
    field = formik.getFieldProps(id);
  }

  return noField && meta ? (
    <div
      className={classnames("GmRadioButton", className, {
        error: meta.touched && meta.error,
      })}
    >
      {label && (
        <label htmlFor={id}>
          {label} {info && <PopupIcon content={info} />}
        </label>
      )}
      {options.map((option: IOption, index: number) => {
        return (
          <div key={index} className="field-radiobutton">
            <RadioButton
              inputId={id}
              name={id}
              onChange={() => formik.setFieldValue(id, option.id)}
              checked={formik.values[id] === option.id}
            />
            <label htmlFor={option.name}>{option.name}</label>
          </div>
        );
      })}
      {meta.touched && meta.error && <sub>{meta.error}</sub>}
    </div>
  ) : (
    <Field name={id}>
      {({ field, meta }: any) => (
        <>
          {label && <label htmlFor={id}>{label}</label>}
          {options.map((option: IOption, index: number) => {
            return (
              <div key={index} className="field-radiobutton">
                <RadioButton
                  inputId={id}
                  name={id}
                  onChange={() => formik.setFieldValue(id, option.id)}
                  checked={formik.values[id] === option.id}
                  {...field}
                />
                <label htmlFor={option.name}>{option.name}</label>
              </div>
            );
          })}
          {meta.touched && meta.error && <sub>{meta.error}</sub>}
        </>
      )}
    </Field>
  );
}
