import classnames from "classnames";
import React, { ReactNode } from "react";
import { Dropdown } from "primereact/dropdown";
import { Field } from "formik";
import PopupIcon from "~/components/Toaster/PopupIcon";
import "./GmDropdown.scss";

interface IDropdown {
  noField?: boolean;
  formik?: any;
  className?: string;
  id: string;
  required?: boolean;
  disabled?: boolean;
  filter?: boolean;
  filterBy?: string;
  label?: string;
  options: any;
  optionLabel?: string;
  optionValue?: string;
  placeholder?: string;
  showClear?: boolean;
  onChange?(e: any): void;
  info?: string | ReactNode;
}
export default function GmDropdown(props: IDropdown) {
  let meta, field;
  if (props.noField && props.formik) {
    meta = props.formik.getFieldMeta(props.id);
    field = props.formik.getFieldProps(props.id);
  }

  return props.noField && meta ? (
    <div
      className={classnames("GmDropdown", props.className, {
        error: meta.touched && meta.error,
      })}
    >
      {props.label && (
        <label htmlFor={props.id}>
          {props.label} {props.info && <PopupIcon content={props.info} />}
        </label>
      )}
      <Dropdown
        {...field}
        {...props}
        //todo make configurable
        filterPlaceholder="Filter..."
        inputId={props.id}
      />
      {meta.touched && meta.error && <sub>{meta.error}</sub>}
    </div>
  ) : (
    <Field name={props.id}>
      {({ field, meta }: any) => (
        <>
          {props.label && <label htmlFor={props.id}>{props.label}</label>}
          <Dropdown
            {...field}
            {...props}
            //todo make configurable
            filterPlaceholder="Filter..."
            inputId={props.id}
            onChange={props?.onChange || field.onChange}
          />
          {meta.touched && meta.error && <sub>{meta.error}</sub>}
        </>
      )}
    </Field>
  );
}
