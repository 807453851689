import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import InvoicesListView from "~/components/Invoice/List/InvoicesListView";
import Sidebar from "~/components/Sidebar/Sidebar";
import SidePanel from "~/components/SidePanel/SidePanel";
import "./InvoiceView.scss";

export default function ListInvoices() {
  const authenticatedUser = useSelector((state: any) => state.users.authenticatedUser);
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);

  if (authenticatedUser?.talent?.invoices_available === false) return <Redirect to="/" />;

  return (
    <Sidebar className="ChatView" isOpen={isSidebarOpen} close={() => setSidebarOpen(!isSidebarOpen)}>
      <SidePanel />
      <div className="content">
        <InvoicesListView />
      </div>
    </Sidebar>
  );
}
