import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { modalActions } from "~/store/modal";
import { optionsActions } from "~/store/options";
import "./ProfileIntroduction.scss";
import {
  Slide1,
  Slide10,
  Slide2,
  Slide3,
  Slide4,
  Slide5,
  Slide6,
  Slide7,
  Slide8,
  Slide9,
} from "./ProfileIntroductionSlides";
import { FormikValues, useFormik } from "formik";
import { SelectedItem } from "~/components/ItemSelector/ItemSelector";
import { usersActions } from "~/store/user";
import usePreferences, { IPreference } from "~/hooks/usePreferences";
import { Preference, preferenceCodes, preferenceTypes } from "~/components/modals/PreferencesModal/PreferencesModal";
import { useTalentContext } from "~/contexts/TalentContext";
import { setPopup } from "~/store/toasts";
import { formatCurrency } from "~/utils";

const SLIDES_COUNT: number = 10;

export default function TalentProfileIntroduction() {
  const dispatch = useDispatch();
  const { getPreferences, preferences, setPreferences, updatePreferenceById, isPreferenceSelected } = usePreferences();
  const [currentSlide, setCurrentSlide] = useState<number>(1);
  const [skills, setSkills] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [skipOnboarding, setSkipOnboarding] = useState(false);
  const [selectorStep, setSelectorStep] = useState<number>(1);
  const { options: globalOptions } = useSelector((state: any) => state.options);

  const { introductionStarted } = useTalentContext();

  const workingStatuses = useMemo(
    () =>
      globalOptions?.working_statuses?.map((status: { id: string; name: string }) => ({
        value: status.id,
        label: status.name,
      })),
    [globalOptions]
  );
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      skills: [],
      sectors: [],
      platforms: [],
      description: "",
      working_status_id: null,
      employer: "",
      rate: null,
      annual_rate: null,
    },
    onSubmit: (form: FormikValues) => {
      closeIntroduction(form);
      dispatch(modalActions.openModal("PROFILE_MODAL", { _onlyOne: true, side: "left" }));
    },
  });

  const currentWorkingStatusCode = globalOptions?.working_statuses?.find(
    (status: { id: string }) => status.id === formik.values.working_status_id
  )?.code;

  const { discipline, showTalentOnboarding }: { discipline: string; showTalentOnboarding: boolean } = useSelector(
    ({
      users: {
        authenticatedUser: {
          talent: { discipline, intro_flow },
        },
      },
    }: {
      users: any;
    }) => ({ discipline, showTalentOnboarding: intro_flow === "FLOW_INTRO" })
  );

  useEffect(() => {
    if (discipline && globalOptions) {
      const talentDiscipline = globalOptions.disciplines.find((item: { code: string }) => item.code === discipline);
      setSkills(talentDiscipline.skills);
      setSectors(talentDiscipline.sectors);
      setPlatforms(globalOptions.platforms);
    }
  }, [discipline, globalOptions]);

  useEffect(() => {
    getPreferences();
    dispatch(optionsActions.getOptions());
  }, []);

  function closeIntroduction(form: FormikValues) {
    dispatch(usersActions.introductionSeen());
    const data = { ...form };

    if (!data.annual_rate) {
      delete data.annual_rate;
    }

    if (!data.rate) {
      delete data.rate;
    }

    dispatch(usersActions.updateTalentProfiles({ ...data, onboarded: "ONBOARDED" }));
  }

  function renderSwitchSlide() {
    const { values, setFieldValue } = formik;
    switch (currentSlide) {
      case 1:
        return <Slide1 />;
      case 2:
        return (
          <Slide2
            items={skills}
            selected={values.skills}
            onSave={(value: SelectedItem[]) => setFieldValue("skills", value)}
            onStepChange={setSelectorStep}
            selectorStep={selectorStep}
          />
        );
      case 3:
        return (
          <Slide3
            items={sectors}
            selected={values.sectors}
            onSave={(value: SelectedItem[]) => setFieldValue("sectors", value)}
            onStepChange={setSelectorStep}
            selectorStep={selectorStep}
          />
        );
      case 4:
        return (
          <Slide4
            items={platforms}
            selected={values.platforms}
            onSave={(value: SelectedItem[]) => setFieldValue("platforms", value)}
            onStepChange={setSelectorStep}
            selectorStep={selectorStep}
          />
        );
      case 5:
        return <Slide5 onChangeAbout={(event) => setFieldValue("description", event.currentTarget.value)} />;
      case 6:
        return (
          <Slide6
            formik={formik}
            currentWorkingStatusCode={currentWorkingStatusCode}
            workingStatuses={workingStatuses}
          />
        );
      case 7:
        return (
          <Slide7
            briefPreferences={preferences
              ?.filter((preference: IPreference) => preference.type === preferenceTypes.TYPE_BRIEF_AND_NOTIFICATION)
              ?.map((preference: IPreference) => (
                <Preference
                  key={preference.id}
                  preferences={preferences}
                  preference={preference}
                  updatePreferenceById={updatePreferenceById}
                  setPreferences={setPreferences}
                />
              ))}
          />
        );
      case 8:
        return <Slide8 formik={formik} />;
      case 9:
        return <Slide9 formik={formik} />;
      default:
        return <Slide10 skipOnboarding={skipOnboarding} />;
    }
  }

  const getNextSlideIncrement = () => {
    if (currentSlide === 2 && discipline === "DISCIPLINE_DESIGN") {
      return 3;
    }
    if (currentSlide === 3 && discipline !== "DISCIPLINE_SOCIAL") {
      return 2;
    }

    const shouldSkipSlide9 =
      (currentSlide === 7 || currentSlide === 8) &&
      !isPreferenceSelected(preferenceCodes.FIXED_TERM) &&
      !isPreferenceSelected(preferenceCodes.PERMANENT);
    if (currentSlide === 7) {
      const shouldSkipSlide8 = !isPreferenceSelected(preferenceCodes.DAY_RATE);
      if (shouldSkipSlide8 && shouldSkipSlide9) {
        return 3;
      }
      if (shouldSkipSlide8) {
        return 2;
      }
    }
    if (currentSlide === 8 && shouldSkipSlide9) {
      return 2;
    }

    return 1;
  };

  function onClickSkip() {
    if (currentSlide === 5) {
      formik.setFieldValue("description", "No information provided.");
    }
    setCurrentSlide(currentSlide + getNextSlideIncrement());
  }

  async function onClickNext(e: MouseEvent) {
    e.preventDefault();

    if (currentSlide === 1) {
      const response: boolean = await introductionStarted();
      if (!response) {
        setSkipOnboarding(true);
        setCurrentSlide(SLIDES_COUNT);
        return;
      }
    }

    if (currentSlide === 8 && formik.values.rate > 9999) {
      showDayRateWarningPopup();
      return;
    }
    if (currentSlide === 9 && formik.values.annual_rate < 10000) {
      showAnnualSalaryWarningPopup();
      return;
    }

    if (
      [2, 3, 4].includes(currentSlide) &&
      selectorStep === 1 &&
      ((currentSlide === 2 && formik.values.skills.length > 1) ||
        (currentSlide === 3 && formik.values.sectors.length > 1) ||
        (currentSlide === 4 && formik.values.platforms.length > 1))
    ) {
      setSelectorStep(2);
    } else if (isNextSlideAvailable) {
      setSelectorStep(1);
      setCurrentSlide(currentSlide + getNextSlideIncrement());
    }
  }

  const showDayRateWarningPopup = () =>
    dispatch(
      setPopup({
        content: (
          <>
            <h2>Warning!</h2>
            <p>{`The Day Rate figure you have entered (${formatCurrency(
              formik.values.rate,
              0
            )}) is very high, are you sure this is the correct amount?`}</p>
          </>
        ),
        buttons: [
          {
            text: "Cancel",
          },
          {
            text: "Continue",
            callback: () => setCurrentSlide(currentSlide + getNextSlideIncrement()),
          },
        ],
      })
    );

  const showAnnualSalaryWarningPopup = () =>
    dispatch(
      setPopup({
        content: (
          <>
            <h2>Warning!</h2>
            <p>{`The Annual Salary figure you have entered (${formatCurrency(
              formik.values.annual_rate,
              0
            )}) is very low, are you sure this is the correct amount?`}</p>
          </>
        ),
        buttons: [
          {
            text: "Cancel",
          },
          {
            text: "Continue",
            callback: () => setCurrentSlide(currentSlide + getNextSlideIncrement()),
          },
        ],
      })
    );

  const isNextSlideAvailable: boolean =
    currentSlide === 1 ||
    (currentSlide === 2 &&
      ((formik.values.skills.length > 1 && selectorStep === 2) || formik.values.skills.length === 1)) ||
    (currentSlide === 3 &&
      ((formik.values.sectors.length > 1 && selectorStep === 2) || formik.values.sectors.length === 1)) ||
    (currentSlide === 4 &&
      ((formik.values.platforms.length > 1 && selectorStep === 2) || formik.values.platforms.length === 1)) ||
    (currentSlide === 5 && formik.values.description.length > 0) ||
    (currentSlide === 6 && !!formik.values.working_status_id) ||
    currentSlide === 7 ||
    (currentSlide === 8 && !!formik.values.rate) ||
    (currentSlide === 9 && !!formik.values.annual_rate);

  return (
    <div className="ProfileIntroductionModalSlider">
      <div className="container">
        {renderSwitchSlide()}
        <div className="controls">
          <div className="col col-1">
            {currentSlide === 5 && (
              <a href="#" onClick={onClickSkip}>
                Skip
              </a>
            )}
          </div>
          <div className="navigation col col-3">
            {currentSlide !== SLIDES_COUNT && (
              <>
                <a
                  href="#"
                  className={classNames("nextStep", {
                    disabled: !isNextSlideAvailable,
                  })}
                  onClick={onClickNext}
                >
                  Next
                </a>
              </>
            )}
            {currentSlide === SLIDES_COUNT && !skipOnboarding && (
              <a href="#" onClick={() => formik.handleSubmit()}>
                View profile
              </a>
            )}
            {currentSlide === SLIDES_COUNT && skipOnboarding && (
              <a href="#" onClick={() => dispatch(usersActions.introductionSeen())}>
                Got It
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
