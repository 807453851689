import find from "lodash/find";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "~/store/modal";
import "./PlantedTreesModal.scss";
import { formatPlural } from "~/utils";

export default function PlantedTreesModal() {
  const dispatch = useDispatch();
  const modals = useSelector((state: any) => state.modal.modals);
  const { plantedTreesNumber, imageData } = find(modals, { modalType: "PLANTED_TREES_MODAL" })?.modalProps;

  const renderTreesImages = () =>
    [...Array(imageData.count)].map((_value, index) => <img key={index} src={imageData.source} />);

  return (
    <Dialog
      className="PlantedTreesModal"
      header={`Great news! You have planted ${plantedTreesNumber} ${formatPlural(
        plantedTreesNumber,
        "tree",
        "trees"
      )}.`}
      footer={
        <footer>
          <a href="https://ecologi.com/projects" target="_blank" className="ecologi-link">
            Learn more about the projects supported here
          </a>
        </footer>
      }
      onHide={() => dispatch(modalActions.closeModal())}
      visible
    >
      <div>
        <div className="trees">{renderTreesImages()}</div>
        <p>
          We integrate seamlessly with Ecologi to deliver climate action, so for every day you work a tree is planted on
          your behalf. As a result of your incredible work {plantedTreesNumber}{" "}
          {formatPlural(plantedTreesNumber, "tree", "trees")} have been planted.
        </p>

        <p>
          With over 52 million trees planted and 2.3 million tonnes of CO2e avoided to date, Ecologi puts climate action
          at your fingertips with simple and impactful ways to become climate positive.
        </p>
      </div>
    </Dialog>
  );
}
