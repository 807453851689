import React, { useCallback } from "react";
import DragItem, { IDragItem } from "../DragItem/DragItem";
import update from "immutability-helper";

export interface Props {
  items: IDragItem[];
  itemClass: string;
  setItems: Function;
}

export default function DropZone({ items, itemClass, setItems }: Props) {
  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = items[dragIndex];
      setItems(
        update(items, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [items]
  );

  return (
    <div className="DropZone">
      {items.map((item, i) => (
        <DragItem className={itemClass} key={item.id} index={i} id={item.id} text={item.name} moveCard={moveCard} />
      ))}
    </div>
  );
}
