import React from "react";
import { Route } from "react-router-dom";
import { LOGIN_URL } from "~/config";
import { setOriginURL } from "~/utils";

/**
 * Component that protects route from unauthorized users.
 * @type {Function}
 */
export default ({
  component: Component,
  isAuthenticated,
  ...rest
}: {
  component: typeof React.Component;
  isAuthenticated: boolean;
}) => {
  if (!isAuthenticated) {
    setOriginURL();
    location.href = LOGIN_URL;
    return null;
  }
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};
