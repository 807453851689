import "primeflex/primeflex.min.css";
import "primeicons/primeicons.css";
import { updateLocaleOption } from "primereact/api";
import "primereact/resources/primereact.min.css";
import React, { KeyboardEvent, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import NotificationModal, {
  TYPE_PERMANENT_BRIEFS,
  TYPE_PREFERENCES,
} from "~/components/modals/NotificationModal/NotificationModal";
import routes from "~/routes";
import { conversationActions } from "~/store/chat";
import { modalActions } from "~/store/modal";
import { IState } from "~/store/reducers";
import { FLOW_INTRO, FLOW_NONE, usersActions } from "~/store/user";
import { isLocalStorageAvailable, parseModalUrl, setOriginURL } from "~/utils";
import Alerter from "../Alerter/Alerter";
import PrivateRoute from "../common/PrivateRoute";
import Maintenance from "../Maintenance/Maintenance";
import ModalRoot from "../modals";
import TalentProfileIntroduction from "../modals/TalentProfileIntroduction/ProfileIntroduction";
import Popup from "../Toaster/Popup";
import Toaster from "../Toaster/Toaster";
import Websocket from "../Websocket/Websocket";
//TODO: Remove after upgrade Primereact to 6.4.0 https://github.com/primefaces/primereact/issues/2026
import { FileUpload } from "primereact/fileupload";
import allSettled from "promise.allsettled";
import { LOGIN_URL } from "~/config";
import { optionsActions } from "~/store/options";
import PageNotFound from "../common/PageNotFound/PageNotFound";
import UserPopup from "../UserPopup/UserPopup";
import Authorization from "./Authorization";
import "./App.scss";

const ThemeContext = React.createContext({});

// @ts-ignore
FileUpload.prototype.clearInputElement = function () {
  // @ts-ignore
  this.fileInput && (this.fileInput.value = "");
};

const maintenance = false;

const App = () => {
  const dispatch = useDispatch();
  const location = useSelector((state: any) => state.location);
  const authenticatedUser = useSelector((state: any) => state.users.authenticatedUser);
  const showTalentOnboarding = useSelector((state: any) => state.users.authenticatedUser.talent?.intro_flow);
  const isAnyModalOpened = useSelector((state: any) => state.modal.modals.length > 0);

  const showNotificationModal = useSelector((state: IState) => !state.users.authenticatedUser.talent?.preference);
  const showPermBriefsNotificationModal = useSelector(
    (state: IState) => !state.users.authenticatedUser.talent?.ph_briefs_notified
  );
  const showUserPopup = useSelector((state: IState) => !state.users.authenticatedUser.talent?.hide_user_popup);

  const getParams = Object.fromEntries(new URLSearchParams(location.search) as any);

  useEffect(() => {
    if (getParams.debug !== "true") {
      window.console.log = () => {};
      window.console.group = () => {};
      window.console.groupEnd = () => {};
      window.console.groupCollapsed = () => {};
      window.console.warn = () => {};
      window.console.error = () => {};
    } else {
      dispatch(conversationActions.setDebugMode(true));
    }

    if (isLocalStorageAvailable()) {
      const token = localStorage.getItem("user");
      token && dispatch(usersActions.updateToken(token));
    }

    if (!Promise.allSettled) {
      Promise.allSettled = allSettled;
    }

    dispatch(optionsActions.getOptions());
    updateLocaleOption("firstDayOfWeek", 1, "en");
  }, []);

  useEffect(() => {
    !isAnyModalOpened &&
      !!authenticatedUser.ge_psid &&
      getParams.modal &&
      parseModalUrl(getParams.modal, (id: string, params: {}) => dispatch(modalActions.openModal(id, params)));
  }, [authenticatedUser]);

  const handleCloseEsc = (e: KeyboardEvent) => e.key === "Escape" && dispatch(modalActions.closeModal());
  const isAuthenticated: boolean =
    !!authenticatedUser.ge_psid || !!authenticatedUser.token || !!localStorage.getItem("user");
  if (!isAuthenticated) {
    setOriginURL();
    window.location.href = LOGIN_URL;
    return null;
  }

  const renderPopup = () => {
    if (showTalentOnboarding === FLOW_INTRO) {
      return <TalentProfileIntroduction />;
    }
    if (showTalentOnboarding === FLOW_NONE && showNotificationModal) {
      return <NotificationModal type={TYPE_PREFERENCES} />;
    }
    if (showTalentOnboarding === FLOW_NONE && showPermBriefsNotificationModal) {
      return <NotificationModal type={TYPE_PERMANENT_BRIEFS} />;
    }
    if (
      !!authenticatedUser.token &&
      showTalentOnboarding === FLOW_NONE &&
      !showNotificationModal &&
      !showPermBriefsNotificationModal &&
      showUserPopup
    ) {
      return <UserPopup />;
    }
    return null;
  };

  return (
    <DndProvider
      backend={TouchBackend}
      options={{
        enableTouchEvents: true,
        enableMouseEvents: true,
      }}
    >
      <main className="App" tabIndex={0} onKeyDown={handleCloseEsc}>
        <ThemeContext.Provider value={{ maintenance }}>
          <Websocket>
            <>
              <Switch location={location}>
                {routes.map((route: any, i: number) =>
                  route.canAccess ? (
                    <Route key={i} {...route} />
                  ) : (
                    <PrivateRoute key={i} {...route} isAuthenticated={isAuthenticated} />
                  )
                )}
                <Route component={PageNotFound} />
              </Switch>
              <ModalRoot />
            </>
          </Websocket>
        </ThemeContext.Provider>
        {location.pathname !== "/change-password" && renderPopup()}
        <Alerter />
        <Toaster />
        <Popup />
        {maintenance && <Maintenance />}
      </main>
    </DndProvider>
  );
};

const APP = (props: any) => {
  const getParams = Object.fromEntries(new URLSearchParams(location.search) as any);
  const { otp } = getParams;

  return otp ? <Authorization otp={otp} /> : <App {...props} />;
};
export default APP;
