import React from "react";
import "./SelectDateButton.scss";
import { connect } from "react-redux";
import { skillsActions } from "~/store/skills";
import { modalActions } from "~/store/modal";

interface DispatchProps {
  openModal: (modalId: string, modalProps: any) => void;
}

interface OwnProps {
  disabled?: boolean;
  endDate?: string | null;
  isDateRange?: boolean | null;
  startDate?: string | null;
  showIncludeWeekends?: boolean | null;
  includeWeekends?: boolean | null;
}

type Props = DispatchProps & OwnProps;

const mapDispatchToProps = {
  loadSkills: skillsActions.loadSkills,
  openModal: modalActions.openModal,
};

export default connect(
  null,
  mapDispatchToProps
)((props: Props) => {
  function openDatepicker() {
    props.openModal("AVAILABILITY_MODAL", {
      isChatMessage: true,
      side: "left",
    });
  }

  return (
    <button disabled={!!props.disabled} className="SelectDateButton" onClick={openDatepicker} type="button">
      <i className="icon-back"></i>Select Date
      {props.isDateRange && " Range"}
    </button>
  );
});
