import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import Loader from "~/components/common/Loader/Loader";
import InvoiceForm from "~/components/forms/InvoiceForm/InvoiceForm";
import Sidebar from "~/components/Sidebar/Sidebar";
import SidePanel from "~/components/SidePanel/SidePanel";
import useTalent, { IInvoiceReadyBooking } from "~/hooks/useTalent";
import { IState } from "~/store/reducers";

export default function CreateInvoice(props: RouteComponentProps<{ id: string }>) {
  const authenticatedUser = useSelector((state: IState) => state.users.authenticatedUser);
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const { isFetching, getInvoiceReadyBookings, invoiceReadyBookings } = useTalent();

  if (authenticatedUser?.talent?.invoices_available === false) return <Redirect to="/" />;

  const {
    match: {
      params: { id },
    },
  } = props;

  useEffect(() => {
    getInvoiceReadyBookings();
  }, []);

  const booking: IInvoiceReadyBooking | undefined = useMemo(
    () => invoiceReadyBookings?.find((item) => item.booking_id === +id),
    [invoiceReadyBookings, id]
  );

  const isDataReady = !!authenticatedUser && !!booking && !isFetching;

  if (isFetching)
    return (
      <div className="centered">
        <Loader />
      </div>
    );

  return (
    <Sidebar className="ChatView" isOpen={isSidebarOpen} close={() => setSidebarOpen(!isSidebarOpen)}>
      <SidePanel />
      <div className="content">
        {isDataReady ? (
          <InvoiceForm isNew={true} booking={booking} />
        ) : (
          <p className="m-auto">No matching bookings here, sorry</p>
        )}
      </div>
    </Sidebar>
  );
}
