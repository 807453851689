import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "~/store/modal";
import Modal from "../AbstractModal/AbstractModal";
import { conversationActions } from "~/store/chat";
import moment, { Moment } from "moment";
import ApInfiniteCalendar from "./Calendar";
import ConfirmDates from "./ConfirmDates";
import "./CalendarModal.scss";

interface Props {
  side: string;
  isDateRange: boolean;
  showIncludeWeekends: boolean;
  includeWeekends: boolean;
  startDate: string;
  endDate: string;
  dateFormat?: string;
  onSubmit?: any;
}
interface IDate {
  endDate: Moment;
  startDate: Moment;
  isWeekendsIncluded: boolean;
  isConfirmDates: boolean;
}

const getFormatedDate = (date: Moment, format?: string) => date.format(format || "ddd DD MMM, YYYY");

const CalendarModal = (props: Props) => {
  const dispatch = useDispatch();
  const connected = useSelector((state: any) => state.websocket.connected);
  const { isDateRange, showIncludeWeekends, side } = props;
  const [isWeekendsIncluded, setIsWeekendsIncluded] = useState(props.includeWeekends);
  const [isConfirmDates, setIsConfirmDates] = useState(false);
  const confirmDatesTitle = () => <h1>Please, review brief dates</h1>;

  const [startDate, setStartDate] = useState(
    props.startDate !== "null" && typeof props.startDate === "string" ? moment(props.startDate) : moment()
  );

  const [endDate, setEndDate] = useState(
    props.endDate !== "null" && typeof props.endDate === "string"
      ? moment(props.endDate)
      : props.startDate !== "null" && typeof props.startDate === "string"
      ? moment(props.startDate)
      : moment()
  );

  const handleConfirmDates = () => {
    const today = moment().startOf("day");
    const startD = startDate.format(props.dateFormat || "YYYY-MM-DD");
    const endD = endDate ? endDate.format("YYYY-MM-DD") : startD;

    if (connected) {
      let text_content = startDate ? getFormatedDate(startDate, props.dateFormat) : getFormatedDate(today);

      if (isDateRange) {
        text_content += " - ";
        text_content += endDate
          ? getFormatedDate(endDate)
          : startDate
          ? getFormatedDate(startDate)
          : getFormatedDate(today);
      }

      const payload = isDateRange ? `${startD},${endD},${+isWeekendsIncluded}` : `${startD}`;

      if (!props.onSubmit) {
        dispatch(
          conversationActions.sendMessage({
            text_content: text_content,
            payload: { payload },
          })
        );
      } else {
        props.onSubmit(payload);
      }

      dispatch(modalActions.closeModal());
    }
  };

  const handleValidateBrief = () => {
    !endDate && setEndDate(startDate);
    setIsConfirmDates(true);
  };

  const setSelectedDate = ({ startDate, endDate }: IDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const CalendarHeader = () =>
    isDateRange ? (
      <>
        <h1>Select Date Range</h1>
        <span>{`${startDate && getFormatedDate(startDate)} — ${
          endDate ? getFormatedDate(endDate) : startDate && getFormatedDate(startDate)
        }`}</span>
      </>
    ) : (
      <>
        <h1>Select Date</h1>
        <span>{`${startDate && getFormatedDate(startDate)}`}</span>
      </>
    );

  const Buttons = () => {
    let buttons = [
      {
        className: "primary",
        disabled: isDateRange ? startDate === null : startDate === null && endDate === null,
        text: showIncludeWeekends && !isConfirmDates ? "Review" : "Confirm",
        onClick: showIncludeWeekends && !isConfirmDates ? handleValidateBrief : handleConfirmDates,
      },
    ];
    showIncludeWeekends &&
      isConfirmDates &&
      buttons.unshift({
        text: "Go Back",
        onClick: () => setIsConfirmDates(false),
      } as any);
    return buttons;
  };

  return (
    <Modal
      className="CalendarModal"
      headerLeftButton={<button className="invisible" type="button"></button>}
      headerRightButton={
        <button type="button" onClick={() => dispatch(modalActions.closeModal())}>
          <i className="icon-cancel"></i>
        </button>
      }
      headerContent={true}
      buttons={Buttons()}
      title={isConfirmDates ? confirmDatesTitle() : CalendarHeader()}
      side={side}
    >
      {isConfirmDates ? (
        <ConfirmDates
          title={confirmDatesTitle}
          startDate={startDate}
          endDate={endDate}
          isWeekendsIncluded={isWeekendsIncluded}
        />
      ) : (
        <ApInfiniteCalendar
          header={CalendarHeader}
          currStartDate={startDate}
          currEndDate={endDate}
          setSelectedDate={setSelectedDate}
          isWeekendsIncluded={isWeekendsIncluded}
          setIsWeekendsIncluded={(isWeekendsIncluded: boolean) => setIsWeekendsIncluded(isWeekendsIncluded)}
          {...props}
        />
      )}
    </Modal>
  );
};

export default CalendarModal;
