import React, { MutableRefObject, useEffect, useRef } from "react";
import { Messages } from "primereact/messages";
import "./Alerter.scss";
import { useSelector } from "react-redux";

export default function Alerter() {
  const messagesRef = useRef() as MutableRefObject<Messages>;
  const alerts = useSelector((state: any) => state.toasts.alerts);

  useEffect(() => {
    messagesRef.current.replace(alerts);
  }, [alerts]);

  return <Messages className="Alerter" ref={messagesRef}></Messages>;
}
