import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable, DataTableRowClickEventParams } from "primereact/datatable";
import { Menu } from "primereact/menu";
import { MenuItemCommandParams } from "primereact/menuitem";
import { Paginator } from "primereact/paginator";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { IInvoice, ILineItem } from "~/API/InvoiceService";
import Loader from "~/components/common/Loader/Loader";
import useInvoice from "~/hooks/useInvoice";
import { modalActions } from "~/store/modal";
import { IState } from "~/store/reducers";
import { usersActions } from "~/store/user";
import { compareArrays, conditionalWrapper } from "~/utils";
import "../Invoice.scss";
import InvoiceDetails from "../InvoiceDetails/InvoiceDetails";
import { getColumns } from "./columns";
import { getInvoiceStatusData } from "./helpers";
import InvoiceDetailMobile from "./InvoiceDetailMobile";
import InvoiceListMobile from "./InvoiceListMobile";
import "./InvoicesListView.scss";
import { setPopup } from "~/store/toasts";
import { getIR35InsidePopup } from "~/config";
import moment from "moment";

export type IInvoiceFormValues = {
  lineItems: ILineItem[];
  notes: string;
  agree: boolean;
  includeVAT: boolean;
  subtotal: number;
  subtotalVAT: number;
  total: number;
};

const InvoicesListView = (props: RouteComponentProps) => {
  const menuRefs = useRef<Menu[]>([]);
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [expandedRows, setExpandedRows] = useState([]);
  const [activeInvoice, setActiveInvoice] = useState<IInvoice | null>(null);
  const { insurance_number } = useSelector((state: IState) => state.users?.paymentProfile);

  const {
    isFetching,
    getInvoices,
    putInvoice,
    validateBankProfile,
    invoices,
    total: totalRecords,
    validateInsuranceNumber,
    getValidatedInvoice,
  } = useInvoice();

  useEffect(() => {
    getInvoices({ page });
  }, [page]);

  useEffect(() => {
    dispatch(usersActions.getPaymentProfile());
  }, []);

  const renderColumn = ({ name, sortable, cellCallback, field, style }: any) => (
    <Column key={name} field={field} body={cellCallback} header={name} sortable={sortable} className={style} />
  );

  const handleRowClick = (e: DataTableRowClickEventParams) => {
    e.originalEvent.preventDefault();
    setExpandedRows((expRows: any) => {
      const currentRowId = e.data.id;
      const isExpandCurrentElement = !!~expRows.findIndex((row: IInvoice) => row.id === currentRowId);
      return isExpandCurrentElement ? expRows.filter((row: IInvoice) => row.id !== currentRowId) : [...expRows, e.data];
    });
  };

  const renderColumnStatusChange = (rowData: IInvoice, { rowIndex }: { rowIndex: number }) =>
    rowData.available_statuses?.length ? (
      <>
        <Button
          icon="pi pi-ellipsis-h"
          onClick={(event) => {
            event.stopPropagation();
            null !== menuRefs.current && menuRefs.current[rowIndex].toggle(event);
          }}
        />
        <Menu
          model={getActionButtons(rowData)}
          popup
          ref={(el: Menu) => null !== menuRefs.current && (menuRefs.current[rowIndex] = el)}
          className="action-buttons"
          appendTo={document.getElementsByTagName("body")[0]}
        />
      </>
    ) : null;

  const assembleInvoiceValues = (invoice: IInvoice) => {
    return {
      line_items: invoice.line_items.map(({ dates, ...item }) => ({
        ...item,
        start_date: dates?.[0] && moment(dates[0]).format("YYYY-MM-DD"),
        end_date: dates?.[1] && moment(dates[1]).format("YYYY-MM-DD"),
        line_amount: undefined,
      })),
      notes: invoice.notes,
      status: invoice.status,

      currency_code: "GBP",
      currency_rate: 1,
      date: invoice?.date || "",
      due_date: invoice?.due_date || "",

      tax_type: invoice.tax_type,
    } as IInvoice;
  };

  const getActionButtons = (rowData: IInvoice) => [
    ...(["DRAFT", "DISPUTED"].includes(rowData.status || "")
      ? [
          {
            label: "Edit",
            command: () => props.history.push(`/invoices/${rowData.id}`),
          },
        ]
      : []),
    ...(rowData.available_statuses || []).map((status) => ({
      label: getInvoiceStatusData(status, true).labelStatus,
      command: async (e: MenuItemCommandParams) => {
        e.originalEvent.stopPropagation();
        if (status === "SUBMITTED") {
          const isInsideIr35 = rowData?.booking?.ir35_code === "IR35_INSIDE";
          const submitInvoice = () =>
            validateInsuranceNumber(rowData?.booking?.ir35_code, insurance_number) &&
            conditionalWrapper(true, () => putInvoice({ id: rowData.id, status }), validateBankProfile);

          if (!isInsideIr35) {
            submitInvoice();
            return;
          }
          const validatedInvoiceNumbers = await getValidatedInvoice(
            rowData?.booking?.id,
            assembleInvoiceValues(rowData)
          );
          const isValidInvoice =
            rowData.total === validatedInvoiceNumbers.total &&
            rowData.subtotal === validatedInvoiceNumbers.subtotal &&
            compareArrays(rowData.line_items, validatedInvoiceNumbers.line_items, "line_amount");

          if (isValidInvoice) {
            submitInvoice();
          } else {
            dispatch(
              setPopup({
                content: (
                  <div
                    className="IR35InsideDisclaimer IR35InsideDisclaimer-bold-numbers"
                    dangerouslySetInnerHTML={{ __html: getIR35InsidePopup(validatedInvoiceNumbers) }}
                  />
                ),
                buttons: [
                  { text: "Cancel" },
                  {
                    text: "Submit",
                    callback: () => {
                      submitInvoice();
                      dispatch(modalActions.closeModal());
                    },
                  },
                ],
              })
            );
          }
        } else {
          putInvoice({ id: rowData.id, status });
        }
      },
    })),
  ];

  return (
    <div className="InvoicesList">
      <header>
        <h1>
          <Link to={`/`} className="mr-3">
            <i className="icon-back"></i>
          </Link>
          My Invoices
        </h1>
      </header>
      {isFetching && !invoices?.length && <Loader />}
      {!isFetching && (
        <div className="actions-buttons flex justify-content-end">
          <Button
            className="p-button p-component"
            onClick={() => dispatch(modalActions.openModal("SELECT_BOOKING_MODAL", { _onlyOne: true }))}
            icon="pi pi-plus"
            label="Create Invoice"
          />
        </div>
      )}
      {null !== invoices && (
        <>
          <DataTable
            autoLayout={true}
            className="InvoicesList__Table phone:hidden"
            emptyMessage="Not found"
            loading={isFetching}
            expandedRows={expandedRows}
            onRowClick={handleRowClick}
            rowExpansionTemplate={(rowData: IInvoice) => <InvoiceDetails invoice={rowData} />}
            value={invoices}
            rowClassName={({ id: invoiceId }: { id: number }) => {
              const isExpanded = expandedRows.map(({ id }: { id: number }) => id).includes(invoiceId);
              return {
                expanded: isExpanded,
                collapsed: !isExpanded,
              };
            }}
          >
            <Column className="toggler" />
            {getColumns().map(renderColumn)}
            <Column body={renderColumnStatusChange} />
          </DataTable>
          <InvoiceListMobile invoices={invoices} setActiveInvoice={setActiveInvoice} />
          {!!activeInvoice && (
            <InvoiceDetailMobile
              activeInvoice={activeInvoice}
              setActiveInvoice={setActiveInvoice}
              putInvoice={putInvoice}
              validateBankProfile={validateBankProfile}
              insurance_number={insurance_number}
              validateInsuranceNumber={validateInsuranceNumber}
            />
          )}
          <Paginator
            rows={15}
            first={(page - 1) * 15}
            totalRecords={totalRecords}
            onPageChange={({ page }) => setPage(page + 1)}
          />
        </>
      )}
    </div>
  );
};

export default withRouter(InvoicesListView);
