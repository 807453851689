import classnames from "classnames";
import { Field, FieldInputProps, FieldMetaProps } from "formik";
import React from "react";
import "./GmInputTextarea.scss";

interface IGmInputTextarea {
  formik?: any;
  noField?: boolean;
  className?: string;
  id: string;
  label?: string;
  maxChars?: number;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
}

export default function GmInputTextarea(props: IGmInputTextarea) {
  let meta, field;
  if (props.noField && props.formik) {
    meta = props.formik.getFieldMeta(props.id);
    field = props.formik.getFieldProps(props.id);
  }

  return props.noField && meta ? (
    <div
      className={classnames("GmInputTextarea", props.className, {
        error: meta.touched && meta.error,
      })}
    >
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      <textarea
        disabled={props.disabled}
        id={props.id}
        maxLength={props.maxChars}
        placeholder={props.placeholder}
        required={props.required}
        {...field}
      ></textarea>
      {props.maxChars && (
        <div className="charCount p-text-right">{props.maxChars - field.value.length} characters left</div>
      )}
      {meta.touched && meta.error && <sub>{meta.error}</sub>}
    </div>
  ) : (
    <Field name={props.id}>
      {({ field, meta }: { field: FieldInputProps<any>; meta: FieldMetaProps<any> }) => (
        <div
          className={classnames("GmInputTextarea", props.className, {
            error: meta.touched && meta.error,
          })}
        >
          {props.label && <label htmlFor={props.id}>{props.label}</label>}
          <textarea
            disabled={props.disabled}
            id={props.id}
            maxLength={props.maxChars}
            placeholder={props.placeholder}
            required={props.required}
            {...field}
          ></textarea>
          {props.maxChars && (
            <div className="charCount p-text-right">{props.maxChars - field.value.length} characters left</div>
          )}
          {meta.touched && meta.error && <sub>{meta.error}</sub>}
        </div>
      )}
    </Field>
  );
}
