import classNames from "classnames";
import React, { PureComponent, ChangeEvent } from "react";
import { EMAIL_REGEXP } from "~/config";
import "./ThemedInput.scss";

interface Props {
  autoComplete?: string;
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  eye?: boolean;
  forceError?: boolean;
  icon?: string;
  iconPos?: "left" | "right";
  id?: string;
  label?: string;
  match?: string;
  maxLength?: number;
  max?: number;
  min?: number;
  name?: string;
  onBlur?: any;
  onChange?: any;
  onClick?: any;
  onFocus?: any;
  onKeyDown?: any;
  placeholder?: string;
  required?: boolean;
  tag?: string;
  type?: string;
  validation?: string;
  value?: string;
  defaultValue?: string;
  customValidationMessage?: string;
}

type State = {
  focus: boolean;
  showPassword: boolean;
  valid: boolean | string;
};

export default class ThemedInput extends PureComponent<Props, State> {
  state = {
    focus: false,
    showPassword: false,
    valid: true,
  };

  handleFocus = () => {
    this.setState({ focus: true }, () => {
      if (this.props.onFocus) this.props.onFocus();
    });
  };

  handleBlur = () => {
    this.setState({ focus: false }, () => {
      if (this.props.onBlur) this.props.onBlur();
    });
  };

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const { id, value } = e.target;
    if (this.props.validation === "number") {
      this.setState({ valid: this.validate(value) }, () => {
        if (this.state.valid === true && Number.isNaN(+value) !== true) {
          this.props.onChange(id, value === "" ? "" : +value);
        }
      });
    } else {
      this.setState({ valid: this.validate(value) }, () => this.props.onChange(id, value));
    }
  };

  showPassword = (e: React.TouchEvent<HTMLButtonElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    this.setState({ showPassword: true });
  };

  togglePassword = (e: React.TouchEvent<HTMLButtonElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    this.setState((prevState: State) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  hidePassword = (e: React.FocusEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    this.setState({ showPassword: false });
  };

  validate = (value: string) => {
    const { required, customValidationMessage, validation, match } = this.props;

    if (required && value === "") {
      return customValidationMessage || "This field is required.";
    }
    if (value !== "" && validation === "email" && !EMAIL_REGEXP.test(value)) {
      return "Hmmm, something not quite right with your email address.";
    }
    if (value !== "" && validation === "match" && value !== match) {
      return "Probably a typo but those two new passwords don't quite match.";
    }
    if (value !== "" && validation === "number" && !/^\d*$/.test(value)) {
      return "This field is numeric.";
    }
    return true;
  };

  render() {
    const Tag = this.props.tag || "input";
    return (
      <div
        className={classNames("ThemedInput", {
          "p-input-icon-left": this.props.iconPos === "left",
          "p-input-icon-right": this.props.iconPos === "right",
        })}
      >
        {this.props.label && (
          <label className={classNames({ mandatory: this.props.required })} htmlFor={this.props.id}>
            {this.props.label}
          </label>
        )}
        <div>
          {this.props.icon && <i className={classNames("pi", this.props.icon)}></i>}
          <Tag
            autoFocus={this.props.autoFocus ? true : false}
            autoComplete={this.props.autoComplete}
            className={classNames(this.props.className || "input", {
              error: (this.props.validation && this.state.valid !== true) || this.props.forceError,
            })}
            disabled={this.props.disabled}
            id={this.props.id}
            maxLength={this.props.validation === "phone" ? 16 : this.props.maxLength}
            max={this.props.max}
            min={this.props.min}
            name={this.props.name}
            onClick={this.props.onClick}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onKeyDown={this.props.onKeyDown}
            placeholder={this.props.placeholder}
            required={this.props.required}
            type={
              this.props.type === "password" && this.props.eye && this.state.showPassword ? "text" : this.props.type
            }
            defaultValue={this.props.defaultValue}
            value={
              this.props.validation === "phone"
                ? this.props.value
                  ? this.props.value.charAt(0) === "+"
                    ? this.props.value
                    : "+" + this.props.value
                  : ""
                : this.props.value || ""
            }
          />
          {this.props.type === "password" && this.props.eye && (
            <button
              className="eye"
              data-for="new"
              type="button"
              onTouchStart={this.showPassword}
              onTouchEnd={this.togglePassword}
              onMouseDown={this.showPassword}
              onMouseUp={this.togglePassword}
              onBlur={this.hidePassword}
            >
              <i className="icon-eye"></i>
            </button>
          )}
        </div>
        {this.props.validation && (
          <sub
            className={classNames("prt-valid-text", {
              error: this.state.valid !== true,
            })}
          >
            {this.state.valid}
          </sub>
        )}
      </div>
    );
  }
}
