import React from "react";
import { Attachment } from "../../store/chat";

export default ({ attachments }: { attachments: Attachment[] }) => {
  return !attachments ? null : (
    <div className="Attachments">
      {attachments.map((attachment: Attachment, key: number) => {
        switch (attachment.type) {
          case "image":
            return <img src={attachment.url} key={key} />;
          default:
            return null;
        }
      })}
    </div>
  );
};
