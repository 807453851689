import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { API_URL, API_PLACEHOLDER } from "./../../config";
import "./ResponseCard.scss";

export interface IResponseCardButton {
  messenger_extensions: boolean;
  payload?: string;
  title: string;
  type: "postback" | "web_url";
  url?: string;
  webview_height_ratio: string;
  webview_share_button: string;
  animation?: { [key: string]: string };
}

export interface IResponseCard {
  buttons: IResponseCardButton[];
  image_url?: string;
  imageUrl?: string;
  onPostBack: Function;
  subtitle?: string;
  subTitle?: string;
  title: string;
}

export default (card: IResponseCard) => {
  const connected = useSelector((state: any) => state.websocket.connected);

  const buttons =
    card.buttons && !Array.isArray(card.buttons)
      ? Object.keys(card.buttons).map(
          (payload) =>
            ({
              payload,
              title: card.buttons[payload],
            } as IResponseCardButton)
        )
      : card.buttons;

  return (
    <div className="ResponseCard">
      {(card.image_url || card.imageUrl) && <img src={card.image_url || card.imageUrl} />}
      <div>
        {(card.title || card.subtitle) && (
          <header>
            {card.title && (
              <h2>
                {card.title.split("|").map((item, i) => (
                  <div key={i}>
                    <span>{item}</span>
                    <br />
                  </div>
                ))}
              </h2>
            )}
            {(card.subtitle || card.subTitle) && (
              <p>
                {(card.subtitle || card.subTitle)?.split("|").map((item, i) => (
                  <span key={i}>
                    <span>{item}</span>
                    <br />
                  </span>
                ))}
              </p>
            )}
          </header>
        )}
        {buttons && buttons.length > 0 && (
          <ul>
            {buttons.map((button: IResponseCardButton, i: number) => (
              <li key={i}>
                {button.type === "web_url" ? (
                  button.url && button.url.indexOf(API_PLACEHOLDER) === 0 ? (
                    <a href={button.url.replace(API_PLACEHOLDER, API_URL)} target="_blank" rel="nofollow">
                      {button.title}
                    </a>
                  ) : (
                    <a href={button.url} target="_blank" rel="nofollow">
                      {button.title}
                    </a>
                  )
                ) : (
                  <span
                    className={classNames({
                      disabled: !connected,
                    })}
                    onClick={card.onPostBack.bind(null, button.payload, button.title, button.animation)}
                  >
                    {button.title}
                  </span>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
