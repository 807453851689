import { Component, ReactNode } from "react";
import { connect } from "react-redux";
import socketCluster from "socketcluster-client";
import { wsOptions } from "~/config";
import { conversationActions, IMessage } from "~/store/chat";
import { locationChange } from "~/store/location";
import { usersActions } from "~/store/user";
import { websocketActions } from "~/store/websocket";

interface StateProps {
  isAuthenticated: boolean;
  authenticatedUser: any;
  messages: IMessage[];
}

interface DispatchProps {
  changeMessageStatus: (message: any) => void;
  setWebsocketClose: () => void;
  setWebsocketInstance: (instance: any) => void;
  receiveMessage: (message: any) => void;
  logoutUser: (cb: any) => void;
  onWSTokenRemove: (oldToken: string) => void;
  getUserData: (token: string) => Promise<any>;
  loadMessages: () => Promise<any>;
}

interface OwnProps {
  children: ReactNode;
}

export type Props = StateProps & DispatchProps & OwnProps;

const mapDispatchToProps = {
  changeMessageStatus: conversationActions.changeMessageStatus,
  setWebsocketClose: websocketActions.setWebsocketClose,
  setWebsocketInstance: websocketActions.setWebsocketInstance,
  receiveMessage: conversationActions.receiveMessage,
  logoutUser: usersActions.logoutUser,
  onWSTokenRemove: usersActions.onWSTokenRemove,
  getUserData: usersActions.getUserData,
  loadMessages: conversationActions.loadMessages,
};

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.users.isAuthenticated,
  authenticatedUser: state.users.authenticatedUser,
  messages: state.chat.messages,
});

class Websocket extends Component<Props> {
  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.initialWebsocket();
    }
  }
  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isAuthenticated && this.props.isAuthenticated) {
      this.initialWebsocket();
    }
  }
  initialWebsocket = () => {
    const ws = socketCluster.create(wsOptions);
    // Store websocket instance
    ws.on("connect", () => this.props.setWebsocketInstance(ws));
    ws.on("disconnect", this.props.setWebsocketClose);
    // Add message handlers
    ws.on("error", this.handleError);
    ws.on("message_in", this.handleIncomingMessage);
    ws.on("message_status", this.handleMessageStatus);
    //refresh token handler
    ws.on("removeAuthToken", this.props.onWSTokenRemove);
    ws.on("authenticate", (token: string) => {
      const { messages, loadMessages, getUserData } = this.props;
      if (!messages.length) loadMessages();
      getUserData(token).catch((err) => {
        if (err.response.status === 401) {
          this.props.onWSTokenRemove(token);
        }
      });
    });
    ws.on("reload_user", this.handleReloadUser);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleLogout = () => {
    localStorage.clear();
    this.props.logoutUser(() => locationChange("/login"));
  };

  handleIncomingMessage = (message: any, res: any) => {
    this.props.receiveMessage(message);
    res(null, true);
  };

  handleMessageStatus = (message: any, res: any) => {
    this.props.changeMessageStatus(message);
    res(null, true);
  };

  handleReloadUser = (_message: any, res: any) => {
    res(null, true);
    this.props.getUserData(this.props.authenticatedUser.token);
  };

  render() {
    return this.props.children;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Websocket);
