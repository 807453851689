import React from "react";
import "./IncludeWeekendsCheckbox.scss";
import { InputSwitch } from "primereact/inputswitch";

export interface IProps {
  isWeekendsIncluded: boolean;
  setIsWeekendsIncluded: (data: object) => void;
}
const IncludeWeekendsCheckbox = ({ isWeekendsIncluded, setIsWeekendsIncluded }: IProps) => (
  <>
    <div className="include-weekends">
      <InputSwitch
        id="includeWeekends"
        checked={isWeekendsIncluded}
        onChange={(e: any) => setIsWeekendsIncluded(e.value)}
      />
      <label htmlFor="includeWeekends">Include weekend days</label>
    </div>
  </>
);

export default IncludeWeekendsCheckbox;
