import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { IResponseCardButton } from "./ResponseCard";
import "./ResponseCard.scss";

export interface IResponseCard {
  ir35_code: null;
  buttons: IResponseCardButton[];
  imageUrl: string;
  onPostBack: Function;
  subtitle: string;
  title: string;
}

export default (card: IResponseCard) => {
  const connected = useSelector((state: any) => state.websocket.connected);
  return (
    <div className="ResponseCard">
      {card.imageUrl && <img src={card.imageUrl} />}
      <div>
        {(card.title || card.subtitle) && (
          <header>
            {card.title && (
              <h2>
                {card.title.split("|").map((item, i) => (
                  <div key={i}>
                    <span>{item}</span>
                    <br />
                  </div>
                ))}
              </h2>
            )}
            {card.subtitle && (
              <p>
                {card.subtitle.split("|").map((item, i) => (
                  <span key={i}>
                    <span>{item}</span>
                    <br />
                  </span>
                ))}
              </p>
            )}
          </header>
        )}
        {card.buttons && !!Object.keys(card.buttons).length && (
          <ul>
            {Object.keys(card.buttons).map((key, i: number) => (
              <li key={i}>
                <span
                  className={classNames({
                    disabled: !connected,
                  })}
                  onClick={card.onPostBack.bind(null, key, card.buttons[key])}
                >
                  {card.buttons[key]}
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
