import classNames from "classnames";
import React, { ChangeEvent, KeyboardEvent, PureComponent } from "react";
import { connect } from "react-redux";
import DropZone from "~/components/common/DropZone/DropZone";
import { modalActions } from "~/store/modal";
import { setToasts } from "~/store/toasts";
import Modal from "../AbstractModal/AbstractModal";
import "./SectorsModal.scss";

interface StateProps {
  selectedSkills: string[];
  skills: any[];
}

interface DispatchProps {
  closeModal: () => void;
  setToasts: (data: any) => void;
}

interface OwnProps {
  onSubmit: Function;
  presetSectors: any[];
  side: string;
  selected?: any[];
  max?: number;
}
export type Props = StateProps & DispatchProps & OwnProps;
export type State = {
  custom_skills: string[];
  selected_skills: {
    custom_skills: string[];
    skills: {
      [x: string]: string;
    };
  };
  step: number;
  prioritised_skills: { id: number; name: string }[];
  newSkill: string;
};

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  setToasts,
};

const mapStateToProps = (state: any) => ({
  skills: state.skills.skills,
});

class SectorsModal extends PureComponent<Props, State> {
  state = {
    custom_skills: [],
    prioritised_skills: [],
    selected_skills: {
      custom_skills: [],
      skills: {},
    },
    step: 0,
    newSkill: "",
  };

  componentDidMount() {
    if (this.props.selected) {
      const custom_skills = this.props.selected.filter((skill) => skill.id < 1).map((skill) => skill.name);
      const skills = this.props.selected.filter((skill) => skill.id > 0);
      this.setState({
        custom_skills,
        prioritised_skills: this.props.selected,
        selected_skills: {
          custom_skills,
          skills: skills.reduce((acc, cur) => {
            acc[cur.id] = cur.name;
            return acc;
          }, {}),
        },
      });
    }
  }

  addSkills = () => {
    const prioritised_skills =
      this.state.step === 1
        ? this.state.prioritised_skills
        : [
            ...this.state.selected_skills.custom_skills.map((cskill, key) => ({
              id: 0 - key,
              name: cskill,
            })),
            ...Object.keys(this.state.selected_skills.skills).map((skillkey: string) => ({
              id: +skillkey,
              name: this.state.selected_skills.skills[skillkey],
            })),
          ];

    this.props.onSubmit(
      "sectors",
      prioritised_skills.map((skill, key: number) => ({
        ...skill,
        order_id: key + 1,
      }))
    );
  };

  handleAddNewSkill = (e: KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (e.keyCode !== 13) {
      return;
    }

    const { newSkill, custom_skills, selected_skills } = this.state;
    const { setToasts, max, presetSectors } = this.props;
    if (!newSkill.trim()) {
      setToasts([
        {
          severity: "warn",
          summary: "",
          detail: "Sorry, smth must be wrong with this sector’s format",
        },
      ]);
      this.setState({ newSkill: "" });
      return;
    }

    const presetSectorNames = presetSectors.map((sector) => sector.name);
    if (
      [...presetSectorNames, ...custom_skills].some(
        (skill: string) => skill.toLowerCase() === newSkill.toLowerCase().trim()
      )
    ) {
      setToasts([
        {
          severity: "warn",
          summary: "",
          detail: "This sector is already in the list",
        },
      ]);
      return;
    }

    const count = selected_skills.custom_skills.length + Object.keys(selected_skills.skills).length;

    if (!max || (max && count < max)) {
      this.setState({
        custom_skills: [newSkill, ...custom_skills],
        selected_skills: {
          ...selected_skills,
          custom_skills: [...selected_skills.custom_skills, newSkill],
        },
        newSkill: "",
      });
    } else {
      this.setState({
        custom_skills: [newSkill, ...custom_skills],
        newSkill: "",
      });
    }
  };

  handleNewSkillChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    this.setState({ newSkill: e.target.value });
  };

  selectCustomSkill = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    const {
      dataset: { value },
    } = e.target as HTMLLinkElement;

    const count =
      this.state.selected_skills.custom_skills.length + Object.keys(this.state.selected_skills.skills).length;

    if (value) {
      const index = this.state.selected_skills.custom_skills.indexOf(value);
      if (index !== -1) {
        const tmp = [...this.state.selected_skills.custom_skills];
        tmp.splice(index, 1);
        this.setState({
          selected_skills: {
            ...this.state.selected_skills,
            custom_skills: tmp,
          },
        });
      } else if (this.props.max === undefined || (this.props.max !== undefined && count < this.props.max)) {
        this.setState({
          selected_skills: {
            ...this.state.selected_skills,
            custom_skills: [...this.state.selected_skills.custom_skills, value],
          },
        });
      }
    }
  };

  selectSkill = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    const {
      dataset: { value },
    } = e.target;

    const count =
      this.state.selected_skills.custom_skills.length + Object.keys(this.state.selected_skills.skills).length;

    if (Object.keys(this.state.selected_skills.skills).includes(value)) {
      const skills = { ...this.state.selected_skills.skills };
      delete skills[value];
      this.setState({
        selected_skills: {
          ...this.state.selected_skills,
          skills,
        },
      });
    } else if (this.props.max === undefined || (this.props.max !== undefined && count < this.props.max)) {
      const skill = this.props.presetSectors
        ? this.props.presetSectors.find((skill) => skill.id === +value).name
        : this.props.skills.find((skill) => skill.id === +value).name;
      this.setState({
        selected_skills: {
          ...this.state.selected_skills,
          skills: {
            ...this.state.selected_skills.skills,
            [value]: skill,
          },
        },
      });
    } else {
      this.props.setToasts([
        {
          severity: "warn",
          summary: "",
          detail: `Sorry, only ${this.props.max} items can be selected`,
        },
      ]);
    }
  };

  nextStep = () =>
    this.setState((prevState: State) => ({
      step: 1,
      prioritised_skills: [
        ...prevState.selected_skills.custom_skills.map((cskill, key) => ({
          id: 0 - key,
          name: cskill,
        })),
        ...Object.keys(prevState.selected_skills.skills).map((skillkey: string) => ({
          id: +skillkey,
          name: prevState.selected_skills.skills[skillkey],
        })),
      ],
    }));
  prevStep = () => this.setState({ step: 0 });

  renderSelectSkills = () => (
    <ul>
      <li className="skill">
        <input
          onKeyDown={this.handleAddNewSkill}
          onChange={this.handleNewSkillChange}
          placeholder="+ Add your own sector"
          type="text"
          value={this.state.newSkill}
        />
      </li>
      {this.state.custom_skills &&
        this.state.custom_skills.map((skill: string, i: number) => (
          <li
            className={classNames("skill", {
              selected: this.state.selected_skills.custom_skills.includes(`${skill}`),
            })}
            data-value={`${skill}`}
            key={i}
            onClick={this.selectCustomSkill}
          >
            {skill}
          </li>
        ))}
      {(this.props.presetSectors &&
        this.props.presetSectors.map((skill, i: number) => (
          <li
            className={classNames("skill", {
              selected: Object.keys(this.state.selected_skills.skills).includes(`${skill.id}`),
            })}
            data-value={`${skill.id}`}
            key={i}
            onClick={this.selectSkill}
          >
            {skill.name}
          </li>
        ))) ||
        (this.props.skills &&
          this.props.skills.map((skill, i: number) => (
            <li
              className={classNames("skill", {
                selected: Object.keys(this.state.selected_skills.skills).includes(`${skill.id}`),
              })}
              data-value={`${skill.id}`}
              key={i}
              onClick={this.selectSkill}
            >
              {skill.name}
            </li>
          )))}
    </ul>
  );

  renderPrioritiseSkills = () => (
    <>
      <p>
        Hold & drag into order pls
        <br />
        (most important on top)
      </p>
      <DropZone
        items={this.state.prioritised_skills}
        itemClass="skill selected prioritise"
        setItems={(items: any) => this.setState({ prioritised_skills: items })}
      />
      <p>(Again, I'm not binary - this also helps me get a better idea of what's in your portfolio/CV)</p>
    </>
  );

  render() {
    const totalSelected =
      Object.keys(this.state.selected_skills.skills).length + this.state.selected_skills.custom_skills.length;
    return (
      <Modal
        className={classNames("SectorsModal", {
          preset: this.props.presetSectors,
        })}
        headerLeftButton={<button className="invisible" type="button"></button>}
        headerRightButton={
          <button type="button" onClick={this.props.closeModal}>
            <i className="icon-cancel"></i>
          </button>
        }
        buttons={
          this.state.step
            ? [
                { text: "Back", onClick: this.prevStep },
                {
                  className: "primary",
                  disabled: totalSelected === 0,
                  text:
                    totalSelected === 0
                      ? "Add Sectors"
                      : totalSelected === 1
                      ? "Add 1 Sector"
                      : totalSelected > 1
                      ? `Add ${totalSelected} Sectors`
                      : "Add Sectors",
                  onClick: this.addSkills,
                },
              ]
            : [
                { text: "Cancel", onClick: this.props.closeModal },
                {
                  className: "primary",
                  disabled: totalSelected === 0,
                  text:
                    totalSelected === 0
                      ? "Add Sectors"
                      : totalSelected === 1
                      ? "Add 1 Sector"
                      : totalSelected > 1
                      ? `Prioritise ${totalSelected} Sectors`
                      : "Prioritise Sectors",
                  onClick: totalSelected > 1 ? this.nextStep : this.addSkills,
                },
              ]
        }
        title={<h1>{this.state.step ? "Prioritise" : "Select"} Sectors</h1>}
        side={this.props.side}
      >
        <h1>{this.state.step ? "Prioritise" : "Select"} Sectors</h1>
        {this.state.step ? this.renderPrioritiseSkills() : this.renderSelectSkills()}
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SectorsModal);
