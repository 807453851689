import Axios from "axios";
import { API_URL } from "~/config";
import { withAuthorizationHeader } from ".";

const setScopeIsViewed = (brief_id: number) => async (_dispatch: any, getState: () => any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { token } = getState().users.authenticatedUser;
      const res = await Axios.post(
        `${API_URL}/api/user/current/event/scope_viewed`,
        { brief_id },
        withAuthorizationHeader(token)
      );
      resolve(res);
    } catch (err) {
      reject(err);
    }
  });
};

export type IBriefState = {
  pendingBriefs: number | undefined;
};

export const briefActions = {
  setScopeIsViewed,
};
