import { Button } from "primereact/button";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/lib/ReactCrop.scss";
import { useDispatch } from "react-redux";
import { modalActions } from "~/store/modal";
import { setAlerts } from "~/store/toasts";
import Modal from "../AbstractModal/AbstractModal";
import "./ImageCropModal.scss";

interface Props {
  side: "left" | "right";
  form: any;
  onSubmit: Function;
  errors?: string[];
  noProfileImage?: boolean;
}

interface Crop {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  aspect: number;
}

let previewImgSrc: boolean | string = false;

export default function ImageCropModal(props: Props) {
  const defaultCrop: Crop = { aspect: 780 / 410 };
  const inputEl = useRef<HTMLInputElement>(document.createElement("input"));

  const [crop, setCrop] = useState(defaultCrop);
  const [original, setOriginal] = useState("");
  const [preview, setPreview] = useState(false);

  const selectImage = (event: ChangeEvent): void => {
    const { files } = event.target as HTMLInputElement;
    if (files && files.length) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = async () => {
        setOriginal(reader.result as string);
        dispatch(
          setAlerts([
            {
              closable: false,
              content: renderAskModal(closeAskModal),
              severity: "warn",
              sticky: true,
            },
          ])
        );
      };
    }
  };

  const onLoad = (img) => {
    const aspect = 780 / 410;
    const width = img.width / aspect < img.height * aspect ? 100 : ((img.height * aspect) / img.width) * 100;
    const height = img.width / aspect > img.height * aspect ? 100 : (img.width / aspect / img.height) * 100;
    const y = (100 - height) / 2;
    const x = (100 - width) / 2;
    setCrop({
      unit: "px",
      width: (img.width * width) / 100,
      height: (img.height * height) / 100,
      x: (img.width * x) / 100,
      y: (img.height * y) / 100,
      aspect,
    });

    return false; // Return false if you set crop state in here.
  };

  const closeAskModal = () => {
    dispatch(setAlerts([]));
  };

  const renderAskModal = (onClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) => {
    return (
      <div className="flex flex-column confirmModal">
        <div className="text-center">
          <i className="pi pi-exclamation-triangle"></i>
          <p>Great, now crop your image to match your profile's dimensions</p>
        </div>
        <footer>
          <Button className="p-button-success" label="Got It" onClick={onClose} type="button" />
        </footer>
      </div>
    );
  };

  const renderInfoModal = (onClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) => {
    return (
      <div className="flex flex-column confirmModal">
        <div className="text-center">
          <i className="pi pi-exclamation-triangle"></i>
          <p>Upload an image of your work, or a logo of a brand you have worked with</p>
        </div>
        <footer>
          <Button className="p-button-success" label="Got It" onClick={onClose} type="button" />
        </footer>
      </div>
    );
  };

  const getCroppedImg = (image: string, crop: Crop): Promise<Blob> => {
    const canvas = document.createElement("canvas");
    return new Promise((resolve, reject) => {
      if (crop.width && crop.height) {
        const { x: cx, y: cy, width: cw, height: ch } = crop;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        if (ctx !== null) {
          const img = document.getElementsByClassName("ReactCrop__image")[0] as HTMLImageElement;
          img.onload = () => {
            const scaleX = img.naturalWidth / img.width;
            const scaleY = img.naturalHeight / img.height;
            ctx.drawImage(
              img,
              (cx as number) * scaleX,
              (cy as number) * scaleY,
              cw * scaleX,
              ch * scaleY,
              0,
              0,
              cw,
              ch
            );
            canvas.toBlob((blob: Blob | null) => {
              if (blob !== null) {
                //@ts-ignore
                resolve(blob);
              } else {
                reject();
              }
            }, "image/jpeg");
          };
          img.src = image;
        } else {
          reject();
        }
      } else {
        reject();
      }
    });
  };

  const saveImage = async () => {
    const img: Blob | void = await getCroppedImg(original, crop);
    img && props.onSubmit(img);
  };

  const cropSetted: boolean = crop.width && crop.height ? crop.width * crop.height > 0 : false;

  const dispatch = useDispatch();

  function closeModal() {
    dispatch(modalActions.closeModal());
  }

  useEffect(() => {
    props.noProfileImage
      ? dispatch(
          setAlerts([
            {
              closable: false,
              content: renderInfoModal(() => {
                closeAskModal();
                inputEl.current.click();
              }),
              severity: "warn",
              sticky: true,
            },
          ])
        )
      : setTimeout(() => inputEl.current.click(), 1000);
  }, []);

  async function getImageSrc() {
    const blob = await getCroppedImg(original, crop);
    previewImgSrc = (window.URL || window.webkitURL).createObjectURL(blob);
  }

  function togglePreview() {
    if (preview) {
      setPreview(false);
    } else {
      getImageSrc().then(() => setPreview(true));
    }
  }

  return (
    <Modal
      className="ImageCropModal"
      headerLeftButton={
        <button type="button" onClick={closeModal}>
          <i className="icon-back"></i>
        </button>
      }
      headerRightButton={<button className="invisible" type="button"></button>}
      buttons={[
        {
          className: "primary",
          disabled: !cropSetted,
          onClick: saveImage,
          text: `Done`,
        },
      ]}
      title={<h1>{props.form ? "Edit" : "Add"} Image</h1>}
      side={props.side}
    >
      <div className="ImageCropContainer">
        {props.errors &&
          props.errors.length > 0 &&
          props.errors.map((msg: string) => <div className="msg msg-error">{msg}</div>)}
        {original !== "" && (
          <div className="wide">
            <ReactCrop
              src={original}
              crop={crop}
              onImageLoaded={preview ? () => {} : onLoad}
              onChange={(newCrop: Crop) => {
                setCrop(newCrop);
              }}
              className={preview ? "invisible" : ""}
            />
          </div>
        )}
        {original !== "" && preview && previewImgSrc !== false && (
          <div className="wide">
            <img src={previewImgSrc as string} className="previewImage" />
          </div>
        )}
        {(crop.width as number) * (crop.height as number) > 0 && (
          <button className="primary rounded" onClick={togglePreview}>
            {preview ? "Return to Crop" : "Preview"}
          </button>
        )}
        {!preview && (
          <button className="primary rounded" onClick={() => inputEl.current.click()}>
            {original === "" ? "Select image" : "Use Other Image"}
          </button>
        )}
      </div>
      <input className="hidden" ref={inputEl} type="file" onChange={selectImage} accept="image/*" />
    </Modal>
  );
}
