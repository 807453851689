import React from "react";
import { AutoComplete } from "primereact/autocomplete";
import { Field } from "formik";
import classnames from "classnames";
import "./GmAutoComplete.scss";

interface IGmAutoComplete {
  noField?: boolean;
  formik?: any;
  value?: any;

  className?: string;
  disabled?: boolean;
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  suggestions: string[];
  completeMethod: Function;
}

export default function GmInputText(props: IGmAutoComplete) {
  let meta, field;
  if (props.noField && props.formik) {
    meta = props.formik.getFieldMeta(props.id);
    field = props.formik.getFieldProps(props.id);
  }

  return props.noField && meta ? (
    <div
      className={classnames("GmAutoComplete", props.className, {
        error: meta.touched && meta.error,
      })}
    >
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      <AutoComplete
        disabled={props.disabled}
        id={props.id}
        placeholder={props.placeholder}
        required={props.required}
        {...field}
        suggestions={props.suggestions}
        completeMethod={props.completeMethod}
      />

      {meta.touched && meta.error && <sub>{meta.error}</sub>}
    </div>
  ) : (
    <Field name={props.id}>
      {({
        field, // { name, value, onChange, onBlur }
        meta,
      }) => (
        <div
          className={classnames("GmAutoComplete", props.className, {
            error: meta.touched && meta.error,
          })}
        >
          {props.label && <label htmlFor={props.id}>{props.label}</label>}
          <AutoComplete
            disabled={props.disabled}
            id={props.id}
            placeholder={props.placeholder}
            required={props.required}
            {...field}
            suggestions={props.suggestions}
            completeMethod={props.completeMethod}
          ></AutoComplete>
          {meta.touched && meta.error && <sub>{meta.error}</sub>}
        </div>
      )}
    </Field>
  );
}
