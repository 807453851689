import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import Chat from "~/components/Chat/Chat";
import ChatPanel from "~/components/ChatPanel/ChatPanel";
import Loader from "~/components/common/Loader/Loader";
import Header from "~/components/Header/Header";
import NetworkErrorPanel from "~/components/NetworkErrorPanel/NetworkErrorPanel";
import Sidebar from "~/components/Sidebar/Sidebar";
import SidePanel from "~/components/SidePanel/SidePanel";
import { LOGIN_URL } from "~/config";
import "./ChatView.scss";

interface StateProps {
  activeConversation: string;
  authenticatedUser: any;
  location: Location;
}
interface DispatchProps {}
interface OwnProps extends RouteComponentProps {}
export type Props = StateProps & DispatchProps & OwnProps;
export type State = {
  isSidebarOpen: boolean;
  panelShown: string;
};

const mapStateToProps = (state: any) => ({
  activeConversation: state.chat.activeConversation,
  authenticatedUser: state.users.authenticatedUser,
  location: state.location,
});

const mapDispatchToProps = {};

class ChatView extends Component<Props, State> {
  state = {
    isSidebarOpen: false,
    panelShown: "conversations",
  };

  toggleSidebar = () =>
    this.setState((prevState: State) => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));

  render() {
    const { authenticatedUser } = this.props;
    if (authenticatedUser && !!authenticatedUser.must_change_password) {
      return (window.location.href = LOGIN_URL);
    }

    return authenticatedUser ? (
      <Sidebar className="ChatView" isOpen={this.state.isSidebarOpen} close={this.toggleSidebar}>
        <SidePanel />
        <div className="content">
          <Header />
          <Chat />
          <NetworkErrorPanel />
          <ChatPanel />
        </div>
      </Sidebar>
    ) : (
      <div className="centered">
        <Loader />
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatView));
