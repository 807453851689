import Axios, { AxiosError, AxiosResponse } from "axios";
import { push } from "react-router-redux";
import { AnyAction, Dispatch } from "redux";
import { API_URL, networkErrorMessage } from "../config";
import { withAuthorizationHeader } from "./index";
import { modalActions } from "./modal";
import { setToasts, TOAST_ERROR_MESSAGE, TOAST_IMAGE_SUCCESS, TOAST_SUCCESS_MESSAGE } from "./toasts";

// ------------------------------------
// Constants
// ------------------------------------

const USERS_CHANGE_PASSWORD_FAILURE = "USERS_CHANGE_PASSWORD_FAILURE";
const USERS_CHANGE_PASSWORD_REQUEST = "USERS_CHANGE_PASSWORD_REQUEST";
const USERS_CHANGE_PASSWORD_SUCCESS = "USERS_CHANGE_PASSWORD_SUCCESS";

const USERS_GET_PAYMENT_PROFILE_FAILURE = "USERS_GET_PAYMENT_PROFILE_FAILURE";
const USERS_GET_PAYMENT_PROFILE_REQUEST = "USERS_GET_PAYMENT_PROFILE_REQUEST";
const USERS_GET_PAYMENT_PROFILE_SUCCESS = "USERS_GET_PAYMENT_PROFILE_SUCCESS";

const USERS_GET_PERSONAL_PROFILE_FAILURE = "USERS_GET_PERSONAL_PROFILE_FAILURE";
const USERS_GET_PERSONAL_PROFILE_REQUEST = "USERS_GET_PERSONAL_PROFILE_REQUEST";
const USERS_GET_PERSONAL_PROFILE_SUCCESS = "USERS_GET_PERSONAL_PROFILE_SUCCESS";

const USERS_GET_WORK_PROFILE_FAILURE = "USERS_GET_WORK_PROFILE_FAILURE";
const USERS_GET_WORK_PROFILE_REQUEST = "USERS_GET_WORK_PROFILE_REQUEST";
const USERS_GET_WORK_PROFILE_SUCCESS = "USERS_GET_WORK_PROFILE_SUCCESS";

const USERS_LOGIN_USER_FAILURE = "USERS_LOGIN_USER_FAILURE";
const USERS_LOGIN_USER_REQUEST = "USERS_LOGIN_USER_REQUEST";
const USERS_LOGIN_USER_SUCCESS = "USERS_LOGIN_USER_SUCCESS";

const TALENT_INTRODUCTION_SEEN = "TALENT_INTRODUCTION_SEEN";
const USER_PREFERENCES_NOTIFICATION_SEEN = "USER_PREFERENCES_NOTIFICATION_SEEN";
const USER_PERM_BRIEFS_NOTIFICATION_SEEN = "USER_PERM_BRIEFS_NOTIFICATION_SEEN";

export const USERS_LOGOUT_USER_REQUEST = "USERS_LOGOUT_USER_REQUEST";

const AUTH_RESET_REQUEST_PASSWORD_FAILURE = "AUTH_RESET_REQUEST_PASSWORD_FAILURE";
const AUTH_RESET_REQUEST_PASSWORD_REQUEST = "AUTH_RESET_REQUEST_PASSWORD_REQUEST";
const AUTH_RESET_REQUEST_PASSWORD_SUCCESS = "AUTH_RESET_REQUEST_PASSWORD_SUCCESS";

const AUTH_RESET_CHECK_PASSWORD_FAILURE = "AUTH_RESET_CHECK_PASSWORD_FAILURE";
const AUTH_RESET_CHECK_PASSWORD_REQUEST = "AUTH_RESET_CHECK_PASSWORD_REQUEST";
const AUTH_RESET_CHECK_PASSWORD_SUCCESS = "AUTH_RESET_CHECK_PASSWORD_SUCCESS";

const AUTH_CHANGE_LOCATION = "AUTH_CHANGE_LOCATION";

const USERS_SET_PASSWORD_FAILURE = "USERS_SET_PASSWORD_FAILURE";
const USERS_SET_PASSWORD_REQUEST = "USERS_SET_PASSWORD_REQUEST";
const USERS_SET_PASSWORD_SUCCESS = "USERS_SET_PASSWORD_SUCCESS";

const USERS_UPDATE_IMAGE_PROFILE_FAILURE = "USERS_UPDATE_IMAGE_PROFILE_FAILURE";
const USERS_UPDATE_IMAGE_PROFILE_REQUEST = "USERS_UPDATE_IMAGE_PROFILE_REQUEST";
const USERS_UPDATE_IMAGE_PROFILE_SUCCESS = "USERS_UPDATE_IMAGE_PROFILE_SUCCESS";

const USERS_UPDATE_TALENT_CV = "USERS_UPDATE_TALENT_CV";

const USERS_UPDATE_TALENT_PROFILES_REQUEST = "USERS_UPDATE_TALENT_PROFILES_REQUEST";
const USERS_UPDATE_TALENT_PROFILES_FAILURE = "USERS_UPDATE_TALENT_PROFILES_FAILURE";
const USERS_UPDATE_TALENT_PROFILES_SUCCESS = "USERS_UPDATE_TALENT_PROFILES_SUCCESS";

const USERS_UPDATE_USER_TOKEN = "USERS_UPDATE_USER_TOKEN";

const USERS_GET_USER_DATA_SUCCESS = "USERS_GET_USER_DATA_SUCCESS";
const USERS_GET_USER_DATA_FAILURE = "USERS_GET_USER_DATA_FAILURE";

const USERS_GET_UMBRELLAS_FAILURE = "USERS_GET_UMBRELLAS_FAILURE";
const USERS_GET_UMBRELLAS_REQUEST = "USERS_GET_UMBRELLAS_REQUEST";
const USERS_GET_UMBRELLAS_SUCCESS = "USERS_GET_UMBRELLAS_SUCCESS";

const USERS_UPDATE_TALENT_IR35_SEEN_FAILURE = "USERS_UPDATE_TALENT_IR35_SEEN_FAILURE";
const USERS_UPDATE_TALENT_IR35_SEEN_REQUEST = "USERS_UPDATE_TALENT_IR35_SEEN_REQUEST";
const USERS_UPDATE_TALENT_IR35_SEEN_SUCCESS = "USERS_UPDATE_TALENT_IR35_SEEN_SUCCESS";
const USERS_UPDATE_TALENT = "USERS_UPDATE_TALENT";

const USERS_CLOSE_TALENT_IR35 = "USERS_CLOSE_TALENT_IR35";

export const FLOW_INTRO = "FLOW_INTRO";
export const FLOW_NONE = "FLOW_NONE";

// ------------------------------------
// Interfaces
// ------------------------------------

export interface ILoginForm {
  email: string;
  password: string;
}

export interface IResetPasswordForm {
  email: string;
}

export interface ISetPasswordForm {
  password: string;
}

export interface IChangePasswordForm extends ISetPasswordForm {
  current_password: string;
}

export interface ICheckTokenForm {
  token: string;
}

// ------------------------------------
// Action Creators
// ------------------------------------

const changePasswordRequest = () => ({ type: USERS_CHANGE_PASSWORD_REQUEST });
const changePasswordSuccess = (res: AxiosResponse) => ({
  type: USERS_CHANGE_PASSWORD_SUCCESS,
  payload: res.data,
});
const changePasswordFailure = (err: AxiosError) => ({
  type: USERS_CHANGE_PASSWORD_FAILURE,
  payload: err && err.response && err.response.data,
});

const checkResetTokenRequest = () => ({
  type: AUTH_RESET_CHECK_PASSWORD_REQUEST,
});
const checkResetTokenSuccess = (res: AxiosResponse) => ({
  type: AUTH_RESET_CHECK_PASSWORD_SUCCESS,
  payload: res.data && res.data.response,
});
const checkResetTokenFailure = (err: AxiosError) => ({
  type: AUTH_RESET_CHECK_PASSWORD_FAILURE,
  payload: err && err.response && err.response.data,
});
const authChangeLocation = () => ({
  type: AUTH_CHANGE_LOCATION,
});

const getPaymentProfileRequest = () => ({
  type: USERS_GET_PAYMENT_PROFILE_REQUEST,
});
const getPaymentProfileSuccess = (res: AxiosResponse) => ({
  type: USERS_GET_PAYMENT_PROFILE_SUCCESS,
  payload: res.data,
});
const getPaymentProfileFailure = (err: AxiosError) => ({
  type: USERS_GET_PAYMENT_PROFILE_FAILURE,
  payload: err && err.response && err.response.data,
});

export const getPersonalProfileRequest = () => ({
  type: USERS_GET_PERSONAL_PROFILE_REQUEST,
});
const getPersonalProfileSuccess = (res: AxiosResponse) => ({
  type: USERS_GET_PERSONAL_PROFILE_SUCCESS,
  payload: res.data,
});
const getPersonalProfileFailure = (err: AxiosError) => ({
  type: USERS_GET_PERSONAL_PROFILE_FAILURE,
  payload: err && err.response && err.response.data,
});

const getWorkProfileRequest = () => ({
  type: USERS_GET_WORK_PROFILE_REQUEST,
});
const getWorkProfileSuccess = (res: AxiosResponse) => ({
  type: USERS_GET_WORK_PROFILE_SUCCESS,
  payload: res.data,
});
const getWorkProfileFailure = (err: AxiosError) => ({
  type: USERS_GET_WORK_PROFILE_FAILURE,
  payload: err && err.response && err.response.data,
});

const resetUserRequest = () => ({ type: AUTH_RESET_REQUEST_PASSWORD_REQUEST });
const resetUserSuccess = (res: AxiosResponse) => ({
  type: AUTH_RESET_REQUEST_PASSWORD_SUCCESS,
  payload: res.data,
});
const resetUserFailure = (err: AxiosError) => ({
  type: AUTH_RESET_REQUEST_PASSWORD_FAILURE,
  payload: err && err.response && err.response.data,
});

const setPasswordRequest = () => ({ type: USERS_SET_PASSWORD_REQUEST });
const setPasswordSuccess = (res: AxiosResponse) => ({
  type: USERS_SET_PASSWORD_SUCCESS,
  payload: res.data,
});
const setPasswordFailure = (err: AxiosError) => ({
  type: USERS_SET_PASSWORD_FAILURE,
  payload: err && err.response && err.response.data,
});

const loginUserRequest = () => ({ type: USERS_LOGIN_USER_REQUEST });
const loginUserSuccess = (res: AxiosResponse) => ({
  type: USERS_LOGIN_USER_SUCCESS,
  payload: res.data,
});
const loginUserFailure = (err: AxiosError) => ({
  type: USERS_LOGIN_USER_FAILURE,
  payload: (err && err.response && err.response.data && err.response.data.message) || networkErrorMessage,
});

const userIntroductionSeen = () => ({
  type: TALENT_INTRODUCTION_SEEN,
});

const userPreferencesNotificationSeen = () => ({
  type: USER_PREFERENCES_NOTIFICATION_SEEN,
});
const userPermBriefsNotificationSeenSeen = () => ({
  type: USER_PERM_BRIEFS_NOTIFICATION_SEEN,
});

const updateUserToken = (token: string) => ({
  type: USERS_UPDATE_USER_TOKEN,
  payload: token,
});

const logoutUserRequest = () => ({ type: USERS_LOGOUT_USER_REQUEST });

const updateImageProfileRequest = () => ({
  type: USERS_UPDATE_IMAGE_PROFILE_REQUEST,
});
const updateImageProfileSuccess = (res: AxiosResponse) => ({
  type: USERS_UPDATE_IMAGE_PROFILE_SUCCESS,
  payload: res.data,
});
const updateImageProfileFailure = (err: AxiosError) => ({
  type: USERS_UPDATE_IMAGE_PROFILE_FAILURE,
  payload: err && err.response && err.response.data,
});

const updateTalentProfilesRequest = () => ({ type: USERS_UPDATE_TALENT_PROFILES_REQUEST });
const updateTalentProfilesSuccess = (res: AxiosResponse) => ({
  type: USERS_UPDATE_TALENT_PROFILES_SUCCESS,
  payload: res.data,
});
const updateTalentProfilesFailure = (err: AxiosError) => ({
  type: USERS_UPDATE_TALENT_PROFILES_FAILURE,
  payload: err?.response?.data,
});

const getUserDataSuccess = (res: AxiosResponse) => ({
  type: USERS_GET_USER_DATA_SUCCESS,
  payload: res.data,
});
const getUserDataFailure = (err: AxiosError) => ({
  type: USERS_GET_USER_DATA_FAILURE,
  payload: err && err.response && err.response.data,
});

const getUmbrellaCompaniesRequest = () => ({
  type: USERS_GET_UMBRELLAS_REQUEST,
});
const getUmbrellaCompaniesSuccess = (res: AxiosResponse) => ({
  type: USERS_GET_UMBRELLAS_SUCCESS,
  payload: res.data,
});
const getUmbrellaCompaniesFailure = (err: AxiosError) => ({
  type: USERS_GET_UMBRELLAS_FAILURE,
  payload: err && err.response && err.response.data,
});

const updateTalentIR35SeenRequest = () => ({
  type: USERS_UPDATE_TALENT_IR35_SEEN_REQUEST,
});
const updateTalentIR35SeenSuccess = (res: AxiosResponse) => ({
  type: USERS_UPDATE_TALENT_IR35_SEEN_SUCCESS,
  payload: res.data,
});
export const updateTalentInUser = (res: any) => ({
  type: USERS_UPDATE_TALENT,
  payload: res,
});
export const updateTalentCVsuccess = (res: any) => ({
  type: USERS_UPDATE_TALENT_CV,
  payload: res,
});
const updateTalentIR35SeenFailure = (err: AxiosError) => ({
  type: USERS_UPDATE_TALENT_IR35_SEEN_FAILURE,
  payload: err && err.response && err.response.data,
});

// ------------------------------------
// Actions
// ------------------------------------

const loginUser = (form: any) => (dispatch: Dispatch) => {
  dispatch(loginUserRequest());
  return new Promise((resolve, reject) => {
    Axios.post(`${API_URL}/login`, form)
      .then((res) => {
        dispatch(loginUserSuccess(res));
        localStorage.setItem("user", res.data.data.token);
        resolve(res);
      })
      .catch((err) => {
        dispatch(loginUserFailure(err));
        reject(err);
      });
  });
};

const loginUserByOtp = (otp: string) => (dispatch: Dispatch) => {
  dispatch(loginUserRequest());
  return new Promise((resolve, reject) => {
    Axios.get(`${API_URL}/auth/${otp}`)
      .then((res) => {
        dispatch(loginUserSuccess(res));
        localStorage.setItem("user", res.data.token);
        resolve(res);
      })
      .catch((err) => {
        dispatch(loginUserFailure(err));
        reject(err);
      });
  });
};

const logoutUser = (callback?: Function) => (dispatch: Dispatch, getState: () => any) => {
  dispatch(logoutUserRequest());
  const { instance } = getState().websocket;
  if (instance) {
    instance.emit("logout");
    instance.destroy();
  }
  localStorage.removeItem("user");
  if (callback) callback();
};

/**
 * @function getPaymentProfile
 */
const getPaymentProfile = () => async (dispatch: Dispatch, getState: () => any) => {
  dispatch(getPaymentProfileRequest());
  return new Promise(async (resolve, reject) => {
    try {
      const { token } = getState().users.authenticatedUser;
      const res = await Axios.get(`${API_URL}/api/user/current/talent_payment_profile`, withAuthorizationHeader(token));
      dispatch(getPaymentProfileSuccess(res));
      resolve(res);
    } catch (err) {
      dispatch(getPaymentProfileFailure(err as AxiosError));
      reject(err);
    }
  });
};

/**
 * @function getPersonalProfile
 */
const getPersonalProfile = () => async (dispatch: Dispatch, getState: () => any) => {
  dispatch(getPersonalProfileRequest());
  return new Promise(async (resolve, reject) => {
    try {
      const { token } = getState().users.authenticatedUser;
      const res = await Axios.get(
        `${API_URL}/api/user/current/talent_personal_profile`,
        withAuthorizationHeader(token)
      );
      dispatch(getPersonalProfileSuccess(res));
      resolve(res);
    } catch (err) {
      dispatch(getPersonalProfileFailure(err as AxiosError));
      reject(err);
    }
  });
};

/**
 * @function getWorkProfile
 */
const getWorkProfile = () => async (dispatch: Dispatch, getState: () => any) => {
  dispatch(getWorkProfileRequest());
  return new Promise(async (resolve, reject) => {
    try {
      const { token } = getState().users.authenticatedUser;
      const res = await Axios.get(`${API_URL}/api/user/current/talent_work_profile`, withAuthorizationHeader(token));
      dispatch(getWorkProfileSuccess(res));
      resolve(res);
    } catch (err) {
      dispatch(getWorkProfileFailure(err as AxiosError));
      reject(err);
    }
  });
};

/**
 * @function updateTalentProfiles
 */
const updateTalentProfiles =
  (form: object, noToast: boolean = false) =>
  async (dispatch: Dispatch, getState: () => any) => {
    dispatch(updateTalentProfilesRequest());
    return new Promise(async (resolve, reject) => {
      try {
        const { token } = getState().users.authenticatedUser;
        const res = await Axios.put(
          `${API_URL}/api/user/current/talent_profiles?reload_token`,
          form,
          withAuthorizationHeader(token)
        );
        dispatch(updateTalentProfilesSuccess(res));
        !noToast && dispatch(setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
        resolve(res);
      } catch (err) {
        dispatch(setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
        dispatch(updateTalentProfilesFailure(err as AxiosError));
        reject(err);
      }
    });
  };

/**
 * @function updateImageProfile
 */
const updateImageProfile = (file: File) => async (dispatch: Dispatch, getState: () => any) => {
  dispatch(updateImageProfileRequest());
  return new Promise(async (resolve, reject) => {
    try {
      const { token } = getState().users.authenticatedUser;
      const data = new FormData();
      data.append("image_url", file);
      data.append("_method", "PUT");
      const res = await Axios.post(
        `${API_URL}/user/current/talent_work_profile?reload_token`,
        data,
        withAuthorizationHeader(token)
      );
      dispatch(updateImageProfileSuccess(res));
      dispatch(setToasts([{ severity: "success", summary: "", detail: TOAST_IMAGE_SUCCESS }]));
      resolve(res);
    } catch (err) {
      dispatch(updateImageProfileFailure(err as AxiosError));
      dispatch(setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      reject(err);
    }
  });
};

const getUserData = (token?: string) => (dispatch: Dispatch, getState: () => any) => {
  return new Promise(async (resolve, reject) => {
    Axios.get(`${API_URL}/jwt?reload_token`, withAuthorizationHeader(token || getState().users.authenticatedUser.token))
      .then((res) => {
        dispatch(getUserDataSuccess(res));
        resolve(token);
      })
      .catch((err) => {
        err.response.status === 401 && dispatch(getUserDataFailure(err));
        reject(err);
      });
  });
};

/**
 * @function changePassword
 * @description Handle change password for authenticated user
 */
const changePassword = (form: IChangePasswordForm) => (dispatch: Dispatch, getState: () => any) => {
  dispatch(changePasswordRequest());
  const { token } = getState().users.authenticatedUser;
  const { instance } = getState().websocket;

  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.put(
        `${API_URL}/password`,
        {
          ...form,
          sessionId: instance.id,
        },
        withAuthorizationHeader(token)
      );
      if (res.data.token) {
        instance.authenticate(res.data.token);
        dispatch(updateUserToken(res.data.token));
      }
      dispatch(changePasswordSuccess(res));
      resolve(res);
    } catch (err) {
      dispatch(changePasswordFailure(err as AxiosError));
      reject(err);
    }
  });
};

/**
 * @function resetPassword
 * @param form @{}
 */
const resetPassword = (form: IResetPasswordForm) => (dispatch: Dispatch) => {
  dispatch(resetUserRequest());
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.post(`${API_URL}/reset_password`, form);
      dispatch(resetUserSuccess(res));
      resolve(res);
    } catch (err) {
      dispatch(resetUserFailure(err as AxiosError));
      reject((err && err.response && err.response.data && err.response.data.result) || networkErrorMessage);
    }
  });
};

/**
 * @function authChangeRoute
 * @param form @{}
 */
const authChangeRoute = () => (dispatch: Dispatch) => {
  dispatch(authChangeLocation());
};

/**
 * @function setPassword
 * @description Handle change password for newly created account
 */
const setPassword = (form: ISetPasswordForm) => (dispatch: Dispatch, getState: () => any) => {
  const { instance } = getState().websocket;
  dispatch(setPasswordRequest());
  const { token, terms } = getState().users.authenticatedUser;
  return new Promise(async (resolve, reject) => {
    try {
      const data = {
        tos: true,
        ...(terms.hasOwnProperty("nda") && { nda: true }),
      };
      await Axios.post(`${API_URL}/messenger/terms`, data, withAuthorizationHeader(token));
      const res = await Axios.put(
        `${API_URL}/set_password`,
        {
          ...form,
          sessionId: instance.id,
        },
        withAuthorizationHeader(token)
      );
      dispatch(setPasswordSuccess(res));
      localStorage.setItem("user", res.data.token);
      instance.authenticate(res.data.token);
      resolve(res);
    } catch (err) {
      dispatch(setPasswordFailure(err as AxiosError));
      reject(err);
    }
  });
};

/**
 * @function checkResetPasswordToken
 * @param form @{}
 */
const checkResetPasswordToken = (form: ICheckTokenForm) => (dispatch: Dispatch) => {
  dispatch(checkResetTokenRequest());
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.get(`${API_URL}/reset_password/${form.token}`);
      dispatch(checkResetTokenSuccess(res));
      localStorage.setItem("user", res.data.response.token);
      resolve(res);
    } catch (err) {
      dispatch(checkResetTokenFailure(err as AxiosError));
      reject(err);
    }
  });
};

const getUmbrellaCompanies = () => async (dispatch: Dispatch, getState: () => any) => {
  dispatch(getUmbrellaCompaniesRequest());
  return new Promise(async (resolve, reject) => {
    try {
      const { token } = getState().users.authenticatedUser;
      const res = await Axios.get(`${API_URL}/api/umbrellas`, withAuthorizationHeader(token));
      dispatch(getUmbrellaCompaniesSuccess(res));
      resolve(res);
    } catch (err) {
      dispatch(getUmbrellaCompaniesFailure(err as AxiosError));
      reject(err);
    }
  });
};

const introductionSeen = () => (dispatch: Dispatch) => {
  dispatch(userIntroductionSeen());
};
const preferencesNotificationSeen = () => (dispatch: Dispatch) => {
  dispatch(userPreferencesNotificationSeen());
};
const permBriefsNotificationSeen = () => (dispatch: Dispatch) => {
  dispatch(userPermBriefsNotificationSeenSeen());
};

const updateToken = (token: string) => (dispatch: Dispatch) => {
  dispatch(updateUserToken(token));
};

const onWSTokenRemove = (token: string) => async (dispatch: Dispatch, getState: () => any) => {
  try {
    const res = await Axios.post(`${API_URL}/refresh`, { token });

    if (res && res.status === 200 && res.data && res.data.success) {
      const { instance } = getState().websocket;
      const { token } = res.data.response;
      instance.authenticate(token);
      dispatch(updateUserToken(token));
    } else {
      throw "error";
    }
  } catch (err) {
    dispatch(modalActions.closeModal());
    dispatch(push("/login"));
    dispatch(logoutUser());
  }
};

const updateTalentIR35Seen = () => async (dispatch: Dispatch, getState: () => any) => {
  dispatch(updateTalentIR35SeenRequest());
  return new Promise(async (resolve, reject) => {
    try {
      const { token } = getState().users.authenticatedUser;
      const res = await Axios.put(
        `${API_URL}/api/user/current/talent_personal_profile?reload_token`,
        { ir35_onboarded: true },
        withAuthorizationHeader(token)
      );
      dispatch(updateTalentIR35SeenSuccess(res));
      resolve(res);
    } catch (err) {
      dispatch(updateTalentIR35SeenFailure(err));
      reject(err);
    }
  });
};

const closeTalentIR35 = () => async (dispatch: Dispatch) => {
  dispatch({ type: USERS_CLOSE_TALENT_IR35 });
};

export const usersActions = {
  changePassword,
  checkResetPasswordToken,
  closeTalentIR35,
  getPaymentProfile,
  getPersonalProfile,
  getWorkProfile,
  getUmbrellaCompanies,
  loginUser,
  logoutUser,
  resetPassword,
  setPassword,
  authChangeRoute,
  updateTalentIR35Seen,
  updateToken,
  updateImageProfile,
  introductionSeen,
  preferencesNotificationSeen,
  permBriefsNotificationSeen,
  getUserData,
  onWSTokenRemove,
  updateTalentProfiles,
  loginUserByOtp,
};

// ------------------------------------
// Initial state
// ------------------------------------
interface InitialState {
  authenticatedUser: any;
  paymentProfile: any;
  personalProfile: any;
  workProfile: any;
  errors: string[];
  isAuthenticated: boolean;
  isFetching: boolean;
  isResetPassword: boolean;
  user: any;
  users: any[];
}

const initialState: InitialState = {
  isAuthenticated: false,
  isResetPassword: false,
  authenticatedUser: {},
  paymentProfile: {},
  personalProfile: {},
  workProfile: {},
  errors: [],
  isFetching: false,
  user: null,
  users: [],
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case AUTH_RESET_CHECK_PASSWORD_REQUEST:
    case AUTH_RESET_REQUEST_PASSWORD_REQUEST:
    case USERS_CHANGE_PASSWORD_REQUEST:
    case USERS_GET_PAYMENT_PROFILE_REQUEST:
    case USERS_GET_PERSONAL_PROFILE_REQUEST:
    case USERS_GET_WORK_PROFILE_REQUEST:
    case USERS_LOGIN_USER_REQUEST:
    case USERS_SET_PASSWORD_REQUEST:
    case USERS_UPDATE_TALENT_IR35_SEEN_REQUEST:
    case USERS_UPDATE_TALENT_PROFILES_REQUEST:
      return { ...state, isFetching: true };

    case AUTH_RESET_CHECK_PASSWORD_FAILURE:
    case USERS_CHANGE_PASSWORD_FAILURE:
    case USERS_GET_PAYMENT_PROFILE_FAILURE:
    case USERS_GET_PERSONAL_PROFILE_FAILURE:
    case USERS_GET_WORK_PROFILE_FAILURE:
    case USERS_SET_PASSWORD_FAILURE:
    case USERS_UPDATE_TALENT_IR35_SEEN_FAILURE:
    case USERS_UPDATE_TALENT_PROFILES_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: typeof payload === "object" && payload.length !== undefined ? payload : [payload],
      };

    case USERS_LOGIN_USER_FAILURE:
      return { ...state, isFetching: false, errors: payload };

    case USERS_SET_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        authenticatedUser: {
          ...state.authenticatedUser,
          token: payload.token,
          must_change_password: 0,
        },
      };

    case USERS_GET_PAYMENT_PROFILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        paymentProfile: payload,
        authenticatedUser: {
          ...state.authenticatedUser,
          ...{
            talent: {
              ...state.authenticatedUser.talent,
            },
          },
        },
      };

    case USERS_GET_PERSONAL_PROFILE_SUCCESS:
      return { ...state, isFetching: false, personalProfile: payload };

    case USERS_GET_WORK_PROFILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        workProfile: payload,
        authenticatedUser: {
          ...state.authenticatedUser,
          ...{
            talent: {
              ...state.authenticatedUser.talent,
            },
          },
        },
      };

    case USERS_GET_USER_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        authenticatedUser: { ...state.authenticatedUser, ...payload },
      };
    case USERS_LOGIN_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        authenticatedUser: payload,
      };

    case AUTH_RESET_CHECK_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        authenticatedUser: {
          ...state.authenticatedUser,
          ...payload.user,
          token: payload.token,
        },
        isResetPassword: true,
      };

    case USERS_GET_USER_DATA_FAILURE:
    case USERS_LOGOUT_USER_REQUEST:
      return { ...initialState };

    case USERS_UPDATE_TALENT_PROFILES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        personalProfile: payload?.personal_profile,
        workProfile: payload?.work_profile,
        paymentProfile: payload?.payment_profile,
        authenticatedUser: {
          ...state.authenticatedUser,
          ...{
            first_name: payload?.personal_profile?.first_name,
            last_name: payload?.personal_profile?.last_name,
            telephone: payload?.personal_profile?.telephone,
            headline: payload?.work_profile.headline,
            subhead: payload?.work_profile.subhead,
            talent: {
              ...state.authenticatedUser.talent,
            },
          },
        },
      };

    case USERS_UPDATE_USER_TOKEN:
      return {
        ...state,
        authenticatedUser: {
          ...state.authenticatedUser,
          token: payload,
        },
        isAuthenticated: true,
      };

    case TALENT_INTRODUCTION_SEEN:
      return {
        ...state,
        authenticatedUser: {
          ...state.authenticatedUser,
          talent: {
            ...state.authenticatedUser.talent,
            intro_flow: FLOW_NONE,
          },
        },
      };
    case USER_PREFERENCES_NOTIFICATION_SEEN:
      return {
        ...state,
        authenticatedUser: {
          ...state.authenticatedUser,
          talent: {
            ...state.authenticatedUser.talent,
            preference: true,
            ph_briefs_notified: true,
            hide_user_popup: true,
          },
        },
      };
    case USER_PERM_BRIEFS_NOTIFICATION_SEEN:
      return {
        ...state,
        authenticatedUser: {
          ...state.authenticatedUser,
          talent: {
            ...state.authenticatedUser.talent,
            preference: true,
            ph_briefs_notified: true,
            hide_user_popup: true,
          },
        },
      };
    case USERS_UPDATE_IMAGE_PROFILE_SUCCESS:
      return {
        ...state,
        authenticatedUser: {
          ...state.authenticatedUser,
          talent: {
            ...state.authenticatedUser.talent,
            profile_image: payload.image_url,
          },
        },
        workProfile: {
          ...state.workProfile,
          image_url: payload.image_url,
        },
      };

    case USERS_UPDATE_TALENT_IR35_SEEN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        authenticatedUser: {
          ...state.authenticatedUser,
          talent: {
            ...state.authenticatedUser.talent,
            intro_flow: FLOW_NONE,
          },
        },
      };
    case USERS_UPDATE_TALENT:
      return {
        ...state,
        authenticatedUser: {
          ...state.authenticatedUser,
          talent: {
            ...state.authenticatedUser.talent,
            ...payload,
          },
        },
      };
    case USERS_UPDATE_TALENT_CV:
      return {
        ...state,
        workProfile: {
          ...state.workProfile,
          cv_url: payload.cv_url,
        },
      };

    case USERS_CLOSE_TALENT_IR35:
      return {
        ...state,
        authenticatedUser: {
          ...state.authenticatedUser,
          talent: {
            ...state.authenticatedUser.talent,
            intro_flow: FLOW_NONE,
          },
        },
      };

    case USERS_GET_UMBRELLAS_SUCCESS:
      return {
        ...state,
        umbrella_companies: payload,
      };

    case USERS_GET_UMBRELLAS_REQUEST:
    case USERS_GET_UMBRELLAS_FAILURE:
      return {
        ...state,
        umbrella_companies: [],
      };
    case AUTH_RESET_REQUEST_PASSWORD_SUCCESS:
    case AUTH_RESET_REQUEST_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case AUTH_CHANGE_LOCATION:
      return {
        ...state,
        errors: [],
      };
    default:
      return state;
  }
};
