import React from "react";

const FireSimple = () => (
  <>
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.7406 6.13086C5.29685 8.30586 4.1156 10.7996 4.1156 13.1246C4.1156 15.2132 4.94529 17.2162 6.42213 18.6931C7.89898 20.1699 9.90202 20.9996 11.9906 20.9996C14.0792 20.9996 16.0822 20.1699 17.5591 18.6931C19.0359 17.2162 19.8656 15.2132 19.8656 13.1246C19.8656 8.24961 16.4906 4.49961 13.7531 1.77148L10.4906 8.62461L6.7406 6.13086Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);
export default FireSimple;
