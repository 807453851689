import Axios, { AxiosError, AxiosResponse } from "axios";
import { AnyAction, Dispatch } from "redux";
import { API_URL } from "~/config";
import { withAuthorizationHeader } from ".";
import { USERS_LOGOUT_USER_REQUEST } from "./user";
import { isPayloadFromLastMessage } from "~/utils";

// ------------------------------------
// Constants
// ------------------------------------
export interface IQuickReply {
  content_type: string;
  title: string;
  payload: string;
}

export interface IMessage {
  input_opts?: {
    brief_ir35?: string;
    type?: "Buttons" | "BriefCard" | "BriefPermanentCard" | "BriefsCarousel";
    params: any;
    elements?: {
      type: "Button" | "ButtonOpenModal";
      title: string;
      params?: any;
      payload?: string;
    }[];
  };
  id?: string;
  uid?: string;
  created_at?: Date;
  is_mine?: boolean;
  text_content: string;
  sender?: any;
  quick_replies?: IQuickReply[];
  response_cards?: any;
  attachments?: Attachment[];
  showTyping?: boolean;
  renderDelay?: boolean;
  status?: number;
  payload?: any;
}

export interface Attachment {
  url: string;
  type: string;
}

const CONVERSATIONS_RECEIVE_MESSAGE = "CONVERSATIONS_RECEIVE_MESSAGE";
const CONVERSATIONS_MESSAGE_STATUS = "CONVERSATIONS_MESSAGE_STATUS";

const CONVERSATIONS_SEND_REQUEST = "CONVERSATIONS_SEND_REQUEST";
const CONVERSATIONS_SEND_FAILURE = "CONVERSATIONS_SEND_FAILURE";

const LOAD_CONVERSATION_REQUEST = "LOAD_CONVERSATION_REQUEST";
const LOAD_CONVERSATION_SUCCESS = "LOAD_CONVERSATION_SUCCESS";
const LOAD_CONVERSATION_FAILURE = "LOAD_CONVERSATION_FAILURE";

export const SET_ALL_UNREAD_COUNT = "SET_ALL_UNREAD_COUNT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const CONVERSATIONS_UNSET = "CONVERSATIONS_UNSET";
const MESSAGE_SET_TYPING_FALSE = "MESSAGE_SET_TYPING_FALSE";
const MESSAGE_SET_DELAY = "MESSAGE_SET_DELAY";

const DEBUG_MODE_CHANGED = "DEBUG_MODE_CHANGED";

// ------------------------------------
// Action Creators
// ------------------------------------
const setTypingFalse = (id: string) => ({
  type: MESSAGE_SET_TYPING_FALSE,
  payload: id,
});
const setDelay = (id: string, res: boolean) => ({
  type: MESSAGE_SET_DELAY,
  payload: { id, res },
});
const loadConversationRequest = () => ({ type: LOAD_CONVERSATION_REQUEST });
const loadConversationSuccess = (res: AxiosResponse) => ({
  type: LOAD_CONVERSATION_SUCCESS,
  payload: res.data,
});
const loadConversationFailure = (err: AxiosError) => ({
  type: LOAD_CONVERSATION_FAILURE,
  payload: err,
});

const receiveConversationMessage = (message: IMessage) => ({
  type: CONVERSATIONS_RECEIVE_MESSAGE,
  payload: message,
});

const changeStatus = (message: IMessage) => ({
  type: CONVERSATIONS_MESSAGE_STATUS,
  payload: message,
});

const sendMessageRequest = () => ({ type: CONVERSATIONS_SEND_REQUEST });

const sendMessageFailure = (err: any) => ({
  type: CONVERSATIONS_SEND_FAILURE,
  payload: err && err.response && err.response.data,
});

const setDebugMode = (isDebugMode: boolean) => ({
  type: DEBUG_MODE_CHANGED,
  payload: isDebugMode,
});

// ------------------------------------
// Actions
// ------------------------------------

const changeMessageStatus = (message: any) => (dispatch: Dispatch) => {
  dispatch(changeStatus(message));
};

const loadMessages = (from?: number) => (dispatch: Dispatch, getState: () => any) => {
  const { token } = getState().users.authenticatedUser;
  dispatch(loadConversationRequest());
  return new Promise((resolve, reject) => {
    Axios.get(`${API_URL}/messages`, {
      ...withAuthorizationHeader(token),
      params: {
        ...(from && { from: `${from}` }),
      },
    })
      .then((res) => {
        dispatch(loadConversationSuccess(res));
        resolve(res);
      })
      .catch((err) => {
        dispatch(loadConversationFailure(err));
        reject(err);
      });
  });
};

const receiveMessage = (message: any) => (dispatch: Dispatch, getState: () => any) => {
  const { isDebugMode } = getState().chat;
  if (isDebugMode) {
    dispatch(receiveConversationMessage(message));
  } else {
    dispatch(
      receiveConversationMessage({
        ...message,
        showTyping: true,
        renderDelay: true,
      })
    );
  }
};

const sendMessage =
  (message: string | IMessage, forceSend = false) =>
  (dispatch: Dispatch, store: any) => {
    const getStore = typeof store !== "function" ? store.getState : store;
    const {
      websocket: { instance },
      chat: { messages },
    } = getStore();
    dispatch(sendMessageRequest());
    const lastMessage = messages[messages.length - 1];

    if (
      (lastMessage.input_opts?.disableChatResponse &&
        !forceSend &&
        +new Date(lastMessage.created_at) + 60 * 60 * 3 * 1000 >= +new Date()) ||
      (lastMessage.input_opts?.disableNewFlow &&
        typeof message !== "string" &&
        typeof message.payload === "object" &&
        !isPayloadFromLastMessage(message.payload?.payload, lastMessage.quick_replies))
    ) {
      dispatch(
        sendMessageFailure({
          response: {
            data: "Not allowed",
          },
        })
      );
      return;
    }

    instance.emit(
      "message_out",
      typeof message === "string" ? { text_content: message } : message,
      (err: Error, res: any) => (err ? dispatch(sendMessageFailure(err)) : dispatch(receiveConversationMessage(res)))
    );
  };

const setRenderDelay =
  (id: string, res: boolean) =>
  (dispatch: Dispatch, getState: any): void => {
    const { messages } = getState().chat;
    const message = messages.find((msg: IMessage) => msg.id === id);
    if (message.renderDelay !== res) dispatch(setDelay(id, res));
  };

const setTyping = (id: string) => (dispatch: Dispatch, getState: any) => {
  const { messages } = getState().chat;
  const message = messages.find((msg: IMessage) => msg.id === id);
  if (message.showTyping) dispatch(setTypingFalse(id));
};

export const conversationActions = {
  changeMessageStatus,
  loadMessages,
  receiveMessage,
  sendMessage,
  setTyping,
  setRenderDelay,
  setDebugMode,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  errors: [],
  isFetching: false,
  messages: [],
  totalMessages: 0,
  isDebugMode: false,
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case LOAD_CONVERSATION_REQUEST:
      return { ...state, isFetching: true };
    case USERS_LOGOUT_USER_REQUEST:
      return { ...initialState };

    case CONVERSATIONS_RECEIVE_MESSAGE:
      return {
        ...state,
        isFetching: false,
        messages: [...state.messages, action.payload],
      };

    case CONVERSATIONS_MESSAGE_STATUS:
      return {
        ...state,
        messages: state.messages.map((message: IMessage) => {
          if (message.id === action.payload.id) {
            message.status = action.payload.status;
          }
          return message;
        }),
      };

    case LOAD_CONVERSATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        messages: [...action.payload.data.messages, ...state.messages],
        totalMessages: action.payload.data.total,
      };

    case LOAD_CONVERSATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload,
      };

    case MESSAGE_SET_TYPING_FALSE:
      const processedMessages = state.messages.map((message: IMessage) => {
        if (action.payload !== message.id) {
          return message;
        } else {
          return { ...message, showTyping: false };
        }
      });
      return { ...state, messages: processedMessages };

    case MESSAGE_SET_DELAY:
      const messagesArr = state.messages.map((message: IMessage) => {
        if (action.payload.id !== message.id) {
          return message;
        } else {
          return { ...message, renderDelay: action.payload.res };
        }
      });

      return { ...state, messages: messagesArr };

    case DEBUG_MODE_CHANGED:
      return { ...state, isDebugMode: action.payload };

    default:
      return state;
  }
};
