import { Dispatch, AnyAction } from "redux";
import Axios, { AxiosResponse, AxiosError } from "axios";
import { API_URL } from "~/config";
import { withAuthorizationHeader } from ".";

// ------------------------------------
// Constants
// ------------------------------------
export const SKILLS_LOAD_REQUEST = "SKILLS_LOAD_REQUEST";
export const SKILLS_LOAD_SUCCESS = "SKILLS_LOAD_SUCCESS";
export const SKILLS_LOAD_FAILURE = "SKILLS_LOAD_FAILURE";

export const SKILLS_SAVE_REQUEST = "SKILLS_SAVE_REQUEST";
export const SKILLS_SAVE_SUCCESS = "SKILLS_SAVE_SUCCESS";
export const SKILLS_SAVE_FAILURE = "SKILLS_SAVE_FAILURE";

// ------------------------------------
// Actions
// ------------------------------------
const loadSkills = (discipline: string) => (dispatch: Dispatch, getState: () => any) => {
  const { token } = getState().users.authenticatedUser;
  dispatch({ type: SKILLS_LOAD_REQUEST });
  Axios.get(`${API_URL}/skills/${discipline}`, withAuthorizationHeader(token))
    .then((res: AxiosResponse) =>
      dispatch({
        type: SKILLS_LOAD_SUCCESS,
        payload: res.data,
      })
    )
    .catch((err: AxiosError) =>
      dispatch({
        type: SKILLS_LOAD_FAILURE,
        payload: err && err.response && err.response.data,
      })
    );
};

const saveSkills = (data: object) => (dispatch: Dispatch, getState: () => any) => {
  const { token } = getState().users.authenticatedUser;
  dispatch({ type: SKILLS_SAVE_REQUEST });
  Axios.post(`${API_URL}/skills`, data, withAuthorizationHeader(token))
    .then((res: AxiosResponse) =>
      dispatch({
        type: SKILLS_SAVE_SUCCESS,
        payload: res.data,
      })
    )
    .catch((err: AxiosError) =>
      dispatch({
        type: SKILLS_SAVE_FAILURE,
        payload: err && err.response && err.response.data,
      })
    );
};

export const skillsActions = {
  loadSkills,
  saveSkills,
};

// ------------------------------------
// State
// ------------------------------------
const initialState = {
  errors: [],
  isFetching: false,
  skills: null,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case SKILLS_LOAD_REQUEST:
    case SKILLS_SAVE_REQUEST:
      return { ...state, isFetching: true, skills: null, errors: [] };
    case SKILLS_LOAD_SUCCESS:
      return { ...state, isFetching: false, skills: payload };
    case SKILLS_SAVE_SUCCESS:
      return { ...state, isFetching: false, skills: null };
    case SKILLS_LOAD_FAILURE:
    case SKILLS_SAVE_FAILURE:
      return { ...state, isFetching: false, errors: payload };
    default:
      return state;
  }
};
