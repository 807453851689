import cn from "classnames";
import { Field, FieldArray, Form, Formik, FormikProps } from "formik";
import get from "lodash/get";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { Button } from "primereact/button";
import { BlockUI } from "primereact/blockui";
import { InputSwitch } from "primereact/inputswitch";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { IAttachment, IInvoice, ILineItem, ILineItemUnit } from "~/API/InvoiceService";
import GmFileUpload from "~/components/common/GmFileUpload/GmFileUpload";
import GmInputTextarea from "~/components/common/GmInputTextarea/GmInputTextarea";
import Loader from "~/components/common/Loader/Loader";
import { getIR35InsideDisclaimer, getIR35InsidePopup } from "~/config";
import useInvoice from "~/hooks/useInvoice";
import { IInvoiceReadyBooking } from "~/hooks/useTalent";
import { InvoiceSchema } from "~/schemas/InvoiceSchema";
import { modalActions } from "~/store/modal";
import { IState } from "~/store/reducers";
import { setPopup, setToasts } from "~/store/toasts";
import { usersActions } from "~/store/user";
import { compareArrays, conditionalWrapper, extractFileName, formatCurrency, validateFile } from "~/utils";
import "./InvoiceForm.scss";
import LineItemForm from "./LineItemForm";
import LineItemFormHeaders from "./LineItemFormHeaders";
import LineItemFormMobile from "./LineItemFormMobile";

export type IInvoiceFormValues = {
  lineItems: ILineItem[];
  notes: string;
  agree: boolean;
  includeVAT: boolean;
  attachments: IAttachment[] | File[];
};

type ISubmitValues = IInvoiceFormValues & { status?: "SUBMITTED" };

const VALIDATE_DELAY = 1000;

function InvoiceForm(
  props: RouteComponentProps & { invoice?: IInvoice; booking?: IInvoiceReadyBooking; isNew?: boolean }
) {
  const { booking, invoice, isNew, history } = props;

  const { talent_rate, start_date, end_date } = props.booking || {
    talent_rate: String(invoice?.line_items[0]?.unit_amount),
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  };

  const dispatch = useDispatch();
  const [showNotes, setShowNotes] = useState<boolean>(false);
  const [activeLineItem, setActiveLineItem] = useState<number | null>(0);
  const { company_tax_number, insurance_number } = useSelector((state: IState) => state.users?.paymentProfile);

  const formikRef = useRef<FormikProps<IInvoiceFormValues> | null>(null);

  const isIR35CommissionApplied: boolean = useMemo(() => {
    return (isNew && booking?.ir35_code === "IR35_INSIDE") || (!isNew && invoice?.booking?.ir35_code === "IR35_INSIDE");
  }, []);

  const {
    isFetching,
    postInvoice,
    putInvoice,
    validateBankProfile,
    uploadAttachments,
    deleteAttachment,
    getValidatedInvoice,
    validateInsuranceNumber,
    validatedInvoice,
    setValidatedInvoice,
    isValidatedInvoiceFetching,
  } = useInvoice();

  const createDefaultLineItem = (): ILineItem => ({
    isNew: true,
    description: "",
    unit_type: ILineItemUnit.TYPE_DAY_RATE,
    unit_amount: parseInt(talent_rate || "0"),
    quantity: 0,
    dates: [
      moment().isAfter(start_date) ? start_date : moment().format("YYYY-MM-DD"),
      moment().isAfter(end_date) ? end_date : moment().format("YYYY-MM-DD"),
    ],
    line_amount: undefined,
  });

  const initialValues: IInvoiceFormValues = {
    lineItems: isNew
      ? [createDefaultLineItem()]
      : invoice?.line_items.map(({ start_date, end_date, ...rest }) => ({
          ...rest,
          dates: [start_date, end_date],
        })) || [],
    notes: invoice?.notes || "",
    agree: false,
    includeVAT: isNew ? false : invoice?.tax_type === "TAX_EXCLUSIVE",
    attachments: invoice?.attachments || [],
  };

  useEffect(() => {
    (!!invoice?.notes || invoice?.attachments?.length) && setShowNotes(true);
    !!invoice?.id && setActiveLineItem(null);
  }, [invoice]);

  useEffect(() => {
    dispatch(usersActions.getPaymentProfile());
  }, []);

  const addRemoveItemConfirm = (addItem: boolean = true, callback: any) => {
    const content: string = addItem ? "Do you want to add a new Item?" : "Are you sure you want to remove Item?";

    dispatch(
      setPopup({
        content,
        buttons: [
          { text: "Close" },
          {
            text: addItem ? "Add" : "Remove",
            callback,
          },
        ],
      })
    );
  };

  const onAddItem = (values: IInvoiceFormValues, setValues: any) => {
    const lineItems = [...values.lineItems, createDefaultLineItem()];
    setValues({ ...values, lineItems });
  };

  const onRemoveLineItem = (values: IInvoiceFormValues, setValues: any, index: number) => {
    if (values.lineItems.length > 1) {
      const lineItems = [...values.lineItems];
      lineItems.splice(index, 1);
      setValues({ ...values, lineItems });
    }
  };

  const onChangeVAT = (values: IInvoiceFormValues, setFieldValue: any) => {
    if (!!company_tax_number) {
      setFieldValue("includeVAT", !values.includeVAT);
    } else {
      if (values.includeVAT) {
        setFieldValue("includeVAT", false);
      } else {
        dispatch(
          setPopup({
            content: (
              <>
                You must have your UK VAT Number or International Equivalent added to the Getting Paid section of your
                profile to add VAT to this invoice.
              </>
            ),
            buttons: [
              {
                text: "Close",
              },
              {
                text: "Add",
                callback: () => dispatch(modalActions.openModal("GETTING_PAID_MODAL")),
              },
            ],
          })
        );
      }
    }
  };

  const onUploadAttachment = (files: File[]) => {
    const filesErrors: string[] = [];
    const validFiles: File[] = [];

    files.forEach((file: File) => {
      const errors = validateFile(file, { size: 3000000 });

      const isAlreadyExists = ((formikRef.current?.values.attachments as IAttachment[]) || []).find(
        (attachment: File | IAttachment) => {
          if (attachment instanceof File) {
            return attachment.name === file.name;
          }
          return extractFileName(attachment.url) === file.name;
        }
      );

      if (!!isAlreadyExists) {
        errors.push(`File already exists`);
      }

      if (errors.length) {
        filesErrors.push(`${file.name}: ${errors.join(", ")}`);
      } else {
        validFiles.push(file);
      }
    });

    formikRef.current?.setFieldValue("attachments", [...(formikRef.current?.values.attachments || []), ...validFiles]);
    filesErrors.length && dispatch(setToasts([{ severity: "error", summary: "", detail: filesErrors.join("; ") }]));
  };

  const onDeleteFile = async (file: File | IAttachment | undefined, index?: number) => {
    if (!formikRef.current || !formikRef.current.values.attachments?.length) return;

    let files;
    const { attachments }: { attachments: IAttachment[] | File[] } = formikRef.current.values;

    if (file instanceof File) {
      files = (attachments as File[]).filter((_file: any, i: number) => i !== index);
    } else if (invoice?.id && file?.id && (await deleteAttachment(invoice.id, file?.id)) === 200) {
      files = (attachments as IAttachment[]).filter(({ id }: { id: number }) => id !== file.id);
    }

    formikRef.current && !!files && formikRef.current.setFieldValue("attachments", files);
  };

  const onSubmit = async (values: ISubmitValues) => {
    if (values.status === "SUBMITTED") {
      const ir35code = isNew ? booking?.ir35_code : invoice?.booking?.ir35_code;
      const isInsideIr35 = ir35code === "IR35_INSIDE";
      const changeInvoiceStatusToSubmit = () =>
        validateInsuranceNumber(isNew ? booking?.ir35_code : invoice?.booking?.ir35_code, insurance_number) &&
        validateBankProfile(() => submitInvoice(values));

      if (!isInsideIr35) {
        changeInvoiceStatusToSubmit();
        return;
      }

      const bookingId = invoice ? invoice?.booking.id : booking.booking_id;
      const assembledInvoice = assembleInvoiceValues(values);
      const newValidatedInvoiceNumbers = await getValidatedInvoice(bookingId, assembledInvoice);
      const isValidInvoice =
        validatedInvoice.total === newValidatedInvoiceNumbers.total &&
        validatedInvoice.subtotal === newValidatedInvoiceNumbers.subtotal &&
        compareArrays(validatedInvoice.line_items, newValidatedInvoiceNumbers.line_items, "line_amount");

      if (isValidInvoice) {
        changeInvoiceStatusToSubmit();
      } else {
        dispatch(
          setPopup({
            content: (
              <div
                className="IR35InsideDisclaimer IR35InsideDisclaimer-bold-numbers"
                dangerouslySetInnerHTML={{ __html: getIR35InsidePopup(newValidatedInvoiceNumbers) }}
              />
            ),
            buttons: [
              { text: "Cancel" },
              {
                text: "Submit",
                callback: () => {
                  changeInvoiceStatusToSubmit();
                  dispatch(modalActions.closeModal());
                },
              },
            ],
          })
        );
      }
    } else {
      submitInvoice(values);
    }
  };

  const assembleInvoiceValues = (values: ISubmitValues) => {
    const status = isNew ? values.status || "DRAFT" : invoice?.status !== values.status ? values.status : null;
    return {
      line_items: values.lineItems.map(({ dates, ...item }) => ({
        ...item,
        start_date: dates?.[0] && moment(dates[0]).format("YYYY-MM-DD"),
        end_date: dates?.[1] && moment(dates[1]).format("YYYY-MM-DD"),
        line_amount: undefined,
      })),
      notes: values.notes,
      ...(!!status && { status }),

      currency_code: "GBP",
      currency_rate: 1,
      date: isNew ? moment().format("YYYY-MM-DD") : invoice?.date || "",
      due_date: isNew ? moment().add(1, "M").format("YYYY-MM-DD") : invoice?.due_date || "",

      tax_type: values.includeVAT ? "TAX_EXCLUSIVE" : "TAX_NONE",
    } as IInvoice;
  };

  const submitInvoice = async (values: ISubmitValues) => {
    const payload = assembleInvoiceValues(values);
    if (isNew && booking) {
      const { status, id } = await postInvoice(booking.booking_id, payload);
      values.attachments?.length && id && (await uploadAttachments(id, values.attachments as File[]));

      status === 200 && history.push("/invoices");
    } else if (invoice?.id) {
      payload.id = invoice.id;
      const status = await putInvoice(payload);
      values.attachments?.length &&
        (await uploadAttachments(
          payload.id,
          (values.attachments as File[]).filter((file: IAttachment | File) => file instanceof File)
        ));

      status === 200 && history.push("/invoices");
    }
  };

  const handleBackButton = (deleteActiveLineItem: boolean, values: IInvoiceFormValues, setValues: any) => {
    if (deleteActiveLineItem && activeLineItem) {
      values.lineItems = values.lineItems.filter((_item, index) => index !== activeLineItem);
      setValues({ ...values });
    }

    setActiveLineItem(null);
  };

  return (
    <div className="InvoiceNew">
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={InvoiceSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, setValues, setFieldValue, validateForm, isValid, dirty, isValidating }) => {
          const isNewLineItem = get(values, `lineItems[${activeLineItem}].isNew`, false);

          useEffect(() => {
            if (!validatedInvoice && invoice) {
              setValidatedInvoice(invoice);
            }
          }, []);

          useEffect(() => {
            !!validatedInvoice?.line_items?.length &&
              validatedInvoice.line_items.map((item, index) => {
                setFieldValue(`lineItems.${index}.line_amount`, item.line_amount);
              });
          }, [validatedInvoice]);

          const debouncedValidate = debounce(() => {
            dirty &&
              validateForm().then((res) => {
                if (isEmpty(res) || (Object.keys(res).length === 1 && res.hasOwnProperty("agree"))) {
                  const bookingId = booking?.booking_id || invoice?.booking?.id;
                  !!bookingId && getValidatedInvoice(bookingId, assembleInvoiceValues(values));
                }
              });
          }, VALIDATE_DELAY);

          useEffect(() => {
            debouncedValidate();
            return debouncedValidate.cancel;
          }, [values.includeVAT, values.lineItems.length]);

          return (
            <Form>
              {!!booking && (
                <h1>
                  <Link to={`/invoices`} className="mr-3 phone:hidden">
                    <i className="icon-back"></i>
                  </Link>
                  Create New Invoice for {booking.client_name}
                </h1>
              )}
              {!!invoice && (
                <h1>
                  <Link to={`/invoices`} className="mr-3 phone:hidden">
                    <i className="icon-back"></i>
                  </Link>
                  Edit Invoice for {invoice?.client?.name}
                </h1>
              )}
              <div className="InvoiceNew__form">
                <div className="InvoiceNew__form__lineItems">
                  {activeLineItem !== null &&
                    (((!isNewLineItem || !!invoice?.id) && !get(errors, `lineItems[${activeLineItem}]`, false)) ||
                      (isNewLineItem && !!activeLineItem)) && (
                      <i
                        className="icon-back desktop:hidden"
                        onClick={() => handleBackButton(isNewLineItem && !!activeLineItem, values, setValues)}
                      ></i>
                    )}
                  {((isNewLineItem && values.lineItems.length === 1) || activeLineItem === null) && (
                    <i className="icon-back desktop:hidden" onClick={() => props.history.push(`/invoices`)}></i>
                  )}
                  <LineItemFormHeaders />
                  <FieldArray name="lineItems">
                    {() =>
                      values.lineItems.map((item, i) => (
                        <div
                          key={i}
                          className={cn("InvoiceNew__form__lineItems__item phone:hidden", {
                            "active-line-item": i === activeLineItem,
                          })}
                        >
                          <div
                            className={cn("delete", values.lineItems.length < 2 && "disabled")}
                            onClick={() =>
                              values.lineItems.length > 1 &&
                              addRemoveItemConfirm(false, () => onRemoveLineItem(values, setValues, i))
                            }
                          />
                          <LineItemForm
                            key={i}
                            index={i}
                            item={item}
                            debouncedValidate={debouncedValidate}
                            isValidatedInvoiceFetching={isValidatedInvoiceFetching}
                          />
                          <div
                            className={cn(
                              "phone-add-lineitem phone:block desktop:hidden",
                              errors.lineItems !== undefined && "disabled"
                            )}
                            onClick={() => {
                              validateForm().then(({ lineItems }) => {
                                activeLineItem !== null && (values.lineItems[activeLineItem].isNew = false);
                                setValues({ ...values });
                                lineItems === undefined && setActiveLineItem(null);
                              });
                            }}
                          >
                            {item.isNew ? "Add" : "Edit"} item
                          </div>
                        </div>
                      ))
                    }
                  </FieldArray>
                  {values.lineItems.map((item, i) => (
                    <div key={i}>
                      <div
                        className={cn("delete desktop:hidden", values.lineItems.length < 2 && "disabled")}
                        onClick={() => addRemoveItemConfirm(false, () => onRemoveLineItem(values, setValues, i))}
                      />
                      <LineItemFormMobile
                        item={item}
                        index={i}
                        key={`p${i}`}
                        onClick={() => setActiveLineItem(i)}
                        isValidatedInvoiceFetching={isValidatedInvoiceFetching}
                      />
                    </div>
                  ))}
                  <Field name="numberOfLineItems">
                    {() => (
                      <Button
                        label="+ Add item"
                        onClick={() =>
                          addRemoveItemConfirm(true, () => {
                            onAddItem(values, setValues);
                            setActiveLineItem(values.lineItems.length);
                          })
                        }
                        type="button"
                        name="lineItems"
                        className="inverse"
                      />
                    )}
                  </Field>
                </div>
                <div className="InvoiceNew__form__bottom grid">
                  {isIR35CommissionApplied && (
                    <div className="col-12">
                      <b
                        className="red-bordered"
                        dangerouslySetInnerHTML={{ __html: getIR35InsideDisclaimer(validatedInvoice) }}
                      />
                    </div>
                  )}
                  <div className="md:col-6">
                    {!showNotes && (
                      <span className="href" onClick={() => setShowNotes(true)}>
                        + Add Notes / Attachments <span>(Optional)</span>
                      </span>
                    )}
                    {showNotes && (
                      <>
                        <GmInputTextarea
                          id="notes"
                          label="Additional notes / Attachments"
                          placeholder="Please add any extra information you wish to share with the client here…"
                        />
                        <GmFileUpload
                          id="attachments"
                          mode="basic"
                          auto
                          multiple
                          onChange={onUploadAttachment}
                          chooseLabel={"Add Attachment"}
                          onDelete={onDeleteFile}
                        />
                      </>
                    )}
                  </div>
                  <div className="md:col-6 flex justify-content-end align-items-end">
                    <BlockUI
                      blocked
                      template={<Loader />}
                      className={cn("z-0", !isValidatedInvoiceFetching && "hidden")}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <td>Subtotal:</td>
                            <td>{formatCurrency(validatedInvoice?.subtotal)}</td>
                          </tr>
                          <tr>
                            <td>Include Total VAT, 20%:</td>
                            <td>{formatCurrency(validatedInvoice?.total_tax)}</td>
                          </tr>
                          <tr>
                            <td>Total:</td>
                            <td>{formatCurrency(validatedInvoice?.total)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </BlockUI>
                  </div>
                  <div className="md:col-7">
                    <div className="InputSwitchWrapper">
                      <InputSwitch
                        id="includeVAT"
                        checked={values.includeVAT}
                        onChange={() => onChangeVAT(values, setFieldValue)}
                      />
                      <label htmlFor="includeVAT">Include VAT</label>
                    </div>
                    <div className="InputSwitchWrapper">
                      <InputSwitch
                        id="agree"
                        checked={values.agree}
                        onChange={() => setFieldValue("agree", !values.agree)}
                      />
                      <label htmlFor="agree">
                        I confirm the details above are an accurate representation of the work completed during the
                        billing period
                      </label>
                    </div>
                  </div>
                  <div className="md:col-5 flex justify-content-end">
                    <div className="buttons-container">
                      {isFetching ? (
                        <Loader />
                      ) : (
                        <>
                          <Button
                            label={isNew ? "Draft" : "Update draft"}
                            type="submit"
                            disabled={!isValid || isValidatedInvoiceFetching}
                            className="mr-2"
                            tooltip={`${isNew ? "Save" : "Update"} Invoice as Draft`}
                            tooltipOptions={{ position: "top" }}
                          />
                          <Button
                            label="Submit"
                            type="submit"
                            disabled={!isValid || isValidatedInvoiceFetching}
                            tooltip={`Submit Invoice to ${booking?.client_name ?? invoice?.client?.name}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              onSubmit({ ...values, status: "SUBMITTED" });
                            }}
                            tooltipOptions={{ position: "top" }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default withRouter(InvoiceForm);
