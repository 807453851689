import React from "react";
import { useDispatch } from "react-redux";
import { conversationActions } from "~/store/chat";
import { modalActions } from "~/store/modal";

export default function EditTalentResponse({
  reason,
  rate,
  briefType,
  disabled,
  buttons,
}: {
  reason: string | null;
  rate: string | null;
  briefType: string | null;
  disabled: boolean;
  buttons: any;
}) {
  const dispatch = useDispatch();

  function openForm() {
    dispatch(modalActions.openModal("EDIT_TALENT_RESPONSE", { side: "right", reason, rate, briefType }));
  }

  function sendMessage() {
    dispatch(
      conversationActions.sendMessage({
        text_content: buttons[0].title,
        payload: {
          payload: buttons[0].payload,
        },
      })
    );
  }

  return (
    <>
      <button className="SelectRebookButton" disabled={!!disabled} onClick={openForm} type="button">
        <i className="icon-back"></i> {buttons[1].title}
      </button>
      <button className="SelectRebookButton" disabled={!!disabled} onClick={sendMessage} type="button">
        <i className="icon-back"></i> {buttons[0].title}
      </button>
    </>
  );
}
