import cn from "classnames";
import moment from "moment";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Menu } from "primereact/menu";
import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import Loader from "~/components/common/Loader/Loader";
import useTalent, { IInvoiceReadyBooking } from "~/hooks/useTalent";
import { modalActions } from "~/store/modal";
import Modal from "../AbstractModal/AbstractModal";
import "./SelectBookingModal.scss";

function SelectBookingModal(props: any) {
  const dispatch = useDispatch();
  const menuRefs = useRef<Menu[]>([]);

  const [selectedBookingId, setSelectedBookingId] = useState<number | null>(null);
  const {
    isFetching,
    isFetchingBooking,
    invoiceReadyBookings,
    getInvoiceReadyBookings,
    setBookingCompleted,
    setBookingUncompleted,
  } = useTalent();

  useEffect(() => {
    getInvoiceReadyBookings();
  }, []);

  const renderActionMenu = (booking: IInvoiceReadyBooking, index: number) => (
    <>
      <Button
        icon="pi pi-ellipsis-h"
        onClick={(event) => {
          event.stopPropagation();
          null !== menuRefs.current && menuRefs.current[index].toggle(event);
        }}
      />
      <Menu
        className="booking-action-buttons"
        model={[
          {
            label: "Actions",
            template: () => (
              <>
                <div className="GmMultiCheckbox">
                  <label className="booking-action-buttons-item">
                    <Checkbox
                      name={booking.booking_id}
                      checked={booking.invoice_completed}
                      onChange={() => {
                        booking.invoice_completed
                          ? setBookingUncompleted(booking.booking_id)
                          : setBookingCompleted(booking.booking_id);
                        menuRefs.current[index].toggle();
                      }}
                    />
                    <span>Mark booking as complete</span>
                  </label>
                </div>
              </>
            ),
          },
        ]}
        popup
        ref={(el: Menu) => null !== menuRefs.current && (menuRefs.current[index] = el)}
        appendTo={document.getElementsByTagName("body")[0]}
      />
    </>
  );

  return (
    <Modal
      className="SelectBooking"
      side="right"
      title={<h1>Choose Your Booking</h1>}
      headerRightButton={<button className="invisible" type="button" />}
      buttons={[
        { text: "Cancel", onClick: () => dispatch(modalActions.closeModal()) },
        {
          className: selectedBookingId ? "primary" : "disabled",
          text: "Create Invoice",
          onClick: () => {
            if (!!selectedBookingId) {
              props.history.push(`/invoice/new/${selectedBookingId}`);
              dispatch(modalActions.closeModal());
            }
          },
        },
      ]}
    >
      {isFetching ? (
        <Loader />
      ) : (
        <div className="bookings-grid">
          {invoiceReadyBookings &&
            invoiceReadyBookings?.map((booking, index: number) => {
              const isCurrBookingSelected = selectedBookingId === booking.booking_id;
              return (
                <div
                  key={booking.booking_id}
                  className={cn("bookings-grid__item", {
                    selected: booking.booking_id === selectedBookingId,
                    marked: booking.invoice_completed,
                    loading: isFetchingBooking === booking.booking_id,
                  })}
                  onClick={() => setSelectedBookingId(booking.booking_id)}
                >
                  {isFetchingBooking === booking.booking_id && <Loader />}
                  {isCurrBookingSelected && <div className="action-menu">{renderActionMenu(booking, index)}</div>}
                  <RadioButton id={booking.booking_id?.toString()} checked={booking.booking_id === selectedBookingId} />
                  <div className="client-brief">
                    <p className="client">{booking.client_name}</p>
                    <p>
                      {booking.contract_name} ({booking.ir35_name})
                    </p>
                    <p>Invoice(s) created: {booking.invoices_count}</p>
                  </div>
                  <div className="date-start">
                    <p className="date-title">Start Day</p>
                    <p>{moment(booking.start_date).format("MMM D, YYYY")}</p>
                  </div>
                  <div className="date-end">
                    <p className="date-title">Finish Day</p>
                    <p>{moment(booking.end_date).format("MMM D, YYYY")}</p>
                  </div>
                </div>
              );
            })}
          {invoiceReadyBookings && !invoiceReadyBookings.length && (
            <>
              <p className="m-auto">You don't have any bookings that require an invoice.</p>
              <p className="m-auto">
                Please note, you are only required to generate invoices for bookings that are OUTSIDE or EXEMPT.
              </p>
              <p className="m-auto">
                Please reach out to <a href="mailto:invoices@meetgenie.co">invoices@meetgenie.co</a> if you have any
                queries.
              </p>
            </>
          )}
        </div>
      )}
    </Modal>
  );
}

export default withRouter(SelectBookingModal);
