import { Field } from "formik";
import { SelectButton } from "primereact/selectbutton";
import React from "react";
import "./GmSelectButton.scss";

interface IGmSelectButton {
  formik?: any;
  noField?: boolean;
  id: string;
  disabled?: boolean;
  options: any;
  placeholder?: string;
}

export default function GmSelectButton(props: IGmSelectButton) {
  let meta, field;
  if (props.noField && props.formik) {
    meta = props.formik.getFieldMeta(props.id);
    field = props.formik.getFieldProps(props.id);
  }

  return props.noField && meta ? (
    <SelectButton
      className="GmSelectButton"
      {...props}
      {...field}
      onChange={(e) => e.value !== null && e.value !== field.value && field.onChange(e)}
    />
  ) : (
    <Field name={props.id}>
      {({ field, meta }: any) => (
        <SelectButton
          className="GmSelectButton"
          {...props}
          {...field}
          onChange={(e) => e.value !== null && e.value !== field.value && field.onChange(e)}
        />
      )}
    </Field>
  );
}
