import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useEffect } from "react";
import useUserPopup from "~/hooks/useUserPopup";
import { isValidUrl } from "~/utils";

export default function UserPopup() {
  const { userPopup, getUserPopup, markAsViewed } = useUserPopup();

  useEffect(() => {
    getUserPopup();
  }, []);

  const handleAction = async (action?: string) => {
    !!userPopup && (await markAsViewed(userPopup.id));

    if (!!action && isValidUrl(action)) {
      window.location.href = action;
    }
  };

  if (!userPopup) return null;

  const { header, body, primary_button_text, primary_button_action, secondary_button_text, secondary_button_action } =
    userPopup;

  return (
    <Dialog
      modal={true}
      className="ReferTalentModal"
      header={header}
      footer={
        <footer>
          {primary_button_text && (
            <Button label={primary_button_text} onClick={() => handleAction(primary_button_action)} className="mr-2" />
          )}
          {secondary_button_text && (
            <Button
              className="p-button-outlined"
              label={secondary_button_text}
              onClick={() => handleAction(secondary_button_action)}
            />
          )}
        </footer>
      }
      visible={!!userPopup}
      onHide={handleAction}
    >
      <p dangerouslySetInnerHTML={{ __html: body }}></p>
    </Dialog>
  );
}
