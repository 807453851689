import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "~/components/common/Loader/Loader";
import BankingInformationForm from "~/components/forms/BankingInformationForm/BankingInformationForm";
import { modalActions } from "~/store/modal";
import Modal from "../AbstractModal/AbstractModal";
import useInvoice from "~/hooks/useInvoice";

export default function BankingInformationModal() {
  const dispatch = useDispatch();
  const { updateBankProfile, isBankProfileFetching } = useInvoice();

  const [screen, setScreen] = useState({
    buttons: [
      {
        className: "primary",
        text: "Update",
        onClick: () => setScreen({ ...screen, submit: true }),
      },
    ],
    loader: [
      {
        className: "primary loader",
        text: <Loader />,
      },
    ],
    submit: false,
    title: "Banking information",
    touched: false,
  });

  const onUpdateBankingInformation = async (data: any) => {
    updateBankProfile(data)
      .then(() => dispatch(modalActions.openModal("INVOICES_MODAL", { _onlyOne: true, side: "left" })))
      .catch(() => setScreen({ ...screen, submit: false }));
  };

  return (
    <Modal
      askOnClose
      backModal="INVOICES_MODAL"
      className="BankingInformationModal no-animate"
      buttons={isBankProfileFetching ? screen.loader : screen.buttons}
      shouldIAsk={screen.touched}
      side="left"
      title={<h1>Banking information</h1>}
      headerRightButton={<button className="invisible" type="button"></button>}
    >
      <BankingInformationForm
        onSubmit={onUpdateBankingInformation}
        onTouch={(touched: boolean) => setScreen({ ...screen, touched })}
        onValidationFail={() => setScreen({ ...screen, submit: false })}
        submitTrigger={screen.submit}
      />
    </Modal>
  );
}
