import React from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "~/store/modal";
import "./OpenModalButton.scss";

export default function OpenModalButton({
  id,
  title,
  props = {},
  disabled = false,
}: {
  id: string;
  title: string;
  props?: any;
  disabled: boolean;
}) {
  const dispatch = useDispatch();

  function openModal() {
    dispatch(modalActions.openModal(id, props));
  }

  return (
    <button className="OpenModalButton" onClick={openModal} type="button" disabled={disabled}>
      {props?.icon && <i className="icon-back" />} {title}
    </button>
  );
}
