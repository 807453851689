import React, { useRef, useState } from "react";

export default (props: Props) => {
  const cLeft = useRef(null);
  const cCenter = useRef(null);
  const cRight = useRef(null);

  let currentAnimationTime = 0;
  const centreY = 10;
  const amplitude = 5;

  function animate() {
    if (cLeft && cLeft.current && cCenter && cCenter.current && cRight && cRight.current) {
      cLeft.current.setAttribute("cy", centreY + amplitude * Math.sin(currentAnimationTime));
      cCenter.current.setAttribute("cy", centreY + amplitude * Math.sin(currentAnimationTime - 1));
      cRight.current.setAttribute("cy", centreY + amplitude * Math.sin(currentAnimationTime - 2));
    }
    currentAnimationTime += 0.15;
    requestAnimationFrame(animate);
  }

  animate();

  return (
    <svg width="30" height="20">
      <circle ref={cLeft} id="cLeft" cx="5" cy="10" r="3" />
      <circle ref={cCenter} id="cCenter" cx="15" cy="10" r="3" />
      <circle ref={cRight} id="cRight" cx="25" cy="10" r="3" />
    </svg>
  );
};
