import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { ILineItem, ILineItemUnit } from "~/API/InvoiceService";
import GmCalendar from "~/components/common/GmCalendar/GmCalendar";
import GmDropdown from "~/components/common/GmDropdown/GmDropdown";
import GmInputText from "~/components/common/GmInputText/GmInputText";
import GmInputNumber from "~/components/common/GmInputNumber/GmInputNumber";
import GmInputTextarea from "~/components/common/GmInputTextarea/GmInputTextarea";
import { IInvoiceFormValues } from "./InvoiceForm";
import Loader from "~/components/common/Loader/Loader";
import cn from "classnames";
import { BlockUI } from "primereact/blockui";
import { yupMessages } from "~/utils";

export const LINE_ITEM_UNIT_TYPES = [
  { value: ILineItemUnit.TYPE_DAY_RATE, label: "Day Rate" },
  { value: ILineItemUnit.TYPE_FIXED_AMOUNT, label: "Fixed Amount" },
  { value: ILineItemUnit.TYPE_WEEKEND_RATE, label: "Weekend Rate" },
  { value: ILineItemUnit.TYPE_OVERTIME, label: "Overtime Hourly" },
  { value: ILineItemUnit.EXPENSE, label: "Expense" },
];

export default function LineItemForm({
  item,
  index,
  debouncedValidate,
  isValidatedInvoiceFetching,
}: {
  item: ILineItem;
  index: number;
  debouncedValidate: any;
  isValidatedInvoiceFetching: boolean;
}) {
  const { setValues, setFieldError, setFieldTouched, values } = useFormikContext<IInvoiceFormValues>();

  const { description, unit_amount, quantity, unit_type, dates } = item || {};

  const isFixTerm = item.unit_type === ILineItemUnit.TYPE_FIXED_AMOUNT;
  const isExpense = item.unit_type === ILineItemUnit.EXPENSE;

  useEffect(() => {
    debouncedValidate();
    return debouncedValidate.cancel;
  }, [description, unit_amount, unit_type, quantity, dates]);

  const onChangeUnitType = ({ value }: { value: ILineItemUnit }) => {
    const lineItems = [...values.lineItems];

    if (value === ILineItemUnit.EXPENSE) {
      lineItems[index] = {
        ...lineItems[index],
        dates: [],
      };
    }

    lineItems[index].unit_type = value;
    lineItems[index].quantity = value === ILineItemUnit.TYPE_DAY_RATE ? 0 : 1;

    setFieldTouched(`lineItems.${index}.dates`, true);

    setValues({ ...values, lineItems });
  };

  return (
    <>
      <h1 className="desktop:hidden">{item.isNew ? "Add" : "Edit"} Line Item</h1>
      <div className="index">{index + 1}.</div>
      <div className="description">
        <GmInputTextarea
          id={`lineItems.${index}.description`}
          label="Description"
          placeholder="Enter a description of the work you complete in this period…"
        />
      </div>
      <div className="unit">
        <GmDropdown
          id={`lineItems.${index}.unit_type`}
          label="Unit"
          options={LINE_ITEM_UNIT_TYPES}
          onChange={onChangeUnitType}
        />
      </div>
      <div className="amount">
        <GmInputNumber
          required
          id={`lineItems.${index}.unit_amount`}
          label="Amount"
          mode="currency"
          currency="GBP"
          max={1000000}
          minFractionDigits={0}
          maxFractionDigits={2}
        />
      </div>
      <div className="qty">
        <GmInputNumber
          id={`lineItems.${index}.quantity`}
          label="Qty"
          mode="decimal"
          disabled={isFixTerm || isExpense}
          max={1000000}
          minFractionDigits={0}
          maxFractionDigits={2}
        />
      </div>
      <div className="dates">
        <GmCalendar
          id={`lineItems.${index}.dates`}
          disabled={isExpense}
          placeholder="N/A"
          readOnlyInput
          maxDate={new Date()}
          label="Start Date - End Date"
          selectionMode="range"
          onChange={({ value }) => {
            if (!!value && Array.isArray(value)) {
              const newValues = { ...values };
              newValues.lineItems[index].dates = value;
              setValues(newValues, false);

              if (!value[1]) {
                setFieldError(`lineItems.${index}.dates`, yupMessages.required);
              } else {
                setFieldTouched(`lineItems.${index}.dates`, true);
              }
            }
          }}
          value={[new Date(item.dates[0]), !!item.dates[1] ? new Date(item.dates[1]) : null]}
        />
      </div>
      <div className="total">
        <BlockUI
          blocked
          template={<Loader />}
          className={cn("desktop:hidden", !isValidatedInvoiceFetching && "hidden")}
        >
          <GmInputText disabled id={`lineItems.${index}.line_amount`} label="Total" price />
        </BlockUI>
      </div>
    </>
  );
}
