import React from "react";
import moment from "moment";
import { IMessage } from "~/store/chat";

export function isSameDay(currentMessage: IMessage, diffMessage: IMessage) {
  if (!diffMessage || !diffMessage.created_at) {
    return false;
  }
  const currentCreatedAt = moment(currentMessage.created_at);
  const diffCreatedAt = moment(diffMessage.created_at);
  if (!currentCreatedAt.isValid() || !diffCreatedAt.isValid()) {
    return false;
  }
  return currentCreatedAt.isSame(diffCreatedAt, "day");
}

export default function Day({
  dateFormat,
  currentMessage,
  previousMessage,
}: {
  dateFormat: string;
  currentMessage: IMessage;
  previousMessage: IMessage;
}) {
  return currentMessage && !isSameDay(currentMessage, previousMessage) ? (
    <li>
      <div className="Day">{moment(currentMessage.created_at).format(dateFormat)}</div>
    </li>
  ) : null;
}
