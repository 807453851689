import React from "react";
import "./SelectSkillsButton.scss";
import { connect } from "react-redux";
import { skillsActions } from "~/store/skills";
import { modalActions } from "~/store/modal";

interface DispatchProps {
  loadSkills: (discipline: string) => void;
  openModal: (modalId: string, modalProps: any) => void;
}

interface OwnProps {
  disabled?: boolean;
  discipline: string;
}

type Props = DispatchProps & OwnProps;

const mapDispatchToProps = {
  loadSkills: skillsActions.loadSkills,
  openModal: modalActions.openModal,
};

export default connect(
  null,
  mapDispatchToProps
)((props: Props) => {
  function loadSkills() {
    props.loadSkills(props.discipline);
    props.openModal("SKILLS_MODAL", {
      side: "right",
      customSkills: true,
      max: 4,
    });
  }

  return (
    <button disabled={!!props.disabled} className="SelectSkillsButton" onClick={loadSkills} type="button">
      <i className="icon-back"></i>Select superpowers
    </button>
  );
});
