import React, { ReactNode } from "react";
import "./UmbrellaSelect.scss";
import { IUmbrellaCompany } from "~/store/options";
import PopupIcon from "~/components/Toaster/PopupIcon";

interface IUmbrellaSelect {
  id: string;
  label?: string;
  onSelectCLick: Function;
  formik: any;
  max: number;
  info?: string | ReactNode;
}

export default function UmbrellaSelect(props: IUmbrellaSelect) {
  const { onSelectCLick, formik, label, max, info } = props;

  return (
    <div className="UmbrellaSelect">
      {!!label && (
        <label htmlFor={props.id}>
          {label} {info && <PopupIcon content={info} />}
        </label>
      )}
      <ul>
        {formik.values.umbrella_companies && !!formik.values.umbrella_companies.length && (
          <>
            {formik.values.umbrella_companies.map((item: IUmbrellaCompany, i: number) => (
              <li key={i} data-value={item.id} onClick={onSelectCLick}>
                {item.company_name}
              </li>
            ))}
          </>
        )}
        {formik.values.umbrella_companies.length < max && (
          <button type="button" onClick={onSelectCLick}>
            + Add Company
          </button>
        )}
      </ul>
    </div>
  );
}
