import React from "react";
import { useDispatch } from "react-redux";
import BriefCardPermanent, { IBriefCardPermanent } from "~/components/BriefCardPermanent/BriefCardPermanent";
import { modalActions } from "~/store/modal";
import Modal from "../AbstractModal/AbstractModal";
import "./BriefCardModal.scss";

export default function BriefCardModal({
  brief,
  onPostBack,
  expandedRow,
}: {
  brief: IBriefCardPermanent;
  onPostBack: any;
  expandedRow: string;
}) {
  const dispatch = useDispatch();

  return (
    <Modal
      buttons={Object.keys(brief.buttons).map((key) => ({
        className: "primary",
        text: brief.buttons[key],
        onClick: () => {
          onPostBack.bind(null, key, brief.buttons[key])();
          dispatch(modalActions.closeModal());
        },
      }))}
      headerLeftButton={
        <button className="mobile-only" type="button" onClick={() => dispatch(modalActions.closeModal())}>
          <i className="icon-back"></i>
        </button>
      }
      className="BriefPermanentCard no-animate"
      side="right"
      title={<h1>Brief Details</h1>}
      headerRightButton={<button className="invisible" type="button"></button>}
    >
      <BriefCardPermanent {...brief} mode="full" expandedRow={expandedRow} />
    </Modal>
  );
}
