import React, { SyntheticEvent, useState } from "react";
import GmAutoComplete from "~/components/common/GmAutoComplete/GmAutoComplete";
import Axios from "axios";
import { API_URL } from "~/config";
import { withAuthorizationHeader } from "~/store";
import { useSelector } from "react-redux";
import { FormikProps } from "formik";
import debounce from "lodash/debounce";

interface CurrentEmployerSelect {
  formik: FormikProps<any>;
}

export default function CurrentEmployerSelect(props: CurrentEmployerSelect) {
  const token = useSelector((state: any) => state.users.authenticatedUser.token);
  const [filteredClients, setFilteredClients] = useState<string[] | null>(null);

  const searchClients = (e: { e: SyntheticEvent<HTMLInputElement>; query: string }) => getClients(e);

  const getClients = debounce(async (e: { e: SyntheticEvent<HTMLInputElement>; query: string }) => {
    const result = await Axios.get(
      `${API_URL}/api/user/current/available_clients?search=${e.query}`,
      withAuthorizationHeader(token)
    );
    setFilteredClients(result.data);
  }, 250);

  return (
    <div className="CurrentEmployerSelect field">
      <GmAutoComplete
        id="employer"
        formik={props.formik}
        suggestions={filteredClients || []}
        completeMethod={searchClients}
        label="Current Employer"
        placeholder="Type employer name"
        noField
      />
    </div>
  );
}
