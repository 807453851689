import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IOption } from "~/components/common/GmMultiCheckbox/GmMultiCheckbox";
import Loader from "~/components/common/Loader/Loader";
import GettingPaidForm from "~/components/forms/GettingPaidForm/GettingPaidForm";
import { modalActions } from "~/store/modal";
import { optionsActions } from "~/store/options";
import { usersActions } from "~/store/user";
import Modal from "../AbstractModal/AbstractModal";
import "../ProfileModal/ProfileModal.scss";

export default function GettingPaidModal() {
  const dispatch = useDispatch();
  const isUserProfileFetching: boolean = useSelector((state: any) => state.users.isFetching);
  const { options } = useSelector((state: any) => state.options);

  useEffect(() => {
    dispatch(optionsActions.getOptions());
  }, []);

  const [screen, setScreen] = useState({
    buttons: [
      {
        className: "primary",
        text: "Update",
        onClick: () => setScreen({ ...screen, submit: true }),
      },
    ],
    loader: [
      {
        className: "primary loader",
        text: <Loader />,
      },
    ],
    submit: false,
    title: "Getting Paid",
    touched: false,
  });

  function onUpdatePaymentProfile(data: any) {
    const form = { ...data };

    const isLtdCompany = data.company_types.includes(
      options && options.company_types.find((company: IOption) => company.code === "COMPANY_LTD").id
    );
    const isTaxCountryUK = form.registered_tax_country === "GB";

    const isUmbrella =
      form.company_types.includes(
        options?.company_types.find((company: IOption) => company.code === "COMPANY_UMBRELLA").id
      ) ||
      form.ir35_types.includes(
        options?.ir35_types.find((ir35_type: IOption) => ir35_type.code === "IR35_UMBRELLA")?.id
      );

    if (!isLtdCompany) {
      form.company_name = null;
      form.company_number = null;
      form.company_registration_year = null;
      form.company_country = null;
      form.company_city = null;
      form.company_address_1 = null;
      form.company_address_2 = null;
      form.company_postcode = null;
      form.company_tax_number = null;
    }

    if (!form.rate) {
      form.rate = null;
    }

    if (!form.annual_rate) {
      form.annual_rate = null;
    }

    if (!isTaxCountryUK) {
      form.ir35_types = [];
      form.insurance_number = null;
    }

    if (form.use_tax_country) {
      form.country = form.registered_tax_country;
    }

    if (form.insurance_number) {
      form.insurance_number = form.insurance_number.toUpperCase();
    } else {
      form.insurance_number = null;
    }

    if (!isTaxCountryUK || !isLtdCompany) {
      form.substitution_type = null;
    }

    if (!isTaxCountryUK || !isUmbrella) {
      form.umbrella_companies = [];
    }

    dispatch(usersActions.updateTalentProfiles(form))
      .then(onBack)
      .catch(() => setScreen({ ...screen, submit: false }));
  }

  function onBack() {
    dispatch(modalActions.openModal("USER_PROFILE_MODAL", { _onlyOne: true, side: "left" }));
  }

  return (
    <Modal
      askOnClose
      backModal="USER_PROFILE_MODAL"
      className="ProfileModal GettingPaidModal no-animate"
      buttons={isUserProfileFetching ? screen.loader : screen.buttons}
      shouldIAsk={screen.touched}
      side="left"
      title={<h1>Getting Paid</h1>}
      headerRightButton={<button className="invisible" type="button"></button>}
    >
      <GettingPaidForm
        onSubmit={onUpdatePaymentProfile}
        onTouch={(touched: boolean) => setScreen({ ...screen, touched })}
        onValidationFail={() => setScreen({ ...screen, submit: false })}
        submitTrigger={screen.submit}
      />
    </Modal>
  );
}
