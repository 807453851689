import Axios from "axios";
import { API_URL } from "~/config";
import { withAuthorizationHeader } from "~/store";

export class UserPopupService {
  static getUserPopup = async (token: string) =>
    await Axios.get(`${API_URL}/api/user/current/popups`, withAuthorizationHeader(token));

  static markAsViewed = async (id: number, token: string) =>
    await Axios.post(`${API_URL}/api/user/current/popups/${id}/markAsViewed`, {}, withAuthorizationHeader(token));
}
