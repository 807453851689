import * as Yup from "yup";
import { ILineItemUnit } from "~/API/InvoiceService";
import { BankProfileTypes } from "~/hooks/useInvoice";
import { yupMessages, yupString } from "~/utils";

const LineItemSchema = Yup.object().shape({
  description: yupString(true, 10, 200),
  unit_type: Yup.string().oneOf(Object.keys(ILineItemUnit)),
  unit_amount: Yup.number().positive("Must be a positive number").typeError("Must be a positive number"),
  quantity: Yup.number().positive("Must be a positive number").typeError("Must be a positive number"),
  dates: Yup.array()
    .of(Yup.string().typeError(yupMessages.required))
    .min(2, yupMessages.required)
    .required(yupMessages.required)
    .when("unit_type", {
      is: ILineItemUnit.EXPENSE,
      then: () => Yup.array().nullable(),
    }),
  total: Yup.number().min(1),
});

export const InvoiceSchema = Yup.object().shape({
  id: Yup.number().nullable(),
  notes: yupString(),
  lineItems: Yup.array().of(LineItemSchema).min(1),
  agree: Yup.bool().oneOf([true], "This field must be checked"),
  attachments: Yup.array().max(10, "Please, add less than 10 files"),
});

export const BankingInfoSchema = Yup.object().shape({
  type: Yup.string().oneOf(Object.values(BankProfileTypes)),
  bank_account_name: Yup.string().when("type", {
    is: BankProfileTypes.LOCAL,
    then: Yup.string().min(1).max(100).required(yupMessages.required),
  }),
  bank_account_number: Yup.string().when("type", {
    is: BankProfileTypes.LOCAL,
    then: Yup.string()
      .min(8, yupMessages.min)
      .max(17, yupMessages.max)
      .matches(/^[0-9]*$/, yupMessages.digits)
      .required(yupMessages.required),
  }),
  sort_code: Yup.string().when("type", {
    is: BankProfileTypes.LOCAL,
    then: Yup.string()
      .length(6, yupMessages.length)
      .matches(/^[0-9]*$/, yupMessages.digits)
      .required(yupMessages.required),
  }),
  global_account_name: Yup.string().when("type", {
    is: BankProfileTypes.INTERNATIONAL,
    then: Yup.string().min(1).max(100).required(yupMessages.required),
  }),
  iban: Yup.string().when("type", {
    is: BankProfileTypes.INTERNATIONAL,
    then: Yup.string()
      .max(34, yupMessages.max)
      .matches(/^[a-z0-9]+$/i, yupMessages.alphanumeric)
      .required(yupMessages.required),
  }),
  swift_bic: Yup.string().when("type", {
    is: BankProfileTypes.INTERNATIONAL,
    then: Yup.string()
      .max(11, yupMessages.max)
      .matches(/^[a-z0-9]+$/i, yupMessages.alphanumeric),
  }),
});
