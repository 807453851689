import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTalent from "~/hooks/useTalent";
import { modalActions } from "~/store/modal";
import { setToasts } from "~/store/toasts";
import { selectAndCopyToClipboard } from "~/utils";
import "./ReferTalentModal.scss";

export default function ReferTalentModal() {
  const dispatch = useDispatch();
  const { getReferCode, referCode } = useTalent();
  const { options: globalOptions } = useSelector((state: any) => state.options);

  useEffect(() => {
    getReferCode();
  }, []);

  const { CLIPBOARD_TEXT, COPY_TO_CLIPBOARD_BUTTON_TEXT, HEADER_TEXT, INTRO_TEXT } = useMemo(
    () =>
      referCode && referCode.limit > 0 && globalOptions?.refer_talent_popup_settings
        ? {
            ...globalOptions.refer_talent_popup_settings,
            CLIPBOARD_TEXT: globalOptions.refer_talent_popup_settings?.CLIPBOARD_TEXT.replaceAll(
              "{{code}}",
              referCode.code
            ).replaceAll("{{referal_link}}", referCode.link),
          }
        : {},
    [referCode, globalOptions]
  );

  const handleCopyToClipboard = () => {
    const element = document.createElement("div");
    element.setAttribute("id", "CLIPBOARD_CONTAINER");
    element.innerHTML = CLIPBOARD_TEXT;
    Array.from(element.getElementsByTagName("a")).forEach((a) => (a.textContent = a.href));
    document.body.appendChild(element);
    if (element) {
      selectAndCopyToClipboard(element);
      element.remove();
      dispatch(setToasts([{ severity: "success", summary: "", detail: "Invitation copied to clipboard" }]));
    }
  };

  return (
    <Dialog
      className="ReferTalentModal"
      header={HEADER_TEXT}
      footer={
        <footer>
          {referCode && referCode.limit > 0 && (
            <Button label={COPY_TO_CLIPBOARD_BUTTON_TEXT} onClick={handleCopyToClipboard} />
          )}
          <a href="#" className="ReferTalentModalButton" onClick={() => dispatch(modalActions.closeModal())}>
            Got it
          </a>
        </footer>
      }
      visible={!!referCode}
      onHide={() => dispatch(modalActions.closeModal())}
    >
      {!!referCode?.code && !!referCode?.link && referCode?.limit > 0 ? (
        <div>
          <div dangerouslySetInnerHTML={{ __html: INTRO_TEXT }}></div>
          <hr />
          <div id="ReferenceText" dangerouslySetInnerHTML={{ __html: CLIPBOARD_TEXT }}></div>
        </div>
      ) : (
        <>
          <p>
            It looks like you’ve already used up your five allowable referrals. We’ll be in touch soon to give you more
            invites.
          </p>
          <p>Thanks for your help so far.</p>
        </>
      )}
    </Dialog>
  );
}
