import { Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import GmInputText from "~/components/common/GmInputText/GmInputText";
import { modalActions } from "~/store/modal";
import Modal from "../AbstractModal/AbstractModal";
import "./InfluentialEmployerModal.scss";
import { MostInfluentialManagementSchema } from "~/schemas/TheWorkSchema";

interface Props {
  form: any;
  index: number;
  onDelete: Function;
  onSubmit: Function;
  onUpdate: Function;
  side: "left" | "right";
}

export default function InfluentialEmployerModal(props: Props) {
  const dispatch = useDispatch();

  function closeModal() {
    dispatch(modalActions.closeModal());
  }

  function renderModalButtons(formik) {
    let modalButtons = [
      {
        className: "primary",
        disabled: formik.values.client_name === "" || formik.values.start_year === "" || formik.values.end_year === "",
        onClick: () => {
          formik.validateForm().then((res) => {
            formik.handleSubmit();
            if (!Object.keys(res).length) {
              if (props.index !== undefined && props.onUpdate) {
                props.onUpdate(props.index, "most_influential", formik.values);
              } else {
                props.onSubmit(formik.values);
              }
            }
          });
        },
        text: `${props.form ? "Edit" : "Add"} Company`,
      },
    ];
    if (props.form) {
      modalButtons.unshift({
        onClick: () => {
          if (props.index !== undefined && props.onDelete) {
            props.onDelete(props.index, "most_influential");
          }
        },
        text: "Delete",
      });
    }
    return modalButtons;
  }

  return (
    <Formik
      initialValues={{
        client_name: (props.form && props.form.client_name) || "",
        start_year: (props.form && props.form.start_year) || "",
        end_year: (props.form && props.form.end_year) || "",
      }}
      validationSchema={MostInfluentialManagementSchema}
      onSubmit={() => {}}
    >
      {(formik: any) => (
        <Modal
          className="InfluentialEmployerModal"
          headerLeftButton={
            <button type="button" onClick={closeModal}>
              <i className="icon-back"></i>
            </button>
          }
          headerRightButton={<button className="invisible" type="button"></button>}
          buttons={renderModalButtons(formik)}
          title={<h1>{props.form ? "Edit" : "Add"} Company</h1>}
          side={props.side}
        >
          <Form className="fluid">
            <div className="field">
              <GmInputText
                id="client_name"
                label="Add a previous influential employer (permanent role)"
                placeholder="Company Name"
                required
              />
            </div>
            <div className="field field grid">
              <div className="col-6">
                <GmInputText
                  id="start_year"
                  keyfilter={/[0-9]+/}
                  max={2999}
                  maxLength={4}
                  min={1970}
                  placeholder="Start Year"
                  required
                  type="number"
                />
              </div>
              <div className="col-6">
                <GmInputText
                  id="end_year"
                  keyfilter={/[0-9]+/}
                  max={2999}
                  maxLength={4}
                  min={1970}
                  placeholder="Finish Year"
                  required
                  type="number"
                />
              </div>
            </div>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}
