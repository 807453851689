import { Dispatch, AnyAction } from "redux";

export interface IWebsocketMessage {
  type: string;
  payload: any;
}

// ------------------------------------
// Constants
// ------------------------------------
export const WS_SET_CLOSE = "WS_SET_CLOSE";
export const WS_SET_INSTANCE = "WS_SET_INSTANCE";

// ------------------------------------
// Actions
// ------------------------------------
const setWebsocketClose = () => (dispatch: Dispatch) => {
  dispatch({ type: WS_SET_CLOSE });
};

const setWebsocketInstance = (instance: any) => (dispatch: Dispatch) => {
  dispatch({ type: WS_SET_INSTANCE, payload: instance });
};

export const websocketActions = {
  setWebsocketClose,
  setWebsocketInstance,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  connected: true,
  instance: null,
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case WS_SET_CLOSE:
      return { ...state, connected: false };
    case WS_SET_INSTANCE:
      return { ...state, instance: action.payload, connected: true };
    default:
      return state;
  }
};
