import { useFormik } from "formik";
import React, { useEffect } from "react";
import GmInputText from "~/components/common/GmInputText/GmInputText";
import { BankingInfoSchema } from "~/schemas/InvoiceSchema";
import "./BankingInformationForm.scss";
import GmSelectButton from "~/components/common/GmSelectButton/GmSelectButton";
import useInvoice, { BankProfileTypes } from "~/hooks/useInvoice";
import omit from "lodash/omit";

interface Props {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  onTouch: (value: boolean) => void;
  onValidationFail: () => void;
  submitTrigger: boolean;
}

export default function BankingInformationForm(props: Props) {
  const { bankProfile, getBankProfile } = useInvoice();

  const bankProfileOptions = [
    { label: "Local (UK)", value: BankProfileTypes.LOCAL },
    { label: "International (SWIFT)", value: BankProfileTypes.INTERNATIONAL },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: bankProfile?.type || BankProfileTypes.LOCAL,
      bank_account_name: bankProfile?.bank_account_name || "",
      bank_account_number: bankProfile?.bank_account_number || "",
      sort_code: bankProfile?.sort_code || "",
      global_account_name: bankProfile?.global_account_name || "",
      iban: bankProfile?.iban || "",
      swift_bic: bankProfile?.swift_bic || "",
    },
    validationSchema: BankingInfoSchema,
    onSubmit: () => {},
  });

  useEffect(() => {
    getBankProfile();
  }, []);

  useEffect(() => {
    props.onTouch(JSON.stringify(formik.values) !== JSON.stringify(formik.initialValues));
  }, [formik.values]);

  useEffect(() => {
    const form = omit(
      formik.values,
      formik.values.type === BankProfileTypes.LOCAL
        ? ["global_account_name", "iban", "swift_bic"]
        : ["bank_account_name", "bank_account_number", "sort_code"]
    );
    if (props.submitTrigger) {
      formik.validateForm().then((res) => {
        formik.handleSubmit();
        if (!Object.keys(res).length) {
          props.onSubmit(form);
        } else {
          props.onValidationFail();
        }
      });
    }
  }, [props.submitTrigger]);

  return (
    <form className="BankingInformationForm fluid" onSubmit={formik.handleSubmit}>
      <div className="field">
        <b className="red-bordered">
          You are limited to saving one bank account on your profile. Only the account details of the selected tab below
          will be saved.
        </b>
      </div>
      <div className="field">
        <GmSelectButton formik={formik} id="type" noField options={bankProfileOptions} />
      </div>
      {formik.values.type === BankProfileTypes.LOCAL ? (
        <>
          <div className="field">
            <GmInputText
              id="bank_account_name"
              label="Bank Account Name"
              required
              formik={formik}
              noField
              maxLength={100}
            />
          </div>
          <div className="field">
            <GmInputText
              id="bank_account_number"
              label="Bank Account Number"
              keyfilter={/[0-9]+/}
              required
              formik={formik}
              noField
              maxLength={17}
            />
          </div>
          <div className="field">
            <GmInputText
              id="sort_code"
              label="Sort Code"
              required
              keyfilter={/[0-9]+/}
              formik={formik}
              noField
              maxLength={6}
            />
          </div>
        </>
      ) : (
        <>
          <div className="field">
            <GmInputText
              id="global_account_name"
              label="Account Holder Full Name"
              required
              formik={formik}
              noField
              maxLength={100}
            />
          </div>
          <div className="field">
            <GmInputText
              id="iban"
              label="IBAN"
              keyfilter={/^[a-z0-9]+$/i}
              required
              formik={formik}
              noField
              maxLength={34}
            />
          </div>
          <div className="field">
            <GmInputText
              id="swift_bic"
              label="SWIFT/ BIC (Required for Outside EU payments)"
              keyfilter={/^[a-z0-9]+$/i}
              required
              formik={formik}
              noField
              maxLength={11}
            />
          </div>
        </>
      )}
    </form>
  );
}
