import Axios from "axios";
import { API_URL } from "../config";
import { withAuthorizationHeader } from "../store";

export interface ITalent {
  is_available: boolean;
  start_from?: string;
  remind_at?: string;
}
export class TalentService {
  static async updateTalent(values: ITalent, token: string) {
    return await Axios.put(`${API_URL}/api/user/current/talent?reload_token`, values, withAuthorizationHeader(token));
  }
  static async updateTalentCV(file: any, token: string) {
    const data = new FormData();
    data.append("cv_url", file);
    return await Axios.post(
      `${API_URL}/user/current/talent_work_profile?reload_token`,
      data,
      withAuthorizationHeader(token)
    );
  }
  static async deleteTalentCV(token: string) {
    return await Axios.post(
      `${API_URL}/api/user/current/talent_work_profile?reload_token`,
      { cv_url: null },
      withAuthorizationHeader(token)
    );
  }
  static async getReferCode(token: string) {
    return await Axios.get(`${API_URL}/api/user/current/referral_code/active`, withAuthorizationHeader(token));
  }
  static async updateFlow(values: any, type: string, token: string) {
    return await Axios.post(`${API_URL}/flow/${type}`, values, withAuthorizationHeader(token));
  }
  static async getInvoiceReadyBookings(token: string) {
    return await Axios.get(`${API_URL}/api/user/current/invoice_ready_bookings`, withAuthorizationHeader(token));
  }
  static async setBookingCompleted(bookingId: number, token: string) {
    return await Axios.post(
      `${API_URL}/api/user/current/invoice_completed_bookings`,
      { booking_id: bookingId },
      withAuthorizationHeader(token)
    );
  }
  static async setBookingUncompleted(bookingId: number, token: string) {
    return await Axios.delete(
      `${API_URL}/api/user/current/invoice_completed_bookings/${bookingId}/delete`,
      withAuthorizationHeader(token)
    );
  }
  static postUserEvent = async (token: string, event: string) =>
    await Axios.post(`${API_URL}/api/user/current/event/${event}`, {}, withAuthorizationHeader(token));
}
