import { AxiosError } from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "~/components/common/Loader/Loader";
import ChangePasswordForm from "~/components/forms/ChangePasswordForm/ChangePasswordForm";
import { modalActions } from "~/store/modal";
import { setToasts, TOAST_SUCCESS_MESSAGE } from "~/store/toasts";
import { usersActions } from "~/store/user";
import { validatePassword } from "~/utils";
import Modal from "../AbstractModal/AbstractModal";
import "../ProfileModal/ProfileModal.scss";

export default function ChangePasswordModal() {
  const dispatch = useDispatch();
  const isUserProfileFetching: boolean = useSelector((state: any) => state.users.isFetching);

  const [error, setError] = useState("");
  const [passwords, setPasswords] = useState({
    confirm_password: "",
    current_password: "",
    new_password: "",
  });

  function onBack() {
    dispatch(modalActions.openModal("USER_PROFILE_MODAL", { _onlyOne: true, side: "left" }));
  }

  function updatePassword() {
    if (passwords.confirm_password === "" || passwords.current_password === "" || passwords.new_password === "") {
      setError("Sorry, looks like you missed filling in one of the passwords.");
    } else if (validatePassword(passwords.new_password, passwords.confirm_password)) {
      dispatch(
        usersActions.changePassword({
          current_password: passwords.current_password,
          password: passwords.new_password,
        })
      )
        .then((res: any) => {
          dispatch(
            setToasts([{ severity: "success", summary: "", detail: res.data?.result || TOAST_SUCCESS_MESSAGE }])
          );
          onBack();
          //setLastResult(res.data?.result);
        })
        .catch((err: AxiosError) => {
          setError(err?.response?.data?.result || "");
        });
    } else {
      setError("☝🏽oh, that password won’t work, (Fort Knox remember....)");
    }
  }

  function handleChangePassword(id: string, value: string) {
    setPasswords({ ...passwords, [id]: value });
    setError("");
  }

  return (
    <Modal
      askOnClose
      shouldIAsk={false}
      backModal="USER_PROFILE_MODAL"
      buttons={[
        {
          className: `primary ${isUserProfileFetching ? "password-update" : ""}`,
          text: isUserProfileFetching ? <Loader /> : "Update",
          onClick: updatePassword,
        },
      ]}
      className="ProfileModal ChangePasswordModal no-animate"
      side="left"
      title={<h1>Change Password</h1>}
      headerRightButton={<button className="invisible" type="button"></button>}
    >
      <ChangePasswordForm
        handleChangePassword={handleChangePassword}
        onSubmit={updatePassword}
        error={error}
        passwords={passwords}
      />
    </Modal>
  );
}
