import React from "react";
import Logo from "~/assets/genie-logo.svg";
import "./Maintenance.scss";

export default function Maintenance() {
  return (
    <div className="Maintenance">
      <img alt="Genie" src={Logo} />
      <p>
        Hi there!
        <br />
        <br />
        I'm updating my scripts to bring you a lot of new features! Please, try to talk to me a little bit later.
        <br />
        <br />
        xxx Genie
      </p>
    </div>
  );
}
