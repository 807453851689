import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "~/components/common/Loader/Loader";
import PersonalYouForm from "~/components/forms/PersonalYouForm/PersonalYouForm";
import { modalActions } from "~/store/modal";
import { usersActions } from "~/store/user";
import Modal from "../AbstractModal/AbstractModal";
import "../ProfileModal/ProfileModal.scss";

export default function PersonalProfileModal() {
  const dispatch = useDispatch();
  const isUserProfileFetching: boolean = useSelector((state: any) => state.users.isFetching);
  const [screen, setScreen] = useState({
    buttons: [
      {
        className: "primary",
        text: "Update",
        onClick: () => setScreen({ ...screen, submit: true }),
      },
    ],
    loader: [
      {
        className: "primary loader",
        text: <Loader />,
      },
    ],
    submit: false,
    title: "Profile Details",
    touched: false,
  });

  function onUpdatePersonalYou(data: any) {
    const form = { ...data };

    if (form.uk_work_permit) {
      if (form.uk_work_verification !== "VISA") {
        form.uk_visa_code = "";
        form.uk_visa_expire_at = null;
      }
    } else {
      form.uk_visa_code = "";
      form.uk_visa_expire_at = null;
    }

    if (!form.uk_work_permit) {
      form.country_by_passport = "";
    }

    dispatch(usersActions.updateTalentProfiles(form))
      .then(onBack)
      .catch(() => setScreen({ ...screen, submit: false }));
  }

  function onBack() {
    dispatch(modalActions.openModal("USER_PROFILE_MODAL", { _onlyOne: true, side: "left" }));
  }

  return (
    <Modal
      askOnClose
      backModal="USER_PROFILE_MODAL"
      shouldIAsk={screen.touched}
      className="ProfileModal PersonalProfileModal no-animate"
      buttons={isUserProfileFetching ? screen.loader : screen.buttons}
      side="left"
      title={<h1>Personal Details</h1>}
      headerRightButton={<button className="invisible" type="button"></button>}
    >
      <PersonalYouForm
        onSubmit={onUpdatePersonalYou}
        onTouch={(touched: boolean) => setScreen({ ...screen, touched })}
        onValidationFail={() => setScreen({ ...screen, submit: false })}
        submitTrigger={screen.submit}
      />
    </Modal>
  );
}
