import classNames from "classnames";
import React, { ChangeEvent, KeyboardEvent, PureComponent } from "react";
import { connect } from "react-redux";
import DropZone from "~/components/common/DropZone/DropZone";
import { modalActions } from "~/store/modal";
import { setToasts } from "~/store/toasts";
import Modal from "../AbstractModal/AbstractModal";
import "./PlatformsModal.scss";

interface StateProps {
  selectedPlatforms: string[];
  platforms: any[];
  options;
}
interface DispatchProps {
  closeModal: () => void;
  savePlatforms: (data: object) => void;
  setToasts: (data: any) => void;
}
interface OwnProps {
  onSubmit: Function;
  presetPlatforms: any[];
  selected?: any[];
  side: string;
  max?: number;
  customPlatforms?: boolean;
}
export type Props = StateProps & DispatchProps & OwnProps;
export type State = {
  custom_platforms: string[];
  selected_platforms: {
    custom_platforms: string[];
    platforms: {
      [x: string]: string;
    };
  };
  step: number;
  prioritised_platforms: { id: number; name: string }[];
  newPlatform: string;
};

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  setToasts,
};

const mapStateToProps = (state: any) => ({
  platforms: state.options.options.platforms,
});

class PlatformsModal extends PureComponent<Props, State> {
  state = {
    custom_platforms: [],
    prioritised_platforms: [],
    presetPlatforms: null,
    selected_platforms: {
      custom_platforms: [],
      platforms: {},
    },
    step: 0,
    newPlatform: "",
  };

  componentDidMount() {
    if (this.props.selected) {
      const custom_platforms = this.props.selected
        .filter((platform) => platform.id < 1)
        .map((platform) => platform.name);
      const platforms = this.props.selected.filter((platform) => platform.id > 0);
      this.setState({
        custom_platforms,
        prioritised_platforms: this.props.selected,
        selected_platforms: {
          custom_platforms,
          platforms: platforms.reduce((acc, cur) => {
            acc[cur.id] = cur.name;
            return acc;
          }, {}),
        },
      });
    }
  }

  addPlatforms = () => {
    const prioritised_platforms =
      this.state.step === 1
        ? this.state.prioritised_platforms
        : [
            ...this.state.selected_platforms.custom_platforms.map((cplatform, key) => ({
              id: 0 - key,
              name: cplatform,
            })),
            ...Object.keys(this.state.selected_platforms.platforms).map((platformkey: string) => ({
              id: +platformkey,
              name: this.state.selected_platforms.platforms[platformkey],
            })),
          ];

    this.props.onSubmit(
      "platforms",
      prioritised_platforms.map((platform, key: number) => ({
        ...platform,
        order_id: key + 1,
      }))
    );
  };

  handleAddNewPlatform = (e: KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (e.keyCode !== 13) {
      return;
    }

    const { newPlatform, custom_platforms, selected_platforms } = this.state;
    const { setToasts, max, presetPlatforms } = this.props;
    if (!newPlatform.trim()) {
      setToasts([
        {
          severity: "warn",
          summary: "",
          detail: "Sorry, smth must be wrong with this platform’s format",
        },
      ]);
      this.setState({ newPlatform: "" });
      return;
    }

    const presetPlatformNames = presetPlatforms.map((platform) => platform.name);
    if (
      [...presetPlatformNames, ...custom_platforms].some(
        (platform: string) => platform.toLowerCase() === newPlatform.toLowerCase().trim()
      )
    ) {
      setToasts([
        {
          severity: "warn",
          summary: "",
          detail: "This platform is already in the list",
        },
      ]);
      return;
    }

    const count = selected_platforms.custom_platforms.length + Object.keys(selected_platforms.platforms).length;

    if (!max || (max && count < max)) {
      this.setState({
        custom_platforms: [newPlatform, ...custom_platforms],
        selected_platforms: {
          ...selected_platforms,
          custom_platforms: [...selected_platforms.custom_platforms, newPlatform],
        },
        newPlatform: "",
      });
    } else {
      this.setState({
        custom_platforms: [newPlatform, ...custom_platforms],
        newPlatform: "",
      });
    }
  };

  handleNewPlatformChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    this.setState({ newPlatform: e.target.value });
  };

  selectCustomPlatform = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    const {
      dataset: { value },
    } = e.target as HTMLLinkElement;

    const count =
      this.state.selected_platforms.custom_platforms.length +
      Object.keys(this.state.selected_platforms.platforms).length;

    if (value) {
      const index = this.state.selected_platforms.custom_platforms.indexOf(value);
      if (index !== -1) {
        const tmp = [...this.state.selected_platforms.custom_platforms];
        tmp.splice(index, 1);
        this.setState({
          selected_platforms: {
            ...this.state.selected_platforms,
            custom_platforms: tmp,
          },
        });
      } else if (this.props.max === undefined || (this.props.max !== undefined && count < this.props.max)) {
        this.setState({
          selected_platforms: {
            ...this.state.selected_platforms,
            custom_platforms: [...this.state.selected_platforms.custom_platforms, value],
          },
        });
      } else {
        this.props.setToasts([
          {
            severity: "warn",
            summary: "",
            detail: `Sorry, only ${this.props.max} items can be selected`,
          },
        ]);
      }
    }
  };

  selectPlatform = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    const {
      dataset: { value },
    } = e.target;

    const count =
      this.state.selected_platforms.custom_platforms.length +
      Object.keys(this.state.selected_platforms.platforms).length;

    if (Object.keys(this.state.selected_platforms.platforms).includes(value)) {
      const platforms = { ...this.state.selected_platforms.platforms };
      delete platforms[value];
      this.setState({
        selected_platforms: {
          ...this.state.selected_platforms,
          platforms,
        },
      });
    } else if (this.props.max === undefined || (this.props.max !== undefined && count < this.props.max)) {
      const platform = this.props.presetPlatforms
        ? this.props.presetPlatforms.find((platform) => platform.id === +value).name
        : this.props.platforms.find((platform) => platform.id === +value).name;
      this.setState({
        selected_platforms: {
          ...this.state.selected_platforms,
          platforms: {
            ...this.state.selected_platforms.platforms,
            [value]: platform,
          },
        },
      });
    } else {
      this.props.setToasts([
        {
          severity: "warn",
          summary: "",
          detail: `Sorry, only ${this.props.max} items can be selected`,
        },
      ]);
    }
  };

  nextStep = () =>
    this.setState((prevState: State) => ({
      step: 1,
      prioritised_platforms: [
        ...prevState.selected_platforms.custom_platforms.map((cplatform, key) => ({
          id: 0 - key,
          name: cplatform,
        })),
        ...Object.keys(prevState.selected_platforms.platforms).map((platformkey: string) => ({
          id: +platformkey,
          name: prevState.selected_platforms.platforms[platformkey],
        })),
      ],
    }));
  prevStep = () => this.setState({ step: 0 });

  renderSelectPlatforms = () => (
    <ul>
      {this.props.customPlatforms !== false && (
        <li className="platform">
          <input
            onKeyDown={this.handleAddNewPlatform}
            onChange={this.handleNewPlatformChange}
            placeholder="+ Add your own platform"
            type="text"
            value={this.state.newPlatform}
          />
        </li>
      )}
      {this.state.custom_platforms &&
        this.state.custom_platforms.map((platform: string, i: number) => (
          <li
            className={classNames("platform", {
              selected: this.state.selected_platforms.custom_platforms.includes(`${platform}`),
            })}
            data-value={`${platform}`}
            key={i}
            onClick={this.selectCustomPlatform}
          >
            {platform}
          </li>
        ))}
      {(this.props.presetPlatforms &&
        this.props.presetPlatforms.map((platform, i: number) => (
          <li
            className={classNames("platform", {
              selected: Object.keys(this.state.selected_platforms.platforms).includes(`${platform.id}`),
            })}
            data-value={`${platform.id}`}
            key={i}
            onClick={this.selectPlatform}
          >
            {platform.name}
          </li>
        ))) ||
        (this.props.platforms &&
          this.props.platforms.map((platform, i: number) => (
            <li
              className={classNames("platform", {
                selected: Object.keys(this.state.selected_platforms.platforms).includes(`${platform.id}`),
              })}
              data-value={`${platform.id}`}
              key={i}
              onClick={this.selectPlatform}
            >
              {platform.name}
            </li>
          )))}
    </ul>
  );

  renderPrioritisePlatforms = () => (
    <>
      <p>
        Hold &amp; drag into order pls
        <br />
        (most important on top)
      </p>
      <DropZone
        items={this.state.prioritised_platforms}
        itemClass="platform selected prioritise"
        setItems={(items: any) => this.setState({ prioritised_platforms: items })}
      />
      {this.props.customPlatforms === false && (
        <p>(Again, I'm not binary - this also helps me get a better idea of whats in your portfolio/CV)</p>
      )}
    </>
  );

  render() {
    const totalSelected =
      Object.keys(this.state.selected_platforms.platforms).length +
      this.state.selected_platforms.custom_platforms.length;
    return (
      <Modal
        className={classNames("PlatformsModal", {
          preset: this.props.presetPlatforms,
        })}
        headerLeftButton={<button className="invisible" type="button"></button>}
        headerRightButton={
          <button type="button" onClick={this.props.closeModal}>
            <i className="icon-cancel"></i>
          </button>
        }
        buttons={
          this.state.step
            ? [
                { text: "Back", onClick: this.prevStep },
                {
                  className: "primary",
                  disabled: totalSelected === 0,
                  text:
                    totalSelected === 0
                      ? "Add Platforms"
                      : totalSelected === 1
                      ? "Add 1 Platform"
                      : totalSelected > 1
                      ? `Add ${totalSelected} Platforms`
                      : "Add Platforms",
                  onClick: this.addPlatforms,
                },
              ]
            : [
                { text: "Cancel", onClick: this.props.closeModal },
                {
                  className: "primary",
                  disabled: totalSelected === 0,
                  text:
                    totalSelected === 0
                      ? "Add Platforms"
                      : totalSelected === 1
                      ? "Add 1 Platform"
                      : totalSelected > 1
                      ? `Prioritise ${totalSelected} Platforms`
                      : "Prioritise Platforms",
                  onClick: totalSelected > 1 ? this.nextStep : this.addPlatforms,
                },
              ]
        }
        title={<h1>{this.state.step ? "Prioritise" : "Select"} Platforms</h1>}
        side={this.props.side}
      >
        <h1>{this.state.step ? "Prioritise" : "Select"} Platforms</h1>
        {this.state.step ? this.renderPrioritisePlatforms() : this.renderSelectPlatforms()}
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlatformsModal);
