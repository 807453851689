import { useFormik } from "formik";
import cn from "classnames";
import moment from "moment";
import React, { useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import Loader from "~/components/common/Loader/Loader";
import AvailabilityForm from "~/components/forms/AvailabilityForm";
import useTalent from "~/hooks/useTalent";
import { modalActions } from "~/store/modal";
import { setToasts, TOAST_SUCCESS_MESSAGE } from "~/store/toasts";
import Modal from "../AbstractModal/AbstractModal";
import "./AvailabilityModal.scss";
import { conversationActions } from "~/store/chat";

interface Props {
  side: string;
  isChatMessage?: boolean;
}
const currentDate = moment();
const tomorrow = moment().add(1, "days");

export default function AvailabilityModal(props: Props) {
  const dispatch = useDispatch();
  const { isChatMessage } = props;
  const { updateTalent, isFetching } = useTalent();
  const authenticatedUser = useSelector((state: any) => state.users.authenticatedUser);
  const { is_available: isAvailable, start_from: startFrom, remind_at: remindAt } = authenticatedUser?.talent;

  const [screen, setScreen] = useState({
    submit: false,
    touched: false,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      is_available: isAvailable || false,
      start_from: !!startFrom ? moment(startFrom) : currentDate,
      is_remind: !!remindAt || false,
      remind_at: !!remindAt ? moment(remindAt) : tomorrow,
    },
    onSubmit: onUpdateTalent,
  });

  function onUpdateTalent() {
    const form = isChatMessage ? { is_available: true, start_from: formik.values.start_from } : { ...formik.values };
    updateTalent(form, () => {
      setScreen({ touched: false, submit: true });
      if (isChatMessage) {
        batch(() => {
          dispatch(
            conversationActions.sendMessage({
              text_content: formik.values.start_from.format("ddd DD MMM, YYYY"),
              payload: { payload: `${formik.values.start_from.format("YYYY-MM-DD")}` },
            })
          );
          dispatch(modalActions.closeModal());
        });
      } else {
        batch(() => {
          dispatch(modalActions.openModal("PROFILE_MODAL", { side: "left", _onlyOne: true }));
          dispatch(setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
        });
      }
    });
  }

  return (
    <Modal
      askOnClose
      shouldIAsk={screen.touched}
      backModal="PROFILE_MODAL"
      buttons={[
        {
          className: "primary",
          text: isFetching ? <Loader /> : "Update",
          onClick: formik.handleSubmit,
        },
      ]}
      className={cn("AvailabilityModal ProfileModal PersonalProfileModal", { "no-animate": !isChatMessage })}
      side={props.side}
      title={<h1>Availability</h1>}
      headerRightButton={<button className="invisible" type="button"></button>}
    >
      <AvailabilityForm
        formik={formik}
        onTouch={(touched: boolean) => setScreen({ ...screen, touched })}
        isChatMessage={isChatMessage}
      />
    </Modal>
  );
}
