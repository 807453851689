import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import Loader from "~/components/common/Loader/Loader";
import InvoiceForm from "~/components/forms/InvoiceForm/InvoiceForm";
import Sidebar from "~/components/Sidebar/Sidebar";
import SidePanel from "~/components/SidePanel/SidePanel";
import useInvoice from "~/hooks/useInvoice";
import "./InvoiceView.scss";

export default function EditInvoicenvoice(props: RouteComponentProps<{ id: string }>) {
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const { isFetching, invoice, getInvoice } = useInvoice();

  const {
    match: {
      params: { id },
    },
  } = props;

  useEffect(() => {
    getInvoice(+id);
  }, []);

  if (isFetching)
    return (
      <div className="centered">
        <Loader />
      </div>
    );

  return (
    <Sidebar className="ChatView" isOpen={isSidebarOpen} close={() => setSidebarOpen(!isSidebarOpen)}>
      <SidePanel />
      <div className="content">
        {!!invoice ? <InvoiceForm invoice={invoice} /> : <p className="m-auto">No invoice here, sorry</p>}
      </div>
    </Sidebar>
  );
}
