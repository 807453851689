import React from "react";
import "./LoadMoreButton.scss";
import { connect } from "react-redux";

interface DispatchProps {}

interface OwnProps {
  title: string;
  payload: string;
  sendMessage: any;
}

type Props = DispatchProps & OwnProps;

const mapDispatchToProps = {};

export default connect(
  null,
  mapDispatchToProps
)((props: Props) => {
  function handleSendMessage() {
    const message = {
      text_content: props.title,
      payload: {
        payload: props.payload,
        button_type: "quick_reply",
      },
    };
    props.sendMessage(message);
  }

  return (
    <button className="LoadMoreButton" onClick={handleSendMessage} type="button">
      <i className="icon-back"></i>
      {props.title}
    </button>
  );
});
