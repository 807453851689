import cn from "classnames";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LogoIcon from "~/assets/genie-logo.svg";
import { IR35_TYPES } from "~/hooks/useTalent";
import { briefActions } from "~/store/brief";
import { modalActions } from "~/store/modal";
import { formatCurrency } from "~/utils";
import "./BriefCardPermanent.scss";

const workingSettings: { [key: string]: string } = {
  WORK_REMOTE: "Remote Only (WFH)",
  WORK_HYBRID: "Remote & Office (Hybrid)",
  WORK_ONSITE: "Office Only (On-site)",
};

interface AbstractCardParams {
  id: number;
  title: string;
  client: string;
  clientImage: string;
  type: string;
  buttons: {
    [key: string]: string;
  };

  onPostBack: any;
  ir35_code: keyof typeof IR35_TYPES;
}

interface IBriefCardPermanentParams extends AbstractCardParams {
  rates: number[];
  work_setting: string;
  company: { [key: string]: string };
  role: { [key: string]: string };
  attachment?: string;
  genie_take: string;
  mode?: "full" | "compact";
  expandedRow?: string;
}

export interface IBriefCardParams extends AbstractCardParams {
  start_date: string;
  end_date: string;
  budget: number;
  subtitle: string;
  clientDescription: string;
  briefDetails: { [key: string]: string };
}

export interface IBriefParams extends IBriefCardParams, IBriefCardPermanentParams {}

const BriefCardPermanent = (brief: IBriefParams) => {
  const {
    id,
    clientImage,
    client,
    title,
    rates,
    type,
    budget,
    work_setting,
    company,
    role,
    attachment,
    genie_take,
    buttons,
    onPostBack,
    mode = "compact",
    expandedRow = null,
    clientDescription,
    start_date,
    end_date,
    briefDetails,
    subtitle,
    ir35_code,
  } = brief;
  const disabled = !useSelector((state: any) => state.websocket.connected);
  const [openRow, setOpenRow] = useState<string | null>(expandedRow);
  const dispatch = useDispatch();

  const isPermanent = ir35_code === "IR35_PERMANENT";

  const responseButtons = useMemo(
    () =>
      !!buttons &&
      Object.keys(buttons).map((key) => (
        <li key={key} className={cn({ disabled })} onClick={!disabled && onPostBack.bind(null, key, buttons[key])}>
          {buttons[key]}
        </li>
      )),
    [buttons, disabled]
  );

  const onCLick = (clickable: boolean, expandedRow: string) => {
    switch (true) {
      // Card in Chat: open Modal
      case clickable && mode === "compact":
        dispatch(
          modalActions.openModal("BRIEF_PERMANENT_MODAL", {
            brief,
            expandedRow,
            onPostBack,
          })
        );
        dispatch(briefActions.setScopeIsViewed(id));
        break;
      // Card in Modal: expand row
      case mode === "full":
        setOpenRow(openRow !== expandedRow ? expandedRow : null);
        break;
    }
  };

  const renderGroup = (group: { [key: string]: string }) =>
    group
      ? Object.keys(group).map((label: string, i: number) =>
          group[label] ? (
            <React.Fragment key={i}>
              <h4>{label}</h4>
              <p>{group[label]}</p>
            </React.Fragment>
          ) : null
        )
      : null;

  const content = useMemo(
    () =>
      isPermanent
        ? [
            {
              id: "salary",
              label: "Salary Range (D.O.E)",
              value: rates.map((r) => `£${r}`).join(" — "),
            },
            {
              id: "type",
              label: "Brief Type",
              value: type,
            },
            {
              id: "work_setting",
              label: "Working Location",
              value: workingSettings[work_setting],
            },
            {
              id: "client_description",
              label: "About the Company",
              clickable: true,
              expandedContent: renderGroup(company),
            },
            {
              id: "role",
              label: "What’s the Role?",
              clickable: true,
              expandedContent: (
                <>
                  {renderGroup(role)}
                  {attachment && (
                    <p>
                      <a target="_blank" href={attachment}>
                        Full Job Description{`  `}
                        <span className="pi pi-external-link" />
                      </a>
                    </p>
                  )}
                </>
              ),
            },
            ...(!!genie_take
              ? [
                  {
                    id: "genie_take",
                    label: "Genie’s Opinion",
                    clickable: true,
                    value: <img src={LogoIcon} />,
                    expandedContent: <p>{genie_take}</p>,
                  },
                ]
              : []),
          ]
        : [
            {
              id: "start_date",
              label: "Start Date",
              value: start_date,
              disabled: true,
            },
            {
              id: "end_date",
              label: "End Date",
              value: end_date,
              disabled: true,
            },
            ...(budget
              ? [
                  {
                    id: "type",
                    label: "Ballpark budget",
                    value: formatCurrency(budget, 0),
                    disabled: true,
                  },
                ]
              : [
                  {
                    id: "type",
                    label: "Brief Type",
                    value: type,
                    disabled: true,
                  },
                ]),
            {
              id: "company",
              label: "About the Company",
              clickable: true,
              expandedContent: clientDescription,
            },
            {
              id: "details",
              label: "View Brief Details",
              clickable: true,
              expandedContent: renderGroup(briefDetails),
            },
          ],
    []
  );

  return !!brief?.client ? (
    <div className="ResponseCard BriefPermanent">
      {clientImage && <img src={clientImage} />}
      <div>
        <header>
          <h2>{client}</h2>
          <p>{isPermanent ? `${title} - Permanent` : subtitle}</p>
        </header>
        <div className="brief__items">
          {content.map(({ id, label, value, expandedContent, clickable }) => (
            <div key={id} className={cn("row", { clickable, expanded: openRow === id })}>
              <div className="row__controls" onClick={() => onCLick(!!clickable, id)}>
                <label htmlFor={id}>{label}</label>
                <div id={id}>{value && value}</div>
              </div>
              {!!expandedContent && openRow === id && <div className="row__expanded">{expandedContent}</div>}
            </div>
          ))}
        </div>
        {!!Object.keys(buttons).length && mode === "compact" && <ul>{responseButtons}</ul>}
      </div>
    </div>
  ) : null;
};

export default BriefCardPermanent;
