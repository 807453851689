import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { conversationActions } from "~/store/chat";
import { modalActions } from "~/store/modal";
import { usersActions } from "~/store/user";
import Modal from "../AbstractModal/AbstractModal";
import { IUmbrellaCompany } from "~/store/options";
import { arrUnique } from "~/utils";
import { setToasts } from "~/store/toasts";
import Loader from "~/components/common/Loader/Loader";

interface Props {
  max: number;
  side: "left" | "right";
  isProfile?: boolean;
  formik: any;
}

export default function UmbrellasModal(props: Props) {
  const { max, side, isProfile, formik } = props;
  const dispatch = useDispatch();
  const userIr35Profile = useSelector((state: any) => state.users.paymentProfile);

  const [selected, setSelected] = useState<Array<number>>(
    (formik &&
      formik.values.umbrella_companies &&
      formik.values.umbrella_companies.map((company: IUmbrellaCompany) => company.id)) ||
      []
  );
  const [newCompany, setNewCompany] = useState<string>("");
  const [customCompanies, setCustomCompanies] = useState<IUmbrellaCompany[]>([]);
  const [inputFocused, setInputFocused] = useState<boolean>(false);

  const isUserProfileFetching: boolean = useSelector((state: any) => state.users.isFetching);

  useEffect(() => {
    dispatch(usersActions.getPaymentProfile());
    dispatch(usersActions.getUmbrellaCompanies());
  }, []);

  const umbrellaCompanies: IUmbrellaCompany[] =
    (formik && formik.values.umbrella_companies) || userIr35Profile.umbrella_companies || [];

  const allUmbrellas: IUmbrellaCompany[] = useSelector((state: any) => state.users.umbrella_companies) || [];

  const genieMPS = allUmbrellas.filter((item: IUmbrellaCompany) => item.order_id);

  const umbrellaTitles: string[] = [...genieMPS, ...customCompanies, ...umbrellaCompanies].map(
    (item: IUmbrellaCompany) => item.company_name
  );

  const suggestions = allUmbrellas.filter(
    (item: IUmbrellaCompany) =>
      ~item.company_name.toLowerCase().indexOf(newCompany.toLowerCase()) && !umbrellaTitles.includes(item.company_name)
  );

  function closeModal() {
    dispatch(modalActions.closeModal());
  }

  function selectUmbrella(e: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    e.stopPropagation();

    const {
      dataset: { value },
    } = e.currentTarget;

    if (!value) return;

    if (selected.includes(+value)) {
      const newItems: number[] = [...selected];
      newItems.splice(selected.indexOf(+value), 1);
      setSelected(newItems);
    } else if (selected.length < max) {
      setSelected([...selected, +value]);
    } else if (max === 1) {
      setSelected([+value]);
    } else if (selected.length >= max) {
      dispatch(
        setToasts([
          {
            severity: "warn",
            summary: "",
            detail: `Sorry, only ${max} item${max > 1 ? "s" : ""} can be selected`,
          },
        ])
      );
    }
  }

  function addNewCompany(e: React.ChangeEvent<HTMLInputElement>) {
    setNewCompany(e.currentTarget.value);
  }

  function selectNewCompany(name: string = "") {
    const company_name = name || newCompany.trim();
    const allCompanies = [...genieMPS, ...customCompanies, ...umbrellaCompanies];
    if (company_name.length > 0) {
      const exist = allCompanies.find(
        (item: IUmbrellaCompany) => item.company_name.toLowerCase() === company_name.toLowerCase()
      );

      let id: number;
      if (exist) {
        id = exist.id;
      } else {
        const lowestId = Math.min(...allCompanies.map((company) => company.id));
        id = lowestId < 0 ? lowestId - 1 : -1;
        setCustomCompanies([{ id, company_name }, ...customCompanies]);
      }

      setNewCompany("");
      if (selected.length < max) {
        setSelected(arrUnique([id, ...selected]));
      }
    }
  }

  async function saveAndContinue() {
    if (isProfile) {
      const companies: IUmbrellaCompany[] = [...genieMPS, ...customCompanies, ...umbrellaCompanies].filter(
        (item: IUmbrellaCompany) => selected.includes(item.id)
      );
      formik.setFieldValue("umbrella_companies", companies);
      closeModal();
      return;
    }

    let userUmbrellas: IUmbrellaCompany[] = umbrellaCompanies;
    const newCompanies: IUmbrellaCompany[] = customCompanies.filter((item: IUmbrellaCompany) =>
      selected.includes(item.id)
    );

    if (newCompanies.length > 0) {
      const response: any = await dispatch(
        usersActions.updateTalentProfiles({
          umbrella_companies: [...umbrellaCompanies, ...newCompanies],
        })
      );
      userUmbrellas = response.data.umbrella_companies;
      newCompanies.forEach((item: IUmbrellaCompany) => {
        const savedUmbrella: IUmbrellaCompany | undefined = userUmbrellas.find(
          (s: IUmbrellaCompany) => item.company_name.toLowerCase() === s.company_name.toLowerCase()
        );
        if (savedUmbrella) {
          selected[selected.indexOf(item.id)] = savedUmbrella.id;
          allUmbrellas.push(savedUmbrella);
        }
      });
    }
    closeModal();
    dispatch(
      conversationActions.sendMessage(
        {
          text_content: (allUmbrellas.find((item: IUmbrellaCompany) => item.id === selected[0]) as IUmbrellaCompany)
            .company_name,
          payload: {
            payload: selected.join(","),
            button_type: "postback",
          },
        },
        true
      )
    );
  }

  const buttons = {
    buttons: [
      {
        className: "primary",
        text: isUserProfileFetching ? <Loader /> : "Update",
        onClick: saveAndContinue,
      },
    ],
    submit: false,
    title: "Profile Details",
    touched: false,
  };

  return (
    <Modal
      className="RebookModal"
      askOnClose
      shouldIAsk={false}
      backModal={isProfile ? "GETTING_PAID_MODAL" : undefined}
      headerRightButton={<button className="invisible" type="button"></button>}
      buttons={buttons.buttons}
      title={<h1>Umbrella companies</h1>}
      side={side}
      closeModal={closeModal}
    >
      {!isProfile && <h1>Umbrella companies</h1>}
      <ul>
        <li className="skill">
          <input
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                selectNewCompany();
                setInputFocused(false);
              }
            }}
            onChange={addNewCompany}
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
            placeholder="Type a company name"
            type="text"
            value={newCompany}
          />
          {inputFocused && suggestions.length > 0 && (
            <ul className="umbrellas-list">
              {suggestions.map((item: IUmbrellaCompany, i: number) => (
                <li
                  key={i}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    setNewCompany(item.company_name);
                    selectNewCompany(item.company_name);
                  }}
                >
                  {item.company_name}
                </li>
              ))}
            </ul>
          )}
        </li>
        <p className="hint">{`(Only ${max} compan${max === 1 ? "y" : "ies"} can be selected)`}</p>
        {[...genieMPS, ...customCompanies, ...umbrellaCompanies.filter((item) => !item.order_id)].map(
          (item: IUmbrellaCompany, i: number) => (
            <li
              className={classNames("talent", {
                selected: selected.includes(item.id),
              })}
              key={i}
              data-value={item.id}
              onClick={selectUmbrella}
            >
              {item.company_name}
            </li>
          )
        )}
      </ul>
    </Modal>
  );
}
