import classnames from "classnames";
import { Field, FieldProps } from "formik";
import moment from "moment";
import { Calendar, CalendarProps } from "primereact/calendar";
import React from "react";
import "./GmCalendar.scss";

interface IGmCalendar extends CalendarProps {
  formik?: any;
  label?: string;
  valueFormat?: string;
  noField?: boolean;
  id: string;
  useRawValue?: boolean;
}

export default function GmCalendar(props: IGmCalendar) {
  let meta, field;
  if (props.noField && props.formik) {
    meta = props.formik.getFieldMeta(props.id);
    field = props.formik.getFieldProps(props.id);
  }

  return props.noField && meta ? (
    <div
      className={classnames("GmCalendar", props.className, {
        error: meta.touched && meta.error,
      })}
    >
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      <Calendar
        dateFormat={props.valueFormat || "dd/mm/yy"}
        {...field}
        {...props}
        value={moment(field.value, props.valueFormat || "YYYY-MM-DD").toDate()}
        onChange={({ value }: { value: string }) =>
          props.formik.setFieldValue(
            props.id,
            props.useRawValue
              ? moment(value).seconds(0).toDate()
              : moment(value).format(props.valueFormat || "YYYY-MM-DD")
          )
        }
      />
      {meta.touched && meta.error && <sub>{meta.error}</sub>}
    </div>
  ) : (
    <Field name={props.id}>
      {({ field, form, meta }: FieldProps) => (
        <div
          className={classnames("GmCalendar", props.className, {
            error: meta.touched && meta.error,
          })}
        >
          {props.label && <label htmlFor={props.id}>{props.label}</label>}
          <Calendar
            dateFormat={props.valueFormat || "dd/mm/yy"}
            {...field}
            value={moment(field.value, props.valueFormat || "YYYY-MM-DD").toDate()}
            onChange={({ value }: { value: string }) =>
              form.setFieldValue(
                props.id,
                props.useRawValue
                  ? moment(value).seconds(0).toDate()
                  : moment(value).format(props.valueFormat || "YYYY-MM-DD")
              )
            }
            {...props}
          />
          {meta.touched && meta.error && <sub>{meta.error}</sub>}
        </div>
      )}
    </Field>
  );
}
