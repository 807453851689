import cn from "classnames";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import "./SidePanel.scss";
import { modalActions } from "~/store/modal";

interface StateProps {
  authenticatedUser: any;
}
interface DispatchProps {
  openModal: (modalId: string, modalProps?: any) => void;
  setModal: (modalId: string) => void;
}
interface OwnProps {
  // header?: string;
}
export type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (_state: any) => ({
  authenticatedUser: _state.users.authenticatedUser,
});

const mapDispatchToProps = {
  openModal: modalActions.openModal,
};

class SidePanel extends PureComponent<Props> {
  render() {
    const isTalentAvailable = this.props.authenticatedUser?.talent?.is_available;
    const renderTooltipForProfile = () => {
      return isTalentAvailable ? (
        "Profile"
      ) : (
        <p>
          Your current status is "Unavailable"
          <br />
          This means you will not receive any briefs.
          <br />
          To change this please update your status in your profile settings.
        </p>
      );
    };

    return (
      <aside className="SidePanel">
        <button
          className="btn-burger-menu"
          type="button"
          onClick={() => {
            // @TODO: handle click here
          }}
        >
          <i className="icon-list"></i>
        </button>
        {/* {!!this.props.header && <header className="md:hidden">{this.props.header}</header>} */}
        {<i className="SidePanel__Logo"></i>}
        <button
          className="btn-profile"
          type="button"
          onClick={() =>
            this.props.openModal("PROFILE_MODAL", {
              _onlyOne: true,
              side: "left",
            })
          }
        >
          <i className="icon-user"></i>
          {!isTalentAvailable && <span className="info-notification">!</span>}
          <span className={cn("tooltip", { ["big-notification"]: !isTalentAvailable })}>
            {renderTooltipForProfile()}
          </span>
        </button>
      </aside>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel as any);
