import { Dispatch, AnyAction } from "redux";

// ------------------------------------
// Constants
// ------------------------------------
export const MODAL_SHOW = "MODAL_SHOW";
export const MODAL_HIDE = "MODAL_HIDE";

// ------------------------------------
// Actions
// ------------------------------------
const openModal =
  (modalType: string, modalProps = {}) =>
  (dispatch: Dispatch) => {
    dispatch({ type: MODAL_SHOW, payload: { modalType, modalProps } });
  };

const closeModal = () => (dispatch: Dispatch) => {
  dispatch({ type: MODAL_HIDE });
};

export const modalActions = {
  closeModal,
  openModal,
};

// ------------------------------------
// State
// ------------------------------------
const initialState = {
  modals: [],
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case MODAL_SHOW:
      localStorage.removeItem("modalForm");
      return {
        ...state,
        modals: action.payload.modalProps._onlyOne
          ? [
              {
                modalType: action.payload.modalType,
                modalProps: action.payload.modalProps,
              },
            ]
          : [
              ...state.modals,
              {
                modalType: action.payload.modalType,
                modalProps: action.payload.modalProps,
              },
            ],
      };
    case MODAL_HIDE:
      return { modals: [...state.modals.slice(0, -1)] };
    default:
      return state;
  }
};
