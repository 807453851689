import { useState } from "react";
import { useSelector } from "react-redux";
import { UserPopupService } from "~/API/UserPopupService";

export interface IUserPopup {
  id: number;
  name: string;
  variant: "TALENT" | "CLIENT" | "COMMON";
  header: string;
  body: string;
  primary_button_text: string;
  primary_button_action: string;
  secondary_button_text?: string;
  secondary_button_action?: string;
  location: string;
}

const useUserPopup = () => {
  const [userPopup, setUserPopup] = useState<IUserPopup | null>(null);
  const token = useSelector((state: any) => state.users.authenticatedUser.token);

  const getUserPopup = async () => {
    try {
      const res = await UserPopupService.getUserPopup(token);
      res.status === 200 && !!res.data.length && setUserPopup(res.data[0]);
    } catch (err) {
      console.log(err);
    }
  };
  const markAsViewed = async (id: number) => {
    try {
      const res = await UserPopupService.markAsViewed(id, token);
      res.status === 204 && setUserPopup(null);
    } catch (err) {
      console.log(err);
    }
  };

  return {
    userPopup,
    getUserPopup,
    markAsViewed,
  };
};

export default useUserPopup;
