import { FormikValues } from "formik";
import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import GmCalendar from "~/components/common/GmCalendar/GmCalendar";
import Loader from "~/components/common/Loader/Loader";
import ApInfiniteCalendar from "~/components/modals/CalendarModal/Calendar";
import "./AvailabilityForm.scss";

interface Props {
  formik: FormikValues;
  onTouch: (touched: boolean) => void;
  isChatMessage?: boolean;
}
export default function AvailabilityForm(props: Props) {
  const { formik, isChatMessage } = props;
  const authenticatedUser = useSelector((state: any) => state.users.authenticatedUser);

  useEffect(() => {
    props.onTouch(JSON.stringify(formik.values) !== JSON.stringify(formik.initialValues));
  }, [formik.values]);

  return authenticatedUser ? (
    <form className="AvailabilityForm" onSubmit={formik.handleSubmit}>
      {!isChatMessage && (
        <>
          <div className="field">
            <InputSwitch checked={formik.values.is_available} id="is_available" onChange={formik.handleChange} />
            {formik.values.is_available ? (
              <label htmlFor="is_available">I’m available for work</label>
            ) : (
              <label htmlFor="is_available">I’m not available for work</label>
            )}
          </div>
          {!formik.values.is_available && (
            <>
              <div className="field">
                <InputSwitch checked={formik.values.is_remind} id="is_remind" onChange={formik.handleChange} />
                <label htmlFor="is_remind">Remind me to update availability</label>
              </div>
              {formik.values.is_remind && (
                <div className="field">
                  <GmCalendar
                    formik={formik}
                    id="remind_at"
                    label="Reminder date"
                    minDate={moment().add(1, "days").toDate()}
                    noField
                    readOnlyInput
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
      {(formik.values.is_available || isChatMessage) && (
        <div className="field">
          <ApInfiniteCalendar
            header="header"
            currStartDate={formik.values.start_from}
            setSelectedDate={({ startDate }: any) => formik.setFieldValue("start_from", startDate)}
          />
        </div>
      )}
    </form>
  ) : (
    <Loader />
  );
}
