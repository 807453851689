import React from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { modalActions } from "~/store/modal";
import Modal from "../AbstractModal/AbstractModal";

function InvoicesModal(props: RouteComponentProps) {
  const dispatch = useDispatch();

  return (
    <Modal
      backModal="PROFILE_MODAL"
      className="InvoicesModal no-animate"
      side="left"
      askOnClose
      title={<h1>My Invoices</h1>}
      headerRightButton={<button className="invisible" type="button"></button>}
    >
      <ul className="profile-list">
        <li
          className="screen"
          onClick={() =>
            dispatch(
              modalActions.openModal("BANKING_INFORMATION_MODAL", {
                _onlyOne: true,
                side: "left",
              })
            )
          }
        >
          <i className="icon-briefcase"></i>
          Banking information
        </li>
        <li
          className="screen"
          onClick={() => {
            dispatch(modalActions.closeModal());
            props.history.push(`/invoices`);
          }}
        >
          <i className="icon-files custom-icon"></i>
          Invoices
        </li>
      </ul>
    </Modal>
  );
}

export default withRouter(InvoicesModal);
