import React, { useState, useEffect } from "react";
import InfiniteCalendar, { Calendar, withRange, RangedSelection, DateType } from "react-infinite-calendar";
import moment, { Moment } from "moment";
import { themeConfig, displayConfig, localeConfig } from "./constants";
import IncludeWeekendsCheckbox from "./IncludeWeekendsCheckbox";
import "./Calendar.scss";
import "./CalendarModal.scss";

export type IselectedDate =
  | {
      end: DateType;
      start: DateType;
    }
  | Date
  | boolean;

const getHeightCalendar = (window: any, showIncludeWeekends: boolean) =>
  window.innerHeight - (window.innerWidth >= 900 ? 100 : 70) - 61 - 49 - (showIncludeWeekends ? 49 : 0); // header - footer - weekdays panel
const getSelectedDates = (currStartDate: Moment, currEndDate: Moment, isDateRange: boolean) => {
  if (currStartDate && isDateRange) {
    return {
      end: currEndDate ? currEndDate.toDate() : currStartDate.toDate(),
      start: currStartDate.toDate(),
    };
  }
  return currStartDate ? currStartDate.toDate() : false;
};

const ApInfiniteCalendar = (props: any) => {
  const { isDateRange, showIncludeWeekends, currStartDate, currEndDate, setSelectedDate, header: Header } = props;
  const [calendarHeight, setCalendarHeight] = useState(getHeightCalendar(window, props.showIncludeWeekends));
  const selected: IselectedDate = getSelectedDates(currStartDate, currEndDate, isDateRange) || false;
  const locale = {
    ...localeConfig,
    blank: isDateRange ? "Select Date Range" : "Select Date",
  };

  const updateWindowDimensions = () => {
    setCalendarHeight(getHeightCalendar(window, props.showIncludeWeekends));
  };
  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  const handleSelectDate = (data: RangedSelection | Date) => {
    const ddata = isDateRange ? (data as RangedSelection) : (data as Date);
    const date = moment(isDateRange ? ddata.start : ddata);

    if (!isDateRange || (currStartDate && currEndDate)) {
      return setSelectedDate({ endDate: null, startDate: date });
    }
    const startDate = date < currStartDate ? date : currStartDate;
    const endDate = date > currStartDate ? date : currStartDate;
    setSelectedDate({
      endDate: moment(!isDateRange ? (date as Date) : endDate),
      startDate: moment(!isDateRange ? (date as Date) : startDate),
    });
  };

  return (
    <>
      <header>
        <Header />
      </header>
      <InfiniteCalendar
        Component={isDateRange ? withRange(Calendar) : undefined}
        selected={selected}
        maxDate={moment().startOf("day").add(1, "year").toDate()}
        minDate={new Date()}
        min={moment().subtract(2, "months").toDate()}
        max={moment().add(12, "months").toDate()}
        onSelect={handleSelectDate}
        locale={locale}
        theme={themeConfig}
        width={320}
        height={calendarHeight}
        displayOptions={displayConfig}
      />
      {showIncludeWeekends && <IncludeWeekendsCheckbox {...props} />}
    </>
  );
};

export default ApInfiniteCalendar;
