import { useFormik } from "formik";
import React from "react";
import { batch, useDispatch } from "react-redux";
import { modalActions } from "~/store/modal";
import Modal from "../AbstractModal/AbstractModal";
import "./NoticePeriodModal.scss";
import GmInputText from "~/components/common/GmInputText/GmInputText";
import GmRadioButton from "~/components/common/GmRadioButton/GmRadioButton";
import { noticeDurationOptions, NoticePeriodSchema } from "~/schemas/TheWorkSchema";
import { conversationActions } from "~/store/chat";

export default function NoticePeriodModal({
  side,
  duration,
  type,
  onSubmit,
}: {
  side: string;
  duration?: string;
  type?: number;
  onSubmit?: any;
}) {
  const dispatch = useDispatch();

  const formik = useFormik({
    validationSchema: NoticePeriodSchema,
    initialValues: {
      duration: duration || "",
      type: type || noticeDurationOptions[0].id,
    },
    onSubmit: (data) => {
      const rawDataType = noticeDurationOptions.find((type: any) => type.id === data.type)?.name;
      const dataType = parseInt(data.duration) > 1 ? rawDataType : rawDataType?.slice(0, -1);
      const payload = data.duration + " " + dataType;

      batch(() => {
        !onSubmit && dispatch(conversationActions.sendMessage({ text_content: payload, payload: { payload } }));
        !!onSubmit && onSubmit(payload);
        dispatch(modalActions.closeModal());
      });
    },
  });

  return (
    <Modal
      className="NoticePeriodModal"
      headerLeftButton={<button className="invisible" type="button" />}
      headerRightButton={
        <button type="button" onClick={() => dispatch(modalActions.closeModal())}>
          <i className="icon-cancel" />
        </button>
      }
      headerContent={true}
      buttons={[{ className: "primary", text: "Confirm", onClick: formik.handleSubmit }]}
      title={<h1>Set Minimum Notice Period</h1>}
      side={side}
    >
      <header>
        <h1>Set Minimum Notice Period</h1>
      </header>
      <form className="NoticePeriodForm" onSubmit={formik.handleSubmit}>
        <div className="field">
          <b className="red-bordered">Please set minimum notice period in a number of weeks or months</b>
        </div>
        <div className="field">
          <GmRadioButton formik={formik} id="type" label="Duration in" options={noticeDurationOptions} noField />
        </div>
        <div className="field">
          <GmInputText
            formik={formik}
            id="duration"
            keyfilter={/[0-9]+/}
            label="Number"
            noField
            required
            maxLength={2}
          />
        </div>
      </form>
    </Modal>
  );
}
