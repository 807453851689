import classnames from "classnames";
import { Field } from "formik";
import { FileUpload, FileUploadProps } from "primereact/fileupload";
import React, { ReactNode, useRef } from "react";
import PopupIcon from "~/components/Toaster/PopupIcon";
import { extractFileName } from "~/utils";
import { setAlerts } from "~/store/toasts";
import "./GmFileUpload.scss";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { IAttachment } from "~/API/InvoiceService";

interface IUploadProps extends FileUploadProps {
  id: string;
  noField?: boolean;
  formik?: any;
  label?: string;
  className?: string;
  info?: string | ReactNode;
  onChange: (files: any) => void;
  onDelete?: (file?: File | IAttachment, index?: number) => void;
}

export default function GmFileUpload({
  noField,
  formik,
  id,
  label,
  className,
  info,
  onChange,
  onDelete,
  ...rest
}: IUploadProps) {
  const uploaderRef = useRef<FileUpload>(null);

  const dispatch = useDispatch();

  let meta, field;
  if (noField && formik) {
    meta = formik.getFieldMeta(id);
    field = formik.getFieldProps(id);
  }

  const renderedLabel = label && (
    <label htmlFor={id}>
      {label} {info && <PopupIcon content={info} />}
    </label>
  );

  const renderedError = meta?.error && <sub>{meta.error}</sub>;

  const props = {
    ...rest,
    id,
    customUpload: !!onChange,
    uploadHandler: ({ files }: { files: File[] }) => {
      uploaderRef?.current?.clear();
      !!onChange && onChange(files);
    },
  };

  function deleteFile() {
    dispatch(
      setAlerts([
        {
          closable: false,
          content: renderAskModal(
            () => {
              dispatch(setAlerts([]));
              !!onDelete && onDelete();
            },
            () => dispatch(setAlerts([]))
          ),
          severity: "warn",
          sticky: true,
        },
      ])
    );
  }

  function renderAskModal(onConfirm: () => void, onClose: () => void) {
    return (
      <div className="flex flex-column confirmModal">
        <div className="text-center">
          <i className="pi pi-exclamation-triangle"></i>
          <p>Are you sure you want to delete file?</p>
        </div>
        <footer>
          <Button className="p-button-secondary" label="Cancel" onClick={onClose} type="button" />
          <Button className="p-button-success" label="Delete" onClick={onConfirm} type="button" />
        </footer>
      </div>
    );
  }

  const renderFileName = (file: IAttachment | File, index = 0) => (
    <div className="preview" key={index}>
      {file instanceof File ? (
        <span>{file.name}</span>
      ) : (
        <a href={file?.url} target="_blank">
          {extractFileName(file?.url)}
        </a>
      )}
      {!!onDelete && (
        <Button
          icon="pi pi-trash"
          disabled={props.disabled}
          onClick={(event) => {
            event.preventDefault();
            onDelete(file, index);
          }}
        />
      )}
    </div>
  );

  const fileName = !!noField && !!meta && formik.values[id] ? extractFileName(formik.values[id]) : false;

  const description = rest.accept ? <div className="description">Supported file types: {rest.accept}</div> : null;

  return !!noField && !!meta ? (
    <div
      className={classnames("GmFileUpload", className, {
        error: meta.touched && meta.error,
      })}
    >
      {renderedLabel}
      {fileName && (
        <div className="preview">
          <a href={formik.values[id]} target="_blank">
            {fileName}
          </a>
          {!!onDelete && (
            <button type="button" className="p-button p-component" onClick={deleteFile}>
              <span className="p-button-label">
                <span className="pi pi-trash" />
              </span>
            </button>
          )}
        </div>
      )}
      <FileUpload ref={uploaderRef} {...field} {...props} />
      {description}
      {renderedError}
    </div>
  ) : (
    <Field name={id}>
      {({ field, meta: { error } }: any) => (
        <div className="GmFileUpload">
          {renderedLabel}
          {!!field.value &&
            (props.multiple ? (
              <>{field.value.map((file: IAttachment | File, i: number) => renderFileName(file, i))}</>
            ) : (
              renderFileName(field.value, 0)
            ))}
          <FileUpload ref={uploaderRef} {...field} {...props} />
          {description}
          {!!error && <sub>{error}</sub>}
        </div>
      )}
    </Field>
  );
}
