import asyncComponent from "../components/common/AsyncComponent";

const AsyncChat = asyncComponent(() => import("./Chat/ChatView"));
const AsyncCreateInvoice = asyncComponent(() => import("./Invoice/CreateInvoice"));
const AsyncEditInvoice = asyncComponent(() => import("./Invoice/EditInvoice"));
const AsyncInvoicesList = asyncComponent(() => import("./Invoice/ListInvoices"));

export default [
  {
    canAccess: false,
    component: AsyncChat,
    exact: true,
    path: "/",
  },
  {
    canAccess: false,
    component: AsyncCreateInvoice,
    exact: true,
    path: "/invoice/new/:id",
  },
  {
    canAccess: false,
    component: AsyncEditInvoice,
    exact: true,
    path: "/invoices/:id",
  },
  {
    canAccess: false,
    component: AsyncInvoicesList,
    exact: true,
    path: "/invoices",
  },
];
