import classNames from "classnames";
import React from "react";
import { checkCapital, checkLength, checkLowercase, checkNumber, checkSpecial } from "~/utils";
import "./PasswordRules.scss";

interface Props {
  password: string;
}

export default function PasswordRules({ password }: Props) {
  return (
    <div className="PasswordRules">
      <strong>To be safe, pls make sure your password has:</strong>
      <ul>
        <li className={classNames({ active: checkCapital(password) })}>A capital letter</li>
        <li className={classNames({ active: checkLowercase(password) })}>A lowercase letter</li>
        <li className={classNames({ active: checkNumber(password) })}>A number</li>
        <li className={classNames({ active: checkSpecial(password) })}>A special character (!, @, #, $, %, ^, &, *)</li>
        <li className={classNames({ active: checkLength(password) })}>8 characters minimum</li>
      </ul>
      <span>It’s like Fort Knox here, I know 🏰 🙂</span>
    </div>
  );
}
