import Axios from "axios";
import { API_URL } from "../config";
import { Moment } from "moment";
import { withAuthorizationHeader } from "../store";

interface IBriefValidate {
  startDate: Moment;
  endDate: Moment;
  isWeekendsIncluded: boolean;
  token: string;
}
export class BriefService {
  static async briefValidate({ startDate, endDate, isWeekendsIncluded, token }: IBriefValidate) {
    const response = await Axios.post(
      `${API_URL}/api/brief/validate`,
      {
        start_date: startDate.format("YYYY-MM-DD"),
        end_date: endDate.format("YYYY-MM-DD"),
        include_weekends: isWeekendsIncluded,
      },
      withAuthorizationHeader(token)
    );

    return response.data;
  }
}
