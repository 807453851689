import classnames from "classnames";
import { Button } from "primereact/button";
import React from "react";
import "./GmSelectModal.scss";

interface IGmSelectModal {
  formik?: any;
  className?: string;
  disabled?: boolean;
  id: string;
  label?: string;
  placeholder: string;
  required?: boolean;
  onClick: any;
}

export default function GmSelectModal(props: IGmSelectModal) {
  let meta, field;
  if (props.formik) {
    meta = props.formik.getFieldMeta(props.id);
    field = props.formik.getFieldProps(props.id);
  }

  return (
    <div
      className={classnames("GmSelectModal", props.className, {
        error: meta.touched && meta.error,
      })}
    >
      <label htmlFor={props.id}>{props.label}</label>
      <Button className="input-like" id={props.id} label={props.placeholder} onClick={props.onClick} type="button" />
      {meta.touched && meta.error && <sub>{meta.error}</sub>}
    </div>
  );
}
