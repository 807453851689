import moment from "moment";
import React from "react";
import { ILineItem } from "~/API/InvoiceService";
import { formatCurrency } from "~/utils";
import { BlockUI } from "primereact/blockui";
import Loader from "~/components/common/Loader/Loader";
import cn from "classnames";

export default function LineItemFormMobile({
  index = null,
  item,
  onClick,
  isValidatedInvoiceFetching,
}: {
  index?: number | null;
  item: ILineItem;
  onClick: any;
  isValidatedInvoiceFetching?: boolean;
}) {
  const renderDate = (d: string | undefined) => (d ? moment(d).format("MMM DD, YYYY") : "N/A");

  return (
    <div className="PhoneLineItem desktop:hidden" onClick={onClick}>
      <div className="detail">
        {index !== null && `${index + 1}. `}
        {item.description}
      </div>
      <div className="start_date">
        <label>Start Date</label>
        <span>{renderDate(item.start_date || (!!item.dates ? item.dates[0] : undefined))}</span>
      </div>
      <div className="end_date wd2">
        <label>End Date</label>
        <span>{renderDate(item.end_date || (!!item.dates ? item.dates[1] : undefined))}</span>
      </div>
      <div className="unit">
        <label>Unit Price</label>
        <span>{formatCurrency(item.unit_amount, 2)}</span>
      </div>
      <div className="qty">
        <label>Qty</label>
        <span>{item.quantity}</span>
      </div>
      <div className="total">
        <BlockUI blocked template={<Loader />} className={cn("z-0", !isValidatedInvoiceFetching && "hidden")}>
          <label>Amount, GBP</label>
          <span>{formatCurrency(item.line_amount, 2)}</span>
        </BlockUI>
      </div>
    </div>
  );
}
