import React from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "~/store/modal";
import "./SelectUmbrellaCompany.scss";

interface Props {
  max: number;
  title: string;
  disabled: boolean;
}

export default function SelectUmbrellaCompany(props: Props) {
  const dispatch = useDispatch();

  function openForm() {
    dispatch(
      modalActions.openModal("UMBRELLA_COMPANIES", {
        side: "right",
        max: props.max,
      })
    );
  }

  return (
    <button className="SelectRebookButton" disabled={!!props.disabled} onClick={openForm} type="button">
      <i className="icon-back"></i> {props.title}
    </button>
  );
}
