import moment from "moment";
import React from "react";
import { IInvoice } from "~/API/InvoiceService";
import { formatCurrency } from "~/utils";
import RowInvoiceStatus from "./rowCallbacks/RowInvoiceStatus";

export default function ({
  invoices,
  setActiveInvoice,
}: {
  invoices: IInvoice[];
  setActiveInvoice: (invoices: IInvoice) => void;
}) {
  return (
    <div className="InvoicesList__Table desktop:hidden">
      {invoices.map(({ status, total, date, due_date, reference }, index: number) => (
        <div className="invoice-item" key={index} onClick={() => setActiveInvoice(invoices[index])}>
          <header>
            <div className="status">
              {!!status && <RowInvoiceStatus rowData={{ status, due_date }} mode="circle" />}
            </div>
            <div className="total">{formatCurrency(total)}</div>
          </header>
          <article>
            <div className="invoice_number">{reference}</div>
            <footer>
              <div className="date">
                <label>Issue Date</label>
                <span>{date ? moment(date).format("MMM DD, YYYY") : "N/A"}</span>
              </div>
              <div className="date">
                <label>Due Date</label>
                <span>{!!due_date ? moment(due_date).format("MMM DD, YYYY") : "N/A"}</span>
              </div>
            </footer>
          </article>
        </div>
      ))}
    </div>
  );
}
