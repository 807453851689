import React, { useMemo } from "react";
import "./NotificationModal.scss";
import { modalActions } from "~/store/modal";
import { batch, useDispatch } from "react-redux";
import { usersActions } from "~/store/user";
import { NotificationTypes } from "../PreferencesModal/PreferencesModal";

export const TYPE_PREFERENCES = "TYPE_PREFERENCES";
export const TYPE_PERMANENT_BRIEFS = "TYPE_PERMANENT_BRIEFS";

export default function NotificationModal({ type }: { type: "TYPE_PREFERENCES" | "TYPE_PERMANENT_BRIEFS" }) {
  const dispatch = useDispatch();

  const params = useMemo(() => {
    switch (type) {
      case TYPE_PREFERENCES:
        return {
          content: (
            <>
              <h1>Notification Preferences</h1>
              <p>
                You can now update your notification preferences on your profile!
                <br />
                Click on Settings below to be redirected
              </p>
            </>
          ),
          actionButtonTitle: "Settings",
          finishFlowAction: () =>
            batch(() => {
              dispatch(usersActions.updateTalentProfiles({ preference: true }, true));
              dispatch(usersActions.preferencesNotificationSeen());
            }),
        };
      case TYPE_PERMANENT_BRIEFS:
        return {
          content: (
            <>
              <h1>New Feature</h1>
              <p>
                We are now offering <strong>permanent</strong> briefs on Genie.
                <br />
                If you no longer wish to receive <strong>permanent</strong> briefs, or any other brief types, you can
                opt-out at any time from the <strong>Brief</strong> menu on your profile
              </p>
            </>
          ),
          actionButtonTitle: "Go to Brief Preferences",
          finishFlowAction: () =>
            batch(() => {
              dispatch(usersActions.updateTalentProfiles({ ph_briefs_notified: true }, true));
              dispatch(usersActions.permBriefsNotificationSeen());
            }),
        };
    }
  }, []);

  const finishFlow = (shouldRedirect: boolean) => {
    params.finishFlowAction();
    const screenTtype =
      type === TYPE_PREFERENCES ? NotificationTypes.NOTIFICATION_PREFERENCES : NotificationTypes.BRIEF_PREFERENCES;

    if (shouldRedirect) {
      dispatch(modalActions.openModal("PREFERENCES_MODAL", { _onlyOne: true, side: "left", type: screenTtype }));
    }
  };

  return (
    <div className="notification-modal">
      <div className="container">
        <div className="content">{params.content}</div>
        <div className="controls">
          <a href="#" onClick={() => finishFlow(false)}>
            Dismiss
          </a>
          <a href="#" onClick={() => finishFlow(true)}>
            {params.actionButtonTitle}
          </a>
        </div>
      </div>
    </div>
  );
}
