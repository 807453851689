import { IInvoice } from "~/API/InvoiceService";
import { formatCurrency } from "~/utils";

export const EMAIL_REGEXP =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const URL_REGEXP =
  /^((https?):\/\/)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\/=]*)?$/;
export const __DEV__ = process.env.NODE_ENV === "development";
export const __TEST__ = process.env.NODE_ENV === "test";
export const __PROD__ = process.env.NODE_ENV === "production";
export const API_URL = __DEV__ ? `https://${process.env.APIURL_DEV}` : `//${process.env.APIURL_PROD}`;
export const LOGIN_URL = `https://${process.env.LOGIN_URL}`;
export const wsOptions = {
  authTokenName: "user",
  hostname: __DEV__ ? process.env.APIURL_DEV : process.env.APIURL_PROD,
  port: +(process.env.WS_PORT || 8000),
  secure: process.env.WS_SECURE === "true",
};

export const networkErrorMessage = "Oh no, dodgy network going on, pls try again";

export const getIR35InsideDisclaimer = (invoice?: IInvoice | null) => {
  return !!invoice
    ? `The total amount your services have been charged out at for this invoice period is <span>${formatCurrency(
        invoice.amount_due
      )}</span>. 
  As this is an INSIDE IR35 booking, Employer’s NI of <span>${formatCurrency(
    invoice.total_insurance
  )}</span> has been deducted, resulting in an invoice subtotal of <span>${formatCurrency(invoice.subtotal)}</span>. 
  Please note: HMRC may instruct further minor adjustments to this subtotal when payroll is run.`
    : "As this booking was determined to be Inside IR35, Employers National Insurance will be automatically deducted from the headline rate charged to the client for your services.";
};

export const getIR35InsidePopup = (invoice: IInvoice) => {
  return `The total amount your services have been charged out at for this invoice period is <span>${formatCurrency(
    invoice.amount_due
  )}</span>.
  Because this is an INSIDE IR35 booking, Employer’s NI of <span>${formatCurrency(
    invoice.total_insurance
  )}</span> has been deducted, resulting in an invoice subtotal of <span>${formatCurrency(
    invoice.subtotal
  )}</span>.<br/>
  Please Note: Where you have multiple draft Inside IR35 invoices, adjustments may be made to correctly
  calculate the Employers NI Tax in a given payroll period at the point of submission.
  HMRC may instruct further minor adjustments to this subtotal when payroll is run.`;
};

export const API_PLACEHOLDER: string = "https://apidomain";
