import { useFormik } from "formik";
import { InputSwitch } from "primereact/inputswitch";
import React, { useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import GmCalendar from "~/components/common/GmCalendar/GmCalendar";
import GmDropdown from "~/components/common/GmDropdown/GmDropdown";
import GmInputText from "~/components/common/GmInputText/GmInputText";
import GmSelectButton from "~/components/common/GmSelectButton/GmSelectButton";
import Loader from "~/components/common/Loader/Loader";
import { PersonalYouSchema } from "~/schemas/PersonalYouSchema";
import { usersActions } from "~/store/user";
import "./PersonalYouForm.scss";
import { optionsActions } from "~/store/options";
import GmSelectModal from "~/components/common/GmSelectModal/GmSelectModal";
import { modalActions } from "~/store/modal";

interface Props {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  onTouch: (value: boolean) => void;
  onValidationFail: () => void;
  submitTrigger: boolean;
}

export default function PersonalYouForm(props: Props) {
  const dispatch = useDispatch();
  const authenticatedUser = useSelector((state: any) => state.users.authenticatedUser);
  const { personalProfile } = useSelector((state: any) => state.users);
  const { countries: countryOptions, languages } = useSelector((state: any) => state.options);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      residence_address_1: personalProfile.residence_address_1 || "",
      residence_address_2: personalProfile.residence_address_2 || "",
      residence_city: personalProfile.residence_city || "",
      country_by_passport: personalProfile.country_by_passport || "",
      country_of_residence: personalProfile.country_of_residence || "",
      first_name: personalProfile.first_name || authenticatedUser.first_name,
      languages: personalProfile.languages?.map(({ id }) => id) || [],
      last_name: personalProfile.last_name || authenticatedUser.last_name,
      residence_postcode: personalProfile.residence_postcode || "",
      telephone: personalProfile.telephone || authenticatedUser.telephone,
      uk_work_permit: personalProfile.uk_work_permit || false,
      uk_work_verification: personalProfile.uk_work_verification || "PASSPORT",
      uk_visa_code: personalProfile.uk_visa_code || "",
      uk_visa_expire_at: personalProfile.uk_visa_expire_at || null,
    },
    validationSchema: PersonalYouSchema,
    onSubmit: () => {},
  });

  useEffect(() => {
    props.onTouch(JSON.stringify(formik.values) !== JSON.stringify(formik.initialValues));
  }, [formik.values]);

  useEffect(() => {
    if (props.submitTrigger) {
      formik.validateForm().then((res) => {
        formik.handleSubmit();
        if (!Object.keys(res).length) {
          props.onSubmit(formik.values);
        } else {
          props.onValidationFail();
        }
      });
    }
  }, [props.submitTrigger]);

  useEffect(() => {
    batch(() => {
      dispatch(usersActions.getPersonalProfile());
      dispatch(optionsActions.getCountries());
      dispatch(optionsActions.getLanguages());
    });
  }, []);

  const handleLanguagesSubmit = (value: number[]) => {
    formik.setFieldValue("languages", value);
    dispatch(modalActions.closeModal());
  };

  return personalProfile ? (
    <form className="PersonalYouForm" onSubmit={formik.handleSubmit}>
      <div className="field">
        <GmInputText formik={formik} id="first_name" label="First Name" noField required />
      </div>
      <div className="field">
        <GmInputText formik={formik} id="last_name" label="Last Name" noField required />
      </div>
      <div className="field">
        <GmInputText
          disabled
          formik={formik}
          id="email"
          label="Email"
          noField
          required
          value={authenticatedUser.email}
        />
      </div>
      <div className="field">
        <GmInputText formik={formik} id="telephone" label="Mobile Number" noField required />
      </div>
      <hr />
      <div className="field">
        <GmDropdown filter formik={formik} id="country_of_residence" label="Country" noField options={countryOptions} />
      </div>
      <div className="field">
        <GmInputText formik={formik} id="residence_city" label="City" noField />
      </div>
      <div className="field">
        <GmInputText formik={formik} id="residence_address_1" label="Address 1" noField />
      </div>
      <div className="field GmInputText">
        <GmInputText formik={formik} id="residence_address_2" label="Address 2" noField />
      </div>
      <div className="field GmInputText">
        <GmInputText formik={formik} id="residence_postcode" label="Postcode / zipcode" noField />
      </div>
      <div className="field">
        <InputSwitch checked={formik.values.uk_work_permit} id="uk_work_permit" onChange={formik.handleChange} />
        <label htmlFor="uk_work_permit">Do you have the right to work in the UK?</label>
      </div>
      {formik.values.uk_work_permit && (
        <>
          <div className="field">
            <GmSelectButton
              formik={formik}
              id="uk_work_verification"
              noField
              options={[
                { label: "Passport", value: "PASSPORT" },
                { label: "Visa", value: "VISA" },
              ]}
            />
          </div>
          <div className="field">
            <GmDropdown
              filter
              formik={formik}
              id="country_by_passport"
              label="What Passport do you carry?"
              noField
              options={countryOptions}
            />
          </div>
          {formik.values.uk_work_verification === "VISA" && (
            <>
              <div className="field">
                <GmInputText formik={formik} id="uk_visa_code" label="What Visa?" noField />
              </div>
              <div className="field">
                <GmCalendar formik={formik} id="uk_visa_expire_at" label="Visa expiry date" noField readOnlyInput />
              </div>
            </>
          )}
        </>
      )}
      <div className="field">
        <GmSelectModal
          id="languages"
          formik={formik}
          label="Languages (Fluent)"
          placeholder={
            formik.values.languages?.length ? `${formik.values.languages.length} selected` : "Select languages"
          }
          onClick={() => {
            dispatch(
              modalActions.openModal("MULTI_SELECT_MODAL", {
                side: "left",
                dataType: "language",
                itemsList: languages,
                selectedItems: formik.values.languages,
                onSubmit: handleLanguagesSubmit,
              })
            );
          }}
        />
      </div>
    </form>
  ) : (
    <Loader />
  );
}
