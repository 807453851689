import { useFormik } from "formik";
import { InputSwitch } from "primereact/inputswitch";
import React, { useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import GmCalendar from "~/components/common/GmCalendar/GmCalendar";
import GmDropdown from "~/components/common/GmDropdown/GmDropdown";
import GmInputText from "~/components/common/GmInputText/GmInputText";
import Loader from "~/components/common/Loader/Loader";
import { getGettingPaidSchema } from "~/schemas/GettingPaidSchema";
import { usersActions } from "~/store/user";
import "./GettingPaidForm.scss";
import { setAlerts } from "~/store/toasts";
import { Button } from "primereact/button";
import GmMultiCheckbox, { IOption } from "~/components/common/GmMultiCheckbox/GmMultiCheckbox";
import { optionsActions } from "~/store/options";
import { modalActions } from "~/store/modal";
import UmbrellaSelect from "~/components/common/UmbrellaSelect/UmbrellaSelect";
import PopupIcon from "~/components/Toaster/PopupIcon";

interface Props {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  onTouch: (value: boolean) => void;
  onValidationFail: () => void;
  submitTrigger: boolean;
}

export default function GettingPaidForm(props: Props) {
  const dispatch = useDispatch();
  const { paymentProfile, workProfile } = useSelector((state: any) => state.users);
  const { options, countries: countryOptions } = useSelector((state: any) => state.options);
  const modals = useSelector((state: any) => state.modal.modals);

  const equipmentOptions = [
    { value: "TYPE_PERSONAL", label: "I use my own equipment" },
    { value: "TYPE_COMPANY", label: "I need equipment provided to me" },
    { value: "TYPE_FLEXIBLE", label: "Mix of both required" },
  ];

  const substitutionOptions = [
    {
      value: "TYPE_YES",
      label: "Yes, I have someone I trust",
    },
    { value: "TYPE_LATER", label: "No, not right now" },
    { value: "TYPE_NO", label: "No, I offer a personal service" },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      company_name: paymentProfile.company_name || null,
      company_number: paymentProfile.company_number || null,
      company_registration_year: paymentProfile.company_registration_year || null,
      use_personal_address: false,
      country: workProfile.country?.code || null,
      insurance_provider: paymentProfile.insurance_provider || null,
      insurance_expire_at: paymentProfile.insurance_expire_at || null,
      equipment_type: paymentProfile.equipment_type || null,
      substitution_type: paymentProfile.substitution_type || null,
      umbrella_companies: paymentProfile.umbrella_companies || [],
      company_types: workProfile.company_types?.map(({ id }: { id: number }) => id) || [],
      ir35_types: (paymentProfile.ir35_types && paymentProfile.ir35_types.map(({ id }: { id: number }) => id)) || [],
      rate: workProfile.rate || "",
      annual_rate: workProfile.annual_rate || "",
      registered_tax_country: paymentProfile?.registered_tax_country || "",
      use_tax_country: paymentProfile.registered_tax_country === workProfile.country?.code || false,
      insurance_number: paymentProfile.insurance_number || null,
      company_country: paymentProfile.company_country || "",
      company_city: paymentProfile.company_city || "",
      company_address_1: paymentProfile.company_address_1 || "",
      company_address_2: paymentProfile.company_address_2 || "",
      company_postcode: paymentProfile.company_postcode || "",
      company_tax_number: paymentProfile.company_tax_number || "",
    },
    validationSchema: () =>
      getGettingPaidSchema(options.company_types.find((company) => company.code === "COMPANY_LTD").id),
    onSubmit: () => {},
  });

  useEffect(() => {
    props.onTouch(JSON.stringify(formik.values) !== JSON.stringify(formik.initialValues));
  }, [formik.values]);

  useEffect(() => {
    if (props.submitTrigger) {
      formik.validateForm().then((res) => {
        formik.handleSubmit();
        if (!Object.keys(res).length) {
          props.onSubmit(formik.values);
        } else {
          props.onValidationFail();
        }
      });
    }
  }, [props.submitTrigger]);

  useEffect(() => {
    batch(() => {
      dispatch(usersActions.getPaymentProfile());
      dispatch(usersActions.getWorkProfile());
      dispatch(optionsActions.getOptions());
      dispatch(optionsActions.getCountries());
    });
  }, []);

  useEffect(() => {
    if (paymentProfile && options) {
      const ifScrollableElement = modals[0]?.modalProps?.scrollTo;
      ifScrollableElement && document.getElementById(ifScrollableElement)?.scrollIntoView({ behavior: "smooth" });
    }
  }, [paymentProfile, options]);

  function renderAskModal(msg: string, onClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) {
    return (
      <div className="flex flex-column" style={{ flex: "1" }}>
        <div className="text-center">
          <p>{msg}</p>
        </div>
        <footer>
          <Button className="button-success" label="Ok, got it" onClick={onClose} type="button" />
        </footer>
      </div>
    );
  }

  function onClose() {
    dispatch(setAlerts([]));
  }

  function handleChangeSubstitution(e) {
    if (e.value !== null) {
      formik.setFieldValue(e.target.id, e.value);
      if (e.value === "TYPE_LATER") {
        dispatch(
          setAlerts([
            {
              closable: false,
              severity: "warn",
              sticky: true,
              content: renderAskModal("No problem, If you need help finding someone I'd be happy to do so", onClose),
            },
          ])
        );
      }
    }
  }

  function openForm() {
    dispatch(
      modalActions.openModal("UMBRELLA_COMPANIES", {
        side: "left",
        max: 8,
        isProfile: true,
        formik,
      })
    );
  }

  const isLtdCompany = formik.values.company_types.includes(
    options && options.company_types.find((company: IOption) => company.code === "COMPANY_LTD").id
  );

  const isUmbrellaPickerShown =
    formik.values.company_types.includes(
      options && options.company_types.find((company: IOption) => company.code === "COMPANY_UMBRELLA").id
    ) ||
    formik.values.ir35_types.includes(
      options && options.ir35_types.find((ir35_type: IOption) => ir35_type.code === "IR35_UMBRELLA").id
    );

  const isTaxCountryUK = formik.values.registered_tax_country === "GB";

  return paymentProfile && options ? (
    <form className="GettingPaidForm" onSubmit={formik.handleSubmit}>
      <div className="field">
        <b className="red-bordered">
          Genie works with a range of clients, some who insist on booking via umbrella set ups only. Genie MPS is
          Genie's accredited umbrella payment service, pre approved by all of Genie's clients. For hassle free payments
          we recommend registering. Look out for the email with all the details.
        </b>
      </div>

      {/* rate */}
      <div className="field">
        <GmInputText
          formik={formik}
          id="rate"
          keyfilter={/[0-9]+/}
          label="Preferred Day Rate"
          noField
          placeholder="(You'll get to reset this on a brief by brief basis)"
          price
          required
          info="All rates should be listed in UK Pounds (£). This is the headline figure you would typically charge if all briefs were equal. You will have the opportunity to amend or confirm this rate on a brief by brief basis before it is shared with a client."
        />
      </div>

      {/* annual_rate */}
      <div className="field">
        <GmInputText
          formik={formik}
          id="annual_rate"
          keyfilter={/[0-9]+/}
          label="Preferred Annual Salary (For Permanent & Fixed Term Briefs)"
          noField
          placeholder="(Pls tell me roughly what you'd like to earn annually)"
          price
          info={
            <>
              <p>
                All rates should be listed in UK Pounds (£). When a company creates a fixed term contract brief, they
                are paying you directly via their company payroll, exactly as they do with their full time employees.
                This means you are an employee of that company for the fixed period of time.
              </p>
              <p>
                A fixed term contract requires you to provide your annual salary expectation. As you are paid monthly,
                your rate will be the monthly pro-rata breakdown of a yearly salary expectation. For example. If your
                yearly salary expectation is £120,000 then your monthly GROSS pay would £10,000.
              </p>
            </>
          }
        />
      </div>

      <hr />

      {/* registered_tax_country */}
      <div className="field">
        <GmDropdown
          filter
          formik={formik}
          id="registered_tax_country"
          label="Registered country for paying tax"
          noField
          options={countryOptions}
          info="The country where you pay personal tax and/or company tax."
        />
      </div>

      {/* insurance_number */}
      {isTaxCountryUK && (
        <div className="field" id="insurance_number_field">
          <GmInputText
            formik={formik}
            id="insurance_number"
            label="National Insurance number"
            maxLength={9}
            noField
            placeholder="AA123456A"
          />
        </div>
      )}

      {/* Country of residence */}
      <div className="field">
        <label>
          Country of residence{" "}
          <PopupIcon content="The country where you currently reside and provide your services from. This may or may not be the same as the country you pay you personal and/or company tax." />
        </label>
      </div>
      <div className="field">
        <InputSwitch checked={formik.values.use_tax_country} id="use_tax_country" onChange={formik.handleChange} />
        <label htmlFor="use_tax_country">Same as registered tax country</label>
      </div>
      {!formik.values.use_tax_country && (
        <div className="field">
          <GmDropdown
            filter
            formik={formik}
            id="country"
            placeholder="Select country"
            noField
            options={countryOptions}
          />
        </div>
      )}

      {isTaxCountryUK && (
        <div className="field">
          <GmMultiCheckbox
            id="ir35_types"
            label="Interested in these brief types (IR35, Umbrella etc)"
            options={
              options.ir35_types.filter(
                ({ code }: { code: string }) => code !== "IR35_NONE" && code !== "IR35_EXEMPT"
              ) || []
            }
            formik={formik}
            noField
            info="Genie handles all briefs from clients including INSIDE and OUTSIDE IR35, IR35 Exempt and briefs that are designated by clients to be paid via accredited Umbrella companies. You will always automatically receive 'exempt from IR35' briefs as well as remote working briefs from companies outside of the UK. "
          />
        </div>
      )}

      <div className="field" id="company_types_field">
        <GmMultiCheckbox
          id="company_types"
          label="Intended work set up (company or individual)"
          options={options.company_types || []}
          formik={formik}
          noField
          info="For UK tax payers. You can receive briefs via Genie if you are registered as a business entity, registered with an accredited umbrella company or are directly employed by clients where a 'Fixed Term' contract is the designated brief type. If you are self employed, pay your taxes in the UK and are unwilling to register with an umbrella company you cannot accept bookings via Genie unless labelled 'fixed term'  (where you will be employed directly by the hiring client as a PAYE salaried employee with the relevant taxes deducted by them at source)."
        />
      </div>

      <hr />

      {isTaxCountryUK && isUmbrellaPickerShown && (
        <div className="field">
          <UmbrellaSelect
            id="umbrella_companies"
            label="Registered umbrella company(s) (if applicable)"
            onSelectCLick={openForm}
            formik={formik}
            max={8}
            info={
              <p>
                Put in the preferred umbrella company you are registered with or select from the dropdown menu. Not yet
                registered with an Umbrella? Why not look at Genie MPS, our very own accredited umbrella set up.{" "}
                <a href="https://meetgenie.co/genie-mps" target="_blank" rel="nofollow">
                  https://meetgenie.co/genie-mps
                </a>
              </p>
            }
          />
        </div>
      )}
      {isLtdCompany && (
        <>
          <div className="field">
            <GmInputText formik={formik} id="company_name" label="Company Name" noField required />
          </div>
          <div className="field">
            <GmInputText formik={formik} id="company_number" label="Company Registration Number" noField required />
          </div>
          <div className="field">
            <GmInputText
              formik={formik}
              id="company_registration_year"
              keyfilter={/[0-9]+/}
              label="Company Registration Year"
              maxLength={4}
              noField
              placeholder="Year"
              required
            />
          </div>
          <div className="field">
            <GmInputText
              formik={formik}
              noField
              id="company_tax_number"
              label="UK VAT Number or International Equivalent (If Applicable)"
              required
            />
          </div>

          <div className="field">
            <label htmlFor="company_registered_address">Company Registered Address</label>
          </div>

          <div className="field">
            <GmDropdown
              formik={formik}
              id="company_country"
              label="Country"
              noField
              options={countryOptions}
              required
            />
          </div>
          <div className="field">
            <GmInputText formik={formik} id="company_city" label="City" noField required />
          </div>
          <div className="field">
            <GmInputText formik={formik} id="company_address_1" label="Address line 1" noField required />
          </div>
          <div className="field GmInputText">
            <GmInputText formik={formik} id="company_address_2" label="Address line 2" noField required />
          </div>
          <div className="field GmInputText">
            <GmInputText formik={formik} id="company_postcode" label="Postcode/zipcode" noField required />
          </div>
        </>
      )}

      {/* insurance_provider */}
      <div className="field">
        <GmInputText formik={formik} id="insurance_provider" label="Business Insurance Provider" noField required />
      </div>
      {/* insurance_expire_at */}
      <div className="field">
        <GmCalendar formik={formik} id="insurance_expire_at" label="Insurance Expiration Date" noField readOnlyInput />
      </div>
      <hr />

      {/* equipment_type */}
      <div className="field">
        <GmDropdown
          formik={formik}
          id="equipment_type"
          label="Equipment needed to complete work"
          noField
          options={equipmentOptions}
          info="What are your typical equipment requirements to complete your work."
        />
      </div>

      {/* substitution_type */}
      {isTaxCountryUK && isLtdCompany && (
        <div className="field">
          <GmDropdown
            formik={formik}
            id="substitution_type"
            label="Substitutes (if required)"
            noField
            options={substitutionOptions}
            onChange={handleChangeSubstitution}
            info="A suitable substitute is someone you trust to perform the work your company has been engaged to do, other than yourself. You remain responsible for the fulfillment and quality of that work and for invoicing for it."
          />
        </div>
      )}
    </form>
  ) : (
    <Loader />
  );
}
