import Axios from "axios";
import { BankProfileTypes } from "~/hooks/useInvoice";
import { IR35_TYPES } from "~/hooks/useTalent";
import { API_URL } from "../config";
import { withAuthorizationHeader } from "../store";

export enum ILineItemUnit {
  TYPE_DAY_RATE = "TYPE_DAY_RATE",
  TYPE_FIXED_AMOUNT = "TYPE_FIXED_AMOUNT",
  TYPE_WEEKEND_RATE = "TYPE_WEEKEND_RATE",
  TYPE_OVERTIME = "TYPE_OVERTIME",
  EXPENSE = "EXPENSE",
}

export interface ILineItem {
  isNew?: boolean;
  id?: number;
  description: string;
  unit_type: ILineItemUnit;
  unit_amount: number;
  line_amount?: number;
  quantity: number;
  dates?: [string | undefined, string | undefined] | Date[];
  start_date?: string;
  end_date?: string;
  attachments?: File[];
}

export type IAttachment = {
  id: number;
  type: InvoiceAttachTypes;
  url: string;
};

export enum InvoiceAttachTypes {
  "TYPE_INVOICE_TALENT_PDF" = "TYPE_INVOICE_TALENT_PDF",
  "TYPE_INVOICE_ATTACH" = "TYPE_INVOICE_ATTACH",
}

export type InvoiceStatus = "DRAFT" | "SUBMITTED" | "DISPUTED" | "AUTHORISED" | "PAID" | "CANCELLED";
export interface IInvoice {
  id?: number;
  amount_credited: number;
  amount_due: number;
  amount_paid: number;
  booking?: {
    id: number;
    ir35_code: keyof typeof IR35_TYPES;
  };

  status?: InvoiceStatus;
  available_statuses?: InvoiceStatus[];
  notes: string;
  line_items: ILineItem[];

  currency_code: "GBP";
  currency_rate: 1;
  date: string;
  due_date: string;
  reference?: string;

  subtotal?: string;
  total_insurance?: string;
  total_tax?: string;
  total?: string;
  tax_type: TaxType;

  client?: {
    id: number;
    legal_name: string;
    name: string;
  };

  attachments?: IAttachment[];

  disputes?: {
    created_at: string;
    description: string;
  }[];
}

export enum TaxType {
  Exclusive = "TAX_EXCLUSIVE",
  None = "TAX_NONE",
}

export interface IBankProfile {
  type: BankProfileTypes;
  bank_account_name: string;
  bank_account_number: string;
  sort_code: string;
  global_account_name: string;
  iban: string;
  swift_bic: string;
}

export class InvoiceService {
  static getInvoices = async (token: string, params = {}) =>
    await Axios.get(
      `${API_URL}/payment/user/current/invoices/paginated?${new URLSearchParams(params).toString()}`,
      withAuthorizationHeader(token)
    );

  static getInvoice = async (token: string, id: number) =>
    await Axios.get(`${API_URL}/payment/invoice/${id}`, withAuthorizationHeader(token));

  static postInvoice = async (booking_id: number, invoice: any, token: string) =>
    await Axios.post(`${API_URL}/payment/booking/${booking_id}/invoice`, invoice, withAuthorizationHeader(token));

  static putInvoice = async (invoice: any, token: string) =>
    await Axios.put(`${API_URL}/payment/invoice/${invoice.id}`, invoice, withAuthorizationHeader(token));

  static uploadAttachment = async (id: number, attachment: File, token: string) => {
    const data = new FormData();
    data.append("attachment", attachment);
    return await Axios.post(`${API_URL}/payment/invoice/${id}/attachment`, data, withAuthorizationHeader(token));
  };

  static deleteAttachment = async (invoiceId: number, fileId: number, token: string) =>
    await Axios.delete(`${API_URL}/payment/invoice/${invoiceId}/attachment/${fileId}`, withAuthorizationHeader(token));

  static getBankProfile = async (token: string) =>
    await Axios.get(`${API_URL}/api/user/current/talent_bank_profile`, withAuthorizationHeader(token));

  static updateBankProfile = async (token: string, bankProfile: IBankProfile) =>
    await Axios.put(`${API_URL}/api/user/current/talent_bank_profile`, bankProfile, withAuthorizationHeader(token));

  static getValidatedInvoice = async (token: string, bookingId: number, invoice: IInvoice) =>
    await Axios.post(
      `${API_URL}/payment/booking/${bookingId}/invoice/validate`,
      invoice,
      withAuthorizationHeader(token)
    );
}
