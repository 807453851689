import React from "react";
import { useSelector } from "react-redux";
import NoticePeriodModal from "~/components/modals/NoticePeriodModal";
import AvailabilityModal from "./AvailabilityModal";
import BriefCardModal from "./BriefCardModal/BriefCardModal";
import CalendarModal from "./CalendarModal";
import ChangePasswordModal from "./ChangePasswordModal/ChangePasswordModal";
import EditTalentResponse from "./EditTalentResponse/EditTalentResponse";
import GettingPaidModal from "./GettingPaidModal/GettingPaidModal";
import ImageCropModal from "./ImageCropModal/ImageCropModal";
import InfluentialEmployerModal from "./InfluentialEmployerModal/InfluentialEmployerModal";
import PersonalProfileModal from "./PersonalProfileModal/PersonalProfileModal";
import PlatformsModal from "./PlatformsModal/PlatformsModal";
import ProfileModal from "./ProfileModal/ProfileModal";
import ReferTalentModal from "./ReferTalentModal/ReferTalentModal";
import SectorsModal from "./SectorsModal/SectorsModal";
import SkillsModal from "./SkillsModal/SkillsModal";
import TheWorkModal from "./TheWorkModal/TheWorkModal";
import UmbrellasModal from "./UmbrellasModal/UmbrellasModal";
import UserProfileModal from "./UserProfileModal/UserProfileModal";
import PreferencesModal from "./PreferencesModal/PreferencesModal";
import SelectBookingModal from "./SelectBookingModal/SelectBookingModal";
import PlantedTreesModal from "./PlantedTreesModal/PlantedTreesModal";
import InvoicesModal from "./InvoicesModal/InvoicesModal";
import BankingInformationModal from "./BankingInformationModal/BankingInformationModal";
import MultiSelectModal from "./MultiSelectModal/MultiSelectModal";

const MODAL_COMPONENTS: any = {
  CHANGE_PASSWORD_MODAL: ChangePasswordModal,
  DATE_MODAL: CalendarModal,
  GETTING_PAID_MODAL: GettingPaidModal,
  INFLUENTIAL_EMPLOYER_MODAL: InfluentialEmployerModal,
  PLATFORMS_MODAL: PlatformsModal,
  PROFILE_MODAL: ProfileModal,
  USER_PROFILE_MODAL: UserProfileModal,
  PERSONAL_PROFILE_MODAL: PersonalProfileModal,
  SKILLS_MODAL: SkillsModal,
  SECTORS_MODAL: SectorsModal,
  THE_WORK_MODAL: TheWorkModal,
  IMAGE_CROP_MODAL: ImageCropModal,
  UMBRELLA_COMPANIES: UmbrellasModal,
  AVAILABILITY_MODAL: AvailabilityModal,
  REFER_TALENT_MODAL: ReferTalentModal,
  EDIT_TALENT_RESPONSE: EditTalentResponse,
  PREFERENCES_MODAL: PreferencesModal,
  NOTICE_PERIOD_MODAL: NoticePeriodModal,
  BRIEF_PERMANENT_MODAL: BriefCardModal,
  SELECT_BOOKING_MODAL: SelectBookingModal,
  PLANTED_TREES_MODAL: PlantedTreesModal,
  INVOICES_MODAL: InvoicesModal,
  BANKING_INFORMATION_MODAL: BankingInformationModal,
  MULTI_SELECT_MODAL: MultiSelectModal,
};

const ModalRoot = () => {
  const modals = useSelector((state: any) => state.modal.modals);

  if (!modals.length) return null;

  return modals.map((modal: any, i: number) => {
    const SpecificModal = MODAL_COMPONENTS[modal.modalType];
    return SpecificModal ? <SpecificModal key={i} side={modal.modalProps.side} {...modal.modalProps} /> : null;
  });
};

export default ModalRoot;
