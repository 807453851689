import moment from "moment";

export const checkBeforeDueDate = (date: string) => moment(date).isBefore();
export const checkIsCurrentDay = (date: string) => moment(date).isSame(moment(), "day");

export const getInvoiceStatusData = (status: string, action = false) => {
  let colorClass = "";
  let labelStatus = status;

  switch (status) {
    case "DRAFT":
      colorClass = "gray";
      labelStatus = action ? "Return to draft" : status;
      break;
    case "SUBMITTED":
      colorClass = "yellow";
      labelStatus = action ? "Submit for review" : status;
      break;
    case "PAID":
      colorClass = "green";
      break;
    case "DISPUTED":
      colorClass = "yellow";
      break;
    case "CANCELLED":
      colorClass = "red";
      labelStatus = action ? "Cancel Invoice" : status;
      break;
    case "OVERDUE":
      colorClass = "red";
      break;
    case "AUTHORISED":
      colorClass = "yellow";
      labelStatus = "AWAITING PAYMENT";
      break;
    default:
      colorClass = "gray";
      labelStatus = status;
  }

  labelStatus = labelStatus.charAt(0).toUpperCase() + labelStatus.slice(1).toLowerCase();

  return { colorClass, labelStatus };
};
