import classnames from "classnames";
import React, { ReactNode } from "react";
import { Field } from "formik";
import "./GmMultiCheckbox.scss";
import { Checkbox } from "primereact/checkbox";
import PopupIcon from "~/components/Toaster/PopupIcon";

export interface IOption {
  id: number;
  name: string;
  code: string;
  options_name?: string;
}

interface IGmMultiCheckbox {
  id: string;
  label?: string;
  options: any;
  formik?: any;
  noField?: boolean;
  className?: string;
  disabled?: boolean;
  info?: string | ReactNode;
}
export default function GmMultiCheckbox(props: IGmMultiCheckbox) {
  let meta, field;
  if (props.noField && props.formik) {
    meta = props.formik.getFieldMeta(props.id);
    field = props.formik.getFieldProps(props.id);
  }

  const handleToggle = (option: IOption) => {
    const selected = [...props.formik.values[props.id]];

    const index = selected.indexOf(option.id);
    if (index !== -1) {
      selected.splice(index, 1);
    } else {
      selected.push(option.id);
    }

    props.formik.setFieldValue(props.id, selected);
  };

  return props.noField && meta ? (
    <div
      className={classnames("GmMultiCheckbox", props.className, {
        error: meta.touched && meta.error,
      })}
    >
      {props.label && (
        <label htmlFor={props.id}>
          {props.label} {props.info && <PopupIcon content={props.info} />}
        </label>
      )}
      {props.options.map((option: IOption, index: number) => {
        return (
          <div key={index} className="field-checkbox">
            <Checkbox
              inputId={`${props.id}-${index.toString()}`}
              name={props.id}
              onChange={() => handleToggle(option)}
              checked={props.formik.values[props.id].includes(option.id)}
            />
            <label htmlFor={option.name}>{option.options_name ? option.options_name : option.name}</label>
          </div>
        );
      })}
      {meta.touched && meta.error && <sub>{meta.error}</sub>}
    </div>
  ) : (
    <Field name={props.id}>
      {({ field, meta }: any) => (
        <>
          {props.label && <label htmlFor={props.id}>{props.label}</label>}
          {props.options.map((option: IOption, index: number) => {
            return (
              <div key={index} className="field-checkbox">
                <Checkbox
                  inputId={index.toString()}
                  name={props.id}
                  onChange={() => handleToggle(option)}
                  checked={props.formik.values[props.id].includes(option.id)}
                  {...field}
                />
                <label htmlFor={option.name}>{option.options_name ? option.options_name : option.name}</label>
              </div>
            );
          })}
          {meta.touched && meta.error && <sub>{meta.error}</sub>}
        </>
      )}
    </Field>
  );
}
