import cn from "classnames";
import moment from "moment";
import { Button } from "primereact/button";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import FireSimple from "~/assets/FireSimple";
import LogoIcon from "~/assets/genie-logo.svg";
import TreeL from "~/assets/TreeL.svg";
import TreeM from "~/assets/TreeM.svg";
import TreeS from "~/assets/TreeS.svg";
import TreeXS from "~/assets/TreeXS.svg";
import { modalActions } from "~/store/modal";
import { usersActions } from "~/store/user";
import { extractFileName, makeUniqUrl } from "~/utils";
import "./ProfileForm.scss";

const ProfileForm = () => {
  const dispatch = useDispatch();
  const authenticatedUser = useSelector((state: any) => state.users.authenticatedUser);
  const { options: globalOptions } = useSelector((state: any) => state.options);

  const { OPEN_POPUP_BUTTON_EMOJI, OPEN_POPUP_BUTTON_TEXT } = globalOptions?.refer_talent_popup_settings || {
    OPEN_POPUP_BUTTON_EMOJI: <FireSimple />,
    OPEN_POPUP_BUTTON_TEXT: "Refer a Connection",
  };

  const getAvailabilityStatus = () => {
    const isTalentAvailable = authenticatedUser?.talent?.is_available;
    if (!isTalentAvailable) {
      return "Not available";
    }
    const startFrom = authenticatedUser?.talent?.start_from;
    if (moment(startFrom).isAfter(new Date(), "day")) {
      return `Available from ${moment(startFrom).format("MMM D, YYYY")}`;
    }
    return "Available now";
  };

  function onLogout() {
    dispatch(
      usersActions.logoutUser(() => {
        dispatch(modalActions.closeModal());
      })
    );
  }

  function onAddImage(blob: File) {
    dispatch(usersActions.updateImageProfile(blob));
    dispatch(modalActions.closeModal());
  }

  let profile_image: string = "https://static-prod.meetgenie.co/logo.png";

  if (authenticatedUser.talent && authenticatedUser.talent.profile_image) {
    profile_image = authenticatedUser.talent.profile_image;
  }

  const noProfileImage = extractFileName(profile_image) === "logo.png";

  const plantedTreesNumber = authenticatedUser?.planted_trees_number;

  const getImageData = () => {
    switch (true) {
      case plantedTreesNumber > 90:
        return {
          source: TreeL,
          count: getImageCount(90),
        };
      case plantedTreesNumber > 60:
        return {
          source: TreeM,
          count: getImageCount(60),
        };
      case plantedTreesNumber > 30:
        return {
          source: TreeS,
          count: getImageCount(30),
        };
      default:
        return {
          source: TreeXS,
          count: getImageCount(0),
        };
    }
  };

  const getImageCount = (subtractNumber: number) => {
    const count = Math.ceil((plantedTreesNumber - subtractNumber) / 10);
    return count > 3 ? 3 : count;
  };

  return (
    <>
      {authenticatedUser && (
        <div className="profile-card">
          <i
            className="icon-edit"
            onClick={() => {
              dispatch(
                modalActions.openModal("IMAGE_CROP_MODAL", {
                  side: "left",
                  onSubmit: onAddImage,
                  noProfileImage,
                })
              );
            }}
          />
          <div className={cn("profile-image", { "is-default": noProfileImage })}>
            {noProfileImage ? (
              <>
                <h3>Upload an image of your best work to help stand out to client</h3>
                <h4>
                  (<span>or</span> the brands logo if no work was made)
                </h4>
                <img src={LogoIcon} />
              </>
            ) : (
              <img
                alt="User card"
                src={makeUniqUrl(profile_image)}
                onClick={() => {
                  dispatch(
                    modalActions.openModal("IMAGE_CROP_MODAL", {
                      side: "left",
                      onSubmit: onAddImage,
                    })
                  );
                }}
              />
            )}
          </div>
          <div>
            {authenticatedUser.headline && <h1>{authenticatedUser.headline}</h1>}
            {authenticatedUser.subhead && <p>{authenticatedUser.subhead}</p>}
          </div>
        </div>
      )}
      <ul className="profile-list availability">
        <li
          className="screen"
          onClick={() => {
            dispatch(
              modalActions.openModal("AVAILABILITY_MODAL", {
                _onlyOne: true,
                side: "left",
              })
            );
          }}
        >
          <span className="calendar-icon custom-icon"></span>
          <p>
            <span>Availability</span>
            <span className="status">{getAvailabilityStatus()}</span>
          </p>
        </li>
      </ul>
      <ul className="profile-list">
        <li
          className="screen"
          onClick={() =>
            dispatch(
              modalActions.openModal("USER_PROFILE_MODAL", {
                _onlyOne: true,
                side: "left",
              })
            )
          }
        >
          <i className="icon-user-outline" />
          Profile
        </li>
        <li
          className="screen"
          onClick={() =>
            dispatch(
              modalActions.openModal("PREFERENCES_MODAL", {
                _onlyOne: true,
                side: "left",
                type: "BRIEF_PREFERENCES",
              })
            )
          }
        >
          <i className="icon-sliders-h custom-icon" />
          Brief Preferences
        </li>
        <li
          className="screen"
          onClick={() =>
            dispatch(
              modalActions.openModal("PREFERENCES_MODAL", {
                _onlyOne: true,
                side: "left",
                type: "NOTIFICATION_PREFERENCES",
              })
            )
          }
        >
          <i className="icon-bell"></i>
          Notifications
        </li>
        {!!authenticatedUser?.talent?.invoices_available && (
          <li
            className="screen"
            onClick={() =>
              dispatch(
                modalActions.openModal("INVOICES_MODAL", {
                  _onlyOne: true,
                  side: "left",
                  type: "INVOICES_MODAL",
                })
              )
            }
          >
            <i className="icon-files custom-icon"></i>
            My Invoices
          </li>
        )}
      </ul>
      <div className="profile-list refer-connection">
        <Button
          className="refer-button"
          onClick={() =>
            dispatch(
              modalActions.openModal("REFER_TALENT_MODAL", {
                _onlyOne: true,
              })
            )
          }
        >
          <p>
            <span className="refer-button-emoji">{OPEN_POPUP_BUTTON_EMOJI}</span>
            {OPEN_POPUP_BUTTON_TEXT}
          </p>
        </Button>
      </div>
      {plantedTreesNumber > 0 && (
        <div className="profile-list trees">
          <Button
            className="planted-trees-button"
            onClick={() => {
              dispatch(
                modalActions.openModal("PLANTED_TREES_MODAL", {
                  _onlyOne: false,
                  plantedTreesNumber,
                  imageData: getImageData(),
                })
              );
            }}
          >
            <div className="tree-image-wrap">
              <img src={getImageData().source} />
            </div>
            <p>Trees planted by you:</p>
            <p className="trees-amount">{plantedTreesNumber}</p>
          </Button>
        </div>
      )}
      <ul className="profile-list logout">
        <li onClick={onLogout}>Logout</li>
      </ul>
    </>
  );
};

export default ProfileForm;
