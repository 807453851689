import { ReactNode } from "react";
import { Message } from "primereact/messages";
import { AnyAction } from "redux";
// ------------------------------------
// Constants
// ------------------------------------
export const SET_ALERTS = "SET_ALERTS";
export const SET_TOASTS = "SET_TOASTS";
export const SET_POPUP = "SET_POPUP";

export const TOAST_SUCCESS_MESSAGE = "That’s all updated for you now 👌🏾";
export const TOAST_ERROR_MESSAGE = "It's not you, it's me..... can you try that again pls?";

export const TOAST_IMAGE_SUCCESS = "Image updated for you now 👌🏾";

// ------------------------------------
// Action Creators
// ------------------------------------
export type Popup = {
  content: string | ReactNode;
  buttons?: {
    text: string;
    callback?: Function;
  }[];
};
export type MessageWithContent = Message & { content: JSX.Element };
export const setAlerts = (alerts: MessageWithContent | MessageWithContent[]) => ({
  type: SET_ALERTS,
  payload: alerts,
});
export const setToasts = (toasts: Message | Message[]) => ({
  type: SET_TOASTS,
  payload: toasts,
});

export const setPopup = (popup?: Popup) => ({
  type: SET_POPUP,
  payload: popup,
});

// ------------------------------------
// Interface
// ------------------------------------
export interface IToastsState {
  alerts: MessageWithContent[];
  toasts: Message[];
  popup: Popup | undefined;
}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState: IToastsState = {
  alerts: [],
  toasts: [],
  popup: undefined,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action: AnyAction): IToastsState => {
  switch (action.type) {
    case SET_ALERTS:
      return {
        ...state,
        alerts: action.payload.length !== undefined ? action.payload : [...state.alerts, action.payload],
      };
    case SET_TOASTS:
      return {
        ...state,
        toasts: action.payload.length !== undefined ? action.payload : [...state.toasts, action.payload],
      };
    case SET_POPUP:
      return {
        ...state,
        popup: action.payload,
      };
    default:
      return state;
  }
};
