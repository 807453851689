import React, { useState } from "react";
import { useSelector } from "react-redux";
import Loader from "~/components/common/Loader/Loader";
import EditTalentResponseForm from "~/components/forms/EditTalentResponseForm/EditTalentResponseForm";
import Modal from "../AbstractModal/AbstractModal";

export default function EditTalentResponseModal({
  side,
  reason,
  rate,
  noticePeriod,
  briefType,
}: {
  side: "right" | "left";
  reason: string;
  rate: string;
  noticePeriod: string;
  briefType: string;
}) {
  const isFetching: boolean = useSelector((state: any) => state.users.isFetching);
  const [submitTrigger, setSubmitTrigger] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);

  return (
    <Modal
      className="ResponsePreviewEdit"
      buttons={[
        {
          className: "primary",
          text: isFetching ? <Loader /> : edit ? "Preview" : "Update",
          onClick: () => setSubmitTrigger(true),
        },
      ]}
      side={side}
      title={<h1>Response preview</h1>}
      headerRightButton={<button className="invisible" type="button"></button>}
    >
      <EditTalentResponseForm
        rate={rate}
        reason={reason}
        noticePeriod={noticePeriod}
        briefType={briefType}
        submitTrigger={submitTrigger}
        setSubmitTrigger={setSubmitTrigger}
        edit={edit}
        setEdit={setEdit}
      />
    </Modal>
  );
}
